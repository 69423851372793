
import React, { useState, useEffect } from 'react';
import InputBox from '../../Components/input_box';
import TextArea from '../../Components/text_area';
import Sidebar from '../../Components/main_navbar';
import '../../Components/multi_select.css'; 
import { PostRequestUpload } from '../../../Controller/post_request';
import FilePicker from '../../Components/file_picker';
import Button from '../../Components/button'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { showToast } from '../../Components/toast'; // Import the showToast function
import { categoryMapping } from '../../Components/category';
import Modal from '../../Components/pdf_opener';




const PostRequest = () => {
  const [productName, setProductName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [tradeRollType, setTradeRollType] = useState('');
  const [detailedRequirement, setDetailedRequirement] = useState('');
  const [uploadedFileURL, setUploadedFileURL] = useState(null); 
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate(); 
  const location = useLocation(); 
  const [categoryId, setCategoryId] = useState(null);  // To store the categoryId
  const [isFromHome, setIsFromHome] = useState(false);
  // Handle navigation from the Hexgrid and prepopulate fields
  const [isFromHomePage, setIsFromHomePage] = useState(false);
  const [isLockedRole, setIsLockedRole] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
const [pdfUrl, setPdfUrl] = useState(null);

 
  
  // Redirect to home page when the browser's back button is pressed
  useEffect(() => {
    const handlePopState = () => {
      navigate('/home'); // Redirect to home page
    };

    // Add event listener
    window.addEventListener('popstate', handlePopState);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    if (location.state) {
      const { selectedTradeRole, categoryId, formData, fromHome } = location.state;

      if (formData) {
        setProductName(formData.productName || '');
        setQuantity(formData.quantity || '');
        setDetailedRequirement(formData.detailedRequirement || '');
        setUploadedFileURL(formData.uploadedFileURL || null);
        setIsChecked(formData.isChecked || false);
      }
      if (selectedTradeRole) {
        setTradeRollType(selectedTradeRole);  // Prepopulate with selected role
            // Mark that the user came from the Home page
      }
      if (categoryId) {
        setCategoryId(categoryId);
      }
     // Determine if navigation is from home page
      // This will be true when coming from home page with Investor/Buyer selection
        // Track if we came from home page
        setIsFromHome(fromHome || false);

        // Only lock if:
        // 1. Coming from home page AND
        // 2. It's Investor/Buyer
        const isInvestorOrBuyer = categoryId === 7 || categoryId === 8;
        const shouldLock = fromHome && isInvestorOrBuyer;
        setIsLocked(shouldLock);
    }
  }, [location.state]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleViewPdf = (url) => {
    setPdfUrl(url);
    setIsPdfModalOpen(true);
  };
  

  const handleFileChange = (label, url) => {
    setUploadedFileURL(url); 
  };

  const validateForm = () => {
    const errors = {};
    if (!productName.trim()) errors.productName = 'Enter product name!';
    // if (!quantity.trim()) errors.quantity = 'Enter the required quantity!';
      // Minimum length check for Required Quantity
  if (!quantity.trim()) {
    errors.quantity = 'Enter the required quantity!';
  } else if (quantity.trim().length < 30) {
    errors.quantity = 'Quantity must be at least 30 characters long!';
  } 
    
    if (!tradeRollType.trim()) errors.tradeRollType = 'Choose the trade role type!';
    // if (!detailedRequirement.trim()) errors.detailedRequirement = 'Provide more details regarding your requirement!';
    if (!detailedRequirement.trim()) {
      errors.detailedRequirement = 'Provide more details regarding your requirement!';
    } else if (detailedRequirement.trim().length < 50) {
      errors.detailedRequirement = 'Requirement details must be at least 50 characters long!';
    }
  
    
    if (!uploadedFileURL) {
      errors.uploadedFileURL = 'Please upload a PDF file';
      // showToast('Please upload a PDF file', 'error');
    }
  
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      const response = await PostRequestUpload(
        productName,
        quantity,
        categoryId,
        tradeRollType,
        detailedRequirement,
        uploadedFileURL,
        isChecked
      );

      console.log('Post request response:', response);

      if (response.status === 201) {
        const newPostData = response.data;
        navigate('/posts', { state: { newPostData } });
      } else {
        console.error('Failed to submit item. Response:', response);
      }
    } catch (error) {
      console.error('Error submitting item:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleTradeRollTypeClick = () => {
    if (!isLocked) {
      navigate('/hex-grid', {
        state: {
          formData: {
            productName,
            quantity,
            detailedRequirement,
            uploadedFileURL,
            isChecked
          }
        }
      });
    }
  };

  // const handleQuantityChange = (e) => {
  //   const input = e.target.value;
  //   if (/^\d*$/.test(input)) {
  //     setQuantity(input);
  //   }
  // };


  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };
  const handleOpenDocument = (url) => {
    setPdfUrl(url); // Set the PDF URL
    setIsPdfModalOpen(true); // Open modal
  };


  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins overflow-y-auto">
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-black font-semibold text-xl">Post Request</h1>
        </div>
      </div>
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
      <Sidebar />
      <div className="lg:w-4/5 mx-5 lg:ml-5 justify-center">
        <div className="flex-1 p-2 max-w-2xl mx-auto mt-5 overflow-y-auto">
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]">Product/Service Name</label>
              <InputBox 
                borderRadius='1px'
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                maxLength={30}
              />
              {validationErrors.productName && (
                <p className="text-red-500 text-xs">{validationErrors.productName}</p>
              )}
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]"> Required Quantity</label>
              <InputBox 
                borderRadius='1px'
                value={quantity}
                onChange={handleQuantityChange} 
              />
              {validationErrors.quantity && (
                <p className="text-red-500 text-xs">{validationErrors.quantity}</p>
              )}
            </div>
            <div className="mb-2">
              <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]">Select the type of trade role</label>
              <InputBox 
          borderRadius='1px'
          value={tradeRollType}
          readOnly={true}
          onClick={handleTradeRollTypeClick}
          style={{ 
            cursor: isLocked ? 'default' : 'pointer',
            backgroundColor: isLocked ? '#f5f5f5' : 'white'
          }}
        />
              {validationErrors.tradeRollType && (
                <p className="text-red-500 text-xs">{validationErrors.tradeRollType}</p>
              )}
            </div>

            <div className="mb-1 relative">
              <label className="block mb-2 text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]"> Requirement in detail</label>
              <TextArea
                value={detailedRequirement}
                onChange={(e) => setDetailedRequirement(e.target.value)}
                placeholder="Describe your detailed requirements here..."
                className="w-full p-3 border border-[#A2A2A2] rounded-lg h-40"
                maxLength={250}
              />
              {validationErrors.detailedRequirement && (
                <p className="text-red-500 text-xs">{validationErrors.detailedRequirement}</p>
              )}
            </div>

            <label className="block text-[#4A4A4A] font-poppins lg:text-[14px] text-[12px]">Requirement pdf</label>
            <FilePicker 
              // file={uploadedFileURL}
              // onFileChange={handleFileChange}
              label="Upload Document"
              file={uploadedFileURL}
              onFileChange={handleFileChange}
              openDocument={handleOpenDocument} // Pass the function to open document
              width="w-full"
              height="h-20"
            />
            {validationErrors.uploadedFileURL && (
              <p className="text-red-500 text-xs">{validationErrors.uploadedFileURL}</p>
            )}

            <div className="flex flex-wrap items-start mt-12">
              <input 
                type="checkbox" 
                value="International" 
                onChange={handleCheckboxChange}
                className="form-checkbox text-red-500"
                style={{
                  minWidth: '1.5rem',
                  minHeight: '1.5rem',
                  width: '1.5rem',
                  height: '1.5rem',
                  flexShrink: 0,
                  marginTop: '0.1rem'
                }}
              />
              <label className="ml-2 lg:text-[14px] text-[12px] text-[#4A4A4A] flex-1">
                Agree to receive notifications and messages against this request.
              </label>
            </div>

            <div className="text-center w-full mt-7">
              <Button
                onClick={(event) => handleSubmit(event)} 
                text="Post"
                borderColor="border-transparent"
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                loading={loading} 
              />
            </div>
          </form>
        </div>

        {isPdfModalOpen && (
  <Modal
    isOpen={isPdfModalOpen}
    onClose={() => setIsPdfModalOpen(false)}
    title="File"
  >
    <iframe src={pdfUrl} className="w-full h-full" title="PDF Document" />
  </Modal>
)}

        {/* <Modal isOpen={isPdfModalOpen} onClose={() => setIsPdfModalOpen(false)} title="Requirement PDF">
  <iframe src={pdfUrl} className="w-full h-full" title="Requirement PDF" />
</Modal> */}

      </div>
    </div>
  );
};

export default PostRequest;

