import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../Components/main_navbar';
import FeedbackCard from '../Components/feedback_c';
import Tab from '../Components/tab';
import FeedbackSubmitted from '../Components/feedback_submitted';
import Button from '../Components/button';
import TextArea from '../Components/text_area';
import ReportContainer from '../Components/report';
import DeletePopup from '../Components/delete_pop_up';
import { getSpecificUserFeedback, getFeedback, uploadFeedback, deleteFeedback } from '../../Controller/feedback';
import { FaSpinner } from 'react-icons/fa';
import { getFeedbackById } from '../../Controller/feedback';
import { FaEllipsisV } from 'react-icons/fa'; // Import the FaEllipsisV icon
import { updateFeedback } from '../../Controller/feedback';
import LoaderSpinner from '../Components/loader_spinner';
import { showToast } from '../Components/toast';
import feedback_modal from '../Components/feedback_modal';

const FeedbackView = () => {
    const { uid } = useParams(); // Get feedback uid from the URL params
    const isOwnerView = !uid; // If uid is not present, it is the owner view
    const [isEditing, setIsEditing] = useState(false);
    const [editingFeedback, setEditingFeedback] = useState(null);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [activeTab, setActiveTab] = useState('received');
    const [showForm, setShowForm] = useState(false);
    const [feedbackText, setFeedbackText] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showMobileAlert, setShowMobileAlert] = useState(false);
    const [showMobileFeedbackForm, setShowMobileFeedbackForm] = useState(false);
    const [feedbackList, setFeedbackList] = useState([]); // List from GET request
    const [newlyAddedFeedback, setNewlyAddedFeedback] = useState([]); // List for newly added feedback
    const [submittedFeedbackList, setSubmittedFeedbackList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [feedbackToDelete, setFeedbackToDelete] = useState(null);
    const [expandedFeedbackId, setExpandedFeedbackId] = useState(null); // Track expanded feedback for "more" link
    const [showMenu, setShowMenu] = useState(false); // State to control the visibility of the ellipsis menu
    const { companyUid } = useParams();
    const [companiesFeedback, setCompaniesFeedback] = useState([]); // State to store feedback from companies
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);


    const navigate = useNavigate();
    const [skip, setSkip] = useState(0);
    const limit = 10;
    const company_uid = localStorage.getItem('company_uid'); // Fetch company_uid from localStorage

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            console.log("Is Mobile:", window.innerWidth < 768);  // Check if mobile view is detected
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    useEffect(() => {
        // Function to close the menu when clicking outside
        const handleClickOutside = (event) => {
            if (event.target.closest('.relative')) return; // Check if the click is inside the menu container
            setShowMenu(false);
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    
    // Fetch feedback for owner or based on uid
    useEffect(() => {
        const fetchFeedback = async () => {
            setLoading(true);
            setSelectedFeedback(null);  // Reset selected feedback when tab changes
            try {
                let response;
                const otherProfile = !!uid;  // true if uid is present, false otherwise,
    
                if (activeTab === 'received') {
                    setFeedbackList([]);  // Reset the feedback list
                    response = await getFeedback(company_uid, skip, limit,otherProfile);
                    console.log('received',response)
                    if (response && response.data) {
                        setFeedbackList(response.data.filter(item => item.feedback_to === company_uid));
                    }
    
                } else if (activeTab === 'submitted') {
                    setSubmittedFeedbackList([]);  // Reset the submitted feedback list
                    response = await getSpecificUserFeedback(skip, limit);
                    console.log('submited',response)

                    if (response && response.data) {
                        setSubmittedFeedbackList(response.data.filter(item => item.feedback_by === company_uid));
                    }
                }
    
                if (response?.data.length < limit) {
                    setHasMore(false); // No more data to fetch
                }
    
            } catch (error) {
                console.error("Failed to fetch feedback", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchFeedback();
    }, [activeTab, skip, limit, company_uid]);
    
    
    
    // Fetch existing feedback for the selected company UID when the page loads
    useEffect(() => {
        const fetchExistingFeedback = async () => {
            try {
                const existingCompanyUid = uid || company_uid;
                if (!existingCompanyUid) {
                    console.error('Company UID not found');
                    return;
                }
    
                const response = await getFeedback(existingCompanyUid);
                console.log('existing', response);
    
                if (response && response.data) {
                    const formattedFeedback = response.data.map(item => ({
                        id: item.id,
                        description: item.description,
                        created_on: item.created_on,
                        feedback_by: item.feedback_by,
                        feedback_to: item.feedback_to,
                        // For the feedbacker (person giving feedback)
                        feedbacker_company_id: item.feedbacker_company_id,
                        feedbacker_company_name: item.feedbacker_company_name,
                        feedbacker_profile_image: item.feedbacker_profile_image || '/defaultProfileImage.png',
                        // For the company receiving feedback
                        feedbacking_company_id: item.feedbacking_company_id || item.feedback_to,
                        feedbacking_company_name: item.feedbacking_company_name,
                        feedbacking_profile_image: item.feedbacking_profile_image,
                        // This flag is set to true for existing feedback
                        isExistingFeedback: true 
                    }));
    
                    setFeedbackList(formattedFeedback);
                }
            } catch (error) {
                console.error('Failed to fetch existing feedback:', error);
            }
        };
    
        fetchExistingFeedback();
    }, [uid, company_uid]);
    
    

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        console.log("showMobileFeedbackForm:", showMobileFeedbackForm);  // Check if this turns true on mobile
        console.log("showForm:", showForm);  // Check if this turns true on desktop
        console.log("isEditing:", isEditing);  // Check if editing mode is enabled
        console.log("feedbackText:", feedbackText);  // Verify the feedback description is set correctly
    }, [showMobileFeedbackForm, showForm, isEditing, feedbackText]);
    

    // Function to fetch feedback details by ID when 'more' is clicked
    const handleViewFeedback = async (feedback) => {
        console.log('Viewing feedback:', feedback);
    
        try {
            let feedbackDetails;
            if (feedback.id) {
                // For received feedbacks that already have an ID
                feedbackDetails = await getFeedbackById(feedback.id);
            } else {
                // For company-specific feedbacks that might not have an ID
                feedbackDetails = {
                    id: feedback.feedback_by, // Use feedback_by as a unique identifier
                    feedbacker_profile_image: feedback.profilePicUrl || feedback.feedbacker_profile_image,
                    feedbacker_company_name: feedback.name || feedback.feedbacker_company_name,
                    description: feedback.description,
                    created_on: feedback.created_on,
                    feedback_by: feedback.feedback_by,
                    feedback_to: feedback.feedback_to,
                };
            }
    
            if (feedbackDetails) {
                setSelectedFeedback(feedbackDetails);
                if (isMobile) {
                    setShowMobileAlert(true);
                }
            } else {
                console.error('No feedback data returned');
            }
        } catch (error) {
            console.error('Error fetching feedback:', error);
        }
    };
    

    const handleUpdateFeedback = async () => {
        if (!editingFeedback || !editingFeedback.id) {
            console.error('No feedback selected for editing');
            return;
        }
        setIsSubmitting(true);
        try {
            const response = await updateFeedback(editingFeedback.id, feedbackText);
            if (response.status === 202) {
                console.log('Feedback updated successfully');
                // Update the feedback in the list
                setSubmittedFeedbackList(prevList =>
                    prevList.map(f => f.id === editingFeedback.id ? {...f, description: feedbackText} : f)
                );
                setShowForm(false);
                setIsEditing(false);
                setEditingFeedback(null);
                setFeedbackText('');
                setSelectedFeedback(null); // Reset selected feedback after update
            } else {
                console.error('Failed to update feedback, status code:', response.status);
            }
        } catch (error) {
            console.error("Failed to update feedback", error);
        } finally {
            setIsSubmitting(false);
        }
    };
    const getCompanyName = (feedback) => {
        if (activeTab === 'submitted') {
            return feedback.feedbacking_company_name || 'No Name';

        } else {
            return feedback.feedbacker_company_name || 'No Name';

        }
    };
    const getProfileImage = (feedback) => {
        if (activeTab === 'submitted') {
            return feedback.feedbacking_profile_image || '/path_to_default_image';
        } else {
            return feedback.feedbacker_profile_image || '/path_to_default_image';
        }
    };


    const handleProfileNavigation = (feedback) => {
        let companyId;
        
        // Case 1: For owner view - submitted feedbacks
        if (activeTab === 'submitted') {
            companyId = feedback.feedbacking_company_id;
        }
        // Case 2: For owner view - received feedbacks
        else if (activeTab === 'received') {
            companyId = feedback.feedbacker_company_id;
        }
        // Case 3: For existing feedbacks against a company
        else if (feedback.feedback_by) {
            companyId = feedback.feedback_by;
        }
        // Fallback for any other case
        else {
            companyId = feedback.feedbacker_company_id || feedback.feedback_by;
        }
    
        if (companyId) {
            navigate(`/profile/${companyId}`);
        } else {
            console.error("No valid company ID found for navigation", feedback);
        }
    };

    
    const handleMobileDelete = () => {
        if (!selectedFeedback || !selectedFeedback.id) {
            console.error("No feedback selected for deletion");
            return;
        }
        setFeedbackToDelete(selectedFeedback.id);
        setShowDeletePopup(true);
        setShowMobileAlert(false);  // Close the view alert when deleting
    };
    const handleAddFeedback = (newFeedback) => {
        setFeedbackList(prevFeedbackList => [...prevFeedbackList, newFeedback]);
    };
    const handleAddFeedbackClick = () => {
        console.log("Add Feedback Clicked");
        setIsEditing(false);
        setEditingFeedback(null);
        setFeedbackText('');
        setSelectedFeedback(null); // Reset the detailed view when adding new feedback
    
        if (isMobile) {
            console.log("Setting mobile form to show");
            setShowMobileFeedbackForm(true);
            setShowMobileAlert(false); // Make sure alert is closed
        } else {
            setShowForm(true);
        }
    };
    

    const handleEditClick = (feedback) => {
        console.log("Edit clicked for feedback:", feedback);
        setIsEditing(true);
        setEditingFeedback(feedback);
        setFeedbackText(feedback.description);
        
        if (isMobile) {
            console.log("Setting mobile form for editing");
            setShowMobileAlert(false);
            setShowMobileFeedbackForm(true);
        } else {
            setShowForm(true);
        }
    };
    
const handleDeleteClick = () => {
    setFeedbackToDelete(selectedFeedback.id); // Use the ID from selectedFeedback
    setShowDeletePopup(true); // Show the confirmation popup
  };
  

  


  

const handleMobileEdit = (feedback) => {
    console.log('Mobile edit triggered for feedback:', feedback);
    handleEditClick(feedback);
};

useEffect(() => {
    console.log("State changed - isMobile:", isMobile);
    console.log("State changed - showMobileFeedbackForm:", showMobileFeedbackForm);
    console.log("State changed - isEditing:", isEditing);
    console.log("State changed - feedbackText:", feedbackText);
}, [isMobile, showMobileFeedbackForm, isEditing, feedbackText]);
  

const handleFeedbackChange = (event) => {
    console.log("Current input value:", event.target.value);
    setFeedbackText(event.target.value);
};

useEffect(() => {
    console.log("feedbackText updated:", feedbackText);
}, [feedbackText]);

   
    
const handleSubmitFeedback = async (submittedText) => {
    if (!submittedText.trim()) return;
    
    setIsSubmitting(true);

    try {
        let response;
        if (isEditing && editingFeedback) {
            // Handle update
            response = await updateFeedback(editingFeedback.id, submittedText);
            if (response.status === 202) {
                // Update the feedback in the appropriate list
                const updateFeedbackInList = (list) => 
                    list.map(f => f.id === editingFeedback.id 
                        ? {...f, description: submittedText} 
                        : f
                    );
                
                setFeedbackList(updateFeedbackInList);
                setSubmittedFeedbackList(updateFeedbackInList);
                
                // Reset states
                setSelectedFeedback(null);
                showToast("Feedback updated successfully", "success");
            }
        } else {
            // Handle new submission
            response = await uploadFeedback(uid, submittedText);
            if (response.status === 201) {
                const newFeedback = {
                    id: response.data.data[0].id,
                    feedbacker_profile_image: response.data.data[0].feedbacker_profile_image || "/defaultProfileImage.png",
                    feedbacker_company_name: response.data.data[0].feedbacker_company_name || "Anonymous",
                    description: response.data.data[0].description,
                    created_on: response.data.data[0].created_on,
                    feedback_by: response.data.data[0].feedback_by,
                    feedback_to: response.data.data[0].feedback_to,
                    feedbacker_company_id: response.data.data[0].feedbacker_company_id,
                    feedbacking_company_id: response.data.data[0].feedbacking_company_id,
                };
                setFeedbackList(prevList => [newFeedback, ...prevList]);
            }
        }

        // Reset form state regardless of operation type
        if (isMobile) {
            setShowMobileFeedbackForm(false);
        } else {
            setShowForm(false);
        }
        setFeedbackText('');
        setIsEditing(false);
        setEditingFeedback(null);
        
    } catch (error) {
        console.error("Error submitting feedback", error);
    } finally {
        setIsSubmitting(false);
    }
};

    
    
    
    
        
    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting feedback with ID:', feedbackToDelete);
    
            const response = await deleteFeedback(feedbackToDelete);
            if (response.status === 202) {
                console.log('Feedback deleted successfully');
                setFeedbackList(feedbackList.filter(f => f.id !== feedbackToDelete));
                setSubmittedFeedbackList(submittedFeedbackList.filter(f => f.id !== feedbackToDelete));
                setShowDeletePopup(false);
                setFeedbackToDelete(null);
                setSelectedFeedback(null); // Clear selected feedback
                if (isMobile) {
                    setShowMobileAlert(false); // Close mobile alert after deletion
                }
            } else {
                console.error('Failed to delete feedback, status code:', response.status);
            }
        } catch (error) {
            console.error(`Error deleting feedback with id ${feedbackToDelete}:`, error);
        }
    };

    
   
    

    


    const handleMoreClick = (feedback) => {
        if (isMobile) {
            setSelectedFeedback(feedback);
            setShowMobileAlert(true);
        } else {
            setSelectedFeedback(feedback);
        }
    };

    console.log("Rendering FeedbackView"); 

    
    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
    };

    const handleCloseDeletePopup = () => {
        setShowDeletePopup(false);
        setFeedbackToDelete(null);
    };

    const handleLoadMore = () => {
        setSkip(prev => prev + limit);
    };

    const handleExpand = (feedbackId) => {
        setExpandedFeedbackId(feedbackId === expandedFeedbackId ? null : feedbackId);
    };

    const tabLabels = {
        received: 'Feedbacks Received',
        submitted: 'Feedback Submitted',
    };

    const handleBackClick = () => {
        navigate('/my_subscription');
    };

    const closeMobileAlert = () => {
        setShowMobileAlert(false);
    };

    const handleMobileMoreClick = (feedback) => {
        setSelectedFeedback(feedback);
        setShowMobileAlert(true);
    };
    const isSubmittedFeedback = (feedback) => {
        console.log('Checking if feedback is submitted for feedback:', feedback);
        console.log('Company UID:', company_uid);
        return feedback.feedback_by === company_uid;
    };
    

    const closeMobileFeedbackForm = () => {
        setShowMobileFeedbackForm(false);
        setFeedbackText('');
    };
    const MobileFeedbackForm = ({ feedbackText, handleSubmitFeedback, onClose, isEditing, isSubmitting }) => {
        const [localFeedbackText, setLocalFeedbackText] = useState(feedbackText);
        
        // Update local state when feedbackText prop changes
        useEffect(() => {
            setLocalFeedbackText(feedbackText);
        }, [feedbackText]);
    
        const handleLocalChange = (event) => {
            setLocalFeedbackText(event.target.value);
        };
    
        const handleSubmit = () => {
            if (localFeedbackText.trim()) {
                handleSubmitFeedback(localFeedbackText);
            }
        };
    
        return (
            <div className="fixed inset-0 z-[9999] bg-black bg-opacity-50 flex items-center justify-center">
                <div className="relative bg-white rounded-lg p-4 w-11/12 max-w-sm mx-4">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold">
                            {isEditing ? "Edit Your Feedback" : "Add Your Feedback"}
                        </h2>
                        <button 
                            onClick={onClose}
                            className="text-gray-500 hover:text-gray-700 text-xl"
                        >
                            ×
                        </button>
                    </div>
                    <TextArea
                        value={localFeedbackText}
                        onChange={handleLocalChange}
                        placeholder="Description"
                        className="w-full mb-4 p-2 border rounded"
                        style={{ height: '150px' }}
                        maxLength={150}
                    />
                    <div className="flex justify-end space-x-2">
                        <Button
                            text="Cancel"
                            borderColor="border-[#DB0000]"
                            textColor="text-[#DB0000]"
                            onClick={onClose}
                            disabled={isSubmitting}
                        />
                        <Button
                            text={isEditing ? "Update" : "Submit"}
                            borderColor="border-[#DB0000]"
                            textColor="text-[#FFFFFF]"
                            backgroundColor="bg-[#DB0000]"
                            onClick={handleSubmit}
                            disabled={!localFeedbackText.trim() || isSubmitting}
                            loading={isSubmitting}
                        />
                    </div>
                </div>
            </div>
        );
    };
    
    
const handleCloseMobileAlert = () => {
    setShowMobileAlert(false);
};

    return (
        <div className="flex flex-col md:flex-row w-full h-full bg-[#FBFCF8] font-poppins overflow-y-auto font-poppins">
            <Sidebar />
            <div className="flex flex-col w-full">
                <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
                    <div className="w-full flex justify-start items-center space-x-2">
                        <h1 className="text-black font-semibold font-poppins text-xl ">Feedback</h1>
                    </div>
                </div>
                <div className="border-b border-black mt-4 md:mt-[25px]"></div>
                <div className="flex flex-col md:flex-row h-full">
                    {/* Owner view section */}
                    <div className={`w-full md:w-1/3 md:border-r md:border-gray-400 overflow-auto`}>
                    {isOwnerView ? (
                            <>
                                <Tab activeTab={activeTab} setActiveTab={setActiveTab} labels={tabLabels} />
                                {/* Rendering Received Feedback */}
                                {activeTab === 'received' && (
    <div className="flex flex-col items-center justify-center p-5 md:p-9">
        <div className="w-full">
            {loading ? (
                <div className="flex justify-center items-center">
                    <FaSpinner className="animate-spin text-red-500 text-2xl" />
                </div>
            ) : (
                feedbackList.length > 0 ? (
                    feedbackList.map((feedback, index) => (
                        <FeedbackCard
                            key={index}
                            feedback={feedback}
                            onView={handleViewFeedback}
                            feedbackerProfileImage={feedback.feedbacker_profile_image}
                            showDelete={false}
                            showEdit={false}
                            isSubmitted={false} // This is received feedback
                            isExistingFeedback={true}
                            onProfileClick={() => handleProfileNavigation(feedback)}
                            className="w-full mb-4" // Ensuring spacing between feedback cards
                        />
                    ))
                ) : (
                    <div className="flex items-center justify-center min-h-[calc(100vh-200px)]">
                        <div className="text-center text-black font-poppins">
                            No Feedbacks Received
                        </div>
                    </div>
        )
      )}
    </div>
  </div>
)}

                  

                  {activeTab === 'submitted' && (
  <div className="flex flex-col items-center justify-center p-5 md:p-9">
    <div className="w-full">
      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-red-500 text-2xl" />
        </div>
      ) : (
        submittedFeedbackList.length > 0 ? (
          submittedFeedbackList.map((feedback, index) => (
            <FeedbackCard
              key={index}
              feedback={feedback}
              onView={handleViewFeedback}
              feedbackerProfileImage={feedback.feedbacker_profile_image}
              showDelete={true}
              showEdit={true}
              showEllipsis={true}
              isSubmitted={true}
              onEditClick={() => handleEditClick(feedback)}
              onDeleteClick={() => handleDeleteClick(feedback)}
              className="w-full"
            />
          ))
        ) : (
          <div className="flex items-center justify-center min-h-[calc(100vh-200px)]">
            <div className="text-center text-black font-poppins">
              No Submitted Feedbacks
            </div>
          </div>
        )
      )}
    </div>
  </div>
)}


                            </>
) : (
    // Render for feedback submission when uid is present
    <div className="flex flex-col items-center p-5 mt-5">
    <div className="feedback-button-container w-11/12 mx-auto mb-4">
        <Button
            text="Add your Feedback"
            borderColor="border-[#DB0000]"
            textColor="text-[#DB0000]"
            fontWeight="bold"
            onClick={handleAddFeedbackClick}
            className="w-full" // Ensures the button stretches to fill the container
        />
    </div>

    <div className="feedback-list w-11/12 mx-auto">
        {feedbackList.length > 0 || newlyAddedFeedback.length > 0 ? (
            <>
                {feedbackList.map((feedback, index) => (
                    <FeedbackCard
                        key={index}
                        feedback={{
                            ...feedback,
                            profilePicUrl: feedback.feedbacker_profile_image,
                            name: feedback.feedbacker_company_name,
                            description: feedback.description,
                            created_on: feedback.created_on,
                            feedback_by: feedback.feedback_by,
                            feedback_to: feedback.feedback_to,
                            feedbacker_company_id: feedback.feedbacker_company_id,
                            feedbacking_company_id: feedback.feedbacking_company_id
                        }}
                        onView={handleViewFeedback}
                        feedbackerProfileImage={feedback.feedbacker_profile_image}
                        onExpand={handleExpand}
                        isExpanded={expandedFeedbackId === feedback.id}
                        showDelete={false}
                        isSubmitted={false}
                        isExistingFeedback={true}
                        onProfileClick={() => handleProfileNavigation(feedback)}
                        className="w-full"
                    />
                ))}
                {newlyAddedFeedback.map((feedback, index) => (
                    <FeedbackCard
                        key={`new-${index}`}
                        feedback={{
                            ...feedback,
                            profilePicUrl: feedback.feedbacker_profile_image,
                            name: feedback.feedbacker_company_name,
                            description: feedback.description,
                            created_on: feedback.created_on,
                            feedback_by: feedback.feedback_by,
                            feedback_to: feedback.feedback_to,
                            feedbacker_company_id: feedback.feedbacker_company_id,
                            feedbacking_company_id: feedback.feedbacking_company_id
                        }}
                        feedbackerProfileImage={feedback.feedbacker_profile_image}
                        onView={handleViewFeedback}
                        showDelete={false}
                        isSubmitted={false}
                        isExistingFeedback={true}
                        onProfileClick={() => handleProfileNavigation(feedback)}
                        className="w-full"
                    />
                ))}
            </>
        ) : (
            <div className="flex items-center justify-center h-64 text-center text-black font-gotham">
                No Feedbacks Reported
            </div>
    )}

    {/* Show newly added feedback */}
    {newlyAddedFeedback.length > 0 && (
        <div className="text-center text-black font-gotham mt-4">
            {newlyAddedFeedback.map((feedback, index) => (
                <FeedbackCard
                    key={`new-${index}`}
                    feedback={{
                        ...feedback,
                        profilePicUrl: feedback.feedbacker_profile_image,
                        name: feedback.feedbacker_company_name,
                        description: feedback.description,
                        created_on: feedback.created_on,
                        feedback_by: feedback.feedback_by,
                        feedback_to: feedback.feedback_to,
                        feedbacker_company_id: feedback.feedbacker_company_id,
                        feedbacking_company_id: feedback.feedbacking_company_id
                    }}
                    feedbackerProfileImage={feedback.feedbacker_profile_image}
                    onView={handleViewFeedback}
                    showDelete={false}
                    isSubmitted={false}
                    isExistingFeedback={true}
                    onProfileClick={() => handleProfileNavigation(feedback)}
                    className="w-full"
                />
            ))}
        </div>
    )}
</div>
</div>

)}
                    </div>
                    

                    



                    {/* Detailed view section - Feedback form should be here */}
                    {!isMobile && showForm && (
                        <div className="md:w-2/3 lg:w-1/3 md:ml-9 p-7 h-3/4 bg-white shadow-lg rounded-lg mt-7">
                            <FeedbackForm
                                feedbackText={feedbackText}
                                handleFeedbackChange={handleFeedbackChange}
                                handleSubmitFeedback={handleSubmitFeedback}
                                isEditing={isEditing}
                                isSubmitting={isSubmitting}
                            />
                        </div>
                    )}

{!isMobile && (
    <div className="w-full md:w-2/3 lg:w-1/3 p-7 h-full">
        {!isEditing && selectedFeedback && (
            <div className="flex border p-4 rounded-md relative bg-[#F2F2F2]">
                {/* Ellipsis icon positioned at the top-right corner */}
                {activeTab === 'submitted' && isSubmittedFeedback(selectedFeedback) && (
                    <div className="absolute top-2 right-2 z-20">
                        <FaEllipsisV
                            className="cursor-pointer text-gray-500 text-xl"
                            onClick={() => setShowMenu(!showMenu)}
                        />
                    </div>
                )}

                {/* Profile image container */}
                <div className="w-12 h-12 mr-4 flex-shrink-0">
                                <img
                                    src={getProfileImage(selectedFeedback)}
                                    alt="Profile"
                                    className="rounded-full w-12 h-12 object-cover cursor-pointer"
                                    onClick={() => handleProfileNavigation(selectedFeedback)}
                                />
                            </div>

                {/* Feedback content */}
                <div className="flex-grow">
                    <div className="flex flex-col justify-between">
                        <h2 
                            className="font-poppins text-[#263238] text-lg mb-2 cursor-pointer"
                            onClick={() => handleProfileNavigation(selectedFeedback)}
                        >
                            {getCompanyName(selectedFeedback)}
                        </h2>
                        <div className="text-sm text-[#263238] mb-2">
                            {new Date(selectedFeedback.created_on).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </div>
                        <p className="font-poppins text-[#232323] text-sm">
                            {selectedFeedback.description}
                        </p>
                    </div>
                </div>

                {/* Delete and Edit menu */}
                {activeTab === 'submitted' && showMenu && (
                    <div className="absolute top-10 right-0 bg-white shadow-lg rounded-lg z-30 w-36">
                        <div className="flex flex-col py-2">
                            <div
                                onClick={() => handleDeleteClick(selectedFeedback)}
                                className="flex items-center py-2 px-4 hover:bg-gray-100 cursor-pointer text-black"
                            >
                                <img src="/Assets/bin.svg" alt="Delete Icon" className="w-5 h-5 mr-3" />
                                <span className="text-sm font-medium">Delete</span>
                            </div>
                            <hr className="border-gray-200 my-2" />
                            <div
                                onClick={() => handleEditClick(selectedFeedback)}
                                className="flex items-center py-2 px-4 hover:bg-gray-100 cursor-pointer text-black"
                            >
                                <img src="/Assets/report_icon.svg" alt="Edit Icon" className="w-5 h-5 mr-3" />
                                <span className="text-sm font-medium">Edit</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )}
    </div>
)}




{isMobile && showMobileFeedbackForm && (
    <MobileFeedbackForm
        feedbackText={feedbackText}
        handleSubmitFeedback={handleSubmitFeedback}
        onClose={() => {
            setShowMobileFeedbackForm(false);
            setIsEditing(false);
            setEditingFeedback(null);
            setFeedbackText('');
        }}
        isEditing={isEditing}
        isSubmitting={isSubmitting}
    />
)}


    {/* Render feedback form as a modal only on mobile */}
    


    



                </div>
                
            </div>
            
            {isMobile && showMobileAlert && selectedFeedback && (
                <MobileAlertWindow
                    feedback={selectedFeedback}
                    onClose={handleCloseMobileAlert}
                    onEdit={() => handleEditClick(selectedFeedback)}
                    onDelete={() => handleDeleteClick(selectedFeedback)}
                />
            )}

        
            {showConfirmation && <FeedbackSubmitted onClose={handleCloseConfirmation} />}
            {showDeletePopup && (
                <DeletePopup 
                    message="Are you sure you want to delete this feedback?" 
                    onConfirm={handleConfirmDelete} 
                    onClose={handleCloseDeletePopup} 
                />
            )}
          
        </div>
    );
};





const FeedbackForm = ({ feedbackText, handleFeedbackChange, handleSubmitFeedback, isEditing, isSubmitting }) => {
    console.log('isSubmitting in FeedbackForm:', isSubmitting); // Check if this is true when updating
    const onSubmit = () => {
        handleSubmitFeedback(feedbackText);
    };

    return (
        <div className="rounded-lg p-6 w-full h-auto mt-7">
            <p className="text-[#232323]">
                {isEditing 
                    ? "We value your feedback! Please take a moment to share your experience with us and help us improve our services to better meet your needs."
                    : "We value your feedback! Please take a moment to share your experience with us and help us improve our services to better meet your needs."}
            </p>
            <div className='mt-6'>
                <TextArea
                    value={feedbackText}
                    onChange={handleFeedbackChange}
                    placeholder="Description"
                    className="h-64"
                    style={{ height: '150px' }}
                    maxLength={260}
                />
            </div>
            <div className='mt-5 '>
            <Button
            text={isEditing ? "Update" : "Submit"}
            borderColor="border-[#DB0000]"
            textColor="text-[#FFFFFF]"
            backgroundColor="bg-[#DB0000]"
            onClick={onSubmit}
            loading={isSubmitting}
            disabled={isSubmitting || feedbackText.trim() === ''}
        />
            </div>
        </div>
    );
};


const MobileAlertWindow = ({ feedback, onClose, onEdit, onDelete }) => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const company_uid = localStorage.getItem('company_uid');
    const isSubmitted = feedback?.feedback_by === company_uid;
    
    if (!feedback) return null;

    const handleProfileNavigation = () => {
        let companyId = isSubmitted 
            ? feedback.feedbacking_company_id 
            : feedback.feedbacker_company_id;
        
        if (companyId) {
            navigate(`/profile2/${companyId}`);
            onClose();
        }
    };

    const handleEditClick = () => {
        setShowMenu(false);
        if (onEdit) {
            onEdit(feedback);
            onClose();
        }
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('backdrop')) {
            onClose();
        }
    };

    const displayName = isSubmitted 
        ? (feedback.feedbacking_company_name || feedback.name) 
        : (feedback.feedbacker_company_name || feedback.name || "Anonymous");
    const profileImage = isSubmitted 
        ? (feedback.feedbacking_profile_image || feedback.profilePicUrl) 
        : (feedback.feedbacker_profile_image || feedback.profilePicUrl || "/defaultProfileImage.png");
    const feedbackDate = feedback.created_on ? new Date(feedback.created_on) : null;
    const formattedDate = feedbackDate && !isNaN(feedbackDate)
        ? feedbackDate.toLocaleDateString('en-GB', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        })
        : 'No Date';

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center backdrop"
            onClick={handleOutsideClick}
        >
            <div className="relative bg-white p-4 w-11/12 md:w-1/3 shadow-lg rounded-md">
                <div className="flex justify-between items-start w-full">
                    <div className="flex items-center">
                        <img
                            src={profileImage}
                            alt="Profile"
                            className="rounded-full w-14 h-14 mr-4 cursor-pointer"
                            onClick={handleProfileNavigation}
                        />
                        <div>
                            <h3 
                                className="font-semibold text-base lg:text-lg cursor-pointer"
                                onClick={handleProfileNavigation}
                            >
                                {displayName}
                            </h3>
                            <p className="text-sm text-gray-500">{formattedDate}</p>
                        </div>
                    </div>

                    {isSubmitted && (
                        <div className="relative">
                            <FaEllipsisV
                                className="cursor-pointer text-gray-500 text-xl"
                                onClick={() => setShowMenu(!showMenu)}
                            />
                            {showMenu && (
                                <div className="absolute top-8 right-0 w-32 bg-white shadow-lg rounded-lg z-10">
                                    <div
                                        className="cursor-pointer flex items-center text-sm font-normal font-poppins p-2 hover:bg-gray-200"
                                        onClick={handleEditClick}
                                    >
                                        <img src="/Assets/report_icon.svg" alt="Edit Icon" className="w-4 h-4 mr-2" />
                                        Edit
                                    </div>
                                    <div
                                        className="cursor-pointer flex items-center text-sm font-normal font-poppins p-2 hover:bg-gray-200"
                                        onClick={() => {
                                            setShowMenu(false);
                                            if (onDelete) onDelete(feedback);
                                        }}
                                    >
                                        <img src="/Assets/bin.svg" alt="Delete Icon" className="w-4 h-4 mr-2" />
                                        Delete
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="mt-4 text-sm text-gray-700" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                    {feedback.description}
                </div>
            </div>
        </div>
    );
};
export default FeedbackView;