// import React, { useState, useCallback, useRef, useEffect } from 'react';
// import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

// const containerStyle = {
//   width: '100%',
//   height: '60vh',
// };

// const center = {
//   lat: 48.8566,
//   lng: 2.3522,
// };

// const libraries = ['places'];

// const loadScript = (url) => {
//   return new Promise((resolve, reject) => {
//     const script = document.createElement('script');
//     script.src = url;
//     script.async = true;
//     script.onload = resolve;
//     script.onerror = reject;
//     document.head.appendChild(script);
//   });
// };

// const fetchPlaces = async (input) => {
//   const response = await fetch(
//     `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&key=AIzaSyDyAclZ_3BhDcT1i3wxTiye4TpPOLycoKA`
//   );
//   const data = await response.json();
//   return data.predictions.map((prediction) => ({
//     id: prediction.place_id,
//     label: prediction.description,
//   }));
// };

// const MapDialog = ({ open, onClose, onSelectLocation }) => {
//   const [selectedLocation, setSelectedLocation] = useState(center);
//   const [address, setAddress] = useState('');
//   const [items, setItems] = useState([]);
//   const mapRef = useRef(null);

//   const { isLoaded, loadError } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: 'AIzaSyDyAclZ_3BhDcT1i3wxTiye4TpPOLycoKA',
//     libraries,
//   });

//   const handleSelect = async (value) => {
//     const results = await geocodeByAddress(value);
//     const latLng = await getLatLng(results[0]);
//     setSelectedLocation(latLng);
//     setAddress(value);
//     mapRef.current?.panTo(latLng);
//     mapRef.current?.setZoom(15);
//   };

//   const onMapClick = useCallback((event) => {
//     const location = {
//       lat: event.latLng.lat(),
//       lng: event.latLng.lng(),
//     };
//     setSelectedLocation(location);

//     const geocoder = new window.google.maps.Geocoder();
//     geocoder.geocode({ location }, (results, status) => {
//       if (status === 'OK' && results[0]) {
//         setAddress(results[0].formatted_address);
//       } else {
//         setAddress('Address not found');
//       }
//     });
//   }, []);

//   const handleSave = () => {
//     const locationDetails = {
//       address,
//       latitude: selectedLocation.lat,
//       longitude: selectedLocation.lng,
//     };

//     onSelectLocation(locationDetails);
//     onClose();
//   };

//   if (loadError) return <div className="text-red-500">Error loading maps</div>;
//   if (!isLoaded) return <div className="text-gray-500">Loading...</div>;

//   return (
//     <div className={`fixed inset-0 z-50 overflow-auto bg-white ${open ? 'block' : 'hidden'}`}>
//       <div className="p-4">
//         <h2 className="text-xl font-techna font-normal mb-4">Select Location</h2>
//         <div className="mb-4">
//           <PlacesAutocomplete
//             value={address}
//             onChange={setAddress}
//             onSelect={handleSelect}
//           >
//             {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//               <div>
//                 <input
//                   {...getInputProps({
//                     placeholder: 'Search Places ...',
//                     className: 'location-search-input',
//                   })}
//                   style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
//                 />
//                 <div className="autocomplete-dropdown-container">
//                   {loading && <div>Loading...</div>}
//                   {suggestions.map((suggestion) => {
//                     const className = suggestion.active
//                       ? 'suggestion-item--active'
//                       : 'suggestion-item';
//                     // inline style for demonstration purpose
//                     const style = suggestion.active
//                       ? { backgroundColor: '#fafafa', cursor: 'pointer' }
//                       : { backgroundColor: '#ffffff', cursor: 'pointer' };
//                     return (
//                       <div
//                         {...getSuggestionItemProps(suggestion, {
//                           className,
//                           style,
//                         })}
//                       >
//                         <span>{suggestion.description}</span>
//                       </div>
//                     );
//                   })}
//                 </div>
//               </div>
//             )}
//           </PlacesAutocomplete>
//         </div>
//         <div className="mb-4" style={containerStyle}>
//           <GoogleMap
//             mapContainerStyle={containerStyle}
//             center={selectedLocation}
//             zoom={15}
//             onClick={onMapClick}
//             onLoad={(map) => (mapRef.current = map)}
//             options={{ mapTypeControl: false, streetViewControl: false, fullscreenControl: false }}
//           >
//             <Marker position={selectedLocation} />
//           </GoogleMap>
//         </div>
//         <button
//           onClick={handleSave}
//           className="px-4 py-2 bg-[#DB0000] text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//         >
//           Save
//         </button>
//       </div>
//     </div>
//   );
// };

// export default MapDialog;
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const containerStyle = {
  width: '100%',
  height: '60vh',
};

const defaultCenter = {
  lat: 48.8566,
  lng: 2.3522,
};

const MapDialog = ({ open, onClose, onSelectLocation }) => {
  const [selectedLocation, setSelectedLocation] = useState(defaultCenter);
  const [address, setAddress] = useState('');
  const mapRef = useRef(null);

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setSelectedLocation(currentLocation);
          if (mapRef.current) {
            mapRef.current.panTo(currentLocation);
            mapRef.current.setZoom(15);
          }

          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: currentLocation }, (results, status) => {
            if (status === 'OK' && results[0]) {
              setAddress(results[0].formatted_address);
            } else {
              setAddress('Address not found');
            }
          });
        },
        (error) => {
          console.error("Error getting current location:", error);
          setSelectedLocation(defaultCenter);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      setSelectedLocation(defaultCenter);
    }
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setSelectedLocation(latLng);
    setAddress(value);
    if (mapRef.current) {
      mapRef.current.panTo(latLng);
      mapRef.current.setZoom(15);
    }
  };

  const onMapClick = useCallback((event) => {
    const location = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setSelectedLocation(location);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location }, (results, status) => {
      if (status === 'OK' && results[0]) {
        setAddress(results[0].formatted_address);
      } else {
        setAddress('Address not found');
      }
    });
  }, []);

  const handleSave = () => {
    const locationDetails = {
      address,
      latitude: selectedLocation.lat,
      longitude: selectedLocation.lng,
    };

    onSelectLocation(locationDetails);
    onClose();
  };

  return (
    <div className={` fixed inset-0 z-50 ${open ? 'block' : 'hidden'}`}>
      <div className="flex items-center justify-center min-h-full bg-black bg-opacity-50 overflow-auto ">
        <div className="bg-white rounded-lg p-4 w-[98%] max-w-lg">
          <h2 className="text-xl font-semibold mb-4">Select Location</h2>

          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="mb-4">
                <input
                  {...getInputProps({
                    placeholder: 'Search for a location...',
                    className: 'w-full p-2 border border-gray-300 rounded-md',
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>

          <div style={containerStyle} className="mb-4">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={selectedLocation}
              zoom={15}
              onClick={onMapClick}
              onLoad={(map) => (mapRef.current = map)}
              options={{ mapTypeControl: false, streetViewControl: false, fullscreenControl: false }}
            >
              <Marker position={selectedLocation} />
            </GoogleMap>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 rounded-md bg-gray-200 hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-[#DB0000] text-white rounded-md"
            >
              Save Location
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapDialog;

