// import React from 'react';
// import "./filter_table.css";








// const FilterTable = ({ filter, setFilter, isMobile }) => {
//   const handleFilterChange = (event) => {
//     setFilter(event.target.value);
//   };

//   return (
//     <div className={`p-4 ${isMobile ? 'w-full' : 'mt-12 ml-24'}`}>
//       <div className="flex items-center mb-4 mr-7 font-poppins">
//         <img src="./trade_icon.svg" alt="Trade Role Icon" className="h-6 w-6 mr-2" />
//         <h2 className="font-techna text-2xl font-extrabold leading-tight text-custom-black w-full">
//           Trade Role
//         </h2>
//       </div>
//       <div className="mt-2">
//         {[
//           'Logistics', 
//           'Hand Craft', 
//           'Financial Institutions', 
//           'Start-Up', 
//           'Precious Stones / Metals', 
//           'Seller', 
//           'Buyer', 
//           'Investor', 
//           'Projects', 
//           'Marketplace'
//         ].map(role => (
//           <div key={role} className="mb-4">
//             <label className="flex items-center space-x-2">
//               <input 
//                 type="radio" 
//                 value={role} 
//                 checked={filter === role} 
//                 onChange={handleFilterChange} 
//                 className="custom-radio"
//               />
//               <span className="ml-2 text-option whitespace-nowrap">
//                 {role}
//               </span>
//             </label>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default FilterTable;


import React from 'react';
import "./filter_table.css";
import { categoryMapping } from './category';

const FilterTable = ({ filter, setFilter, isMobile }) => {
  const handleFilterChange = (selectedCategory) => {
    setFilter(prevFilter => prevFilter === selectedCategory ? 0 : selectedCategory);
  };

  console.log("Current Filter:", filter);
  console.log("Category Mapping:", categoryMapping);

  return (
    <div className={`p-4 ${isMobile ? 'w-full' : 'mt-6 ml-24'}`}>
      <div className="flex items-center mb-4 mr-7 font-poppins">
        <img src="./trade_icon.svg" alt="Trade Role Icon" className="h-6 w-6 mr-2" />
        <h2 className="font-poppins text-2xl font-semibold leading-tight text-custom-black w-full">
          Trade Role
        </h2>
      </div>
      <div className="mt-2 font-semibold font-gotham scroll-container">
        {/* {Object.entries(categoryMapping).map(([key, role]) => ( */}
        {Object.entries(categoryMapping)
          .filter(([key]) => key !== "7" && key !== "8") // Exclude Investor (key: 7) and Buyer (key: 8)
          .map(([key, role]) => (
          <div key={key} className="mb-6">
            <label className="flex items-center space-x-2 cursor-pointer" onClick={() => handleFilterChange(parseInt(key))}>
              <div 
                className={`custom-radio ${filter === parseInt(key) ? 'checked' : ''}`}
                onClick={() => handleFilterChange(parseInt(key))}
              >
                {filter === parseInt(key) && <span className="checkmark">✓</span>}
              </div>
              <span className="ml-2 text-option">
                {role}
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterTable;