import React from 'react';

const InputBox = ({
  value,
  onChange,
  onClick,
  placeholder,
  type = "text",
  borderRadius = "4px",
  backgroundColor = "#FFFFFF",
  padding = "12px",
  marginBottom = "auto",
  fontSize = "12px",
  borderColor = "#E8E8E8",
  borderWidth = "1px",
  disabled = false,
  maxLength,
}) => {
  const trimmedValue = value.trim(); // Trimmed value to accurately count characters

  return (
    <>
      <style>
        {`
          .input-placeholder::placeholder {
            color: #8D8D8D;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: ${fontSize};
          }
          .input-placeholder {
            color: #8D8D8D;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: ${fontSize};
          }
        `}
      </style>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        className={`w-full input-placeholder`}
        onChange={onChange}
        onClick={onClick}
        style={{
          borderRadius,
          backgroundColor,
          padding,
          marginBottom,
          outline: 'none',
          width: '100%',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: '400',
          fontSize,
          border: `${borderWidth} solid ${borderColor}`,
          cursor: disabled ? 'not-allowed' : 'text', // Adjust cursor when disabled
        }}
        disabled={disabled}
        maxLength={maxLength}
      />
      {maxLength && (
        <div className="text-right text-[12px] text-gray-500">
          {trimmedValue.length}/{maxLength}
        </div>
      )}
    </>
  );
};

export default InputBox;
