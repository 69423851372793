// import React, { useState } from "react";
// import { useNavigate, useParams } from "react-router-dom"; 
// import Sidebar from "../../Components/main_navbar";
// import Button from "../../Components/button";
// import InputBox from "../../Components/input_box";
// import TextArea from "../../Components/text_area";
// import AddDocument from "../../Components/add_doc";
// import ReviewImageUpload from "../../Components/review_image_add";
// import { add_review } from "../../../Controller/reviews"; 
// import UploadDocuments from "../../../Controller/firebase/firebase_documents";
// import ReportContainer from "../../Components/report";

// const ReviewAdd = () => {
//   const { uid } = useParams(); 
//   const navigate = useNavigate();
//   const [productName, setProductName] = useState('');
//   const [review, setReview] = useState('');
//   const [rating, setRating] = useState(0);
//   const [documentUrl, setDocumentUrl] = useState(''); 
//   const [reviewImages, setReviewImages] = useState([]); 
//   const [loading, setLoading] = useState(false); // State to handle loading

//   const onDocumentUpload = async (file) => {
//     try {
//       const downloadURL = await UploadDocuments(file);
//       setDocumentUrl(downloadURL);
//       console.log("Document uploaded and available at:", downloadURL);
//     } catch (error) {
//       console.error("Error uploading document:", error);
//       alert("Failed to upload document. Please try again.");
//     }
//   };

//   const handleSubmit = async () => {
//     setLoading(true); // Start the loading spinner
//     const payload = {
//       rating: rating,
//       title: productName,
//       description: review,
//       confirmation_document: documentUrl || null, 
//       image_url: reviewImages, 
//     };

//     try {
//       const response = await add_review(uid, payload); 
//       console.log("Review submitted successfully:", response);
//       navigate(`/reviews_page/${uid}`);
//     } catch (error) {
//       console.error("Error submitting review:", error);
//       alert("There was an error submitting your review. Please try again.");
//     } finally {
//       setLoading(false); // Stop the loading spinner
//     }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins overflow-auto">
//       <Sidebar />
//       <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Add Review</h1>
//         </div>
//         <ReportContainer />
//       </div>
//       <div className="border-b border-black mt-2 md:mt-[25px]"></div>
//       <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-1 mx-4 ">
//       <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg mt-2">
//           <h3 className="lg:text-[14px] text-[12px]font-semibold mb-2 text-[#4A4A4A]">Rate the Product/Service</h3>
//           <div style={{ display: 'flex',  }}>
//             {[1, 2, 3, 4, 5].map((star) => (
//               <svg
//                 key={star}
//                 style={{ margin: '10px', cursor: 'pointer' }}
//                 className={rating >= star ? 'filled' : 'empty'}
//                 onClick={() => setRating(star)}
//                 viewBox="0 0 24 24"
//                 width="29"
//                 height="29"
//                 fill={rating >= star ? '#DB0000' : '#FFFFFF'}
//                 stroke={rating >= star ? '#DB0000' : '#DB0000'}
//                 strokeWidth="1"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//               >
//                 <polygon points="12 2 15 8.3 22 9.2 17 14.1 18.5 21 12 17.8 5.5 21 7 14.1 2 9.2 9 8.3 12 2" />
//               </svg>
//             ))}
//           </div>
//           <p className="lg:text-[14px] text-[12px]font-semibold mb-2 text-[#4A4A4A]">Product/Service Name</p>
//           <InputBox
//             value={productName}
//             onChange={(e) => setProductName(e.target.value)}
//             placeholder="Enter the product/service name"
//             borderRadius="1px"
//           />
//            <p className="lg:text-[14px] text-[12px]font-semibold mb-2 text-[#4A4A4A]">Review</p>
//           <TextArea
//             value={review}
//             onChange={(e) => setReview(e.target.value)}
//             placeholder="Your review on the product/service"
//             className="mt-4"
//             maxLength={250}
//           />
//           <h3 className="lg:text-[14px] text-[12px]font-semibold mt-6 mb-2 text-[#4A4A4A]">Supporting Document</h3>
          
//           {/* Add Document Component */}
//           <AddDocument label="Add Supporting Document" onFileUpload={onDocumentUpload} accept=".pdf" />
//           <p className="lg:text-[14px] text-[12px]font-semibold mb-2 text-[#4A4A4A]">Images</p>
//           {/* Review Image Upload Component */}
//           <ReviewImageUpload
//             reviewImages={reviewImages}
//             setReviewImages={setReviewImages}
//             svgImagePath="/Assets/review.svg"
//             bgColor="#C4C4C4"
//             borderRadius="8px"
//             width="80px"
//             height="90px"
//           />

//           <div className="mt-10">
//             <Button
//               text="Submit"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleSubmit}
//               loading={loading} // Pass loading state to Button component
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReviewAdd;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; 
import Sidebar from "../../Components/main_navbar";
import Button from "../../Components/button";
import InputBox from "../../Components/input_box";
import TextArea from "../../Components/text_area";
import AddDocument from "../../Components/add_doc";
import ReviewImageUpload from "../../Components/review_image_add";
import { add_review } from "../../../Controller/reviews"; 
import UploadDocuments from "../../../Controller/firebase/firebase_documents";
import ReportContainer from "../../Components/report";
import { showToast } from '../../Components/toast'; // Assuming you have a toast component for feedback

const ReviewAdd = () => {
  const { uid } = useParams(); 
  const navigate = useNavigate();
  const [productName, setProductName] = useState('');
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(5); // Default to 5 stars
  const [documentUrl, setDocumentUrl] = useState(''); 
  const [reviewImages, setReviewImages] = useState([]); 
  const [loading, setLoading] = useState(false); // State to handle loading
  const [errors, setErrors] = useState({});

  const onDocumentUpload = async (file) => {
    try {
      const downloadURL = await UploadDocuments(file);
      setDocumentUrl(downloadURL);
      console.log("Document uploaded and available at:", downloadURL);
    } catch (error) {
      console.error("Error uploading document:", error);
      alert("Failed to upload document. Please try again.");
    }
  };

  // const validateForm = () => {
  //   const errors = {};
  //   if (!productName) {
  //     errors.productName = "Please enter the product name!";
  //   }
  //   if (!review) {
  //     errors.review = "Please enter the review!";
  //   }
  //   if (reviewImages.length === 0) {
  //     errors.images = "Please upload at least one image!";
  //   }
  //   if (!documentUrl) {
  //     errors.document = "Please upload a supporting document!";
  //   }
  //   setErrors(errors);
  //   return Object.keys(errors).length === 0;
  // };
  const validateForm = () => {
    const errors = {};
    if (!productName) {
      errors.productName = "Please enter the product name!";
    }
    if (!review) {
      errors.review = "Please enter the review!";
    } else if (review.length < 30) {
      showToast("Review must be at least 30 characters long."); // Show toast for short review
      errors.review = "Review must be at least 30 characters long!";
    }
    if (reviewImages.length === 0) {
      errors.images = "Please upload at least one image!";
    }
    if (!documentUrl) {
      errors.document = "Please upload a supporting document!";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true); // Start the loading spinner
    const payload = {
      rating: rating,
      title: productName,
      description: review,
      confirmation_document: documentUrl || null, 
      image_url: reviewImages, 
    };

    try {
      const response = await add_review(uid, payload); 
      console.log("Review submitted successfully:", response);
      
      navigate(`/reviews_page/${uid}`);
    } catch (error) {
      console.error("Error submitting review:", error);

    } finally {
      setLoading(false); // Stop the loading spinner
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins overflow-auto">
      <Sidebar />
      <div className="flex lg:ml-7 ml-3  md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Add Review</h1>
        </div>
        {/* <ReportContainer /> */}
      </div>
      <div className="border-b border-black mt-2 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-1 mx-4">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg mt-2">
          <h3 className="lg:text-[14px] text-[12px] font-semibold mb-2 text-[#4A4A4A]">Rate the Product/Service</h3>
          <div style={{ display: 'flex' }}>
            {[1, 2, 3, 4, 5].map((star) => (
              <svg
                key={star}
                style={{ margin: '10px', cursor: 'pointer' }}
                className={rating >= star ? 'filled' : 'empty'}
                onClick={() => setRating(star)}
                viewBox="0 0 24 24"
                width="29"
                height="29"
                fill={rating >= star ? '#DB0000' : '#FFFFFF'}
                stroke={rating >= star ? '#DB0000' : '#DB0000'}
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polygon points="12 2 15 8.3 22 9.2 17 14.1 18.5 21 12 17.8 5.5 21 7 14.1 2 9.2 9 8.3 12 2" />
              </svg>
            ))}
          </div>

          {/* Product Name Field */}
          <p className="lg:text-[14px] text-[12px] font-semibold mb-2 text-[#4A4A4A]">Product/Service Name</p>
          <InputBox
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Enter the product/service name"
            borderRadius="1px"
          />
          {errors.productName && <p className="text-red-600 text-xs mt-1">{errors.productName}</p>}

          {/* Review Field */}
          <p className="lg:text-[14px] text-[12px] font-semibold  text-[#4A4A4A]">Review</p>
          <TextArea
            value={review}
            onChange={(e) => setReview(e.target.value)}
            placeholder="Your review on the product/service"
            className="mt-2"
            maxLength={250}
          />
          {errors.review && <p className="text-red-600 text-xs mt-1">{errors.review}</p>}

          {/* Supporting Document Upload */}
          <h3 className="lg:text-[14px] text-[12px] font-semibold mt-3 mb-2 text-[#4A4A4A]">Supporting Document</h3>
          <AddDocument label="Add Supporting Document" onFileUpload={onDocumentUpload} accept=".pdf" />
          {errors.document && <p className="text-red-600 text-xs mt-1">{errors.document}</p>}

          {/* Review Images Upload */}
          <p className="lg:text-[14px] text-[12px] font-semibold mb-2 text-[#4A4A4A]">Images</p>
          <ReviewImageUpload
            reviewImages={reviewImages}
            setReviewImages={setReviewImages}
            svgImagePath="/Assets/review.svg"
            bgColor="#C4C4C4"
            borderRadius="8px"
            width="80px"
            height="90px"
          />
          {errors.images && <p className="text-red-600 text-xs mt-1">{errors.images}</p>}

          {/* Submit Button */}
          <div className="mt-10">
            <Button
              text="Submit"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSubmit}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewAdd;
