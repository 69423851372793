



// import React, { useState, useEffect } from 'react';
// import { collection, query, where, getDocs } from 'firebase/firestore';
// import { db } from '../../firebase_service';


// const ChatList = ({ messages, currentUserUid, onSelectMessage, selectedMessageId }) => {
//   const [userImages, setUserImages] = useState({});

//   useEffect(() => {
//     const fetchUserImages = async () => {
//       try {
//         // Get unique user IDs from messages (both senders and receivers)
//         const userIds = new Set();
//         messages.forEach(message => {
//           userIds.add(message.senderUid);
//           userIds.add(message.receiverUid);
//         });

//         // Fetch user documents for all unique users
//         const usersRef = collection(db, 'users');
//         const userPromises = Array.from(userIds).map(async uid => {
//           const q = query(usersRef, where('companyUid', '==', uid));
//           const querySnapshot = await getDocs(q);
//           if (!querySnapshot.empty) {
//             const userData = querySnapshot.docs[0].data();
//             return [uid, userData.profileImage || ''];
//           }
//           return [uid, ''];
//         });

//         const userImageResults = await Promise.all(userPromises);
//         setUserImages(Object.fromEntries(userImageResults));
//       } catch (error) {
//         console.error('Error fetching user images:', error);
//       }
//     };

//     if (messages?.length > 0) {
//       fetchUserImages();
//     }
//   }, [messages]);

//   if (!Array.isArray(messages) || !currentUserUid) {
//     return null;
//   }

//   const validMessages = messages.filter(message => message.lastMessage && message.lastMessage.trim() !== '');

//   const handleChatSelect = (message) => {
//     // Only mark as seen if the current user is the receiver of the last message
//     const isReceiver = message.lastMessageUid !== currentUserUid;
//     onSelectMessage(message, isReceiver);
//   };

//   return (
//     <div className="p-4"> {/* Add padding to the container for spacing */}
//       {messages.length === 0 ? (
//         <p>No chats available</p>
//       ) : (
//         validMessages.map((message)  => {
//           const otherUserId = message.participants.find(uid => uid !== currentUserUid);
//           const isSelected = selectedMessageId === message.id;

//           const displayName = message.receiverUid === currentUserUid ? message.senderName : message.receiverName;
//           // Get the profile image from our userImages state
//           const displayImage = message.receiverUid === currentUserUid 
//             ? userImages[message.senderUid] || message.senderImage
//             : userImages[message.receiverUid] || message.receiverImage;

//           // Trim the last message to 30 characters, append '...' if the message is longer
//           const trimmedLastMessage = message.lastMessage.length > 30 
//             ? `${message.lastMessage.substring(0, 30)}...`
//             : message.lastMessage;

//           // Determine if the message is important for the current user
//           const isImportant = currentUserUid === message.senderUid
//             ? message.isImportantForSender
//             : message.isImportantForReceiver;


//               // Determine if this chat has unread messages
//           const hasUnreadMessages = !message.lastMessageSeen && 
//           message.lastMessageUid !== currentUserUid;

//            // Show double tick ONLY when:
//           // 1. Current user is the sender AND
//           // 2. The message has been seen (lastMessageSeen is true) AND
//           // 3. There is a lastMessageSeenTime (confirms the receiver actually opened the chat)
//           const showDoubleTick = 
//             message.senderUid === currentUserUid && 
//             message.lastMessageSeen === true && 
//             message.lastMessageSeenTime !== null;


//           return (
//             <div
//               key={message.id}
//               onClick={() => onSelectMessage(message)}
//               className={`flex items-center font-poppins space-x-4 p-4 mb-4 bg-gray-100 rounded-lg shadow-md cursor-pointer w-full ${isSelected ? 'bg-gray-200' : ''}`}
//               style={{ marginBottom: '10px' }} // Add margin for spacing between items
//             >
//               <img
//                 src={displayImage || ''}
//                 alt={displayName}
//                 className="w-12 h-12 rounded-full"
//               />
//               <div className="flex-grow">
//                 <h3 className="font-semibold text-[16px]">{displayName}</h3>
//                 <p className="text-gray-600 text-sm">{trimmedLastMessage}</p>
//               </div>

//               {/* Render double ticks if the last message has been seen */}
//              {/* Render double ticks only if the current user is the sender and the message has been seen */}
//                   {/* Only show double tick when all conditions are met */}
//               {showDoubleTick && (
//                 <div>
//                   <img
//                     src="/Assets/double_tick.svg"
//                     alt="Seen"
//                     className="w-4 h-4"
//                   />
//                 </div>
//               )}


//               {/* Render a star icon if the message is important */}
//               {isImportant && (
//                 <div>
//                   <img
//                     src="/Assets/important_star.svg" // Make sure to add a path to your star icon
//                     alt="Important"
//                     className="w-4 h-4"
//                   />
//                 </div>
//               )}
//             </div>
//           );
//         })
//       )}
//     </div>
//   );
// };

// export default ChatList;
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase_service';

const ChatList = ({ messages, currentUserUid, onSelectMessage, selectedMessageId }) => {
  const [userImages, setUserImages] = useState({});

  useEffect(() => {
    const fetchUserImages = async () => {
      try {
        const userIds = new Set();
        messages.forEach(message => {
          userIds.add(message.senderUid);
          userIds.add(message.receiverUid);
        });

        const usersRef = collection(db, 'users');
        const userPromises = Array.from(userIds).map(async uid => {
          const q = query(usersRef, where('companyUid', '==', uid));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            return [uid, userData.profileImage || ''];
          }
          return [uid, ''];
        });

        const userImageResults = await Promise.all(userPromises);
        setUserImages(Object.fromEntries(userImageResults));
      } catch (error) {
        console.error('Error fetching user images:', error);
      }
    };

    if (messages?.length > 0) {
      fetchUserImages();
    }
  }, [messages]);

  if (!Array.isArray(messages) || !currentUserUid) {
    return null;
  }

  // const validMessages = messages.filter(message => message.lastMessage && message.lastMessage.trim() !== '');

  const validMessages = messages
  .filter(message => message.lastMessage && message.lastMessage.trim() !== '')
  .sort((a, b) => {
    const timeA = a.lastMessageTime?.toMillis ? a.lastMessageTime.toMillis() : 0;
    const timeB = b.lastMessageTime?.toMillis ? b.lastMessageTime.toMillis() : 0;
    return timeB - timeA; // Sort in descending order (newest first)
  });

  const handleChatSelect = (message) => {
    const isReceiver = message.lastMessageUid !== currentUserUid;
    onSelectMessage(message, isReceiver);
  };


  return (
    <div className="p-4">
      {messages.length === 0 ? (
        <p>No chats available</p>
      ) : (
        validMessages.map((message) => {
          const otherUserId = message.participants.find(uid => uid !== currentUserUid);
          const isSelected = selectedMessageId === message.id;
          const displayName = message.receiverUid === currentUserUid ? message.senderName : message.receiverName;
          const displayImage = message.receiverUid === currentUserUid 
            ? userImages[message.senderUid] || message.senderImage
            : userImages[message.receiverUid] || message.receiverImage;

          const trimmedLastMessage = message.lastMessage.length > 30 
            ? `${message.lastMessage.substring(0, 30)}...`
            : message.lastMessage;

          const isImportant = currentUserUid === message.senderUid
            ? message.isImportantForSender
            : message.isImportantForReceiver;

          const hasUnreadMessages = !message.lastMessageSeen && 
            message.lastMessageUid !== currentUserUid;

          const showDoubleTick = 
            message.senderUid === currentUserUid && 
            message.lastMessageSeen === true && 
            message.lastMessageSeenTime !== null;

          return (
            <div
              key={message.id}
              onClick={() => onSelectMessage(message)}
              className={`flex items-center font-poppins space-x-4 p-4 mb-4 rounded-lg shadow-md cursor-pointer w-full 
                ${isSelected ? 'bg-gray-200' : 'bg-gray-100'}
                ${hasUnreadMessages ? '' : ''}`}
              style={{ marginBottom: '10px' }}
            >
              <div className="relative">
                <img
                  src={displayImage || ''}
                  alt={displayName}
                  className="w-12 h-12 rounded-full"
                />
                {hasUnreadMessages && (
                  <div className="absolute -top-1 -right-1 w-3 h-3 bg-red-600 rounded-full"></div>
                )}
              </div>
              
              <div className="flex-grow">
                <div className="flex justify-between items-center">
                  <h3 className={`font-semibold text-[16px] ${hasUnreadMessages ? '' : ''}`}>
                    {displayName}
                  </h3>
                  {message.lastMessageTime && (
                    <span className="text-xs text-gray-500">
                     
                    </span>
                  )}
                </div>
                <p className={`text-sm ${hasUnreadMessages ? 'font-semibold text-gray-900' : 'text-gray-600'}`}>
                  {trimmedLastMessage}
                </p>
              </div>

              <div className="flex items-center space-x-2">
                {showDoubleTick && (
                  <img
                    src="/Assets/double_tick.svg"
                    alt="Seen"
                    className="w-4 h-4"
                  />
                )}

                {isImportant && (
                  <img
                    src="/Assets/important_star.svg"
                    alt="Important"
                    className="w-4 h-4"
                  />
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default ChatList;