// import React, { useState } from "react";
// import { FaStar } from "react-icons/fa";

// const Rate = () => {
//     const [rate, setRate] = useState(0);

//     return (
//         <div className="flex justify-center items-center min-h-[60vh] text-3xl">
//             {[...Array(5)].map((item, index) => {
//                 const givenRating = index + 1;
//                 return (
//                     <label key={index} className="cursor-pointer">
//                         <input
//                             type="radio"
//                             value={givenRating}
//                             className="hidden"
//                             onClick={() => {
//                                 setRate(givenRating);
//                             }}
//                         />
//                         <FaStar
//                             style={{
//                                 color: givenRating <= rate ? "yellow" : "transparent",
//                                 stroke: "red",
//                                 strokeWidth: "1px",
//                             }}
//                         />
//                     </label>
//                 );
//             })}
//         </div>
//     );
// };

// export default Rate;
// StarRating.js
import React from 'react';

const StarRating = ({ rating, setRating }) => {
  const handleClick = (value) => {
    if (setRating) {
      setRating(value);
    }
  };

  const renderStars = () => {
    return [1, 2, 3, 4, 5].map((star) => (
      <svg
        key={star}
        className={rating >= star ? 'filled' : 'empty'}
        onClick={() => handleClick(star)}
        viewBox="0 0 24 24"
      >
        <polygon points="12 2 15 8.3 22 9.2 17 14.1 18.5 21 12 17.8 5.5 21 7 14.1 2 9.2 9 8.3 12 2" />
      </svg>
    ));
  };

  return (
    <>
      <style>
        {`
          .star-rating {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          
          .star-rating svg {
            margin: 0 2px;
            transition: fill 0.2s ease, stroke 0.2s ease;
          }
          
          .star-rating svg.filled {
            fill: #DB0000;
            stroke: #DB0000;
          }
          
          .star-rating svg.empty {
            fill: white;
            stroke: #DB0000;
          }

          /* Large screens (default) */
          .star-rating svg {
            width: 23px;
            height: 23px;
          }

          /* For screens smaller than 768px (tablets) */
          @media (max-width: 768px) {
            .star-rating svg {
              width: 18px;
              height: 18px;
            }
          }

          /* For screens smaller than 480px (mobile) */
          @media (max-width: 480px) {
            .star-rating svg {
              width: 16px;
              height: 16px;
            }
          }
        `}
      </style>

      <div className="star-rating">{renderStars()}</div>
    </>
  );
};

export default StarRating;
