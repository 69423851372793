import { jwtDecode } from "jwt-decode";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { REFRESH_URL } from "../../Controller/urls";
import { ACCESS_TOKEN } from "../../Controller/Constants/constants";
import { REFRESH_TOKEN } from "../../Controller/Constants/constants";
import { refreshapi } from "../../Controller/api.js";
const ProtectedRoute = ({ page }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    auth().catch(() => setIsAuthenticated(false));
  }, []);

  const refreshToken = async () => {
    const refresh = localStorage.getItem(REFRESH_TOKEN);
    try {
      const res = await refreshapi.post(REFRESH_URL, refresh, {
        headers: {
            'Content-Type': 'application/octet-stream'

        }
      });
      if (res.status === 200) {
        localStorage.setItem(ACCESS_TOKEN, res.data.accessToken);
        localStorage.setItem(REFRESH_TOKEN, res.data.refreshToken);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
      console.log(error);
    }
  };

  const auth = async () => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (!token) {
      setIsAuthenticated(false);
      return;
    }
    const expiry = jwtDecode(token).exp;
    const now = Date.now() / 1000;
    if (expiry > now) {
      setIsAuthenticated(true);
      return;
    }
    await refreshToken();
  };

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? page : <Navigate to="/login" />;
};

export default ProtectedRoute;
