import React from 'react';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8] ">
    <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] lg:w-4/5">
    <div className="w-full flex justify-start items-center space-x-2">
        <h1 className="text-[#171717] text-[16px] lg:text-xl font-poppins font-semibold">About Us - eSwaF by eMartApp </h1>
      </div>
    </div>
    
    <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

      <div className="flex flex-col gap-4 md:gap-[25px] p-6 items-center w-full overflow-y-auto ">
        <div className="bg-[#F0F0F0] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[800px]">
          <h2 className="text-black font-bold text-lg">About eSwaF by eMartApp</h2>
          <p className="text-[#171717] mt-2">
            <strong>eSwaF</strong> - A Business Focused platform that connects your Brand, Service, or Business to the World — <strong>Everything Electronically</strong>.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>Get Started!</strong><br />
            • Create a Page,<br />
            • Get Verified,<br />
            • Unlock the power to Explore, Connect, Grow —Limitless <br />
            • Become a Global Superstar with eSwaF!
          </p>
        </div>

        <div className="bg-[#F0F0F0] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[800px]">
          <h2 className="text-black font-bold text-lg">Why Choose eSwaF</h2>
          <p className="text-[#171717] mt-2">
            <strong>Everything Electronically: Secured & Verified</strong><br />
            eSwaF transforms your business operations into a fully digital experience. Every process is secure and verified.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>Ecosystem Support: Holistic Business Environment</strong><br />
            Our ecosystem adapts to diverse operational needs, positioning your brand optimally in the market.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>Disruption-Free Engagement: No Irrelevant Ads</strong><br />
            With eSwaF, your audience engages with authentic, organic content focused on real connections, not forced interruptions.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>Unified Industry Coverage: One-Stop Solution</strong><br />
            We bridge the gap across industries, connecting every sector for a seamless business experience.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>Local and Global Expansion: Unlock New Markets</strong><br />
            eSwaF provides the connections and insights you need to expand locally and globally.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>Limitless Business Connections: Network with Purpose</strong><br />
            Build partnerships, explore joint ventures, and scale alongside other businesses with limitless possibilities.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>Eliminate Fragmented Data: A Secure Platform</strong><br />
            Navigating fragmented and unreliable data is history. eSwaF focuses on verified and accurate information.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>Organic Ranking: Build Trust, Grow Reliably</strong><br />
            eSwaF ranks businesses based on verified performance, encouraging transparency for consistent growth.
          </p>
          <p className="text-[#171717] mt-2">
            <strong>Algorithm-Driven Optimization: Insights that Matter</strong><br />
            Powered by research and analytics, our platform delivers meaningful insights, ensuring the right audience reach every time.
          </p>
        </div>

        <div className="bg-[#F0F0F0] p-6 md:p-[25px] rounded-md shadow-md w-full max-w-[800px]">
          <h2 className="text-black font-bold text-lg">eSwaF is for?</h2>
          <p className="text-[#171717] mt-2">
            Any Brand, Service, or Business that wants to connect, grow, and succeed. <strong>eSwaF yourself to the world!</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
