import React, { useState, useEffect } from 'react';
import CenteredImageContainer from '../../Components/black_container';
import CountryDropdown from '../../Components/country_drop_down';
import ContinentDropdown from '../../Components/continent_drop_down';
import Button from '../../Components/button';
import { Toast } from '../../Components/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { showToast } from '../../Components/toast';
import ReportContainer from '../../Components/report';
import { fetchCountries } from '../../../Controller/location_contoller';
import { saveLocation } from '../../../Controller/location_contoller';
const SelectDropDownRegion = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { completed } = location.state || { completed: [] };
  const defaultContinent = 'north_america';
  const [selectedContinent, setSelectedContinent] = useState(localStorage.getItem('continent') || defaultContinent);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  const continentImages = {
    asia: 'Assets/asia1.svg',
    europe: 'Assets/europe1.svg',
    north_america: 'Assets/northamerica1.svg',
    south_america: 'Assets/southamerica1.svg',
    africa: 'Assets/AFRICA1.svg',
    australia: 'Assets/austarlia1.svg'
  };

  useEffect(() => {
    if (!localStorage.getItem('continent')) {
      localStorage.setItem('continent', defaultContinent);
    }
    if (selectedContinent) {
      fetchCountries(selectedContinent, setLoading, setCountries); 
    }
  }, [selectedContinent]);

  const handleContinentChange = (e) => {
    const selectedContinent = e.target.value;
    if (selectedContinent) {
      setSelectedContinent(selectedContinent);
      localStorage.setItem('continent', selectedContinent);
      setSelectedCountry('');
      fetchCountries(selectedContinent, setLoading, setCountries); 
    } else {
      showToast('Please select a continent', 'error');
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setSelectedCountry(selectedCountry);
    localStorage.setItem('country', selectedCountry);
  };

  const handleButtonClick = async () => {
    saveLocation(navigate, completed, setLoading); // Call the controller function
  };

  return (
    <div className="h-full w-full flex bg-[#FBFCF8]">
      <Toast />
      <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
        <div className='w-4/5 h-full'> 
        <div className='w-4/5 h-full'> 
  <CenteredImageContainer imgSrc={continentImages[selectedContinent]} className="w-full h-full object-cover" />
</div>
        </div>
      </div>

      <div className="absolute top-5 right-6">
        <ReportContainer />
      </div>

      <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-4 font-poppins ">
        <div className="p-6 w-full max-w-2/5">
          <h1 className="text-[26px] lg:text-2xl font-semibold text-[#171717] mb-3">Select continent and country</h1>
          <p className="text-gray-500 mb-6 mt-2 text-sm">Please select the continent and country in the below text field</p>
          
          <div className="flex flex-col space-y-2 ">
            <label htmlFor="continent" className="text-sm font-medium text-[#545454]">Continent</label>
            <ContinentDropdown 
              id="continent"
              selectedContinent={selectedContinent} 
              onChange={handleContinentChange} 
            />
          </div>

          <div className="flex flex-col space-y-2 mt-4">
            <label htmlFor="country" className="text-sm font-medium text-[#545454]">Country</label>
            <CountryDropdown 
              id="country"
              selectedCountry={selectedCountry} 
              onChange={handleCountryChange} 
              countries={countries} 
            />
          </div>

          <div className="w-full mt-8 flex justify-center">
            <Button 
              text="Save" 
              onClick={handleButtonClick} 
              backgroundColor="bg-[#DB0000]" 
              textColor="text-white" 
              loading={loading}
              className="w-full lg:w-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDropDownRegion;
