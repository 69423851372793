// categories.js
// categories.js

export const categoryMapping = {
    1: 'Logistics',
    2: 'Legal',
    3: 'Finance',
    4: 'Start Up',
    5: 'Jewelry',
    6: 'Seller',
    7: 'Investor',
    8: 'Buyer',
    9: 'Market Place',
    10: 'Projects',
};
