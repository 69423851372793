import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PasswordInputField from '../../Components/password-input-field';
import Button from '../../Components/button';
import { Toast } from '../../Components/toast';
import CenteredImageContainer from '../../Components/black_container';
import { handlePasswordReset } from '../../../Controller/reset_password_controller';
import { FaCheck } from "react-icons/fa";
import { MdError } from 'react-icons/md';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [validationMessages, setValidationMessages] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const { secret, email } = location.state || {};

  useEffect(() => {
    if (!email || !secret) {
      navigate('/forgot-password-page');
    }
  }, [email, secret, navigate]);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const validatePassword = (password) => {
    const length = password.length >= 8;
    const lowercase = /[a-z]/.test(password);
    const uppercase = /[A-Z]/.test(password);
    const number = /\d/.test(password);
    const special = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setValidationMessages({
      length,
      lowercase,
      uppercase,
      number,
      special,
    });
  };

  const handleSaveClick = async () => {
    handlePasswordReset(password, confirmPassword, email, secret, navigate, setLoading); // Call the controller function
  };

  return (
    <div className="h-full w-full flex bg-[#FBFCF8] font-poppins">
      <Toast />
      <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
        <div className='w-4/5 h-full'> 
          <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
        </div>
      </div>
      <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-4 font-poppins">
        <div className="p-6 w-full max-w-2/5">
          <div className="flex items-center mb-6">
            <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
          </div>
          <div className="mt-8 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">New Password</label>
              <PasswordInputField
                placeholder="New Password"
                name="password"
                onChange={handlePasswordChange}
                border="1px solid #ccc"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Retype New Password</label>
              <PasswordInputField
                placeholder="Retype New Password"
                name="confirmPassword"
                onChange={handleConfirmPasswordChange}
                border="1px solid #ccc"
                inputClassName="placeholder-red-600 font-helvetica"
              />
            </div>

            {/* Password validation messages */}
            <div className="grid grid-cols-2 gap-x-6 gap-y-2 mt-2 text-sm text-[#545454] font-poppins font-normal">
              <div className="flex items-center space-x-2">
                {validationMessages.length ? (
                  <FaCheck className="text-green-500" />
                ) : (
                  <MdError className="text-[#DB0000]" />
                )}
                <span>At least 8 characters</span>
              </div>
              <div className="flex items-center space-x-2">
                {validationMessages.lowercase ? (
                  <FaCheck className="text-green-500" />
                ) : (
                  <MdError className="text-[#DB0000]" />
                )}
                <span>Lowercase letter</span>
              </div>
              <div className="flex items-center space-x-2">
                {validationMessages.uppercase ? (
                  <FaCheck className="text-green-500" />
                ) : (
                  <MdError className="text-[#DB0000]" />
                )}
                <span>Uppercase letter</span>
              </div>
              <div className="flex items-center space-x-2">
                {validationMessages.number ? (
                  <FaCheck className="text-green-500" />
                ) : (
                  <MdError className="text-[#DB0000]" />
                )}
                <span>Number</span>
              </div>
              <div className="flex items-center space-x-2">
                {validationMessages.special ? (
                  <FaCheck className="text-green-500" />
                ) : (
                  <MdError className="text-[#DB0000]" />
                )}
                <span>Special character</span>
              </div>
            </div>

            <div className="mt-10">
              <Button
                text="Save"
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                onClick={handleSaveClick}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
