// import React from 'react';
// import PropTypes from 'prop-types';

// const StepsCompleted = ({ completedSteps }) => {
//   return (
//     <div className="flex justify-center items-center mt-4">
//       <p className="text-[#DB0000] font-semibold">
//         {completedSteps} OF 4 STEPS COMPLETED
//       </p>
//     </div>
//   );
// };

// StepsCompleted.propTypes = {
//   completedSteps: PropTypes.number.isRequired,
// };

// export default StepsCompleted;
import React from 'react';
import PropTypes from 'prop-types';

const StepsCompleted = ({ completedSteps }) => {
  return (
    <div className="flex justify-center items-center mt-4 font-poppins">
      <p className="text-[#DB0000] font-semibold">
        {completedSteps} OF 3 STEPS COMPLETED
      </p>
    </div>
  );
};

StepsCompleted.propTypes = {
  completedSteps: PropTypes.number.isRequired,
};

export default StepsCompleted;
