import React from 'react';
import LoaderSpinner from './loader_spinner';

const ImageButton = ({ onClick, imageSrc, buttonText, imageSize = '16px', loading,  spinnerColor = '#FFFFFF'  }) => {
  return (
    <button
      onClick={onClick}
      className="flex flex-col items-center justify-center p-3"
      style={{
        backgroundColor: '#DB0000',
        color: 'white',
        width: '160px',
        height: '45px',
        borderRadius: '3px',
        border: 'none',
        fontFamily: 'Poppins, sans-serif',
        cursor: 'pointer',
        outline: 'none',
      }}
      disabled={loading} // Disable button while loading
    >
      {/* Loader spinner */}
      {loading ? (
        <LoaderSpinner visible={loading} height={15} color={spinnerColor} />
      ) : (
        <>
          {/* Display the image if not loading */}
          <img
            src={imageSrc}
            alt={buttonText}
            className="w-4 h-4"
            style={{
              width: imageSize,
              height: imageSize,
            }}
          />
          {/* Display the button text */}
          <span className="text-white mt-1 text-xs font-normal">{buttonText}</span>
        </>
      )}
    </button>
  );
};

export default ImageButton;
