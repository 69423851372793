import { DELETE_VIDEO, GET_All_VIDEO_URL, GET_USER_VIDEO_URL, POST_VIDEO_URL } from "./urls";
import { api } from "./api";
import { GET_ALL_USERS_VIDEO_URL} from "./urls";

const post_video = async (title, description, video_url,thumb_nail_url) => {
    const res = await api.post(POST_VIDEO_URL, {
        title: title,
        description: description,
        video_url: video_url,  
        thumb_nail_url:thumb_nail_url  
  });
     return res;
  };
  const get_user_videos_api = async (company_id, skip = 0) => {
    try {
      // If no company_id is passed, fetch it from localStorage
      if (!company_id) {
        company_id = localStorage.getItem('company_id'); // Get company_id as string from localStorage
        if (!company_id) {
          throw new Error('No company_id found in localStorage.');
        }
      }
  
      const limit = 10; // Fetch 10 videos at a time
  
      // Make the GET request with company_id, skip, and limit
      const res = await api.get(GET_ALL_USERS_VIDEO_URL(company_id), {
        params: {
          skip,  // Pass skip value as query param
          limit, // Pass limit value as query param
        },
      });
  
      if (res.data && Array.isArray(res.data.data)) {
        return res.data.data; // Return the video data array
      } else {
        throw new Error('Invalid response format.');
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
      throw error;
    }
  };
  

  const get_user_video_api = async (videoId) => {
    try {
      // Check if videoId is provided
      if (!videoId) {
        throw new Error('No videoId provided.');
      }
  
      // Make the API call using axios
      const res = await api.get(GET_USER_VIDEO_URL(videoId));
  
      console.log('API response:', res); // Log the entire response for debugging
  
      // Check for the status code and validate the response format
      if (res.status === 200 && res.data && res.data.data) {
        console.log('Video data:', res.data.data);
        return res.data.data; // Return the video data
      } else {
        throw new Error('Invalid response format or status code.');
      }
    } catch (error) {
      console.error('Error fetching video:', error);
      throw error; // Re-throw the error to be handled in the calling function
    }
  };
  const delete_video_Id = async (videoId) => {
    try {
      const res = await api.delete(DELETE_VIDEO(videoId)); 
      return res;
    } catch (error) {
      console.error("Error deleting media:", error);
      throw error;
    }
  };    


  const get_all_User_video = async (skip, limit) => {
   
    try {
      const res = await api.get(GET_All_VIDEO_URL, {
        params: {
          skip: skip,
          limit: limit,
        },
      });
  
      return res;
    } catch (error) {
      console.error("Error fetching media images:", error);
      throw error;
    }
  }; 


  export{post_video ,get_user_videos_api,get_user_video_api,delete_video_Id,get_all_User_video}