

import React, { useState, useRef } from 'react';
import { FaSpinner } from 'react-icons/fa';
import Cropper from 'react-easy-crop';
import Button from './button';
import uploadImage from '../../Controller/firebase/firebase_upload_image';

function LogoUploader({ logo, onUpload, isEditable = true, error, onError }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [tempImage, setTempImage] = useState(null);
  const [localLogo, setLocalLogo] = useState(logo);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTempImage(reader.result);
        setShowCropper(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const getCroppedImg = async (imageSrc, cropPixels) => {
    const canvas = document.createElement('canvas');
    const image = new Image();
    
    return new Promise((resolve) => {
      image.src = imageSrc;
      image.onload = () => {
        const ctx = canvas.getContext('2d');
        canvas.width = cropPixels.width;
        canvas.height = cropPixels.height;

        ctx.drawImage(
          image,
          cropPixels.x,
          cropPixels.y,
          cropPixels.width,
          cropPixels.height,
          0,
          0,
          cropPixels.width,
          cropPixels.height
        );

        canvas.toBlob((blob) => {
          const croppedImageUrl = URL.createObjectURL(blob);
          resolve({ url: croppedImageUrl, blob });
        }, 'image/jpeg');
      };
    });
  };

  const handleSaveCroppedImage = async () => {
    if (!croppedAreaPixels || !tempImage) return;

    setIsLoading(true);
    try {
      // Get the cropped image data
      const { blob } = await getCroppedImg(tempImage, croppedAreaPixels);
      
      // Create a File object from the blob
      const file = new File([blob], `profile_${Date.now()}.jpg`, { type: 'image/jpeg' });
      
      // Upload to Firebase
      const firebaseUrl = await uploadImage(file);
      
      // Update local state with the Firebase URL
      setLocalLogo(firebaseUrl);
      
      // Pass the Firebase URL to parent component
      if (onUpload) {
        onUpload(firebaseUrl);
      }

      setShowCropper(false);
      setTempImage(null);
    } catch (error) {
      console.error('Error saving cropped image:', error);
      if (onError) {
        onError('Failed to save cropped image.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageError = () => {
    if (onError) {
      onError("Failed to load image.");
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div
        className="bg-gray-300 rounded-full flex items-center justify-center relative overflow-hidden"
        style={{ width: '120px', height: '120px' }}
      >
        {isLoading ? (
          <FaSpinner className="text-white w-8 h-8 animate-spin" />
        ) : localLogo ? (
          <>
            <img
              src={localLogo}
              alt="Company Logo"
              className="w-full h-full rounded-full object-cover"
              onError={handleImageError}
            />
            {isEditable && (
              <div
                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-10 rounded-full cursor-pointer"
                onClick={triggerFileInput}
              >
                <img
                  src="/Assets/edit.svg"
                  alt="Edit"
                  className="w-5 h-5 lg:w-6 lg:h-6 cursor-pointer"
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div
              className="flex items-center justify-center w-full h-full cursor-pointer"
              onClick={isEditable ? triggerFileInput : undefined}
            >
              {!isLoading && (
                <img
                  src="/Assets/grey_plus.svg"
                  alt="Plus Icon"
                  className="w-6 h-6"
                />
              )}
            </div>
          </>
        )}
        {isEditable && (
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleImageUpload}
          />
        )}
      </div>

      {showCropper && (
        <div className="fixed inset-0 flex items-center justify-center z-50 mx-2 bg-black bg-opacity-20">
          <div className="bg-white p-4 rounded-2xl w-[380px] h-[480px]">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-xl font-semibold">Crop Image</h2>
              <button 
                onClick={() => {
                  setShowCropper(false);
                  setTempImage(null);
                }} 
                className="text-black"
              >
                ✕
              </button>
            </div>
            
            <div className="flex flex-col items-center justify-center mt-4 relative h-60 w-full bg-gray-200">
              <Cropper
                image={tempImage}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>

            <div className="flex justify-between items-center mt-4 px-4">
              <label htmlFor="zoom-range">Zoom</label>
              <input
                id="zoom-range"
                type="range"
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(Number(e.target.value))}
                className="w-full ml-2"
              />
            </div>

            <div className="mt-4">
              <Button
                onClick={handleSaveCroppedImage}
                text="Save"
                borderColor="border-transparent"
                backgroundColor="bg-custom-button-color"
                textColor="text-white"
                loading={isLoading}
                loaderColor="#FFFFFF"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LogoUploader;

