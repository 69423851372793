import { api } from './api'; // Assuming you're using axios or another service for API calls
import { CHANGE_PASSWORD_URL } from './urls';

const changeUserPassword = async (oldPassword, newPassword) => {
    try {
        const payload = {
            old_password: oldPassword,
            new_password: newPassword
        };

        const response = await api.post(CHANGE_PASSWORD_URL, payload);
        console.log("Change Password API Response:", response);
        return response;
    } catch (error) {
        console.error("Error changing password:", error);
        throw error;
    }
};

export { 
    changeUserPassword,
    
};
