import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CenteredImageContainer from '../Components/black_container';
import InputBox from '../Components/input_box';
import DocumentUpload from '../Components/document_upload';
import MapButton from '../Components/map_button';
import { update_company_details } from '../../Controller/company_details';
import LoaderSpinner from '../Components/loader_spinner';
import iconUp from './icon-up.svg';
import { Toast } from '../Components/toast';
import UploadDocuments from '../../Controller/firebase/firebase_documents';
import { showToast } from '../Components/toast';
import Button from '../Components/button';
import { ScaleLoader } from 'react-spinners';

const UpdateForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [fileUploading, setFileUploading] = useState({
    authorization: false,
    tradingLicense: false,
    tenancyContract: false,
    tradingRegister: false,
  });

  // Fetching data from localStorage
  const storedCompanyName = localStorage.getItem('companyName') || '';
  const storedWebsite = localStorage.getItem('website') || '';
  const storedAccountManager = localStorage.getItem('manager') || '';
  const storedOfficeLocation = localStorage.getItem('location') || '';

  const storedAuthorizationDocument = localStorage.getItem('authorization_document') || '';
  const storedTradingLicense = localStorage.getItem('trading_license') || '';
  const storedTenancyContract = localStorage.getItem('tenancy_contract') || '';
  const storedTradingRegister = localStorage.getItem('trading_register') || '';

  // Setting states
  const [companyName, setCompanyName] = useState(storedCompanyName);
  const [website, setWebsite] = useState(storedWebsite);
  const [accountManager, setAccountManager] = useState(storedAccountManager);
  const [officeLocation, setOfficeLocation] = useState(storedOfficeLocation);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(!!storedOfficeLocation);
  const [address, setAddress] = useState('');

  useEffect(() => {
    const storedRemarks = localStorage.getItem('remarks') || 'No remarks available';
    setRemarks(storedRemarks);
  }, []);

  // Border style for empty fields
  const inputBorderStyle = (value) => ({
    borderColor: value === '' ? '#DB0000' : '#989898', // Red border if field is empty
  });

  const handleFileChange = async (e, documentType) => {
    const file = e.target.files[0];
    const fileSizeLimit = 25 * 1024 * 1024; // 25 MB size limit

    if (file) {
      if (file.size <= fileSizeLimit && file.type === 'application/pdf') {
        setFileUploading((prev) => ({ ...prev, [documentType]: true }));

        try {
          const downloadURL = await UploadDocuments(file);
          showToast(`${documentType.replace(/_/g, ' ')} uploaded successfully`, 'success');
          localStorage.setItem(documentType, downloadURL);
        } catch (error) {
          console.error(`Error uploading ${documentType}:`, error);
          showToast(`Failed to upload ${documentType}`, 'error');
        } finally {
          setFileUploading((prev) => ({ ...prev, [documentType]: false }));
        }
      } else {
        showToast('Please upload a PDF document under 25MB.', 'error');
      }
    }
  };

  const handleSelectLocation = (locationDetails) => {
    const { address, latitude, longitude } = locationDetails;
    setAddress(address);
    setLatitude(latitude);
    setLongitude(longitude);
    setIsAddressSelected(true);
    setOfficeLocation(address);
  };

  const handleSave = async () => {
    setLoading(true);
  
    const payload = {
      companyName,
      website,
      officeLocation,
      latitude,
      longitude,
      accountManager,
      bio: localStorage.getItem('bio') || '',
      authorizationDocument: localStorage.getItem('authorization_document') || '',
      tradingLicense: localStorage.getItem('trading_license') || '',
      tradingRegister: localStorage.getItem('trading_register') || '',
      tenancyContract: localStorage.getItem('tenancy_contract') || ''
    };
  
    try {
      const type = true;  // Set type to true for the URL parameter
  
      // Log the URL and payload data
      console.log('Type parameter being sent in URL:', type);
      console.log('Payload data being sent:', payload);
  
      const res = await update_company_details(
        payload.companyName,
        payload.website,
        payload.officeLocation,
        payload.latitude,
        payload.longitude,
        payload.accountManager,
        payload.bio,
        payload.authorizationDocument,
        payload.tradingLicense,
        payload.tradingRegister,
        payload.tenancyContract,
        type  // Pass the type here
      );
  
      if (res.status === 202) {
        showToast('Company details updated successfully', 'success');
        navigate('/home');
      } else {
        showToast('Failed to update company details', 'error');
      }
    } catch (error) {
      showToast('An error occurred while updating company details', 'error');
    } finally {
      setLoading(false);
    }
  };
  
  
  return (
    <div className="flex w-full h-full overflow-y-auto bg-[#FBFCF8] font-gotham">
      <Toast />
      <div className="hidden lg:flex w-full lg:w-2/5 h-full ">
        <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
      </div>
      <div className="flex flex-col w-full lg:w-3/5 h-full p-8 overflow-y-auto">
        <h2 className="text-2xl font-semibold mb-1">Update</h2>
        <p className="text-lg mb-6 text-gray-500">Fill the field to update the form</p>

        


<div className="bg-white shadow-lg rounded-lg p-8 ">
  {/* Remarks Section */}
  <div className="mb-6">
    <h3 className="text-xl font-semibold font-poppins mb-4">Remarks</h3>
    <div 
      className="flex items-start p-4  rounded-md max-h-32 overflow-y-auto" // Adjusted styling for remarks
      style={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
    >
      <img src="./remarks_arrow.svg" alt="Arrow" className="w-4 h-4 mt-1 mr-2" />
      <p className="text-gray-600 text-[#2D3748] font-gotham font-medium">
        {remarks}
      </p>
    </div>
  </div>

  {/* Input Fields */}
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
    <div className="lg:col-span-1">
      <div className="flex flex-col">
        <label className="text-[#989898] mb-2">Company name</label>
        <InputBox
          placeholder="Company name"
          value={companyName}
          style={inputBorderStyle(companyName)}
          onChange={(e) => setCompanyName(e.target.value)}
          disabled={storedCompanyName !== ''}
        />
      </div>

      <div className="flex flex-col mt-6">
        <label className="text-[#989898] mb-2">Website</label>
        <InputBox
          placeholder="www.eswaf.com"
          value={website}
          style={inputBorderStyle(website)}
          onChange={(e) => setWebsite(e.target.value)}
          disabled={storedWebsite !== ''}
        />
      </div>

      <div className="flex flex-col mt-6">
        <label className="text-[#989898] mb-2">Account Manager</label>
        <InputBox
          placeholder="Name"
          value={accountManager}
          style={inputBorderStyle(accountManager)}
          onChange={(e) => setAccountManager(e.target.value)}
          disabled={storedAccountManager !== ''}
        />
      </div>
    </div>

    <div className="lg:col-span-1 flex flex-col">
      <label className="text-[#989898] mb-2">Office Location</label>
      <div className="flex flex-col">
        <InputBox
          placeholder="Location"
          value={officeLocation}
          style={inputBorderStyle(officeLocation)}
          onChange={(e) => setOfficeLocation(e.target.value)}
          disabled={storedOfficeLocation !== ''}
        />
        <div className="w-full mt-4">
          <MapButton
            onAddressSelect={handleSelectLocation}
            padding="15px"
            disabled={storedOfficeLocation !== ''}
          />
        </div>
      </div>
    </div>
  </div>

  <h3 className="text-lg lg:text-xl font-semibold mb-4">Upload Documents</h3>

  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
    <DocumentUpload
      label="Business License"
      imgSrc='/Assets/icon-up.svg'
      disabled={storedTradingLicense !== ''}
      existingFile={storedTradingLicense}
    />

    <DocumentUpload
      label="Tenancy Contract"
      imgSrc='/Assets/icon-up.svg'
      disabled={storedTenancyContract !== ''}
      existingFile={storedTenancyContract}
    />

    <DocumentUpload
      label="Trading Register"
      imgSrc='/Assets/icon-up.svg'
      disabled={storedTradingRegister !== ''}
      existingFile={storedTradingRegister}
    />
  </div>

  <div className="flex justify-end mt-8">
  <button
  className="bg-[#DB0000] text-white px-3 py-3 rounded-lg shadow-lg w-32 flex justify-center items-center"
  onClick={handleSave}
  disabled={loading}
>
  {loading ? <ScaleLoader height={12} color="#FFFFFF" /> : 'Save'}
</button>

  </div>
</div>

      </div>
    </div>
  );
};

export default UpdateForm;
