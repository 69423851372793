import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { FaSpinner } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom'; 
import CompanyCard from '../../Components/comapny_card';
import CompanyHeader from '../../Components/company_header';
import Sidebar from '../../Components/main_navbar';
import { get_sub_categories_api } from '../../../Controller/sub_categories_home';
import { categoryMapping } from '../../Components/category';

const libraries = ['places'];

const CategoryPage = ({ hideSidebar = false }) => {
  const { categoryId } = useParams();
  const [search, setSearch] = useState(''); 
  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');
  const [companies, setCompanies] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const navigate = useNavigate();
  const observer = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const lastCompanyElementRef = useCallback(
    (node) => {
      if (loading || loadingMore || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          handleLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, loadingMore, hasMore]
  );

  const handleLocationSelect = (latitude, longitude) => {
    setLat(latitude);
    setLon(longitude);
    setSkip(0);
    setCompanies([]);
    setHasMore(true);
  };

  const fetchCompanies = async (skipCount) => {
    if (skipCount === 0) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }

    try {
      const response = await get_sub_categories_api(
        categoryId,
        skipCount,
        5,
        lat || undefined,
        lon || undefined,
        debouncedSearch
      );

      if (skipCount === 0) {
        setCompanies(response.data);
      } else {
        setCompanies(prevCompanies => [...prevCompanies, ...response.data]);
      }
      
      setHasMore(response.data.length === 5);
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  // Debounce search input
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300);

    return () => clearTimeout(timer);
  }, [search]);

  // Main effect for fetching companies
  useEffect(() => {
    setSkip(0);
    setCompanies([]);
    setHasMore(true);
    fetchCompanies(0);
  }, [categoryId, lat, lon, debouncedSearch]);

  const handleLoadMore = () => {
    if (!loadingMore && hasMore) {
      const nextSkip = skip + 5;
      setSkip(nextSkip);
      fetchCompanies(nextSkip);
    }
  };

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <div className="h-full lg:w-full flex flex-col bg-[#FBFCF8]">
      <h1 className="text-2xl font-semibold leading-tight" style={{ color: '#171717', marginTop: '2rem', marginLeft: '2rem' }}>
        {categoryMapping[categoryId] || 'Category'}
      </h1>
      
      <CompanyHeader 
        setSearch={setSearch} 
        onLocationSelect={handleLocationSelect}
        toggleMobileMenu={() => {}} 
        hideLogo 
        hideFilterIcon 
      />

      <div className="flex flex-col md:flex-row w-full mt-4 md:mt-0 overflow-y-auto overflow-x-hidden">
        <div className="flex flex-col lg:w-4/5 w-full p-2 space-y-6 mr-16 overflow-y-auto">
          <div className="space-y-6 mt-4 md:px-8 flex-1 overflow-x-hidden">
            <div className="flex flex-col items-center md:items-center md:w-1/2 md:mx-auto overflow-y-auto overflow-x-hidden">
              {companies.length === 0 && !loading ? (
                <div className="flex justify-center items-center mt-40">
                  <p className="text-center font-poppins text-gray-500">No companies to show!</p>
                </div>
              ) : (
                companies.map((company, index) => (
                  <div
                    key={company.id}
                    className="w-full md:w-full p-2"
                    onClick={() => navigate(`/profile2/${company.id}`)}
                    ref={index === companies.length - 1 ? lastCompanyElementRef : null}
                  >
                    <CompanyCard company={company} />
                  </div>
                ))
              )}

              {(loading || loadingMore) && (
                <div className="flex justify-center my-4">
                  <FaSpinner className="animate-spin text-gray-500 text-2xl" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {!hideSidebar && (
        <div className="md:block w-64 p-4">
          <Sidebar />
        </div>
      )}
    </div>
  );
};

export default CategoryPage;