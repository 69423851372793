import React, { useState, useEffect } from 'react';
import InputBox from '../../Components/input_box';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import { Toast, showToast } from '../../Components/toast';
import { post_social_links } from '../../../Controller/company_details';
import { useNavigate, useLocation } from 'react-router-dom';

const SocialLinks = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // States for each social link
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [twitter, setTwitter] = useState('');
  const [loading, setLoading] = useState(false);

  // Error states
  const [facebookError, setFacebookError] = useState('');
  const [instagramError, setInstagramError] = useState('');
  const [linkedinError, setLinkedinError] = useState('');
  const [twitterError, setTwitterError] = useState('');

  // Prefill social links, excluding "Website" links
  useEffect(() => {
    if (state?.links) {
      console.log("Received links for prefill:", state.links);

      state.links.forEach(link => {
        if (link.includes('facebook.com')) setFacebook(link.split(': ')[1]);
        if (link.includes('instagram.com')) setInstagram(link.split(': ')[1]);
        if (link.includes('linkedin.com')) setLinkedin(link.split(': ')[1]);
        if (link.includes('twitter.com')) setTwitter(link.split(': ')[1]);
      });
    }
  }, [state]);

  // Specific URL validation functions
  const isValidFacebookUrl = (url) => /^https:\/\/www\.facebook\.com\/[A-Za-z0-9._-]+$/.test(url);
  const isValidInstagramUrl = (url) => /^https:\/\/www\.instagram\.com\/[A-Za-z0-9._-]+\/?$/.test(url);
  const isValidLinkedinUrl = (url) => /^https:\/\/(www\.)?linkedin\.com\/in\/[A-Za-z0-9._-]+\/?$/.test(url);
  const isValidTwitterUrl = (url) => /^https:\/\/(www\.)?twitter\.com\/[A-Za-z0-9._-]+\/?$/.test(url);

  // Handlers for input changes
  const handleFacebookChange = (e) => {
    setFacebook(e.target.value);
    if (facebookError) setFacebookError('');
  };
  const handleInstagramChange = (e) => {
    setInstagram(e.target.value);
    if (instagramError) setInstagramError('');
  };
  const handleLinkedinChange = (e) => {
    setLinkedin(e.target.value);
    if (linkedinError) setLinkedinError('');
  };
  const handleTwitterChange = (e) => {
    setTwitter(e.target.value);
    if (twitterError) setTwitterError('');
  };

  // Handle save functionality
  const handleSave = async () => {
    let hasError = false;

    // Validate and set errors for each field
    if (facebook && !isValidFacebookUrl(facebook)) {
      setFacebookError('Enter a valid Facebook URL');
      hasError = true;
    }
    if (instagram && !isValidInstagramUrl(instagram)) {
      setInstagramError('Enter a valid Instagram URL');
      hasError = true;
    }
    if (linkedin && !isValidLinkedinUrl(linkedin)) {
      setLinkedinError('Enter a valid LinkedIn URL');
      hasError = true;
    }
    if (twitter && !isValidTwitterUrl(twitter)) {
      setTwitterError('Enter a valid Twitter URL');
      hasError = true;
    }

    // Check if at least one valid URL is provided
    if (!facebook && !instagram && !linkedin && !twitter) {
      showToast('Please provide at least one URL.', 'error');
      return;
    }

    // Stop if there are validation errors
    if (hasError) return;

    // Create the payload with trimmed values
    const payload = {};
    if (facebook) payload.facebook = facebook.trim();
    if (instagram) payload.instagram = instagram.trim();
    if (linkedin) payload.linked_in = linkedin.trim();
    if (twitter) payload.twitter = twitter.trim();

    // Log the payload
    console.log("Payload before sending:", payload);

    setLoading(true);

    try {
      // Make the API call to save social links
      const response = await post_social_links(payload);

      if (response.status === 201) {
        showToast('Social links saved successfully.', 'success');
        
        // Extract the company ID from the response data
        const companyId = response.data?.data?.company; // Corrected path to access company ID
        
        // Navigate to the Profile page with the company ID
        if (companyId) {
          navigate(`/profile/${companyId}`);
        } else {
          showToast('Company ID not found in the response.', 'error');
        }
      } else {
        console.error('API Error:', response.data);
        showToast('Failed to save social links. Please try again.', 'error');
      }
    } catch (error) {
      console.error('Error Response:', error.response?.data || error.message);
      showToast('An error occurred while saving social links.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9]">
      <Sidebar />
      <Toast />
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5 ">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Social Links</h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-1 mx-4 overflow-auto">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          {/* Facebook URL */}
          <div className="flex items-center mb-2 mt-4">
            <img src="./assets/facebook1.svg" alt="Facebook Icon" className="w-6 h-6 mr-2" />
            <p className="font-semibold text-sm lg:text-[16px]">Facebook URL</p>
          </div>
          <InputBox
            placeholder="https://www.facebook.com/username"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />
          {facebookError && <p className="text-red-600 text-sm mt-1">{facebookError}</p>}

          {/* Instagram URL */}
          <div className="flex items-center mt-4 mb-2">
            <img src="./assets/insta.svg" alt="Instagram Icon" className="w-6 h-6 mr-2" />
            <p className="font-semibold text-sm lg:text-[16px]">Instagram URL</p>
          </div>
          <InputBox
            placeholder="https://www.instagram.com/username"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
          {instagramError && <p className="text-red-600 text-sm mt-1">{instagramError}</p>}

          {/* LinkedIn URL */}
          <div className="flex items-center mt-4 mb-2">
            <img src="./assets/linkedin1.svg" alt="LinkedIn Icon" className="w-6 h-6 mr-2" />
            <p className="font-semibold text-xs lg:text-[16px]">LinkedIn URL</p>
          </div>
          <InputBox
            placeholder="https://www.linkedin.com/in/username"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
          />
          {linkedinError && <p className="text-red-600 text-sm mt-1">{linkedinError}</p>}

          {/* Twitter URL */}
          <div className="flex items-center mt-4 mb-2">
            <img src="/assets/twitter.svg" alt="Twitter Icon" className="w-6 h-6 mr-2" />
            <p className="font-semibold text-xs lg:text-[16px]">Twitter URL</p>
          </div>
          <InputBox
            placeholder="https://www.twitter.com/username"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
          />
          {twitterError && <p className="text-red-600 text-sm mt-1">{twitterError}</p>}

          {/* Save Button */}
          <div className="flex justify-center items-center lg:mt-4 mt-5">
            <Button
              text="Save"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSave}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SocialLinks;
