import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { sendOTP } from "../../../Controller/firebase/firebase_phone_verification";
import { showToast } from "../../Components/toast";
import CenteredImageContainer from "../../Components/black_container";
import "./custom_phone_input.css";
import { Toast } from "../../Components/toast";
import { useEffect } from "react";
import { checkPhone } from "../../../Controller/auth";
import ReportContainer from "../../Components/report";

const CompanyDetailsPhone = () => {
  const [phoneValue, setPhoneValue] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to remove search icon
    const removeSearchIcon = () => {
      const searchIcon = document.querySelector(".search-box .search-icon");
      if (searchIcon) {
        searchIcon.remove(); // Remove the icon element from the DOM
      }
    };

    // Use setInterval to check and remove the search icon repeatedly
    const intervalId = setInterval(() => {
      removeSearchIcon();
    }, 500); // Check every 500ms

    return () => {
      clearInterval(intervalId); // Clean up when component is unmounted
    };
  }, []); // Empty dependency array means it runs once after component mounts

  const handleSendOTP = async () => {
    if (!phoneValue) {
      showToast("Please enter a phone number.", "error");
      return;
    }

    setLoading(true);
    try {

      const response = await checkPhone(`+${phoneValue}`);
      if (response && response.exists) {
        showToast(response.message, "error"); // Display backend message if phone exists
      } else {
      const confirmationResult = await sendOTP(`+${phoneValue}`);
      sessionStorage.setItem("verificationId", confirmationResult.verificationId);
      navigate("/phone_verification", {
        state: {
          phone: `+${phoneValue}`,
        },
      });
    }
    } catch (error) {
      console.error("Error sending OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full w-full flex bg-[#FBFCF8]">
      <Toast />
      <div className="absolute top-6 right-6">
      <ReportContainer />
      </div>
      <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
        <div className="w-4/5 h-full">
          <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
        </div>
      </div>
     
      {/* Right Section: Form */}
      <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-8 font-poppins">
      
        <div className="p-6 w-full max-w-2/5">
          {/* ESWAF Logo */}
          <div className="w-full mb-4 flex justify-start">
            <img src="./eswaf-name.svg" alt="ESWAF Logo" className="h-12" />
          </div>

          {/* Phone Verification Heading */}
          <div id="recaptcha-container"></div>

          <div className="w-full mb-4">
            <h1 className="text-[#0D0907] font-semibold font-poppins text-2xl text-left">
              Phone Verification
            </h1>
          </div>

          {/* Instruction */}
          <p className=" text-sm lg:w-full text-left text-[#6B6B6B] font-poppins lg:text-lg mb-5">
            Please enter your phone number for verification
          </p>

          {/* Phone Input with Searchable Country List */}
          <div className="w-full mb-4">
            <PhoneInput
              country={"us"} // Default country
              value={phoneValue}
              onChange={setPhoneValue}
              inputStyle={{
                width: "100%",
                height: "42px",
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
              }}
              buttonStyle={{
                backgroundColor: "#FFFFFF",
                padding: "0.2rem",
                height: "42px",
              }}
              containerStyle={{
                width: "100%",
                border: "1px solid #E8E8E8",
              }}
              placeholder="+1 123 456 789"
              inputClass="w-full bg-[#FFFFFF] border border-gray-300 rounded-lg"
              enableSearch // Enable search in the country dropdown
              searchPlaceholder="Search country" // Add a placeholder for search input
            />
          </div>

          {/* OTP Button */}
          <div className="w-full mt-8">
            <Button
              text="Send OTP"
              borderColor="border-red-600"
              backgroundColor="bg-red-600"
              textColor="text-white"
              id="otp-btn"
              onClick={handleSendOTP}
              loading={loading}
              className="w-full h-12 rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsPhone;
