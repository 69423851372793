import React from 'react';

import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import { useNavigate } from 'react-router-dom';
import ReportContainer from '../../Components/report';

const SampleFormat = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/account_manager');
  };
  const OnClick = () => {
    navigate('/report_page'); 
  };
  return (
    <div className="flex flex-col w-screen h-screen bg-[#F2F3F3] font-techna">
      <Sidebar />
      <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
            <div className="w-full flex justify-start items-center space-x-2">
                
              <img
                src="/Assets/arrow_back.svg"
                alt="Back"
                className="cursor-pointer mr-2 h-6 w-6"
                onClick={handleBackClick}
              />
              <h1 className="text-black font-bold text-xl">Company Details</h1>   </div>
              <ReportContainer/>
          </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="w-4/5 ml-5">
      <div className='flex justify-between  mt-2 max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8' style={{ height: '50px', width: '400px' }}>
          <a href="#" className="text-[#535353] underline text-sm">View Sample Format of Authorization Document</a>
          <img src="Assets/download.svg" alt="download icon" className="w-6 h-6 cursor-pointer" onClick={() => window.location.href = 'path/to/download'} />
        </div>
      <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8 bg-[#6D6D6D] rounded-lg font-techna" style={{ height: '450px', width: '350px' }}>
        
       
      </div>
      <div className=" max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8  rounded-lg font-techna" style={{ height: '40px', width: '400px' }}>
        <Button
          text="Back"
          borderColor="border-red-600"
          backgroundColor="bg-[#DB0000]"
          textColor="text-white"
          navigateTo="/account_manager"
        />
      </div>
    </div>
    </div>
  );
};

export default SampleFormat;
