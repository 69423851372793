// authController.js
import { resetPasswordMail } from './auth';
import { showToast } from '../View/Components/toast';

export const sendResetPasswordEmail = async (email, navigate, setLoading) => {
  if (!email) {
    showToast('Please provide an email', 'error');
    return;
  }

  setLoading(true);

  try {
    console.log('Sending reset password email:', email);

    // Store the email in localStorage
    localStorage.setItem('email', email);
    console.log('Email stored in localStorage:', localStorage.getItem('email'));

    const response = await resetPasswordMail(email);

    if (response.status === 200) {
      console.log('Response:', response);
      // showToast('Reset password email sent successfully!', 'success');
      navigate('/reset-password-otp-page');
    } else {
      // Handle error if the response status is not 200
      showToast('Failed to send reset password email. Please try again.', 'error');
    }
  } catch (error) {
    // Handle network errors or any other errors during the request
    console.error('Error while sending reset password email:', error);
    // showToast('An error occurred. Please try again later.', 'error');
  } finally {
    setLoading(false);
  }
};

