import React, { useState } from 'react';
import Sidebar from '../../Components/main_navbar';
import { useNavigate } from 'react-router-dom';
import InputBox from '../../Components/input_box';
import PasswordInputField from '../../Components/password-input-field';
import Button from '../../Components/button';

const Security = () => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleBackClick = () => {
    navigate('/payment_successful');
  };

  const handleForgotPasswordClick = () => {
    navigate('/forgot_password'); // replace with the actual path you want to navigate to
  };

  return (
    <div className="flex flex-col w-screen h-screen bg-[#F2F3F3] font-techna">
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
         
          <h1 className="text-black font-bold text-xl">Security</h1>
        </div>
      </div>
      <div className="border-b border-black w-screen mt-4 md:mt-[25px]"></div>
      <Sidebar />

      <div className="flex flex-1 justify-center w-4/5 ml-7">
        <div className='sm:w-2/3 md:w-1/3 lg:w-1/3 mt-10'>
          <p className='font-bold text-lg'>Password</p>
          <div className='mt-4'>
            <InputBox
              placeholder="Current Password"
              border="1px solid #ccc"
              marginBottom='8px'
            />
            <PasswordInputField
              placeholder="New Password"
              name="password"
              onChange={handlePasswordChange}
              backgroundColor="#F2F3F3"
              border="1px solid #ccc"
            />
            <PasswordInputField
              placeholder="Retype New Password"
              name="password"
              onChange={handlePasswordChange}
              backgroundColor="#F2F3F3"
              border="1px solid #ccc"
              inputClassName="placeholder-red-600 font-helvetica"
            />
            <div className="flex justify-end mt-2">
              <a 
                href="#" 
                className="text-[#DB0000] text-sm"
                onClick={(e) => {
                  e.preventDefault(); // prevent the default anchor behavior
                  handleForgotPasswordClick();
                }}
              >
                Forgot your password?
              </a> 
              

            </div>
            <div className='mt-10'>
            <Button
                  text="Save"
                  backgroundColor="bg-[#DB0000]"
                  textColor="text-white" 
                  navigateTo="/forgot_password"
                  
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
