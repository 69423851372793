

import React, { useState, useEffect,useRef } from 'react';
import { collection, addDoc, serverTimestamp, onSnapshot, query, orderBy, updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase_service';
import { uploadChatImage } from '../../Controller/firebase/chat_image_upload';
import { useNavigate } from 'react-router-dom';
import DeletePopup from './delete_pop_up';
import LoaderSpinner from './loader_spinner';
import { showToast} from './toast';

const ChatWindow = ({ message: initialMessage, selectedUser, currentUserUid }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isImportant, setIsImportant] = useState(false); // State for importance
  const [showPopup, setShowPopup] = useState(false); 
  const [lastSeen, setLastSeen] = useState(''); 
  const [popupMessage, setPopupMessage] = useState('');// State to hold the last seen status
  const [isUploading, setIsUploading] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockPopupMessage, setBlockPopupMessage] = useState('');
  const [showBlockPopup, setShowBlockPopup] = useState(false);
  const [selectedUserImage, setSelectedUserImage] = useState(selectedUser?.userImage);
  const [currentUserImage, setCurrentUserImage] = useState('');

  const navigate = useNavigate();
  const messageEndRef = useRef(null); // Ref to track the end of the message list
  // Ensure consistent image preview and sent image size
  const IMAGE_WIDTH = 150;
  const IMAGE_HEIGHT = 150;

 // Function to calculate the last seen status
const getLastSeenStatus = (user) => {
  if (user.status === 'Online') {
    return 'Online';
  }

  if (user.lastOnlineAt) {
    const lastOnline = new Date(user.lastOnlineAt.seconds * 1000); // Convert Firestore timestamp
    const now = new Date();
    const diffTime = Math.abs(now - lastOnline);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffMinutes = Math.floor(diffTime / (1000 * 60));

    if (diffDays > 0) {
      return `Last seen: ${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    } else if (diffHours > 0) {
      return `Last seen: ${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    } else if (diffMinutes > 0) {
      return `Last seen: ${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
    } else {
      return 'Last seen: Just now';
    }
  }

  return 'Last seen: Unknown';
};

useEffect(() => {
  const fetchBlockingStatus = async () => {
    if (initialMessage && initialMessage.id) {
      const docRef = doc(db, 'chats', initialMessage.id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const chatData = docSnapshot.data();
        const isCurrentUserSender = currentUserUid === chatData.senderUid;
        const blockField = isCurrentUserSender ? 'isBlockedBySender' : 'isBlockedByReceiver';
        setIsBlocked(chatData[blockField]);
      }
    }
  };

  fetchBlockingStatus();
}, [initialMessage, currentUserUid]);

const handleBlockUser = () => {
  setBlockPopupMessage(isBlocked ? 'Are you sure you want to unblock this user?' : 'Are you sure you want to block this user?');
  setShowBlockPopup(true);
};
const handleConfirmBlock = async () => {
  if (initialMessage && initialMessage.id) {
    try {
      const docRef = doc(db, 'chats', initialMessage.id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const chatData = docSnapshot.data();
        const isCurrentUserSender = currentUserUid === chatData.senderUid;
        const updateField = isCurrentUserSender ? 'isBlockedBySender' : 'isBlockedByReceiver';

        // Toggle the blocking status in Firestore
        await updateDoc(docRef, {
          [updateField]: !isBlocked
        });

        setIsBlocked(!isBlocked);
      }
    } catch (error) {
      console.error('Error toggling block status:', error);
    }
  }
  setShowBlockPopup(false);
};



// Function to update user status to 'Online' or 'Offline'
const updateUserStatus = async (status) => {
  if (currentUserUid) {
    const userRef = doc(db, 'users', currentUserUid);
    await updateDoc(userRef, {
      status: status,
      lastOnlineAt: status === 'Offline' ? serverTimestamp() : null,
    });
  }
};

// Handling visibility change (tab close, switching tabs)
const handleVisibilityChange = () => {
  if (document.hidden) {
    updateUserStatus('Offline');
  } else {
    updateUserStatus('Online');
  }
};

useEffect(() => {
  // Set initial online status when component mounts
  updateUserStatus('Online');

  // Add event listeners to handle visibility change and tab close
  window.addEventListener('beforeunload', () => updateUserStatus('Offline'));
  document.addEventListener('visibilitychange', handleVisibilityChange);

  // Cleanup: set status to 'Offline' when component unmounts
  return () => {
    updateUserStatus('Offline');
    window.removeEventListener('beforeunload', () => updateUserStatus('Offline'));
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, [currentUserUid]);

// Subscribing to selected user's status updates (for last seen)
useEffect(() => {
  if (selectedUser) {
    const userDocRef = doc(db, 'users', selectedUser.id);
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        const lastSeenStatus = getLastSeenStatus(userData);
        setLastSeen(lastSeenStatus); // Set last seen status
      }
    });

    return () => unsubscribe();
  }
}, [selectedUser]);

  // Update useEffect to fetch and set last seen status
  useEffect(() => {
    if (!initialMessage || !selectedUser || !initialMessage.id) return;

    const messagesRef = collection(db, 'chats', initialMessage.id, 'messages');
    const q = query(messagesRef, orderBy('sentTime', 'asc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map(doc => {
        const messageData = doc.data();
        return {
          id: doc.id,
          ...messageData,
          isCurrentUser: messageData.senderUid === currentUserUid
        };
      });
      setMessages(fetchedMessages);
      scrollToBottom();

      if (fetchedMessages.length > 0 && 
        !initialMessage.lastMessageSeen && 
        initialMessage.lastMessageUid !== currentUserUid && 
        selectedUser) {
      updateLastMessageSeen(initialMessage.id);
    }
  });

    return () => unsubscribe();
  }, [initialMessage, selectedUser, currentUserUid]);

    // // Scroll to the bottom of the message list
    // const scrollToBottom = () => {
    //   messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    // };

    const scrollToBottom = () => {
      if (!isBlocked) {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    };
    
  
    // Scroll to the bottom when a new message is sent or received
    // useEffect(() => {
    //   scrollToBottom();
    // }, [messages]);
    useEffect(() => {
      scrollToBottom();
    }, [messages, isBlocked]); // Add isBlocked as a dependency
    

  const updateLastMessageSeen = async (chatId) => {
    try {
      const chatRef = doc(db, 'chats', chatId);
      await updateDoc(chatRef, {
        lastMessageSeen: true,
        lastMessageSeenTime: serverTimestamp()
      });
    } catch (error) {
      console.error('Error updating lastMessageSeen:', error);
    }
  };

  
  
  
    useEffect(() => {
      // Listen to changes in the current user's profile image
      if (currentUserUid) {
        const currentUserRef = doc(db, 'users', currentUserUid);
        const unsubscribeCurrentUser = onSnapshot(currentUserRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setCurrentUserImage(userData.profileImage); // Set the current user's updated image
          }
        });
  
        // Cleanup listener on component unmount
        return () => unsubscribeCurrentUser();
      }
    }, [currentUserUid]);
  
    useEffect(() => {
      // Listen to changes in the selected user's profile image
      if (selectedUser?.id) {
        const selectedUserRef = doc(db, 'users', selectedUser.id);
        const unsubscribeSelectedUser = onSnapshot(selectedUserRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setSelectedUserImage(userData.profileImage); // Set the selected user's updated image
          }
        });
  
        // Cleanup listener on component unmount
        return () => unsubscribeSelectedUser();
      }
    }, [selectedUser]);

 
// Fetch the initial importance status from Firestore when the component mounts
useEffect(() => {
  const fetchImportanceStatus = async () => {
    if (initialMessage && initialMessage.id) {
      const docRef = doc(db, 'chats', initialMessage.id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const chatData = docSnapshot.data();
        const isCurrentUserSender = currentUserUid === chatData.senderUid;
        const importanceField = isCurrentUserSender ? 'isImportantForSender' : 'isImportantForReceiver';
        
        // Set the local importance state based on Firestore data
        setIsImportant(chatData[importanceField]);
      }
    }
  };

  fetchImportanceStatus();
}, [initialMessage, currentUserUid]);



  // Handle sending messages
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (isBlocked) {
      showToast('User is blocked. Unblock to send messages.', 'error');
      return;
    }
    console.log("Attempting to send message"); // Debug log

    if (!initialMessage || (!newMessage.trim() && !selectedFile)) {
      console.log("No message to send"); // Debug log
      return;
    }
    if (!selectedUser || !selectedUser.id) {
      console.error("Selected user or user ID is undefined");
      return;
    }
  
    try {
      console.log("Preparing message data"); // Debug log
      let messageData = {
        isSeen: false,
        message: newMessage.trim() || '',
        messageType: selectedFile ? 1 : 0, // 0 for text, 1 for image
        receiverUid: selectedUser.id,
        senderUid: currentUserUid,
        sentTime: serverTimestamp()
      };

      if (selectedFile) {
        setIsUploading(true); // Start showing the loader
        const imageUrl = await uploadChatImage(initialMessage.id, selectedFile, { id: currentUserUid });
        messageData.message = imageUrl;
        setIsUploading(false); // Stop showing the loader
      }

       // Add the message to the 'messages' subcollection
       console.log("Adding message to Firestore", messageData); // Debug log
       const docRef = await addDoc(collection(db, 'chats', initialMessage.id, 'messages'), messageData);
       console.log("Message added with ID: ", docRef.id); // Debug log
 

       console.log("Updating chat document"); // Debug log
       await updateDoc(doc(db, 'chats', initialMessage.id), {
         lastMessage: newMessage.trim() || (selectedFile ? 'Sent an image' : ''),
         lastMessageTime: serverTimestamp(),
         lastMessageUid: currentUserUid,
         lastMessageSeen: false,
       });
       const receiverRef = doc(db, 'users', selectedUser.id);
       await updateDoc(receiverRef, {
         newChatNotification: true
       });

       console.log("Message sent successfully"); // Debug log
       setNewMessage('');
       setSelectedFile(null);
       setImagePreview('');
       scrollToBottom(); // Scroll to bottom after sending the message
     } catch (error) {
       console.error('Error sending message:', error);
       setIsUploading(false); // Ensure loader is hidden if there's an error
     }
   };

 // Handle toggling message importance
 const handleToggleImportant = () => {
  // Set the appropriate message based on the current importance status
  if (isImportant) {
    setPopupMessage('Are you sure you want to remove this chat from important messages?');
  } else {
    setPopupMessage('Are you sure you want to move this chat to important messages?');
  }
  setShowPopup(true); // Show the popup before toggling the important status
};
  // Confirm moving to important messages
  const handleConfirmImportant = async () => {
    if (initialMessage && initialMessage.id) {
      try {
        const docRef = doc(db, 'chats', initialMessage.id);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const chatData = docSnapshot.data();
          const isCurrentUserSender = currentUserUid === chatData.senderUid;
          const updateField = isCurrentUserSender ? 'isImportantForSender' : 'isImportantForReceiver';

          const currentImportanceStatus = chatData[updateField];

          // Toggle the importance status in Firestore
          await updateDoc(docRef, {
            [updateField]: !currentImportanceStatus
          });

          setIsImportant(!currentImportanceStatus);

          // Navigate to the appropriate page based on the new status
          if (!currentImportanceStatus) {
            navigate('/important_message');
          } else {
            navigate('/direct_message');
          }
        }
      } catch (error) {
        console.error('Error toggling importance:', error);
      }
    }
    setShowPopup(false); // Hide the popup
  };

  // Close the popup without confirming
  const handleClosePopup = () => {
    setShowPopup(false);
  };



  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  
    // Reset the file input to allow picking the same file again
    e.target.value = null;
  };
  

  // Remove image preview
  const removeImagePreview = () => {
    setSelectedFile(null);
    setImagePreview('');
  };

  // Format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Toggle the menu open/close
  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleInputClick = () => {
    if (isBlocked) {
      console.log("Blocked user toast triggered"); // Debug log
      showToast('User is blocked. Unblock to send messages.', 'error');
    }
  };
  const handleReportclick = () => {
    navigate('/report_page');
  }
  
  const handleProfileClick = () => {
    if (initialMessage && initialMessage.receiverId) {
      navigate(`/profile/${initialMessage.receiverId}`);
    } else {
      console.error("Receiver ID not found in the initial message.");
    }
  };
  
  return (
    <>
    <div className="flex flex-col w-full h-full ">
      <div className="flex justify-between items-center border-b p-2 bg-[#F9F9F9] lg:w-[78%] w-full">
        <div className="flex items-center space-x-4" onClick={handleProfileClick}>
        <img 
    src={selectedUserImage || '/Assets/default_profile.svg'} // Use updated image from state or default image
    alt={selectedUser?.userName || 'User'}
    className="w-10 h-10 rounded-full" 
  />
          <div>
            <h2 className="font-semibold lg:text-lg text-sm">{selectedUser?.userName || 'Unknown User'}</h2>
            <p className="text-xs text-gray-500">{lastSeen}</p> {/* Show last seen */}
          </div>
        </div>
        <div className="flex items-center space-x-4 relative">
        <button className="p-2" onClick={handleToggleImportant}>
  <img 
    src={isImportant ? "/Assets/important_star.svg" : "/Assets/move_important.svg"} 
    alt="Important" 
    className="w-7 h-7" 
  />
</button>

          <button className="p-2" onClick={handleMenuToggle}>
            <img src="/Assets/dots.svg" alt="More options" className="w-6 h-5" />
          </button>
          {isMenuOpen && (
              <div className="absolute right-0 mt-20 w-48 bg-white shadow-xl rounded-lg z-50 p-2">
                {/* Close button inside the menu */}
                <button
                  className="absolute top-2 right-2 bg-transparent text-black hover:text-gray-500 text-xl"
                  onClick={handleCloseMenu}
                >
                  &times;
                </button>

                {/* Menu items */}
                <div className="flex flex-col items-center py-4 mt-2">
                  <div className="flex items-center py-2 px-3 w-full hover:bg-gray-100 cursor-pointer text-black">
                    <img
                      src="/Assets/report_icon.svg"
                      alt="Report Icon"
                      className="w-5 h-5 mr-3"
                      onClick={handleReportclick}
                    />
                    <span className="text-sm font-medium">Report an Issue</span>
                  </div>
                  <hr className="w-full border-gray-200 my-2" />
                  <div
                    className="flex items-center py-2 px-3 w-full hover:bg-gray-100 cursor-pointer text-black"
                    onClick={handleBlockUser}
                  >
                    <img
                      src={isBlocked ? "/Assets/unblock_new.svg" : "/Assets/block_new.svg"}
                      alt={isBlocked ? "Unblock Icon" : "Block Icon"}
                      className="w-5 h-5 mr-3"
                    />
                    <span className="text-sm font-medium">
                      {isBlocked ? "Unblock User" : "Block User"}
                    </span>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

      {/* <div className="flex-grow overflow-y-auto p-4 max-h-screen lg:mb-40  lg:pb-0" > */}
      <div
        className="flex-grow overflow-y-auto p-4 max-h-screen lg:mb-24 lg:pb-0 lg:w-[78%] "
        style={{ maxHeight: window.innerWidth < 1024 ? 'calc(100vh - 160px)' : 'none' }}
      >
      {messages.map((msg) => (
      <div key={msg.id} className={`flex flex-col mb-3 ${msg.isCurrentUser ? 'items-end' : 'items-start'}`}>
      <div 
        className={`p-3 text-xs lg:text-sm rounded-lg ${msg.isCurrentUser ? 'bg-[#DB0000] text-white' : 'bg-gray-200'}`}
        style={{
          maxWidth: '55%', // Limit message width to 75% of the container
          wordBreak: 'break-word', // Break words to avoid overflow
          backgroundColor: msg.message.includes('firebase') ? 'transparent' : undefined, // Handle image messages
          padding: msg.message.includes('firebase') ? 0 : undefined,
        }}
      >
        {msg.message.includes('firebase') ? (
          <img 
          src={msg.message} 
          alt="Chat Image" 
          className="rounded-lg" 
          style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT, objectFit: 'cover' }}
        />
        ) : (
          <p>{msg.message}</p>
        )}
      </div>
      <span className="text-xs text-gray-500 mt-1">
        {formatTimestamp(msg.sentTime)}
      </span>
    </div>
    
      
))}
 {/* Add the reference div here */}
 {!isBlocked && <div ref={messageEndRef}></div>}

 
 </div>
 
</div>
  


  
    
      
    
    {imagePreview && (
        <div className="fixed bottom-14 right-4 lg:mr-64 lg:mb-8  p-4 bg-[#F9F9F9] shadow-xl rounded-lg">
          {isUploading ? (
            <div 
              className="image-upload-loader flex items-center justify-center"
              style={{ 
                width: IMAGE_WIDTH, 
                height: IMAGE_HEIGHT,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                borderRadius: '8px'
              }}
            >
              <LoaderSpinner visible={true} height={30} />
            </div>
          ) : (
            <img 
              src={imagePreview} 
              alt="Preview" 
              className="rounded-lg object-cover"
              style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }} 
            />
          )}
          <button
            onClick={removeImagePreview}
            className="absolute top-2 right-2 bg-black text-white rounded-full p-1 flex items-center justify-center w-6 h-6"
          >
            &times;
          </button>
        </div>
      )}
    <form onClick={handleInputClick} onSubmit={handleSendMessage} className="lg:mb-2 fixed bottom-0 lg:w-[58%] w-full bg-white border-t ">
  <div className="flex items-center justify-between p-2">
    {/* Write a message input with border and background color */}
    <div onClick={handleInputClick} className="flex-grow bg-[#D9D9D9] rounded-lg h-12 flex items-center px-4">
    <input 
              type="text" 
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Write a message" 
              className="w-full bg-transparent outline-none text-sm"
              disabled={isBlocked} 
              onClick={handleInputClick} // Ensure this is here
           
            />
    </div>

    {/* Paperclip and Send button outside the input container */}
    <div className="flex items-center ml-2">
    <input 
              type="file" 
              accept="image/*" 
              onChange={handleFileChange} 
              className="hidden"
              id="imageUpload"
              disabled={isBlocked} // Disable file upload if user is blocked
            />
      <label htmlFor="imageUpload" className="cursor-pointer p-2">
        <img src="/Assets/paperclip.svg" alt="Attach" className="w-7 h-7" />
      </label>
      <button type="submit" className="p-2 ml-1" disabled={isBlocked}>
              <img src="/Assets/send_message.svg" alt="Send" className="w-7 h-7" />
            </button>
    </div>
  </div>
</form>
{showBlockPopup && (
          <DeletePopup
            message={blockPopupMessage}
            onConfirm={handleConfirmBlock}
            onClose={() => setShowBlockPopup(false)}
          />
        )}

        {/* Blocked status message */}
        {isBlocked && (
          <div className="text-center py-4 bg-gray-100 text-gray-600">
            This user is blocked. Unblock to continue chatting.
          </div>
        )}
   {showPopup && (
        <DeletePopup 
        message={popupMessage} 
          onConfirm={handleConfirmImportant}
          onClose={handleClosePopup}
        />
      )}
   </>
  );
};

export default ChatWindow;
