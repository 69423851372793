import React from 'react';
import { categoryMapping } from './category';

const PostCard = ({ post, onView }) => {
  // Check if post has a nested data object (in case of POST request response)
  const postData = post.data || post; // Handle both cases: direct data or nested data

  return (
    <div
      className="bg-white rounded-lg p-4 shadow-md w-full mb-4 cursor-pointer"
      onClick={() => onView(postData.id)} // Handle onClick with the correct ID
    >
      <div className="flex">
        <img
          src={postData.profile_image_url || '/profile.webp'} // Fallback image if profile image is missing
          alt="Post"
          className="rounded-full w-12 h-12 mr-4 flex-shrink-0"
        />
        <div className="flex flex-col overflow-hidden"> {/* Added overflow-hidden here */}
          {/* Company Name */}
          <div className="font-poppins font-bold text-[#181717] truncate"> {/* Added truncate */}
            {postData.company_name || 'Unknown Company'}
          </div>
          {/* Name and Category */}
          <div className="text-[#181717] font-semibold text-sm font-gotham truncate"> {/* Added truncate */}
            {postData.name || 'No name provided'}
          </div>
          <div className="text-[#181717] font-bold text-sm text-[#6B6B6B] font-gotham truncate"> {/* Added truncate */}
          {categoryMapping[postData.category] || 'No category provided'}
          </div>
          {/* Details */}
          <div className="text-sm mt-2 font-gotham text-[#6B6B6B] line-clamp-2"> {/* Keep line-clamp-2 */}
            {postData.details 
              ? postData.details.length > 100 
                ? `${postData.details.substring(0, 50)}...` 
                : postData.details
              : 'No details available'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;