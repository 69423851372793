import React, { useEffect, useState, useRef } from 'react';
import VideoCard from './video_display_card';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom'; 
import { get_all_User_video } from '../../Controller/upload_videos';
import { FaSpinner } from 'react-icons/fa';

const DEFAULT_THUMBNAIL = './explore2.svg'; 

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5 // Changed to 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6 // Changed to 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const CustomLeftArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-0 w-10 h-10 ml-5"
    style={{ top: '50%', transform: 'translateY(80%)', left: '-2.8rem' }}
  >
    <img src={'/left_arrow.svg'} alt="Previous" className="w-6 h-6 cursor-pointer" />
  </button>
);

const CustomRightArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute w-10 h-10 mr-2"
    style={{ 
      top: '50%', 
      transform: 'translateY(80%)', 
      right: '-45px', 
      zIndex: 10 
    }}
  >
    <img src={'/right_arrow.svg'} alt="Next" className="w-6 h-6 cursor-pointer" />
  </button>
);

const Discover = () => {
  const [videos, setVideos] = useState([]);
  const carouselRef = useRef();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchVideos = async () => {
    try {
      const response = await get_all_User_video(0, 100); // Get all videos at once
      console.log('API Response:', response.data);

      if (response.status === 200) {
        if (Array.isArray(response.data.data)) {
          const videoData = response.data.data.map(video => ({
            ...video,
            thumbnail: video.thumb_nail_url || DEFAULT_THUMBNAIL
          }));
          setVideos(videoData);
        } else {
          console.error('Error: Expected an array but got something else:', response.data.data);
        }
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const handleThumbnailClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  return (
    <div className="relative lg:w-[96%] h-full mt-7 mx-6 sm:w-full">
      {loading && (
        <div className="absolute inset-0 flex justify-center items-center z-50 bg-opacity-50">
          <FaSpinner className="animate-spin text-3xl text-gray-600" /> {/* Spinner icon */}
        </div>
      )}

      {!loading && (
        <>
          <Carousel
            ref={carouselRef}
            responsive={responsive}
            infinite={false}
            arrows={false}
            slidesToSlide={1}  // This makes it move one at a time
            partialVisible={false}  // Don't show partial items
          >
            {videos.map((video, index) => (
              <div key={index} className="p-1" onClick={() => handleThumbnailClick(video.id)} style={{ marginRight: '8px' }}>
                <VideoCard videoUrl={video.video_url} thumbnail={video.thumbnail} />
              </div>
            ))}
          </Carousel>

          {videos.length > 5 && (
            <>
              <CustomLeftArrow onClick={() => carouselRef.current.previous()} />
              <CustomRightArrow onClick={() => carouselRef.current.next()} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Discover;