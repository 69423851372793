import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';

const EditPopup = ({ show, onClose, heading, message, icon }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleContinueClick = async () => {
    setIsLoading(true);
    try {
      // Simulate async operation
      await new Promise((resolve) => setTimeout(resolve, 1000));
      onClose();
      navigate('/profile1');
    } catch (error) {
      console.error('Error during navigation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 font-poppins">
      <div className="bg-white rounded-lg p-6 w-80 text-center relative">
        <div className="flex justify-center mb-4">
        <img src='./pop-up.svg' alt="Info" className="w-12 h-12" />
        </div>
        <p className="text-black mb-4">{message}</p>
        <div className="flex justify-center">
          <button
            className="bg-[#DB0000] text-[#FFFFFF] px-4 py-2 rounded-lg mt-4 flex items-center justify-center font-poppins"
            onClick={handleContinueClick}
            disabled={isLoading}
          >
            {isLoading ? <ScaleLoader color="#FFFFFF" height={10} /> : 'Continue'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditPopup;
