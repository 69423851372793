// import React, { useState } from 'react';



// import { useNavigate } from 'react-router-dom';
// import Sidebar from '../../Components/main_navbar';
// import Button from '../../Components/button';

// const Verified = () => {
//   // Define state for phone value
  

//   const navigate = useNavigate();
      
//   const handleBackClick = () => {
//     navigate('/details_phone');
//   };
//   const OnClick = () => {
//     navigate('/'); 
//   };
//   return (
//     <div className="flex flex-col w-screen h-screen bg-[#F2F3F3] font-techna">
//       <Sidebar />
//       <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
//             <div className="w-full flex justify-start items-center space-x-2">
                
             
//               <h1 className="text-black font-bold text-xl">Number</h1>   </div>
//                <div className="mr-5 md:mr-7 pr-5 flex self-end"> 
//           <img
//             src="/Assets/dots.svg" // Make sure this path is correct
//             alt="Upload"
//             className="cursor-pointer h-5 w-5"
//             onClick={OnClick}
//           />
//              </div>
//           </div>
     
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="w-4/5 ml-10">
//       <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8 bg-[#F9F9F9] rounded-lg font-techna mt-5" style={{ height: '600px', width: '450px' }}>
//       <div className="flex items-center mt-2 mb-4 space-x-3 ml-5">
//           <img src="Assets/phone.svg" alt="company icon" className="w-6 h-6 " />
//           <p className="font-bold ">Phone Verification</p>
//         </div>
//         <div className='flex justify-center'>
//         <img src="Assets/phone_otp.svg" alt="company icon" className="w-60 h-72 mt-9"/>
//         </div>
//         <div className='flex justify-center space-x-3 mt-3 ' >
//         <img src="./tick.svg"  className='w-7 h-7'></img>
//         <p className="font-bold text-lg">Verified</p>
//         </div>
        
//      <div className="flex justify-center mt-10">
//         <Button
//           text="Next"
//           borderColor="border-red-600"
//           backgroundColor="bg-[#DB0000]"
//           textColor="text-white"
//           navigateTo="/details_page"
//         />
//         </div>
        
//       </div>
//     </div>
//     </div>
//   );
// };

// export default Verified;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import ReportContainer from '../../Components/report';
import { showToast, Toast } from '../../Components/toast'; // Import the toast functions

const Verified = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Add loading state
  const handleBackClick = () => {
    navigate("/phone_verification");
  };
  const handleNextClick = () => {
    setLoading(true);

    
      localStorage.setItem('phoneCompleted', 'true');


      navigate('/confirm-password');

      setLoading(false);
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-techna">
      <Sidebar />
      <Toast />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="cursor-pointer mr-2 h-6 w-6"
            onClick={handleBackClick}
          />
          <h1 className="text-[#171717] font-normal sm:text-lg lg:text-xl font-techna">Phone</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-8 mt-4 p-4">
      <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg" >
          <div className="flex items-center mt-5 space-x-3">
            <img src="Assets/phone.svg" alt="company icon" className="w-6 h-6" />
            <p className="text-[#171717] font-normal sm:text-lg lg:text-xl font-techna">Phone Verification</p>
          </div>
          <div className="flex justify-center">
            <img src="Assets/phone_otp.svg" alt="company icon" className="w-60 h-72 mt-9" />
          </div>
          <div className="flex justify-center space-x-3 mt-3">
            <img src="./tick.svg" className="w-7 h-7" />
            <p className="font-normal sm:text-lg lg:text-xl">Verified</p>
          </div>
          <div className="flex justify-center mt-10">
            <Button
              text="Next"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleNextClick}
              loading={loading} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verified;
