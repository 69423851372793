import { ref, getDownloadURL, uploadString } from "firebase/storage";
import { storage } from "../../firebase_service";

const uploadThumbnailToFirebase = (base64Image) => {
  return new Promise((resolve, reject) => {
    if (!base64Image) {
      reject("No image provided");
      return;
    }

    const filename = `thumbnail_${Date.now()}.jpg`;
    const storageRef = ref(storage, `thumbnailUpload/${filename}`);

    uploadString(storageRef, base64Image, 'data_url').then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        console.log("Thumbnail available at", downloadURL);
        resolve(downloadURL);
      }).catch((error) => {
        console.error("Error getting download URL:", error);
        reject(error);
      });
    }).catch((error) => {
      console.error("Error uploading thumbnail:", error);
      reject(error);
    });
  });
};

export default uploadThumbnailToFirebase;