
// import React, { useState } from 'react';
// import InputBox from '../../Components/input_box';
// import ReportContainer from '../../Components/report';
// import Sidebar from '../../Components/main_navbar';
// import LogoUploader from '../../Components/circle_avatar';
// import { useNavigate } from 'react-router-dom';
// import Button from '../../Components/button';
// import uploadImage from '../../../Controller/firebase/firebase_upload_image';

// const CompanyLogo = () => {
//   const [logo, setLogo] = useState(localStorage.getItem('profile_image_url') || null);
//   const [companyName, setCompanyName] = useState(localStorage.getItem('name') || '');
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   const handleBackClick = () => {
//     navigate('/details_page');
//   };

//   const handleImageUpload = async (file, preview) => {
//     setLogo(preview); // Set the preview image first

//     try {
//       const downloadURL = await uploadImage(file);
//       console.log("File uploaded, download URL:", downloadURL);
//       setLogo(downloadURL);
//       localStorage.setItem('profile_image_url', downloadURL); // Save uploaded image URL to localStorage
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       setError("Failed to upload image. Please try again.");
//     }
//   };

//   const handleSave = () => {
//     localStorage.setItem('name', companyName);
//     navigate('/account_manager');
//   };

//   return (
//     <div className="flex flex-col w-screen h-screen bg-[#F2F3F3] font-techna">
//       <Sidebar />
//       <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <img
//             src="/Assets/arrow_back.svg"
//             alt="Back"
//             className="cursor-pointer mr-2 h-6 w-6"
//             onClick={handleBackClick}
//           />
//           <h1 className="text-black font-bold sm:text-lg lg:text-xl">Company Details</h1>
//         </div>
//         <ReportContainer />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="w-4/5 ml-7">
//         <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8 bg-[#F2F3F3] rounded-lg font-techna" style={{ height: '600px', width: '450px' }}>
//           <h2 className="text-xl md:text-xl font-bold mb-4 text-[#171717]">Company Logo</h2>
//           <div className="flex justify-center p-4 md:p-6 lg:p-8">
//             <LogoUploader
//               logo={logo}
//               onUpload={handleImageUpload}
//               error={error}
//               onError={setError}
//             />
//           </div>
//           <div className="flex items-center mt-20">
//             <img src="Assets/company.svg" alt="company icon" className="w-8 h-8 mr-2 mb-2" />
//             <p className="font-bold">Company Name</p>
//           </div>
//           <div className="mb-6">
//             <InputBox
//               placeholder="Company Name"
//               value={companyName}
//               onChange={(e) => setCompanyName(e.target.value)}
//               borderRadius='1px'
//             />
//           </div>
//           <div className="mt-20">
//             <Button
//               text="Save"
//               borderColor="border-red-600"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleSave}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyLogo;

import React, { useState, useEffect } from 'react';
import InputBox from '../../Components/input_box';
import ReportContainer from '../../Components/report';
import Sidebar from '../../Components/main_navbar';
import LogoUploader from '../../Components/circle_avatar';
import { useNavigate } from 'react-router-dom';
import Button from '../../Components/button';
import LoaderSpinner from '../../Components/loader_spinner'; // Import LoaderSpinner component
import uploadImage from '../../../Controller/firebase/firebase_upload_image';
import { update_advanced_api, update_company_name_url } from '../../../Controller/company_details';
import { showToast, Toast } from '../../Components/toast';

const CompanyLogo = () => {
  const [profile_image_url, setLogo] = useState(localStorage.getItem('profile_image_url') || '');
  const [name, setCompanyName] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Profile image URL or name updated:", profile_image_url, name);
  }, [profile_image_url, name]);

  const handleBackClick = () => {
    navigate('/details_page');
  };

  const handleImageUpload = async (file, preview) => {
    setLogo(preview); // Set the preview image first
    setError(null); // Clear any previous error

    try {
      const downloadURL = await uploadImage(file);
      setLogo(downloadURL);
      localStorage.setItem('profile_image_url', downloadURL); // Save uploaded image URL to localStorage
    } catch (error) {
      console.error("Failed to upload image:", error);
      setError("Failed to upload image."); // Set error message on upload failure
      showToast("Failed to upload image.", 'error'); // Show toast error message
    }
  };

  const handleSave = async () => {
    if (!name && !profile_image_url) {
      showToast("Please provide both company name and logo.", 'error');
      return;
    }

    if (!name) {
      showToast("Please provide a company name.", 'error');
      return;
    }

    if (!profile_image_url) {
      showToast("Please upload a company logo.", 'error');
      return;
    }

    setLoading(true); // Set loading state to true

    localStorage.setItem('name', name);
    localStorage.setItem('companyLogoCompleted', 'true');

    const companyNamePayload = { name: name }; // Adjust the payload as needed
    const advancedPayload = {
      profile_image_url: profile_image_url,
      // Add other fields required by your API
    };

    try {
      const nameResponse = await update_company_name_url(companyNamePayload);
      console.log("Company Name API Response:", nameResponse);
      if (nameResponse.status !== 202) {
        console.error("Company Name API Response Error:", nameResponse);
        showToast("Failed to update company name.", 'error'); // Show toast error message
        setLoading(false); // Set loading state to false
        return;
      }

      const advancedResponse = await update_advanced_api(advancedPayload);
      console.log("Advanced API Response:", advancedResponse);

      console.log("Navigation to /account_manager");
      showToast("Company details updated successfully.", 'success'); // Show toast success message
      navigate('/account_manager');
    } catch (error) {
      console.error("Error updating company details:", error);
      showToast("Error updating company details.", 'error'); // Show toast error message
      setLoading(false); // Set loading state to false
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna">
      <Sidebar />
      <Toast />  
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="cursor-pointer mr-2 h-6 w-6"
            onClick={handleBackClick}
          />
          <h1 className="text-[#171717] font-bold sm:text-lg lg:text-xl">Company Details</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="w-4/5 ml-7">
        <div className="max-w-full md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8 bg-[#F2F3F3] rounded-lg font-techna" style={{ height: '600px', width: '450px' }}>
          <h2 className="sm:text-lg lg:text-xl font-bold mb-4 text-[#171717]">Company Logo</h2>
          <div className="flex justify-center p-4 md:p-6 lg:p-8">
            <LogoUploader
              logo={profile_image_url}
              onUpload={handleImageUpload}
              error={error}
              onError={setError}
              showEditIcon={true}  // Disable the edit icon
            />
          </div>
          <div className="flex items-center mt-20">
            <img src="Assets/company.svg" alt="company icon" className="w-8 h-8 mr-2 mb-2" />
            <p className="font-bold">Company Name</p>
          </div>
          <div className="mb-6">
            <InputBox
              placeholder="Company Name"
              value={name}
              onChange={(e) => setCompanyName(e.target.value)}
              borderRadius='1px'
            />
          </div>
          <div className="mt-20">
            {loading ? ( // Conditionally render the loader spinner or button based on loading state
              <LoaderSpinner visible={true} />
            ) : (
              <Button
                text="Save"
                borderColor="border-red-600"
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                onClick={handleSave}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyLogo;
