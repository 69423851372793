import { api } from './api'; // Assuming api is already configured in this file
import { POST_MEDIA_IMAGE_URL ,GET_MEDIA_IMAGES_BY_USER_URL,GET_MEDIA_IMAGE_BY_ID_URL,DELETE_MEDIA_IMAGE_URL} from './urls';// Assuming MEDIA_UPLOAD_URL is the URL for your media endpoint
import { GET_ALL_MEDIA_IMAGES_URL } from './urls';
// API to upload media (image/video)
const uploadMedia = async (title, description, imageUrlArray) => {
  try {
    const payload = {
      title: title,
      description: description,
      image_url: imageUrlArray, // Array of image/video URLs
    };

    const res = await api.post( POST_MEDIA_IMAGE_URL , payload);
    return res;
  } catch (error) {
    console.error("Error uploading media:", error);
    throw error;
  }
};
// const getMediaImagesByUser = async (skip, limit) => {
//     const id = localStorage.getItem('company_id'); 
//     console.log('company_id', id); 
    
//     if (!id) {
//       console.error("No company UID found in localStorage");
//       return;
//     }
  
//     try {
//       const res = await api.get(GET_MEDIA_IMAGES_BY_USER_URL(id), {
//         params: {
//           skip: skip,
//           limit: limit,
//         },
//       });
  
//       return res;
//     } catch (error) {
//       console.error("Error fetching media images:", error);
//       throw error;
//     }
//   };


// const fetchMediaImages = async (uid, skip = 0, limit = 10) => {
//   try {
//     const response = await api.get(GET_MEDIA_IMAGES_BY_USER_URL(uid, skip, limit));
//     console.log('Fetched Company Images:', response.data);  // Log the response to verify
//     return response.data;  // Return the data from the API
//   } catch (error) {
//     console.error('Error fetching company images:', error);
//     throw error;
//   }
// };

const fetchMediaImages = async (uid, skip = 0) => {
  try {
    if (!uid) {
      uid = localStorage.getItem('company_uid');
      if (!uid) {
        throw new Error('No company_uid found in localStorage.');
      }
    }
    const limit = 6; // Fetch 10 videos at a time
    const response = await api.get(GET_MEDIA_IMAGES_BY_USER_URL(uid), {
      params: {
        skip,
        limit,
      },
    });

    console.log('Fetched Company Images:', response.data);

    if (response.data && Array.isArray(response.data.data)) {
      return response.data.data; // Return the image data array
    } else {
      throw new Error('Invalid response format.');
    }
  } catch (error) {
    console.error('Error fetching company images:', error);
    throw error;
  }
};
  const getMediaImageById = async (id) => {
    try {
      const res = await api.get(GET_MEDIA_IMAGE_BY_ID_URL(id)); // Use the URL function to get the correct URL
      return res;
    } catch (error) {
      console.error("Error fetching media image by ID:", error);
      throw error;
    }
  };
  
  const deleteMediaById = async (id) => {
    try {
      const res = await api.delete(GET_MEDIA_IMAGE_BY_ID_URL(id)); 
      return res;
    } catch (error) {
      console.error("Error deleting media:", error);
      throw error;
    }
  };

  const getAllMedia = async (skip, limit) => {
    try {
      // Passing skip and limit as query parameters
      const res = await api.get(GET_ALL_MEDIA_IMAGES_URL, { // Removed the parentheses
        params: { skip, limit },
      });
      return res;
    } catch (error) {
      console.error("Error fetching media images:", error);
      throw error;
    }
  };


export { uploadMedia,getMediaImageById ,deleteMediaById,getAllMedia,fetchMediaImages};
