import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase_service";

// Service to upload chat images to Firebase Storage
export const uploadChatImage = async (chatId, file, currentUser) => {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    const storageRef = ref(storage, `chatImagesType/${chatId}/${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Optional: Monitor the progress of the upload
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error("Error uploading image:", error);
        reject(error);
      },
      async () => {
        // Handle successful uploads on complete
        try {
          // Get the image download URL
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Update the chat document with the image URL
          await updateDoc(doc(db, 'chats', chatId), {
            lastImage: downloadURL,
            lastMessageUid: currentUser.id,
            lastMessageSeen: false,
            lastMessageTime: new Date(),
          });

          resolve(downloadURL);
        } catch (error) {
          console.error("Error updating chat with image URL:", error);
          reject(error);
        }
      }
    );
  });
};
