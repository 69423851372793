// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// const TabButton = ({ icon, label, path }) => {
//   const navigate = useNavigate();

//   const handleContainerClick = () => {
//     navigate(path);
//   };

//   return (
//     <div 
//       className="flex items-center justify-center bg-black text-white rounded-lg lg:p-8 p-6 cursor-pointer"
//       onClick={handleContainerClick}  // Trigger navigation on container click
//     >
//       <img src={icon} alt={label} className="lg:w-6 lg:h-6 h-4 w-4 lg:mr-3 mr-2" />
//       <span className="lg:text-base font-medium hover:underline text-xs">
//         {label}
//       </span>
//     </div>
//   );
// };

// export default TabButton;

import React from 'react';
import { useNavigate } from 'react-router-dom';

const TabButton = ({ icon, label, path }) => {
  const navigate = useNavigate();

  const handleContainerClick = () => {
    // If path is a function, call it to get the navigation path, otherwise use the string directly
    const navigationPath = typeof path === 'function' ? path() : path;
    // console.log('Navigating to:', navigationPath);  // Debug log
    if (navigationPath) {
      navigate(navigationPath);  // Ensure navigationPath is not null or undefined
    } else {
      // console.error('Navigation path is invalid:', navigationPath);
    }
  };
  

  return (
    <div 
      className="flex items-center justify-center bg-black text-white rounded-lg lg:p-8 p-6 cursor-pointer"
      onClick={handleContainerClick}  // Trigger navigation on container click
    >
      <img src={icon} alt={label} className="lg:w-6 lg:h-6 h-4 w-4 lg:mr-3 mr-2" />
      <span className="lg:text-base font-medium hover:underline text-xs">
        {label}
      </span>
    </div>
  );
};

export default TabButton;
