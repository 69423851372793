import { api } from "./api";
import { POST_REQUEST } from "./urls";
import { GET_POST_BY_ID } from "./urls";
import { GET_POST_BY_COMPANY_UID } from "./urls";
import { GET_ALL_POSTS } from "./urls";
import { DELETE_POST_BY_ID } from "./urls";

const PostRequestUpload = async (name, quantity, category, sub_category, details, detail_document = null, notification = true) => {
    try {
        const payload = {
            name,
            quantity,
            category,
            sub_category,
            details,
            notification,
        };

        if (detail_document) {
            payload.detail_document = detail_document; // Only add document if it exists
        }

        console.log("Payload being sent:", payload); // Add this to log the payload before sending

        const res = await api.post(POST_REQUEST, payload);
        return res;
    } catch (error) {
        console.error("Error submitting item:", error);
        throw error;
    }
};

export const getPostById = async (postId) => {
    try {
        const response = await api.get(`${GET_POST_BY_ID}/${postId}`);
        console.log('API response:', response); // Log the entire response

        // Ensure response has the necessary structure
        if (response && response.data && response.data.data) {
            const postData = response.data.data;
            console.log('Fetched post data:', postData); // Log the post data
            return postData;
        } else {
            console.error('Unexpected response structure:', response);
            throw new Error('Invalid response structure');
        }
    } catch (error) {
        console.error(`Error fetching post with id ${postId}:`, error);
        throw error;
    }
};



export const getPostByCompanyUID = async (company_id, skip) => {
    try {
        const response = await api.get(`${GET_POST_BY_COMPANY_UID(company_id)}?skip=${skip}&limit=5`);

        // Ensure response has the necessary structure
        if (response && response.data && response.data.data) {
            const postsData = response.data.data;
            return postsData;
        } else {
            console.error('Unexpected response structure:', response);
            throw new Error('Invalid response structure');
        }
    } catch (error) {
        console.error(`Error fetching posts for company UID ${company_id}:`, error);
        throw error;
    }
};
export const getAllPosts = async (skip = 0, limit = 5) => {
    try {
        const response = await api.get(`${GET_ALL_POSTS}?skip=${skip}&limit=${limit}`);

        // Ensure response has the necessary structure
        if (response && response.data && response.data.data) {
            const postsData = response.data.data;
            return postsData;
        } else {
            console.error('Unexpected response structure:', response);
            throw new Error('Invalid response structure');
        }
    } catch (error) {
        console.error('Error fetching all posts:', error);
        throw error;
    }
};



export const deletePostById = async (postId) => {
    try {
      const response = await api.delete(`/post/${postId}`);
      console.log('Delete Post Response:', response); // Log the response
      return response.data;
    } catch (error) {
      console.error('Error deleting post:', error.response);
      throw error;
    }
  };
  


export {PostRequestUpload}