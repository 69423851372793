import React from 'react';
import Image2 from '../../Components/login_image2';
import Button from '../../Components/button';
import EmailVerifiedMessage from '../../Components/email_verified_message';






const MainEmailVerified = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-black p-4 overflow-y-auto">
      <div className="bg-white p-4 md:p-12 rounded-lg shadow-lg w-full max-w-4xl mx-auto flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
        <div className="w-full md:w-1/2 flex justify-center items-center">
          <Image2 imgSrc="./emailverified.svg" />
        </div>
        <div className="w-full md:w-1/2 p-4 flex flex-col items-center space-y-12">
          <div className="w-8 h-8">
            <img src="./tick.svg" alt="Verified Tick" className="w-full h-full" />
          </div>
          <EmailVerifiedMessage message="Email Verified" />
          <Button 
            text="Save" 
            navigateTo="/phone_details" 
            backgroundColor="bg-custom-button-color" 
            textColor="text-white" 
            className="w-32"
          />
        </div>
      </div>
    </div>
  );
};


export default MainEmailVerified;


