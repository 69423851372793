// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { deleteManagementMember } from '../../Controller/management';
// import ConfirmationPopup from './pop_up';
// import DeletePopup from './delete_pop_up'; // Import the DeletePopup component
// import { logout } from '../../Controller/auth';

// const ReportContainer = ({ showRemove, profileId }) => {
//   const [isContainerVisible, setIsContainerVisible] = useState(false);
//   const [showLogoutPopup, setShowLogoutPopup] = useState(false);
//   const [isPopupVisible, setIsPopupVisible] = useState(false);
//   const [isLogoutPopupVisible, setIsLogoutPopupVisible] = useState(false); // State for logout popup
//   const [isLoading, setIsLoading] = useState(false); // State for loading
//   const navigate = useNavigate();

//   const handleContainerOpen = () => {
//     setIsContainerVisible(true);
//   };

//   const handleContainerClose = () => {
//     setIsContainerVisible(false);
//   };

//   const handleRemove = () => {
//     setIsPopupVisible(true);
//   };

//   const handleLogout = async () => {
//     try {
//       setIsLoading(true); // Show the loader when starting the API call
//       const response = await logout();
//       if (response.status === 200) {
//         localStorage.clear();
//         navigate("/login"); // Navigate on successful logout
//       } else {
//         console.error("Logout failed:", response.statusText);
//       }
//     } catch (error) {
//       console.error("Error during logout:", error);
//     } finally {
//       setIsLoading(false); // Hide the loader after the API call
//     }
//   };

//   const handleConfirmRemove = async () => {
//     try {
//       await deleteManagementMember(profileId);
//       navigate('/view-management-owner', { state: { profileDeleted: true } });
//     } catch (error) {
//       console.error("Error deleting management member:", error);
//       alert("Error deleting management member. Please try again.");
//     } finally {
//       setIsPopupVisible(false);
//     }
//   };

//   const handleCancelRemove = () => {
//     setIsPopupVisible(false);
//   };

//   // Show the logout popup
//   const handleLogoutClick = () => {
//     setShowLogoutPopup(true);
//   };

//   const confirmLogout = () => {
//     handleLogout();
//   };
//   const closePopup = () => {
//     setShowLogoutPopup(false);
//   };

//   // Cancel logout in the popup
//   // const handleCancelLogout = () => {
//   //   setIsLogoutPopupVisible(false);
//   // };

//   return (
//     <div className="relative">
//       {/* Dots icon for opening the menu */}
     
//       <div className="cursor-pointer" onClick={handleContainerOpen}>
//     <img src="/Assets/dots.svg" alt="Menu Dots" className='w-4 h-5' />
//   </div>
//       {/* Overlay for transparent background */}
//       {isContainerVisible && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={handleContainerClose}></div>
//       )}

//       {isContainerVisible && (
//         <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg z-50">
//           <button
//             className="absolute  right-2 bg-transparent text-black hover:text-gray-500 text-xl "
//             onClick={handleContainerClose}
//           >
//             &times;
//           </button>
//           <div className="flex flex-col items-center py-4 mt-2">
//             <Link to="/report_page" className="flex items-center py-2 w-full px-3 hover:bg-gray-100 text-black">
//               <img src="/Assets/report_icon.svg" alt="Report Icon" className="w-5 h-5 mr-3" />
//               <span className="text-sm font-medium">Report an Issue</span>
//             </Link>
//             <hr className="w-full border-gray-200 my-2" />
//             <div onClick={handleLogoutClick} className="flex items-center py-2 px-3 w-full hover:bg-gray-100 cursor-pointer text-black">
//               <img src="/Assets/logout_icon.svg" alt="Logout Icon" className="w-5 h-5 mr-3" />
//               <span className="text-sm font-medium">Save & Log out</span>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Popup for deleting management member */}
//       {/* {isPopupVisible && (
//         <ConfirmationPopup 
//           message="Are you sure you want to delete this management member?"
//           onConfirm={handleConfirmRemove}
//           onClose={handleCancelRemove}
//           isLoading={isLoading} // Pass the loading state
//         />
//       )} */}

//       {/* Popup for confirming logout */}
//       {showLogoutPopup && (
//         <DeletePopup
//           message="Are you sure you want to log out?"
//           onConfirm={confirmLogout}
//           onClose={closePopup}
//           isLoading={isLoading} // Pass loading state to the popup
//         />
//       )}
//     </div>
//   );
// };

// export default ReportContainer;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { deleteManagementMember } from '../../Controller/management';
import ConfirmationPopup from './pop_up';
import DeletePopup from './delete_pop_up';
import { logout } from '../../Controller/auth';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase_service'; // Ensure your Firebase service is imported

const ReportContainer = ({ showRemove, profileId }) => {
  const [isContainerVisible, setIsContainerVisible] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLogoutPopupVisible, setIsLogoutPopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleContainerOpen = () => {
    setIsContainerVisible(true);
  };

  const handleContainerClose = () => {
    setIsContainerVisible(false);
  };

  const handleRemove = () => {
    setIsPopupVisible(true);
  };

  // Function to update user status to 'Offline' in Firestore
  const updateUserStatusToOffline = async () => {
    const currentUserUid = localStorage.getItem('company_uid'); // Get companyUid from localStorage
    if (currentUserUid) {
      try {
        const userRef = doc(db, 'users', currentUserUid);
        await updateDoc(userRef, {
          status: 'Offline',
          lastOnlineAt: serverTimestamp(), // Update last online timestamp
        });
      } catch (error) {
        console.error('Error updating user status to Offline:', error);
      }
    }
  };

  const handleLogout = async () => {
    try {
      setIsLoading(true); // Show the loader when starting the API call

      // Update user status to 'Offline' before logging out
      await updateUserStatusToOffline();

      const response = await logout();
      if (response.status === 200) {
        localStorage.clear(); // Clear localStorage after updating Firestore
        navigate("/login"); // Navigate on successful logout
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setIsLoading(false); // Hide the loader after the API call
    }
  };

  const handleConfirmRemove = async () => {
    try {
      await deleteManagementMember(profileId);
      navigate('/view-management-owner', { state: { profileDeleted: true } });
    } catch (error) {
      console.error("Error deleting management member:", error);
      alert("Error deleting management member. Please try again.");
    } finally {
      setIsPopupVisible(false);
    }
  };

  const handleCancelRemove = () => {
    setIsPopupVisible(false);
  };

  // Show the logout popup
  const handleLogoutClick = () => {
    setShowLogoutPopup(true);
  };

  const confirmLogout = () => {
    handleLogout();
  };

  const closePopup = () => {
    setShowLogoutPopup(false);
  };

  return (
    <div className="relative">
      {/* Dots icon for opening the menu */}
      <div className="cursor-pointer" onClick={handleContainerOpen}>
        <img src="/Assets/dots.svg" alt="Menu Dots" className='w-4 h-5' />
      </div>

      {/* Overlay for transparent background */}
      {isContainerVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={handleContainerClose}></div>
      )}

      {isContainerVisible && (
        <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg z-50">
          <button
            className="absolute right-2 bg-transparent text-black hover:text-gray-500 text-xl"
            onClick={handleContainerClose}
          >
            &times;
          </button>
          <div className="flex flex-col items-center py-4 mt-2">
            <Link to="/report_page" className="flex items-center py-2 w-full px-3 hover:bg-gray-100 text-black">
              <img src="/Assets/report_icon.svg" alt="Report Icon" className="w-5 h-5 mr-3" />
              <span className="text-sm font-medium">Report an Issue</span>
            </Link>
            <hr className="w-full border-gray-200 my-2" />
            <div onClick={handleLogoutClick} className="flex items-center py-2 px-3 w-full hover:bg-gray-100 cursor-pointer text-black">
              <img src="/Assets/logout_icon.svg" alt="Logout Icon" className="w-5 h-5 mr-3" />
              <span className="text-sm font-medium">Save & Log out</span>
            </div>
          </div>
        </div>
      )}

      {/* Popup for confirming logout */}
      {showLogoutPopup && (
        <DeletePopup
          message="Are you sure you want to log out?"
          onConfirm={confirmLogout}
          onClose={closePopup}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default ReportContainer;
