import React from 'react';
import { ScaleLoader } from 'react-spinners';

const LoaderSpinner = ({ visible, height = 15, color = '#DB0000' }) => { // Default color is #DB0000
  return (
    <div className={`flex justify-center items-center ${visible ? '' : 'hidden'}`}>
      <ScaleLoader
        height={height} // Use the height prop
        width={4}
        radius={2}
        margin={2}
        color={color} // Use the color prop
        loading={visible}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default LoaderSpinner;
