
import React, { useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import InputBox from './input_box';
import TextArea from './text_area';
import Button from './button';
import AddDocument from './add_doc';
import { post_report } from '../../Controller/report';
import uploadImage from '../../Controller/firebase/firebase_upload_image';
import { AiOutlineClose } from 'react-icons/ai'; // Import the close icon
import { showToast } from './toast'; // Assuming you are using this for showing toast messages
import ReportSubmitted from './report_submitted';



const ReportPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const heading = location.state?.heading|| "Report";
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // To control modal visibility

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    // Use trim() to remove any leading or trailing whitespace from title and description
    if (!title.trim() || !description.trim()) {
      console.error('title and description are required');
      showToast('Subject and Description are Required', 'error');
      return;
    }
  
    setLoading(true);
    try {
      let imageUrl = '';
      if (image) {
        imageUrl = await uploadImage(image);
      }
      const response = await post_report(title.trim(), description.trim(), imageUrl); // Use trimmed values
      if (response.status === 201) {
        console.log(response);
        setShowSuccessModal(true); // Show the success modal
      } else if (response.status === 422) {
        // showToast('Validation error. Please check your input.', 'error');
      }
    } catch (error) {
      console.error('Error saving report:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleFileUpload = (file) => {
    setImage(file);
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false); // Close the success modal
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <>
     <div className="fixed inset-0 bg-black bg-opacity-60 z-40"></div> {/* Ensure the report page has a lower z-index */}
     <div className="fixed inset-0 flex items-center justify-center z-40"> {/* Lower z-index than modal */}
        <div className="bg-white rounded-lg shadow-lg mx-4 flex flex-col lg:w-1/3 lg:h-4/5">
          <div className="flex justify-between p-6">
          <h1 className="text-lg font-semibold">{heading}</h1>
            <button className="text-gray-400" onClick={handleBackClick}>
              <AiOutlineClose className="w-5 h-5 text-black" />
            </button>
          </div>
          <div className="border-b border-black"></div>
          <div className="p-6 lg:mt-8">
            <InputBox
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Subject"
              borderRadius="8px"
            />
            <TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
              className="mt-6"
              backgroundColor="#F2F2F2"
              borderRadius="8px"
            />
            <div className="mt-2 w-3/4">
              <AddDocument label="Add screenshot" onFileUpload={handleFileUpload} accept="image/*" />
            </div>
            <div className="mt-5 lg:mt-16 py-6">
              <Button
                text="Report"
                borderColor="border-red-600"
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                onClick={handleSubmit}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Show the success modal if the report is successfully submitted */}
      {showSuccessModal && <ReportSubmitted onClose={handleCloseModal} />}
    </>
  );
};

export default ReportPage;

