
import React, { useState, useEffect, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Button from './button';
import uploadImage from '../../Controller/firebase/firebase_upload_image';

const CoverImagePopup = ({ onClose, onSave, isEditing, initialImage, title, dimensionNote, isLogo }) => {
  const [newImage, setNewImage] = useState(null); 
  const [selectedFile, setSelectedFile] = useState(null); 
  const [uploading, setUploading] = useState(false); 
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null); 
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showCropperPopup, setShowCropperPopup] = useState(false); // State for showing the cropping modal

  useEffect(() => {
    if (isEditing && initialImage) {
      setNewImage(initialImage);
    }
  }, [isEditing, initialImage]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImage(reader.result);
        setShowCropperPopup(true); // Show cropper when an image is selected
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSave = async () => {
    if (!selectedFile) {
      console.error("No file selected for upload");
      return;
    }

    try {
      setUploading(true);
      const canvas = document.createElement('canvas');
      const image = new Image();
      image.src = newImage;

      image.onload = async () => {
        const ctx = canvas.getContext('2d');
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        
        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        canvas.toBlob(async (blob) => {
          const croppedFile = new File([blob], selectedFile.name, { type: 'image/jpeg' });
          const downloadURL = await uploadImage(croppedFile);
          setUploading(false);
          onSave(downloadURL);
          onClose();
        }, 'image/jpeg');
      };
    } catch (error) {
      setUploading(false);
      console.error("Error uploading image:", error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60"></div>
      <div className="fixed inset-0 flex items-center justify-center z-50 mx-2">
        <div className="bg-white p-4 rounded-2xl w-[380px] h-auto md:w-[400px] lg:w-[450px]">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-xl font-semibold">{title}</h2>
            <button onClick={onClose} className="text-black">✕</button>
          </div>

          <div className="flex flex-col items-center justify-center mt-6">
            <div 
              className={`${isLogo ? 'bg-gray-300 h-28 w-28 rounded-full' : 'bg-gray-300 h-40 w-60 rounded-lg'} flex items-center justify-center mt-2 relative`}
              style={{ backgroundImage: `url(${newImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
              onClick={() => document.getElementById('fileInput').click()} // Trigger the file input when clicking the container
            >
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
              <img
                src={newImage ? "/Assets/edit.svg" : "/Assets/grey_plus.svg"} 
                alt={newImage ? "Edit" : "Add"}
                className="absolute h-8 w-8 cursor-pointer"
              />
            </div>
            {/* <div className="text-gray-500 text-sm mt-2">{dimensionNote}</div> */}

            <div className="mt-4 w-3/4">
            <Button 
  text="Save"
  onClick={handleSave}
  backgroundColor="bg-custom-button-color" 
  textColor="text-white" 
  className="w-full" 
  disabled={uploading} 
  loading={uploading} // Pass the loading state here
/>

            </div>
          </div>
        </div>
      </div>

      {/* Cropper Popup */}
      {showCropperPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 mx-2 bg-black bg-opacity-70">
          <div className="bg-white p-4 rounded-2xl w-[380px] h-[480px] md:w-[400px] lg:w-[450px]">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-xl font-semibold">Crop Image</h2>
              <button onClick={() => setShowCropperPopup(false)} className="text-black">✕</button>
            </div>
            
            <div className="flex flex-col items-center justify-center mt-4 relative h-60 w-full bg-gray-200">
              <Cropper
                image={newImage}
                crop={crop}
                zoom={zoom}
                aspect={isLogo ? 1 / 1 : 4 / 3} // Maintain aspect ratio for cropping
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>

            <div className="flex justify-between items-center mt-4 px-4">
              <label htmlFor="zoom-range">Zoom</label>
              <input
                id="zoom-range"
                type="range"
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(e.target.value)}
                className="w-full ml-2"
              />
            </div>

            <div className="mt-4 ">
            <Button 
  text="Save"
  onClick={handleSave}
  backgroundColor="bg-custom-button-color" 
  textColor="text-white" 
  className="w-full" 
  disabled={uploading} 
  loading={uploading} // Pass the loading state here
/>

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CoverImagePopup;
