
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMediaImageById, deleteMediaById } from '../../../Controller/company_products'; // Import the deleteMediaById function
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import Sidebar from '../../Components/main_navbar';
import DeletePopup from '../../Components/delete_pop_up';
import { FaSpinner } from 'react-icons/fa'; // Import Font Awesome spinner
import { useLocation } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

const DetailedView = () => {
  const { id } = useParams(); // Get the ID from the URL params
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false); // State to show/hide the delete popup

  const { state } = useLocation(); // Access state passed from the navigation

  const { company_id, company_name, logo } = state || {}; // Destructure the values
  // Fetch the media image by ID when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMediaImageById(id); // Call the API function
        setItem(response.data.data); // Update state with the fetched data
        setLoading(false); // Turn off loading
      } catch (error) {
        console.error('Error fetching media image by ID:', error);
        setLoading(false); // Turn off loading even if there is an error
      }
    };

    fetchData();
  }, [id]);

  const handleBackClick = () => {
    navigate('/view_photos');
  };

  

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="text-2xl text-red-600 animate-spin" /> {/* Centered spinner */}
      </div>
    );
  }

  if (!item) {
    return <div>No data available</div>;
  }

  const swiperStyles = {
    width: '100%',
    height: '100%',
  };

  const slideStyles = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '400px',
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imgStyles = {
    display: 'block',
    width: '100%',
    height: '400px',
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] overflow-auto">
      <Sidebar />

      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] ">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Photos</h1>
        </div>
       
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="container lg:w-4/5 w-full  p-3 mt-2">
         <div className='lg:w-4/5 w-full'>
          {/* Conditionally render Logo and Company Name */}
          {company_id && company_name && logo && (
          <div 
            className="flex items-center justify-center mb-2 cursor-pointer"
            onClick={() => navigate(`/profile/${company_id}`)} // Navigate to Profile2Page with company_id
          >
            {/* Logo */}
            <img
              src={logo}
              alt={company_name}
              className="w-12 h-12 rounded-full object-cover mr-4" // Rounded and sized properly
            />
            {/* Company Name */}
            <h2 className="text-sm font-poppins font-semibold">{company_name}</h2>
          </div>
        )}
        </div>

        <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView="auto"
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 250,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={{ clickable: true }}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
          style={swiperStyles}
        >
          {item.image_url && item.image_url.length > 0 && item.image_url.map((image, index) => (
            <SwiperSlide key={index} style={slideStyles}>
              <img
                src={image} // Display each image in the swiper
                alt={`Detail ${index}`}
                style={imgStyles}
              />
            </SwiperSlide>
          ))}
        </Swiper>
       
      </div>
      <div className="text-center lg:w-4/5 w-full p-2 ">
          <h3 className=" lg:text-xl text-[14px] font-bold mb-2">{item.title}</h3>
          <p className='lg:text-sm text-[12px] lg:mx-32'>{item.description}</p>
        </div>

      {/* Show delete popup when bin is clicked */}
      {/* {showDeletePopup && (
        <DeletePopup
          message="Are you sure you want to delete?"
          onConfirm={handleDelete} // Call the delete function on confirmation
          onClose={() => setShowDeletePopup(false)} // Close the popup without action
        />
      )} */}
    </div>
  );
};

export default DetailedView;
