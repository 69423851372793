import React, { useEffect, useState } from "react";
import EswafHeaderLogo from "../../Components/trade_role_header_logo";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Components/main_navbar";
import VerificationStatus from "../../Components/verification_status";
import { get_verified } from "../../../Controller/company_details";
import { FaSpinner } from 'react-icons/fa';  // Import FaSpinner from react-icons
import './hexagonhome.css'
const smallScreenUrls = {
  logistics: './logisticsmobile.svg',
  finance: './finance_small.svg',
  start_up: 'Assets/new-start-up-small.svg',
  legal: './legal_mobile.svg',
  jewelry: './jewelry_small.svg',
  investor: '/Assets/new-investor-small.svg',
  seller: '/Assets/seller-new.svg',
  market_place: '/Assets/market.svg',
  projects: 'Assets/new-projects-small.svg',
  buyer: './buyermobile35827.svg'
};

const largeScreenUrls = {
  logistics: 'Assets/logistics-new-1.svg',
  finance: 'Assets/financeesvg.svg',
  start_up: 'Assets/start-up-new-3.svg',
  legal: 'Assets/legalll.svg',
  jewelry: 'Assets/jewelryy.svg',
  investor: 'Assets/investor-4.svg',
  seller: 'Assets/seller-new-7.svg',
  market_place: 'Assets/market-place-new-8.svg',
  projects: 'Assets/projects-new-10.svg',
  buyer: 'Assets/buyer-new-9.svg'
};

const MainHome = () => {
  const [imageUrls, setImageUrls] = useState(largeScreenUrls);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [remarks, setRemarks] = useState('');
  const [loading, setLoading] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [accountManager, setAccountManager] = useState('');
  const [website, setWebsite] = useState('');
  const [officeLocation, setOfficeLocation] = useState('');
  const [fileUploading, setFileUploading] = useState({
    authorization: false,
    tradingLicense: false,
    tenancyContract: false,
    tradingRegister: false,
  });

  const navigate = useNavigate();
  useEffect(() => {
    let interval;
    
    const forcePreventBack = () => {
      window.history.pushState({ page: 'home' }, '', window.location.href);
      window.history.replaceState({ page: 'home' }, '', window.location.href);
    };
  
    for (let i = 0; i < 10; i++) {
      forcePreventBack();
    }
  
    const disableBackButton = () => {
      forcePreventBack();
    };
  
    // Set up continuous prevention
    interval = setInterval(forcePreventBack, 100);
  
    window.addEventListener('popstate', disableBackButton);
    window.addEventListener('hashchange', disableBackButton);
  
    // Block keyboard shortcuts
    const blockKeyboardNav = (e) => {
      if ((e.altKey && e.keyCode === 37) || 
          (e.altKey && e.keyCode === 39)) {
        e.preventDefault();
      }
    };
    window.addEventListener('keydown', blockKeyboardNav);
  
    return () => {
      clearInterval(interval);
      window.removeEventListener('popstate', disableBackButton);
      window.removeEventListener('hashchange', disableBackButton);
      window.removeEventListener('keydown', blockKeyboardNav);
    };
  }, []);
  
  
  

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setImageUrls(smallScreenUrls);
      } else {
        setImageUrls(largeScreenUrls);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const checkVerificationStatus = async () => {
      try {
        const response = await get_verified();
        console.log("API Response:", response);

        if (response.status === 200) {
          const data = response.data.data;
          const status = data.verification_status;
          const remarksFromResponse = data.remarks;

          setRemarks(remarksFromResponse || '');
          localStorage.setItem('remarks', remarksFromResponse || '');

          console.log("Verification Status:", status);
          console.log("Remarks:", remarksFromResponse);

          const {
            name,
            manager,
            website,
            authorization_document,
            location,
            trading_license,
            trading_register,
            tenancy_contract
          } = data;

          const storeOrClearIfVerified = (key, field) => {
            if (field) {
              if (field.status === "VERIFICATION_COMPLETED") {
                localStorage.setItem(key, field.value || '');
              } else if (field.status === "VERIFICATION_REJECTED") {
                localStorage.removeItem(key);
                resetField(key);
              }
            }
          };

          localStorage.setItem('verificationStatus', status);
          storeOrClearIfVerified('companyName', name);
          storeOrClearIfVerified('manager', manager);
          storeOrClearIfVerified('website', website);
          storeOrClearIfVerified('authorization_document', authorization_document);
          storeOrClearIfVerified('location', location);
          storeOrClearIfVerified('trading_license', trading_license);
          storeOrClearIfVerified('trading_register', trading_register);
          storeOrClearIfVerified('tenancy_contract', tenancy_contract);

          setVerificationStatus(status);

          if (status === "VERIFICATION_PENDING" || status === "VERIFICATION_REJECTED") {
            setIsPopupVisible(true);
          }
        }
      } catch (error) {
        console.error('Error checking verification status:', error);
      } finally {
        setLoading(false);
      }
    };

    checkVerificationStatus();
  }, []);

  const resetField = (key) => {
    switch (key) {
      case 'companyName':
        setCompanyName('');
        break;
      case 'manager':
        setAccountManager('');
        break;
      case 'website':
        setWebsite('');
        break;
      case 'location':
        setOfficeLocation('');
        break;
      case 'authorization_document':
        setFileUploading((prev) => ({ ...prev, authorization: false }));
        break;
      case 'trading_license':
        setFileUploading((prev) => ({ ...prev, tradingLicense: false }));
        break;
      case 'trading_register':
        setFileUploading((prev) => ({ ...prev, tradingRegister: false }));
        break;
      case 'tenancy_contract':
        setFileUploading((prev) => ({ ...prev, tenancyContract: false }));
        break;
      default:
        break;
    }
  };

  const handleNavigation = (path, categoryId) => {
    if (verificationStatus === "VERIFICATION_COMPLETED") {
      // Navigate to /post_request only for 'buyer' and 'investor'
      if (categoryId === 7 || categoryId === 8) { 
        const selectedTradeRole = categoryId === 7 ? 'Investor' : 'Buyer';
        navigate('/post_request', {
          state: { 
            selectedTradeRole,
            categoryId ,
            fromHome: true  // Explicitly set this to true when coming from home
          }
        });
      } else {
        // For other categories, use the categoryId in the URL
        navigate(`/${categoryId}`, { state: { selectedTradeRole: '' } });
      }
    } else {
      setIsPopupVisible(true);  // Show the verification status popup if not verified
    }
  };
  


  const closeVerificationStatusPopup = () => {
    setIsPopupVisible(false);
  };
  
  
  
  

  return (
      <div className="main-home-container flex h-screen overflow-hidden">
        <Sidebar isHomePage={true} handleNavigation={handleNavigation} />
        <div className="flex-1 overflow-auto">
          <EswafHeaderLogo />
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <FaSpinner className="text-gray-400 text-4xl animate-spin" />
            </div>
          ) : (
            <div className="main-home-grid-container pt-12 lg:w-[85%]">
              <div className='main-home-grid'>
                <div className='main-home-item logistics' onClick={() => handleNavigation('logistics', 1)}>
                  <img src={imageUrls.logistics} alt="logistics" />
                </div>
                <div className='main-home-item finance' onClick={() => handleNavigation('financial_institutions', 3)}>
                  <img src={imageUrls.finance} alt="finance" />
                </div>
                <div className='main-home-item start_up' onClick={() => handleNavigation('start_up', 4)}>
                  <img src={imageUrls.start_up} alt="startup" />
                </div>
                <div className='main-home-item legal' onClick={() => handleNavigation('legal', 2)}>
                  <img src={imageUrls.legal} alt="legal" />
                </div>
                <div className='main-home-item jewelry' onClick={() => handleNavigation('precious_stones_metals', 5)}>
                  <img src={imageUrls.jewelry} alt="jewelry" />
                </div>
                <div className='main-home-item investor' onClick={() => handleNavigation('investor', 7)}>
                  <img src={imageUrls.investor} alt="investor" />
                </div>
                <div className='main-home-item seller' onClick={() => handleNavigation('seller', 6)}>
                  <img src={imageUrls.seller} alt="seller" />
                </div>
                <div className='main-home-item market_place' onClick={() => handleNavigation('marketplace', 9)}>
                  <img src={imageUrls.market_place} alt="marketplace" />
                </div>
                <div className='main-home-item projects' onClick={() => handleNavigation('projects', 10)}>
                  <img src={imageUrls.projects} alt="projects" />
                </div>
                <div className='main-home-item buyer' onClick={() => handleNavigation('buyer', 8)}>
                  <img src={imageUrls.buyer} alt="buyer" />
                </div>
              </div>
            </div>
          )}
        </div>
        {isPopupVisible && (
          <VerificationStatus
            verificationStatus={verificationStatus}
            onClose={() => setIsPopupVisible(false)}
          />
        )}
      </div>
    );
  };
  

  


export default MainHome;
