import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileImageContainer from '../../Components/profile_image_container.jsx';
import Button from '../../Components/button.jsx';
import Sidebar from '../../Components/main_navbar.jsx';
import { get_company_api, update_profile_image } from '../../../Controller/company_details.js';
import StarRating from '../../Components/review_star.jsx';
import TabButton from '../../Components/profile_tab.jsx';
import { WebLinkModal, BottomSheet } from '../../Components/web_link.jsx';
import ConfirmationPopup from '../../Components/pop_up';  // Import the ConfirmationPopup component
import { showToast } from '../../Components/toast.jsx';
import { FaSpinner } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { getOtherProfileById } from '../../../Controller/other_profile.js';
import { get_social_links } from '../../../Controller/company_details.js';

const ProfileVisitorsView = ({ editable }) => {
  const navigate = useNavigate();
  const { company_id } = useParams(); // Get the company_id from URL parameters
  const [selectedProfileImage, setSelectedProfileImage] = useState('');
  const [storeName, setStoreName] = useState('');
  const [category, setCategory] = useState('');
  const [locationText, setLocationText] = useState('');
  const [bio, setBio] = useState('');
  const [webLinks, setWebLinks] = useState([]);
  const [review, setReview] = useState(0);
  const [catalogue, setCatalogue] = useState(null);
  const [website, setWebsite] = useState(''); // New state for website
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);  // State to manage the popup visibility
  const [popupMessage, setPopupMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyIdToFetch = company_id || localStorage.getItem('company_id'); // Get company_id from URL or fallback to localStorage
        if (!companyIdToFetch) {
          console.error("No company ID available to fetch profile data.");
          return;
        }


        const response = await getOtherProfileById(companyIdToFetch);
        if (response.status === 200) {

          const data = response.data.data;
          console.log('profile data',data)
          setStoreName(data.name);
          setCategory(data.category);
          setLocationText(data.address);
          setSelectedProfileImage(data.profile_image_url);
          setReview(data.review);
          setBio(data.bio);
          setWebLinks(data.web_links || []);
          setCatalogue(data.catalogue);
          setWebsite(data.website); // Set the website from the response

          // Store data in localStorage
          localStorage.setItem('id', data.id);
         
          localStorage.setItem('name', data.name);
          localStorage.setItem('profile_image_url', data.profile_image_url);
          localStorage.setItem('authorization_file', data.authorization_file);
          localStorage.setItem('website', data.website);
          localStorage.setItem('Bio', data.bio);

        } else {
          console.error("Failed to fetch company data:", response.status);
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
      finally {
        setLoading(false); // Stop loading after data is fetched
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchSocialLinks = async () => {
      const companyIdToFetch = company_id || localStorage.getItem('company_id');
      if (!companyIdToFetch) return;
  
      try {
        const response = await get_social_links(companyIdToFetch);
        console.log("API Response (pro):", response); // Log the full API response
  
        if (response.status === 200) {
          const data = response.data?.data;
          console.log("API Response Data:", data); // Log the processed data
  
          // Check the website state value
          console.log("Website before combining links:", website);
  
          // Combine links conditionally
          const combinedLinks = [];
          if (website) combinedLinks.push(`Website: ${website}`);
          if (data?.instagram) combinedLinks.push(`Instagram: ${data.instagram}`);
          if (data?.facebook) combinedLinks.push(`Facebook: ${data.facebook}`);
          if (data?.linked_in) combinedLinks.push(`LinkedIn: ${data.linked_in}`);
          if (data?.twitter) combinedLinks.push(`Twitter: ${data.twitter}`);
  
          console.log("Combined Links:", combinedLinks); // Log the combined links
          setWebLinks(combinedLinks); // Set the links array
        }
      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    };
  
    fetchSocialLinks();
  }, [company_id, website]);
  
  

  const handleSaveProfileImage = async (newImage) => {
    setSelectedProfileImage(newImage);
    try {
      const response = await update_profile_image(newImage);
      if (response.status === 201) {
        console.log("Profile image updated successfully:", response.data);
      } else {
        console.error("Failed to update profile image:", response);
      }
    } catch (error) {
      console.error("Error updating profile image:", error);
    }
  };

  // const handleViewCatalogue = () => {
  //   if (catalogue) {
  //     window.open(catalogue, '_blank');
  //   }
  // };
  const handleViewCatalogue = () => {
    if (catalogue) {
      navigate('/catalog', { state: { catalogueURL: catalogue } });  // Pass the catalogue URL as state
    }
  };
  

  const handleAddCatalogue = () => {
    navigate('/catalog');  // Adjust the path to your catalog page
  };

  const handleWebLinkClick = () => {
    if (window.innerWidth >= 1024) {
      setIsModalOpen(true);
    } else {
      setIsBottomSheetOpen(true);
    }
  };

  const handleImageClick = (route) => {
    navigate(route);
  };

  const handleEditProfileClick = () => {
    const verificationStatus = localStorage.getItem('verificationStatus');
    if (verificationStatus === 'VERIFICATION_PENDING' || verificationStatus === 'VERIFICATION_REJECTED') {
        setShowPopup(true); 
    } else {
        navigate('/edit_form');  
    }
};

const handleShareProfileClick = () => {
  const verificationStatus = localStorage.getItem('verificationStatus');
  let companyId = company_id || localStorage.getItem('company_id'); // Use URL param or fallback to localStorage

  // Construct the URL based on whether we have a company_id in the params or from localStorage
  const profileUrl = `${window.location.origin}/profile/${companyId}`;

  // Check for "VERIFICATION_PENDING" or "VERIFICATION_REJECTED"
  if (verificationStatus === 'VERIFICATION_PENDING' || verificationStatus === 'VERIFICATION_REJECTED') {
    setPopupMessage('Your profile is not verified. Please complete the verification.');
    setShowPopup(true); // Show the popup if verification is pending or rejected
  } else {
    // Copy the URL to the clipboard only if the verification is completed
    navigator.clipboard.writeText(profileUrl).then(() => {
      showToast('Profile URL copied to clipboard!', 'success'); // Show success message
    }).catch((error) => {
      console.error('Failed to copy URL:', error);
      showToast('Failed to copy URL. Please try again.', 'error'); // Show error message
    });
  }
};

const formatLocation = (fullLocation) => {
  if (!fullLocation) return 'Location not available';
  
  // Split the location by commas and take the last two parts
  const locationParts = fullLocation.split(',');
  
  // Check if there are at least two parts to display
  if (locationParts.length >= 2) {
    const lastTwoParts = locationParts.slice(-1).join(',').trim(); // Join last two parts and trim spaces
    return lastTwoParts;
  }

  return fullLocation; // Return the full location if splitting doesn't provide two parts
};
 // Detect screen size on initial render and on resize
 useEffect(() => {
  const handleResize = () => {
    setIsLargeScreen(window.innerWidth >= 1024); // Adjust threshold as needed
  };

  // Set initial screen size
  handleResize();

  // Add resize listener
  window.addEventListener('resize', handleResize);

  // Cleanup listener on component unmount
  return () => window.removeEventListener('resize', handleResize);
}, []);

if (isLargeScreen) {

  
  return (
  
    <div className="flex h-full bg-[#FFFFFF] font-poppins overflow-auto w-full">
    <Sidebar />
    <div className="flex flex-col w-full" >
      {/* Header Section with Profile Image */}
        {/* Loader */}
    {loading ? (
      <div className="flex justify-center items-center h-full w-4/5">
        <FaSpinner className="text-[#DB0000] animate-spin lg:w-10 lg:h-6 w-8 h-8" />
      </div>
    ) : (
      <>
       
      <div className="relative ">
      <div
          className="relative w-full h-[180px] bg-cover bg-center"
          style={{
            backgroundImage: ` url(/Assets/cover_photo.svg)`, // Replace with the actual path to the uploaded image
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >


          <h1 className="absolute top-8 left-8 text-2xl font-bold text-gray-800">
            Profile
          </h1>
        </div>
        
        {/* Profile Image */}
        <div className="absolute -bottom-16 left-8">
          <ProfileImageContainer 
            editable={editable}
            profileImage={selectedProfileImage}
            onSaveProfileImage={handleSaveProfileImage}
            className="w-32 h-32"
          />
        </div>
      </div>

      {/* Main Content */}
      <div className="px-8 mt-20 w-[84%] ">
        <div className="grid grid-cols-2 gap-8">
          {/* Left Column */}
          <div>
            {/* Business Info Container */}
            <div className=" rounded-lg border border-gray-200 p-4 mb-6">
              <div className="space-y-2">
                <h2 className="text-xl font-semibold text-gray-800">{storeName}</h2>
                <p className="text-sm text-gray-600"> {formatLocation(locationText)} </p>
                <div className="flex items-center">
                  <div className="flex">
                  <StarRating review={review} />
                  </div>
                            <span
              className="text-xs lg:text-sm  text-[#DB0000] cursor-pointer   underline"  
              onClick={() => navigate('/reviews_page')}
            >
              View Reviews
            </span>
                          </div>
              </div>

              {/* About Section */}
              <div className="mt-2">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">About</h3>
                <p className="text-sm text-gray-600">{bio}</p>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            {/* Web Links Container */}
            <div className=" rounded-lg border border-gray-200 p-4">
              <button 
                className="flex items-center justify-center w-full text-red-600 "
                onClick={handleWebLinkClick}
              >
                <img src="/Assets/web_link.svg" alt="Web Links" className="w-5 h-5 mr-2" />
                <span>Web Links</span>
              </button>
            </div>

            {/* Product Catalogue Container */}
            <div className=" rounded-lg border border-gray-200 p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-gray-800 font-medium">Product Catalogue</h3>
                {catalogue ? (
                  <button 
                    className="flex items-center text-gray-600"
                    onClick={handleViewCatalogue}
                  >
                    <img src="/Assets/eye_catalog.svg" alt="View" className="w-5 h-5 mr-1" />
                    <span>View</span>
                  </button>
                ) : (
                  <button 
                    className="flex items-center text-gray-600"
                    onClick={handleAddCatalogue}
                  >
                    <img src="/Assets/add-square.svg" alt="Add" className="w-5 h-5 mr-1" />
                    <span>Add</span>
                  </button>
                )}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex gap-4  ">
              <button
                onClick={handleEditProfileClick}
                className="flex-1 px-4 py-3 border border-[#DB0000] text-[#DB0000] rounded "
              >
                Edit Profile
              </button>
              <button
                onClick={handleShareProfileClick}
                className="flex-1 px-4 py-3 bg-[#DB0000] text-white rounded"
              >
                Share Profile
              </button>
            </div>
          </div>
        </div>

        {/* Bottom Navigation Grid */}
    {/* Bottom Navigation Grid */}
<div className=" grid grid-cols-5  relative h-48 mb-2 mt-2">
  {/* Left Side Navigation */}
  <div className="col-span-2 space-y-4 mr-3">
    <button 
      onClick={() => navigate('/view-management')}
      className="w-full flex items-center justify-center space-x-2 bg-black text-white p-8 rounded-r-xl  "
    >
      <img src="/Assets/profile_management.svg" alt="" className="w-6 h-6" />
      <span>Management</span>
    </button>
    <button 
      onClick={() => navigate('/complaints-received')}
      className="w-full flex items-center justify-center space-x-2 bg-black text-white p-8 rounded-r-xl  "
    >
      <img src="/Assets/profile_complaints.svg" alt="" className="w-6 h-6" />
      <span>Complaints</span>
    </button>
  </div>

  {/* Center Grid */}
  <div className=" flex mr-4">
      <div className="grid grid-cols-2 gap-4    ">
        <button 
          onClick={() => handleImageClick('/view_photos')}
          className="bg-[#DB0000] p-4  rounded-l-xl w-28 " 
         
        >
          <img src="/Assets/profile_photos.svg" alt="Photos" className="w-10 h-10 ml-4" />
        </button>
        <button 
          onClick={() => handleImageClick('/uploaded_video')}
           className="bg-[#DB0000] p-4 rounded-r-xl w-28"
         
        >
          <img src="/Assets/profile_video.svg" alt="Videos" className="w-10 h-10 ml-4" />
        </button>
        <button 
          onClick={() => handleImageClick('/product_service_list')}
           className="bg-[#DB0000] p-4 rounded-l-xl w-28"
         
        >
          <img src="/Assets/profile_product.svg" alt="Products" className="w-10 h-10 ml-4" />
        </button>
        <button 
          onClick={() => handleImageClick('/profile-verification')}
           className="bg-[#DB0000] p-4 rounded-r-xl w-28"
         
        >
          <img src="/Assets/profile_verify.svg" alt="Verification" className="w-10 h-10 ml-4 " />
        </button>
      </div>
  </div>

  {/* Right Side Navigation */}
  <div className="col-span-2 space-y-4">
    <button 
      onClick={() => navigate('/company_docs_view')}
      className="w-full flex items-center justify-center space-x-2 bg-black text-white p-8 rounded-l-xl "
    >
      <img src="/Assets/profile_docs.svg" alt="" className="w-6 h-6" />
      <span>Documents</span>
    </button>
    <button 
      onClick={() => navigate('/feedback/owner')}
      className="w-full flex items-center justify-center space-x-2 bg-black text-white p-8 rounded-l-xl "
    >
      <img src="/Assets/profile_feedback.svg" alt="" className="w-6 h-6" />
      <span>Feedback</span>
    </button>
    </div>
          </div>
        </div>
      </>
    )}
  </div>

  {/* Modal for large screens */}
  <WebLinkModal 
  isOpen={isModalOpen} 
  onClose={() => setIsModalOpen(false)} 
  links={webLinks} // Pass the webLinks array
  onAddClick={() => { /* Handle Add Click */ }}
/>

<BottomSheet 
  isOpen={isBottomSheetOpen} 
  onClose={() => setIsBottomSheetOpen(false)} 
  links={webLinks} // Pass the webLinks array
  onAddClick={() => { /* Handle Add Click */ }}
/>

  {/* Confirmation Popup */}
  <ConfirmationPopup
    onClose={() => setShowPopup(false)}
    show={showPopup}
  />
</div>    
  
);
};

// Mobile Design (existing code)
  return (
    <div className="flex h-full  bg-[#FBFCF8] font-poppins overflow-auto">
      <Sidebar />
      <div className="flex flex-col w-full h-full">
      <div
          className="relative w-full lg:h-72 h-[272px]"  // Ensure the div is full width
          style={{
            backgroundImage: `url(/Assets/cover_photo.svg)`,
            backgroundSize: 'cover', 
            backgroundPosition: 'center',  
            backgroundRepeat: 'no-repeat',  
          }}
        >
          <h1 className="absolute  text-[#263238] font-gotham-Bold font-bold lg:text-2xl text-xl p-4 z-10">Profile</h1>
        
          {/* Profile Image Container */}
          <div className="absolute inset-x-0 lg:mt-20 mt-12 flex justify-center lg:w-4/5">  {/* Adjust the bottom value */}
            <ProfileImageContainer
              editable={editable}
              profileImage={selectedProfileImage}
              onSaveProfileImage={handleSaveProfileImage}
            />
          </div>
          <div className="flex flex-col items-center  lg:w-4/5 lg:mt-52  mt-40 lg:mx-0 mx-5 " >
          {loading ? (
              <FaSpinner className="text-[#DB0000] animate-spin lg:w-6 lg:h-6 w-4 h-4" /> // Loading spinner for company name and address
            ) : (
              <>
                <h2 className="lg:text-lg text-[15px] font-normal font-techna">{storeName}</h2>
                <p className="lg:text-sm font-normal font-gotham-Book text-[13px]">
                {formatLocation(locationText)} {/* Format the location to display only the last two parts */}
              </p>
              </>
            )}

          <div className="flex items-center space-x-2">
  <StarRating review={review} />
  <span
    className="text-xs lg:text-sm  text-[#DB0000] cursor-pointer  underline"  
    onClick={() => navigate('/reviews_page')}
  >
    View Reviews
  </span>
</div>
        
        </div>
        </div>


    
        <div className="mt-2  text-left mx-4 lg:mx-8">
          <h2 className="text-sm lg:text-lg font-semibold mb-1 font-gotham text-[#263238]">About</h2>
         
          <p 
    className="text-[13px] lg:text-[16px] mb-1 text-[#6B6B6B] lg:w-3/4 w-full lg:mx-0 font-gotham"  // Set width to 75% and center the text
    style={{
      wordWrap: 'break-word', // Ensure long words break correctly
    }}>
    {bio}
  </p>
  <h3
  className="flex items-center lg:text-xs text-xs font-normal mb-1 cursor-pointer text-[#DB0000] font-gotham"
  onClick={handleWebLinkClick} // Add onClick to the container
>
  <img
    src="/Assets/web_link.svg"
    alt="Link Icon"
    className="lg:w-5 lg:h-5 mr-2 w-4 h-4"
  />
  Web Links
</h3>


        </div>
        {editable && (
          <div className=" w-full lg:w-4/5 flex justify-center">
            <div className="flex justify-between w-full lg:w-1/2  space-x-3 mx-2">
              <div className="w-full   ">
                <Button
                  text="Edit Profile"
                  borderColor="border-[#DB0000]"
                  
                  textColor="text-[#DB0000]"
                  onClick={handleEditProfileClick}
                   // Use the new handler
                />
              </div>
              <div className="w-full  ">
                <Button
                  text="Share Profile"
                  borderColor="border-[#DB0000]"
                  backgroundColor="bg-[#DB0000]"
                  textColor="text-white"
                  onClick={handleShareProfileClick}
                    // Handle share profile action here
                  
                />
              </div>
            </div>
          </div>
        )}

        <div className="w-full lg:w-4/5 flex justify-between items-center lg:mt-1 mt-2  ">
          <div className="w-1/2 text-black font-semibold lg:ml-8 ml-2 text-sm lg:text-lg" >Product Catalogue</div>
          {/* <div className='w-1/2  flex justify-end '>
          <div className='lg:w-1/3 w-1/2 h-1/5 mb-2 shadow-lg mr-1 '> */}
            <div className="w-1/2 flex justify-end mr-2">
            <div className="lg:w-1/4 w-full flex items-center  shadow-lg  lg:pl-8  pl-10">
  {catalogue ? (
    <div className="flex items-center justify-center cursor-pointer  " onClick={handleViewCatalogue}>
      <img
        src="/Assets/eye_catalog.svg"  // Replace with the path to your "view" icon
        alt="View"
        className="h-6 w-6" // Adjust height and width as needed
      />
      <span className="text-[#263238] ml-2 p-3">View</span>
    </div>
  ) : (
    <div className="flex items-center justify-center cursor-pointer" onClick={handleAddCatalogue}>
      <img
        src="/Assets/add-square.svg"  // Replace with the path to your "add" icon
        alt="Add"
        className="h-6 w-6" // Adjust height and width as needed
      />
      <span className="text-[#263238] ml-2 p-3">Add</span>
    </div>
  )}
</div>

          </div>
        </div>
    
        <div className="w-full lg:w-4/5 relative p-2 lg:ml-6 lg:mt-3 ">
          {/* Grid of TabButtons */}
          <div className="grid grid-cols-2 lg:grid-cols-2 lg:gap-4 gap-5">
            <TabButton
              icon="/Assets/profile_management.svg"
              label="Management"
              path="/view-management"
            />
            <TabButton
              icon="/Assets/profile_docs.svg"
              label="Documents"
              path="/company_docs_view"
            />
            <TabButton
              icon="/Assets/profile_complaints.svg"
              label="Complaints"
              path="/complaints-received"
            />
            <TabButton
              icon="/Assets/profile_feedback.svg"
              label="Feedback"
              path="/feedback/owner"
            />
          </div>

          {/* Centered white circle */}
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <div className="relative lg:w-40 lg:h-40 h-25 w-25 bg-white rounded-full flex items-center justify-center">
              <div className="grid grid-cols-2 lg:gap-3 gap-2 lg:p-3 p-1">
                <img
                  src="/Assets/photos.svg"
                  alt="Photos"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => handleImageClick('/view_photos')}
                />
                <img
                  src="/Assets/videos.svg"
                  alt="Videos"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => handleImageClick('/uploaded_video')}
                />
                <img
                  src="/Assets/product.png"
                  alt="Products"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => handleImageClick('/product_service_list')}
                />
                <img
                  src="/Assets/verified-tick.svg"
                  alt="Verification"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => handleImageClick('/profile-verification')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for large screens */}
      <WebLinkModal 
  isOpen={isModalOpen} 
  onClose={() => setIsModalOpen(false)} 
  links={webLinks} // Pass the webLinks array
  onAddClick={() => { /* Handle Add Click */ }}
/>

<BottomSheet 
  isOpen={isBottomSheetOpen} 
  onClose={() => setIsBottomSheetOpen(false)} 
  links={webLinks} // Pass the webLinks array
  onAddClick={() => { /* Handle Add Click */ }}
/>
      {/* Confirmation Popup */}
      <ConfirmationPopup
        onClose={() => setShowPopup(false)}
        show={showPopup}
      />
    </div>
  );
};

export default ProfileVisitorsView;