import React from 'react';

const ProductAdd = ({ text, onDelete ,showDelete}) => {
  return (
    <div className="flex justify-between font-technaitems-center bg-gray-200 rounded-lg lg:p-4 p-3 mb-2">
      <span className="text-[#B71C1C] font-semibold text-sm lg:text-[17px]">{text}</span>
      {showDelete && (
        <img
          src="/Assets/bin.svg" // Assume this is your delete icon
          alt="Delete"
          className="cursor-pointer"
          onClick={onDelete} // Only trigger delete if showDelete is true
        />
      )}
    </div>
  );
};

export default ProductAdd;
