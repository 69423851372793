
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import { get_company_about } from '../../../Controller/company_details';
import { FaSpinner } from 'react-icons/fa'; // Import FontAwesome Spinner
import { categoryMapping } from '../../Components/category';

const VerificationOfProfile = () => {
  const location = useLocation();
  const { uid } = useParams(); 
  const navigate = useNavigate();
  
  const [isMyProfile, setIsMyProfile] = useState(false); 
  const [loading, setLoading] = useState(true); // State for loading
  const [profileData, setProfileData] = useState({
    storeName: '',
    category: '',
    location: '',
    logoUrl: './default_logo.svg',
    verifiedOn: '',
    validityDate: ''
  });

  const formatDateTime = (dateTime) => {
    if (!dateTime) return 'Not available';
    const date = new Date(dateTime + 'Z');
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        let companyUid;

        if (uid) {
          setIsMyProfile(false);
          companyUid = uid;
        } else {
          setIsMyProfile(true);
          companyUid = localStorage.getItem('company_uid');
        }

        if (!companyUid) {
          throw new Error("Company UID is not available.");
        }

        const response = await get_company_about(companyUid);
         // Console the API response for debugging
      console.log('API Response:', response);
        if (response.status === 200) {
          const company = response.data.data;
          setProfileData({
            storeName: company.name,
            category: company.category, // Store the integer category
            location: company.location,
            logoUrl: company.logo || './default_logo.svg',
            verifiedOn: company.verified_on,
            validityDate: company.valid_till
          });
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      } finally {
        setLoading(false); // Stop loading after data is fetched
      }
    };

    fetchCompanyDetails();
  }, [uid]);

  const formatLocation = (fullLocation) => {
    if (!fullLocation) return 'Location not available';
    
    // Split the location by commas and take the last two parts
    const locationParts = fullLocation.split(',');
    
    // Check if there are at least two parts to display
    if (locationParts.length >= 2) {
      const lastTwoParts = locationParts.slice(-1).join(',').trim(); // Join last two parts and trim spaces
      return lastTwoParts;
    }
  
    return fullLocation; // Return the full location if splitting doesn't provide two parts
  };



  if (loading) {
    // Display only one loading spinner in the center while loading
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="text-4xl text-gray-500 animate-spin" />
      </div>
    );
  }

  return (
    <>
      <div className="h-full w-full flex flex-col overflow-y-auto">
        <Sidebar />
        <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
          <div className="w-full flex justify-start items-center space-x-2">
            <h1 className="text-black font-semibold text-xl">
              {isMyProfile ? 'Verification' : 'Verification'}
            </h1>
          </div>
        </div>

        <div className="border-b border-black mt-4 md:mt-[25px]"></div>

        <main className="flex-grow p-4 lg:w-4/5 w-full flex justify-center overflow-y-auto font-poppins mt-10 ">
          <div className=" bg-white rounded-lg max-w-lg w-full text-center">
            {/* Profile Section */}
            <div className="flex flex-col items-center mb-4">
              <img
                src={profileData.logoUrl}
                alt="logo"
                className="w-28 h-28 mb-4 rounded-full object-cover"
              />
              <h2 className="lg:text-xl sm:text-lg font-poppins font-semibold mb-2">
                {profileData.storeName}
              </h2>
              <p className="lg:text-sm text-xs font-medium text-gray-600">
                {categoryMapping[profileData.category] || 'Unknown Category'} {/* Use categoryMapping to display the category name */}
              </p>
     
            <p className="lg:text-sm text-xs font-medium text-gray-600">
              {formatLocation(profileData.location)} {/* Format the location */}
            </p>
            </div>

            {/* Verification Details */}
            <div className="bg-gray-100 p-4 rounded-md mb-4 text-left mt-12">
              <div className="flex justify-between mb-2">
                <span className="lg:text-sm text-xs ">eSwaF user since:</span>
                <span className="lg:text-sm text-xs">
                  {profileData.verifiedOn ? formatDateTime(profileData.verifiedOn) : 'Not Verified'}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="lg:text-sm text-xs ">Verification validity:</span>
                <span className="lg:text-sm text-xs">
                  {profileData.validityDate ? formatDateTime(profileData.validityDate) : 'Not applicable'}
                </span>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default VerificationOfProfile;
