import { useState, useEffect } from "react";

const ComplaintCard = ({ complaint, onView }) => {
  const [profileImageUrl, setProfileImageUrl] = useState("/profile.webp");

  useEffect(() => {
    try {
      const storedProfileImageUrl = localStorage.getItem("profile_image_url");
      if (storedProfileImageUrl) {
        setProfileImageUrl(storedProfileImageUrl);
      }
    } catch (error) {
      console.error("Error accessing localStorage:", error);
    }
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "Date not available";
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } catch (error) {
      console.error("Date formatting error:", error);
      return "Date not available";
    }
  };

  const truncateText = (text = "", maxLength = 30) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const getComplaintImageUrl = () => {
    if (!complaint?.image_url) return "/profile.webp";
    if (typeof complaint.image_url === "string") return complaint.image_url;
    if (Array.isArray(complaint.image_url) && complaint.image_url.length > 0) {
      return complaint.image_url[0] || "/profile.webp";
    }
    return "/profile.webp";
  };

  const handleImageError = (e) => {
    e.target.src = "/profile.webp";
  };

  return (
    <div
      className="flex bg-gray-100 rounded-lg shadow-md p-4 mb-4 w-full cursor-pointer"
      style={{ height: "140px" }} // Fixed height for consistent card size
      onClick={() => onView(complaint)}
    >
      <div className="flex-none mr-4">
        <img
          src={complaint?.complaint_by_profile_image || profileImageUrl}
          alt="Profile"
          className="w-12 h-12 rounded-full object-cover"
          onError={handleImageError}
        />
      </div>

      <div className="flex-grow overflow-hidden">
        <div className="flex justify-between items-center">
          <span className="font-bold text-sm">
            {truncateText(complaint?.complaint_by_name || "Unknown User")}
          </span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onView(complaint);
            }}
            className="text-red-600 hover:text-red-700 font-semibold text-xs transition-colors duration-200"
          >
            View
          </button>
        </div>
        <span className="text-gray-500 text-xs">
          {formatDate(complaint?.created_on)}
        </span>

        <div className="mt-2 overflow-hidden">
          <h4 className="font-semibold text-sm">
            {truncateText(complaint?.title || "No Title", 20)}
          </h4>
          <p className="text-gray-600 text-xs mt-1 overflow-hidden whitespace-nowrap">
            {truncateText(complaint?.description || "No description available", 40)}
          </p>
        </div>
      </div>

      <div
        className="flex flex-col items-center justify-center ml-4 space-y-2"
        style={{ width: "56px", height: "56px" }}
        onClick={(e) => e.stopPropagation()} // Prevent this div from triggering the main onClick
      >
        <img
          src={getComplaintImageUrl()}
          alt="Complaint"
          className="w-full h-full rounded-lg object-cover"
          onError={handleImageError}
        />
      </div>
    </div>
  );
};

export default ComplaintCard;
