
// import React from 'react';

// const AddDocument = ({ label }) => {
//   return (
//     <div className="flex items-center mb-4 border border-[#A2A2A2] bg-[#E2E2E2] rounded-lg p-2 w-full md:w-4/5 lg:w-3/4">
//       <input type="file" id="file-upload" className="hidden" accept="image/*" />
//       <label htmlFor="file-upload" className="flex-1 cursor-pointer">
//         <div className="flex justify-between items-center">
//           <span className="text-gray-500">{label}</span>
//           <img src="/Assets/doc.svg" alt="Screenshot Icon" />
//         </div>
//       </label>
//     </div>
//   );
// };

// export default AddDocument;
import React, { useState } from 'react';

const AddDocument = ({ label, onFileUpload, accept }) => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      onFileUpload(file);
    }
  };

  const truncateFileName = (name, maxLength = 20) => {
    if (name.length <= maxLength) return name;
    const extension = name.split('.').pop();
    const nameWithoutExtension = name.substring(0, name.lastIndexOf('.'));
    return `${nameWithoutExtension.substring(0, maxLength - extension.length - 3)}...${extension}`;
  };

  return (
    <div className="flex items-center mb-4 border bg-[#E5E5E5] rounded-lg p-2 w-full font-poppins">
      <input
        type="file"
        id="file-upload"
        className="hidden"
        accept={accept}
        onChange={handleFileChange}
      />
      <label htmlFor="file-upload" className="flex-1 cursor-pointer">
        <div className="flex justify-between items-center">
          <span className="text-[#737373] text-[12px]   truncate mr-2">
            {fileName ? truncateFileName(fileName) : label}
          </span>
          <img src="/Assets/doc.svg" alt="Document Icon" className="flex-shrink-0" />
        </div>
      </label>
    </div>
  );
};

export default AddDocument;


