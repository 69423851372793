// import React from 'react';
// import Sidebar from '../../Components/main_navbar';
// import { useNavigate } from 'react-router-dom';

 
// const Paymentmethod = () => {
//   const navigate = useNavigate();
//   const handleBackClick = () => {
//     navigate('/my_subscription');
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F2F3F3]">
//       <Sidebar />
//       <div className="w-full flex justify-start items-center space-x-2 ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
//         <img
//           src="/Assets/arrow_back.svg"
//           alt="Back"
//           className="cursor-pointer h-6 w-6"
//           onClick={handleBackClick}
//         />
//         <h1 className="text-black font-bold sm:text-lg lg:text-xl">
//           Subscription Plans
//         </h1>
//       </div>
//       <div className="flex self-end lg:mr-5 md:mr-7 pr-5">
//         <img
//           src="/Assets/crown.svg" 
//           alt="crown"
//           className="h-8 w-8"
//         />
//       </div>
//       <div className="border-b border-black w-full mt-4 md:mt-[45px]"></div>
      // <div className="flex flex-col items-center mt-8">
      //   <div className="bg-[#A2A2A2] p-8 rounded-lg w-1/3 h-full p-7">
      //     <div className="mb-4">
      //       <input
      //         type="text"
      //         placeholder="Holder Name"
      //         className="w-full p-3 rounded bg-white"
      //       />
      //     </div>
      //     <div className="mb-4 flex items-center">
      //       <input
      //         type="text"
      //         placeholder="Card Number"
      //         className="w-full p-3 rounded bg-white"
              
      //       />
      //       <img src="./visa.svg" alt="Visa" className="ml-2 w-12" />
      //     </div>
      //     <div className="flex space-x-4">
      //       <input
      //         type="text"
      //         placeholder="MM/YY"
      //         className="w-1/2 p-3 rounded bg-white"
      //       />
      //       <input
      //         type="text"
      //         placeholder="CVC"
      //         className="w-1/2 p-3 rounded bg-white"
      //       />
      //     </div>
      //   </div>
      //   <div className="mt-8 flex justify-between w-96">
      //     <img src="./Paypal.svg" alt="PayPal" className="w-20" />
      //     <img src="./gpay.svg" alt="GPay" className="w-16" />
      //     <img src="./apple-pay.svg" alt="Apple Pay" className="w-16" />
      //   </div>
      // </div>
//     </div>
//   );
// };

// export default Paymentmethod;

import { useNavigate } from 'react-router-dom';
import GoldSupplier from '../../Components/gold_supplier';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';

const SubscriptionPayment = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  const handleGoldSupplierClick = () => {
    navigate('/subscription_details'); // replace with the actual path you want to navigate to
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3]">
      <div className="flex ml-7 mt-4 w-4/5">
        <Sidebar />
        <div className="w-full flex justify-start items-center space-x-2">
          <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="cursor-pointer mr-2 h-6 w-6"
            onClick={handleBackClick}
          />
          <h1 className="text-black font-bold sm:text-lg lg:text-xl">Payment Method</h1>
        </div>
        <div className="lg:mr-5 md:mr-7 pr-5 flex self-end">
          <img
            src="/Assets/crown.svg"
            alt="crown"
            className="h-8 w-8"
          />
        </div>
      </div>
      
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
      
      <div className="flex justify-center w-4/5 mt-24 lg:ml-5 mx-auto overflow-auto">
        <div className="flex flex-col space-y-7 w-full  md:px-0 max-w-lg">
          <div className="w-full">
            <GoldSupplier 
              backgroundColor="#DB0000" 
              textColor="#F9F9F9" 
              title="Gold Supplier" 
              subtitle="Standard Extended" 
              price="2999$/year" 
              height="170px" 
              width="full" 
              onClick={handleGoldSupplierClick} 
            />
            <div className="w-full bg-[#F0F0F0] p-4 space-y-4">
              <h2 className="text-xl font-bold mt-4">To Pay now</h2>
              <div className="flex justify-between mt-2">
                <span>Sub Total</span>
                <span>$2999</span>
              </div>
              <div className="flex justify-between mt-2">
                <span>Tax</span>
                <span>$399</span>
              </div>
              <div className="border-t border-gray-300 my-2"></div>
              <div className="flex justify-between font-bold">
                <span>TOTAL</span>
                <span>$3398</span>
              </div>
            </div>
          
          </div>
         
          <div className="mt-20 lg:ml-7 w-full max-w-md">
            <Button
              text="Pay Now "
              borderColor="border-red-600"
              backgroundColor="bg-red-600"
              textColor="text-white"
              id="otp-btn"
            />
          </div>
        </div>
        
      </div>      
      
    

      
    </div>
    
  );
};

export default SubscriptionPayment;
