import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import LoaderSpinner from '../../Components/loader_spinner'; // Import the LoaderSpinner component
import { post_confirm_payment as postConfirmPayment } from '../../../Controller/subscriptions';

const PaymentSuccessful = () => {
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State to manage loading spinner
  const [paymentConfirmed, setPaymentConfirmed] = useState(false); // State to track successful payment

  useEffect(() => {
    // Extract session_id from query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const session_id = queryParams.get('session_id');

    if (session_id) {
      setSessionId(session_id);

      // Call the payment confirmation API once session_id is found
      const confirmPayment = async () => {
        setIsLoading(true); // Start loading spinner
        try {
          const res = await postConfirmPayment(session_id);
          console.log('Response:', res);

          // Check if the response status is 200
          if (res?.status === 200) {
            // Set active_subscriber to true in localStorage
            localStorage.setItem('active_subscriber', 'true');
            setPaymentConfirmed(true); // Set payment confirmed status
          }
        } catch (error) {
          console.error('Error posting user payment:', error);
        } finally {
          setIsLoading(false); // Stop loading spinner
        }
      };

      confirmPayment();
    } else {
      console.error('Session ID not found in URL');
    }
  }, []);

  const handleBackClick = () => {
    navigate('/subscription_details');
  };

  const handleDoneClick = () => {
    // Navigate to the subscription page if payment is confirmed
    if (paymentConfirmed) {
      navigate('/my_subscription');
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3]">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="cursor-pointer mr-2 h-6 w-6"
            onClick={handleBackClick}
          />
          <h1 className="text-black font-bold sm:text-lg lg:text-xl">Subscription</h1>
        </div>
        <div className="mr-5 md:mr-7 pr-5 flex self-end">
          <img
            src="/Assets/crown.svg"
            alt="crown"
            className="h-8 w-8"
          />
        </div>
      </div>

      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
      <div className="flex justify-center w-4/5 lg:ml-10 mx-auto">
        <div className="w-full md:w-[600px] bg-[#F0F0F0] mt-16">
          <div className='mt-5 flex justify-center'>
            <img src="Assets/pay_successful.svg" alt="company icon" className="lg:w-80 lg:h-72" />
          </div>
          <div className='flex justify-center'>
            <p className="text-lg font-bold">Payment Successful</p>
          </div>
          <div className="flex justify-center mt-5 lg:mx-16">
            <button
              className="border border-red-600 bg-[#DB0000] text-white py-2 px-6 rounded-lg flex justify-center items-center w-full"
              style={{ height: '50px', maxWidth: '300px' }}
              disabled={isLoading}
              onClick={handleDoneClick} // Call navigation only on button click
            >
              {isLoading ? (
                <LoaderSpinner visible={isLoading} height={15} /> // Show spinner during loading
              ) : (
                'Done'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
