import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// Helper function to get the appropriate icon path for each link type
const getLinkIcon = (link) => {
  const normalizedLink = link.toLowerCase();
  console.log('Normalized Link:', normalizedLink); // Log to verify
  if (normalizedLink.includes('instagram.com')) return '/Assets/insta.svg';
  if (normalizedLink.includes('facebook.com')) return '/Assets/facebook1.svg';
  if (normalizedLink.includes('linkedin.com')) return '/Assets/linkedin1.svg';
  if (normalizedLink.includes('twitter.com')) return '/Assets/twitter_icon.svg';
  if (normalizedLink.includes('website')) return '/Assets/website.svg';
  return '/Assets/website.svg'; // Default icon for other links
};


const WebLinkModal = ({ isOpen, onClose, links = [], onAddClick, hideAddButton }) => {
  const navigate = useNavigate();

  console.log("Links array in WebLinkModal:", links); // Log to check link structure

  const handleAddClick = () => {
    console.log("Navigating to SocialLinks with links:", links); // Log data before navigating
    navigate('/social_links', { state: { links } });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 font-poppins">
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="bg-[#F2F2F2] p-8 rounded-lg shadow-lg z-10 mx-auto w-[500px] relative">
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-gray-500 font-normal text-3xl"
        >
          &times;
        </button>

        <h2 className="text-lg font-semibold mb-4">Web Links</h2>

        {/* Display Multiple Links */}
        {links.length > 0 ? (
          links.map((link, index) => (
            <div className="mb-4" key={index}>
              <div className="flex items-center mb-1">
                <img 
                  src={getLinkIcon(link)} 
                  alt="Link Icon" 
                  className="w-6 h-6 mr-2 flex-shrink-0"
                />
                <span className="text-gray-700 font-medium flex-shrink-0">
                  {link.split(':')[0]}:
                </span>
              </div>
              <a 
                href={link.includes('http') ? link.split(': ')[1] : `http://${link.split(': ')[1]}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-500 underline break-words"
              >
                {link.split(': ')[1]}
              </a>
            </div>
          ))
        ) : (
          <p className="text-gray-500 mb-6">No web links available.</p>
        )}

        {!hideAddButton && (
          <>
            <h2 className="text-lg font-semibold mb-4">Add Web Links</h2>
            <div className="bg-gray-200 p-4 flex justify-center items-center h-16">
              <button 
                onClick={handleAddClick} 
                className="bg-red-600 rounded-full w-10 h-10 flex items-center justify-center text-white shadow-lg hover:bg-red-500 transition duration-300"
              >
                <FaPlus size={20} />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};



// Adjustments for BottomSheet
const BottomSheet = ({ isOpen, onClose, links = [], onAddClick, hideAddButton }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 z-50">
      {/* Overlay to close the sheet */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>

      {/* Adjust the BottomSheet */}
      <div className="bg-[#E1E1E1] p-4 z-50 relative w-full">
        {/* Cross Icon in Top Right */}
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-[#DB0000] font-bold text-xl"
        >
          &times;
        </button>

        {/* Web Links Heading */}
        <h2 className="text-lg font-semibold mb-4">Web Links</h2>

        {/* Display Multiple Links */}
        {links.length > 0 ? (
          links.map((link, index) => (
            <div className="mb-4" key={index}>
              <div className="flex items-center mb-1">
                <img 
                  src={getLinkIcon(link)} 
                  alt="Link Icon" 
                  className="w-6 h-6 mr-2 flex-shrink-0"
                />
                <span className="text-gray-700 font-medium flex-shrink-0">
                  {link.split(':')[0]}:
                </span>
              </div>
              <a 
                href={link.includes('http') ? link.split(': ')[1] : `http://${link.split(': ')[1]}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-500 underline break-words"
              >
                {link.split(': ')[1]}
              </a>
            </div>
          ))
        ) : (
          <p className="text-gray-500 mb-6">No web links available.</p>
        )}

        {/* Add Web Links Section */}
        {!hideAddButton && (
          <>
            <h2 className="text-lg font-semibold mb-4">Add Web Links</h2>
            <div className="bg-gray-200 p-4 flex justify-center items-center mb-6 h-32">
            <button 
                onClick={() => navigate('/social_links')} 
                className="bg-red-600 rounded-full w-14 h-14 flex items-center justify-center text-white shadow-lg hover:bg-red-500 transition duration-300"
              >
                <FaPlus size={24} />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { WebLinkModal, BottomSheet };
