import React, { useState } from 'react';
import { Sparkles } from 'lucide-react';

const GeminiContentGenerator = ({ value, onChange, placeholder, minLength = 150, maxLength = 200 }) => {
  const [isLoading, setIsLoading] = useState(false);

  const generateDescription = async () => {
    if (!value.trim()) {
      return;
    }

    setIsLoading(true);

    // Use the first line or first few words as the company name
    const companyName = value.split('\n')[0] || value.split(' ').slice(0, 3).join(' ');

    const prompt = `Generate a professional and concise description between ${minLength} and ${maxLength} characters for ${companyName}. Focus on their likely main business activities and value proposition. The description must be at least ${minLength} characters and no more than ${maxLength} characters long. Make it sound professional and realistic.`;

    try {
      const response = await fetch(
        'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=AIzaSyBDNGNDD1B0dcAQ1yL3eEvaVK3nnH13Ym0',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contents: [
              {
                parts: [
                  {
                    text: prompt
                  }
                ]
              }
            ]
          })
        }
      );

      const data = await response.json();
      let generatedText = data.candidates?.[0]?.content?.parts?.[0]?.text || '';
      
      // Ensure the text meets the minimum length requirement
      while (generatedText.length < minLength) {
        generatedText += ' ' + generatedText;
      }
      
      // Trim to maximum length while ensuring we don't cut words in half
      if (generatedText.length > maxLength) {
        generatedText = generatedText.substr(0, maxLength);
        // Find the last space before maxLength
        const lastSpace = generatedText.lastIndexOf(' ');
        if (lastSpace > minLength) {
          generatedText = generatedText.substr(0, lastSpace);
        }
      }
      
      onChange(generatedText);
    } catch (err) {
      console.error('Generation error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full">
      <div className="relative">
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="w-full p-4 bg-white outline-none resize-none font-poppins text-sm text-gray-500"
          placeholder={placeholder || "Enter company name and click the sparkle icon to generate description (150-200 characters)..."}
          style={{
            minHeight: '120px',
            border: '1px solid #E8E8E8',
            borderRadius: '4px',
          }}
          maxLength={maxLength}
        />
        
        <div className="absolute bottom-2 right-2 flex items-center gap-2">
          <button
            onClick={generateDescription}
            disabled={isLoading || !value.trim()}
            className="p-2 text-indigo-600 hover:text-indigo-800 disabled:text-gray-400 bg-white rounded-full transition-colors"
            title="Generate AI Description"
          >
            <Sparkles 
              size={20}
              className={`${isLoading ? 'animate-pulse' : ''}`}
            />
          </button>
        </div>
      </div>
      
      <div className="flex justify-end mt-1">
        <span className={`text-xs font-poppins ${
          value.length < minLength ? '' : 
          value.length > maxLength ? '' : 
          'text-gray-500'
        }`}>
          {value.length}/{maxLength}
        </span>
      </div>
    </div>
  );
};

export default GeminiContentGenerator;