import { CANCEL_COMPANY_SUBSCRIPTION, GET_SUBSCRIPTIONS } from "./urls";
import { api } from "./api";
import { POST_USER_PAYMENTS } from "./urls";
import { GET_COMPANY_SUBSCRIPTION } from "./urls";
import { POST_CONFIRM_SUBSCRIPTION } from "./urls";
const get_subscriptions_api = async () => {
    try {
      const category = localStorage.getItem('category'); 
      const res = await api.get(GET_SUBSCRIPTIONS);
      return res;
    } catch (error) {
      console.error("Error fetching SUBSCRIPTIONS:", error);
      throw error;  
    }
  };



  
  const post_user_payments_api = async (product_id) => {
    // Construct the full URL with product_id as a path parameter
    const apiUrl = `${POST_USER_PAYMENTS}/${product_id}`;
  
    // Log the URL and payload before making the request
    console.log('POST request URL:', apiUrl);
  
    try {
      const res = await api.post(apiUrl);
      return res;
    } catch (error) {
      console.error("Error posting user payment:", error);
      throw error;  
    }
  };


  const post_confirm_payment = async (session_uid) => {
    const apiUrl = POST_CONFIRM_SUBSCRIPTION(session_uid);
  
    console.log('POST request URL:', apiUrl);
  
    try {
      const res = await api.post(apiUrl);
      return res;
    } catch (error) {
      console.error("Error posting user payment:", error);
      throw error;  
    }
  };



  export const getSubscriptionByCompany = async (uid) => {
    try {
      const url = GET_COMPANY_SUBSCRIPTION(uid); // Call the function with uid
      console.log('API URL:', url); // Log the URL to confirm
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription:', error);
      throw error;
    }
  };
  
  const deleteplan = async (uid) => {
    const apiUrl = CANCEL_COMPANY_SUBSCRIPTION(uid);
  
    console.log('POST request URL:', apiUrl);
  
    try {
      const res = await api.delete(apiUrl);
      return res;
    } catch (error) {
      console.error("Error posting user payment:", error);
      throw error;  
    }
  };

export { get_subscriptions_api,post_user_payments_api,post_confirm_payment,deleteplan}