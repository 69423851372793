import { LOGIN_URL,CHECK_EMAIL_URL,SIGNUP_URL, RESET_PASSWORD_OTP, COUNTRY_CODE, PHONE_VERIFY, SET_PASSWORD, LOGOUT_URL,CHECK_PHONE_URL } from "./urls";
import { api } from "./api";
import { GET_SUB_COMPANY_URL } from "./urls";
import { REGISTER_URL } from "./urls";
import { SEND_OTP } from "./urls";
import { RESET_PASSWORD_MAIL } from "./urls";
import { RESET_PASSWORD } from "./urls";
import { POST_LOCATION_URL } from "./urls";
import { POST_SUB_COMPANY_URL } from "./urls";
import { POST_ONBOARDING_PRIMARY } from "./urls";
export const check_email = async (email) => {
  try {
    const res = await api.post(CHECK_EMAIL_URL, { email });
    return res;
  } catch (error) {
    console.error('Error checking email:', error);
    throw error;
  }
};



const login = async (password, mail) => {
    const res = await api.post(LOGIN_URL, {
        username:mail,
        password: password,
       
    },
    
  );
    return res;
};

 const signup = async (country, continent, areaofoperation, company_type, company_sub_type, fcm_token) => {
    const res = await api.post(SIGNUP_URL, {
        country: country,
        continent: continent,
        area_of_operation: areaofoperation,
        company_type: company_type,
        company_sub_type: company_sub_type,
        fcm_token: fcm_token,
  });
     return res;
 };




 const register = async (name, mail, password) => {
  const res = await api.post(REGISTER_URL, {
      name: name,
      mail: mail,
      password: password,    
});
   return res;
};


// const get_sub_companies_api = async () => {
//   try {
//     const category = localStorage.getItem('category'); 
//     const res = await api.get(GET_SUB_COMPANY_URL(category));
//     return res;
//   } catch (error) {
//     console.error("Error fetching companies:", error);
//     throw error;  
//   }
// };
const get_sub_companies_api = async () => {
  try {
    const category = localStorage.getItem('category'); // This will be an integer now
    const res = await api.get(GET_SUB_COMPANY_URL(category));
    return res;
  } catch (error) {
    console.error("Error fetching companies:", error);
    throw error;  
  }
};


const send_otp = async (email, otp) => {
  console.log(`Email: ${email}, OTP: ${otp}`);
  const res = await api.post(SEND_OTP, {
    email: email,
    otp: otp
  });
  return res;
};


const resetPasswordMail = async (email) => {
  console.log(`www${email}`)

  const res = await api.post(RESET_PASSWORD_MAIL, {
    email: email,
  });
  return res;
};



const postLocation =  async (payload) => {
  try {
      const res = await api.post(POST_LOCATION_URL, payload)
      return res;
  } catch (error) {
      console.error("Error:", error);
      throw error;
  }
};


const postOnBoardingDetails =  async (payload) => {
  try {
      const res = await api.post(POST_ONBOARDING_PRIMARY, payload)
      return res;
  } catch (error) {
      console.error("Error:", error);
      throw error;
  }
};




const resetPasswordOtp = async (email, otp) => {
  console.log(`www${email}`)
  const res = await api.post(RESET_PASSWORD_OTP, {
    email: email,
    otp: otp,
  });
  return res;
};

const resetPassword = async (password,email,secret) => {

  const res = await api.post(RESET_PASSWORD, {
    email:email,
    password: password,
    secret: secret 
  });
  return res;
};

const get_country_code = async (continent) => {
  try {
    console.log(continent)
    const res = await api.get(`https://eswaf-api-zhffjqtxgq-uc.a.run.app/app/v1/extras/country/${continent}`);
    return res;
  } catch (error) {
    console.error("Error fetching countries:", error);
    throw error;
  }
};
// const verify_phone= async (email,secret,firebase_uid) => {
//   const res = await api.post(RESET_PASSWORD, {
//     email:email,
//     firebase_uid:firebase_uid,
//     secret: secret 
//   });
//   return res;
// };

const verify_phone = async (payload) => {
  try {
      const res = await api.post(PHONE_VERIFY, payload)
      return res.data;
  } catch (error) {
      console.error("Error:", error);
      throw error;
  }
};
const postsubcategory = async (payload) => {
  try {
      const res = await api.post(POST_SUB_COMPANY_URL, payload)
      return res;
  } catch (error) {
      console.error("Error:", error);
      throw error;
  }
};

const set_password = async (payload) => {
  try {
      const res = await api.post(SET_PASSWORD, payload)
      return res;
  } catch (error) {
      console.error("Error:", error);
      throw error;
  }
};
const logout = async () => {
  const res = await api.post(LOGOUT_URL, {});
  return res;
};

const checkPhone = async (phone) => {
  try {
    const res = await api.post(CHECK_PHONE_URL, { phone });
    return res.data;
  } catch (error) {
    console.error("Error checking phone:", error);
    throw error;
  }
};


export {  login, signup,register,get_sub_companies_api,send_otp,resetPassword,resetPasswordOtp,resetPasswordMail,get_country_code,verify_phone,set_password,postLocation,postsubcategory,postOnBoardingDetails,logout,checkPhone};