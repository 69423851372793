import React, { useEffect } from "react";
import OTPInput from "otp-input-react";
import './otp_input.css';

const OTPInputComponent = ({ otp, handleChange, setOtp }) => {
  const inputStyles = {
    width: '100%',
    maxWidth: '55px',
    height: '60px',
    border: '1px solid rgba(162, 162, 162, 1)',
    textAlign: 'center',
    margin: '0 7px', // Increased margin for more gap
    fontSize: '16px',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    color: 'rgba(162, 162, 162, 1)',
  };

  // Use Web OTP API for auto-filling OTP on mobile
 
  return (
    <div className="otp-container">
      <OTPInput
        value={otp}
        onChange={handleChange}
        autoFocus
        OTPLength={6}
        otpType="number"
        disabled={false}
        secure={false}
        inputStyles={inputStyles}
      />
    </div>
  );
};

export default OTPInputComponent;