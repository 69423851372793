import React, { useEffect } from 'react';

const ImagePopup = ({ imageUrl, onClose }) => {
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.classList.contains('overlay')) {
                onClose();
            }
        };
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [onClose]);

    return (
        <>
            {/* Overlay background */}
            <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60 overlay"></div>
            
            {/* Popup container */}
            <div className="fixed inset-0 flex items-center justify-center overlay">
                <div className="rounded-lg shadow-lg p-4 max-w-md w-full max-h-[600px] overflow-hidden text-center mx-4 flex flex-col items-center">
                    {/* Image with specific dimensions */}
                    <img
                        src={imageUrl}
                        alt="Popup Image"
                        className="w-full h-full max-w-xs max-h-[500px] object-contain rounded-lg" 
                    />
                </div>
            </div>
        </>
    );
};

export default ImagePopup;
