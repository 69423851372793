import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import Sidebar from '../../Components/main_navbar';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

const SwiperGallery = () => {
  const location = useLocation();
  const { state } = location;
  const { selectedPhoto, photos } = state;
  const [initialSlide, setInitialSlide] = useState(0);

  useEffect(() => {
    const initialIndex = photos.findIndex(photo => photo.image_url === selectedPhoto.image_url);
    setInitialSlide(initialIndex);
  }, [selectedPhoto, photos]);

  const swiperStyles = {
    width: '100%',
    height: '100%',
    position: 'relative',
  };

  const slideStyles = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '500px',
    height: 'auto',
  };

  const imgStyles = {
    display: 'block',
    width: '500px',
    height: '400px',
    objectFit: 'cover',
    borderRadius: '10px',
  };

  const textContainerStyles = {
    textAlign: 'center',
    marginTop: '10px',
  };

  const paginationStyles = {
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
  };
  
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/company_docs');
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna overflow-y-auto">
      <Sidebar />
      <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
        <div className="flex items-center space-x-2">
          
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Photos</h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className='w-4/5 mx-auto '>
        <div className="container mx-auto p-6 ">
          <Swiper
            effect="coverflow"
            grabCursor={true}
            centeredSlides={true}
            slidesPerView="auto"
            initialSlide={initialSlide}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 250,
              modifier: 1,
              slideShadows: false,
            }}
            pagination={{ clickable: true, el: '.swiper-pagination' }}
            modules={[EffectCoverflow, Pagination]}
            style={swiperStyles}
            className="mySwiper"
          >
            {photos.map((photo, index) => (
              <SwiperSlide key={index} style={slideStyles}>
                <img src={photo.image_url} alt={`Slide ${index + 1}`} style={imgStyles} />
                <div style={textContainerStyles}>
                  <h2 className="text-lg font-bold">{photo.title}</h2>
                  <p className="text-sm">{photo.description}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-pagination" style={paginationStyles}></div>
        </div>
      </div>
    </div>
  );
};

export default SwiperGallery;
