import { POST_COMPANY_BOOKMARK_URL, DELETE_COMPANY_BOOKMARK_URL ,GET_COMPANY_BOOKMARKS_URL} from "./urls";
import { api } from "./api";

// Function to create a company bookmark
const post_company_bookmark = async (uid) => {
  const res = await api.post(POST_COMPANY_BOOKMARK_URL(uid));
  return res;
};

const delete_company_bookmark = async (uid) => {
    const res = await api.delete(DELETE_COMPANY_BOOKMARK_URL(uid));
    return res;
  };
  

  const get_company_bookmarks = async (uid, skip = 0, limit = 10) => {
    const res = await api.get(GET_COMPANY_BOOKMARKS_URL(uid), {
      params: { skip, limit },
    });
    return res;
  };  

export { post_company_bookmark,delete_company_bookmark,get_company_bookmarks };
