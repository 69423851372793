import React, { useEffect, useState, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import SharePopup from '../../Components/share_alert';
import VerifiedPopup from '../../Components/pop_up';
import Sidebar from '../../Components/main_navbar';
import { get_user_video_api } from '../../../Controller/upload_videos'; // Import your API
import { FaSpinner } from 'react-icons/fa'; // Import the spinner icon from react-icons

const  VideoPlayer = () => {
  const location = useLocation();
  const { videoId } = location.state || {}; // Get videoId from navigation state
  const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [videoData, setVideoData] = useState({});
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showVerifiedPopup, setShowVerifiedPopup] = useState(false);
  const [showReportOptions, setShowReportOptions] = useState(false);
  const [reportSelected, setReportSelected] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false); // New state for description

  const videoRef = useRef(null); // Reference for the video element

  // Fetch the video URL and details using the videoId
  useEffect(() => {
    const fetchVideoData = async () => {
      if (videoId) {
        try {
          const fetchedVideoData = await get_user_video_api(videoId); // Fetch video details by videoId
          if (fetchedVideoData && fetchedVideoData.video_url) {
            setCurrentVideoUrl(fetchedVideoData.video_url);
            setVideoData(fetchedVideoData); // Set other video details (e.g., title, description)
          } else {
            throw new Error('Video data not found');
          }
        } catch (error) {
          console.error('Error fetching video data:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchVideoData();
  }, [videoId]);

  const toggleReportOptions = () => setShowReportOptions(!showReportOptions);
  const handleReportClick = () => setReportSelected(!reportSelected);

  // Toggle play/pause when video is clicked
  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const toggleDescription = () => {
    setIsDescriptionExpanded((prev) => !prev);
  };
  // Show FaSpinner while loading in place of the video
  return (
    <div className='bg-black h-full'>
    <div className="flex w-full  lg:w-[86%] h-full  overflow-y-auto">
      <Sidebar />
      <div className="relative  flex-1 flex flex-col items-center justify-center ">
        {/* <div className="absolute top-4 left-4 flex items-center">
          <div className='w-14 h-15 bg-white rounded-md p-1'>
            <Link to="/discover-page">
              <img src="/discover-box.svg" alt="Discover" className="w-12 h-12" />
            </Link>
          </div>
          <h1 className="text-white font-bold text-2xl ml-4">Discover</h1>
        </div> */}

        <div className="relative mt-40 lg:mt-[20px]">
          <div className="relative mb-6">
            {isLoading ? (
              // Display the loading spinner if the video is still loading
              <div className="flex justify-center items-center w-80 h-96">
                <FaSpinner className="animate-spin text-red-600" size={35} />
              </div>
            ) : (
              // Play the video using the fetched video URL
              <video
                ref={videoRef}
                src={currentVideoUrl}
                className="rounded-lg"
                style={{ width: '350px', height: '550px', objectFit: 'cover' }}
                onClick={togglePlayPause} // Toggle play/pause on click
              />
            )}
            
            {/* Displaying 4 icons on the left */}
            <div className="absolute top-24 mt-56 right-2 flex flex-col space-y-7">
              {/* <img 
                src="/report.svg" 
                alt="Report" 
                className="w-6 h-6 cursor-pointer" 
                onClick={toggleReportOptions} 
              /> */}
              {/* <img 
                src="/follow.svg" 
                alt="Follow" 
                className="w-6 h-6" 
              /> */}
              {/* <img 
                src="/share.svg" 
                alt="Share" 
                className="w-6 h-6 cursor-pointer" 
                onClick={() => setShowSharePopup(true)} 
              /> */}
            
            </div>
          </div>

          {/* Video details: Title and description */}
          {/* <div className="absolute inset-x-0 top-20 bottom-0 w-full bg-opacity-70 p-3 rounded-b-lg mt-[330px]"> */}
          <div className="absolute inset-x-0 top-16 bottom-0 w-full bg-gradient-to-t from-black bg-opacity-70 p-3 rounded-b-lg mt-[330px]">
            <div className="flex items-center space-x-2 mb-1">
              <img 
                src={videoData.logo || './profile.webp'} 
                alt="ID" 
                className="w-7 h-7 rounded-full" 
              />
              <p className="text-gray-200 ">{videoData.title || "Video Title"}</p>
              {videoData.verified && (
                <img
                  src="/tick.svg"
                  alt="Verified"
                  className="w-4 h-4 cursor-pointer"
                  onClick={() => setShowVerifiedPopup(true)}
                />
              )}
            </div>
            <p className="text-sm text-gray-200 pr-5">
              {isDescriptionExpanded
                ? videoData.description // Show full description if expanded
                : `${videoData.description?.substring(0, 100)}...`} {/* Truncate if not expanded */}
              {videoData.description && videoData.description.length > 100 && (
                <span
                  className="text-blue-500 cursor-pointer"
                  onClick={toggleDescription}
                >
                  {isDescriptionExpanded ? ' Show less' : ' Show more'}
                </span>
              )}
            </p>
          </div>

          {/* Show Report options when report icon is clicked */}
          {showReportOptions && (
            <div className="absolute top-24 mt-56 right-10 w-40 bg-gray-800 p-2 rounded-md">
              <p 
                className={`text-sm cursor-pointer p-2 rounded-md ${reportSelected ? 'text-red-500' : 'text-white'} hover:text-red-500 hover:bg-gray-700`} 
                onClick={handleReportClick}
              >
                Report
              </p>
              <p className="text-sm text-white cursor-pointer p-2 rounded-md hover:text-red-500 hover:bg-gray-700">Follow</p>
            </div>
          )}
        </div>

        {showSharePopup && <SharePopup onClose={() => setShowSharePopup(false)} />}
        {showVerifiedPopup && <VerifiedPopup onClose={() => setShowVerifiedPopup(false)} />}
      </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
