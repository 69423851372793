// companyDetailsController.js

import { post_company_details } from './company_details';
export const saveCompanyDetails = async (description, website, address, latitude, longitude, custom_address, navigate, setLoading) => {
  try {
    const response = await post_company_details(description, website, address, latitude, longitude, custom_address); // Pass customAddress here
    const message = response.data.message;

    if (response.status === 202) {
      console.log(response);
      navigate('/details_page'); // Only navigate if the response is successful
    } else if (response.status === 404) {
      // showToast('Failed to save company details', 'error');
    }
  } catch (error) {
    console.error('Error saving company details:', error);
    // showToast('Error saving company details', 'error');
  }
};


// export const validateWebsiteUrl = (url, callback) => {
//   const urlPattern = new RegExp(
//     '^(https?:\\/\\/)?' + // protocol
//       '((([a-z\\d]([a-z\\d-][a-z\\d]))\\.)+[a-z]{2,}|' + // domain name
//       '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
//       '(\\:\\d+)?(\\/[-a-z\\d%_.~+])?' + // port and path
//       '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
//       '(\\#[-a-z\\d_]*)?$',
//     'i'
//   );
//   const error = url && !urlPattern.test(url) ? 'Invalid website URL' : '';
//   callback(error);
// };