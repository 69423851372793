import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileImageContainer from '../../Components/profile_image_container.jsx';
import Button from '../../Components/button.jsx';
import Sidebar from '../../Components/main_navbar.jsx';
import { getOtherProfileById } from '../../../Controller/other_profile.js';
import StarRating from '../../Components/review_star.jsx';
import TabButton from '../../Components/profile_tab.jsx';
import { WebLinkModal, BottomSheet } from '../../Components/web_link.jsx';
import ConfirmationPopup from '../../Components/pop_up';
import { showToast } from '../../Components/toast.jsx';
import ImageButton from '../../Components/image_text_button.jsx';
import { post_company_bookmark,delete_company_bookmark } from '../../../Controller/bookmarks.js';
//addednew 
import { collection, addDoc, query, where, getDocs, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase_service';
import { setDoc,getDoc,doc } from 'firebase/firestore';
import { checkIfChatExists,createNewChat } from '../../../services/chatservice.js';
import { blockCompany ,unblockCompany} from '../../../Controller/block.js';
import DeletePopup from '../../Components/delete_pop_up.jsx';
import { FaSpinner } from 'react-icons/fa';
import { get_social_links } from '../../../Controller/company_details.js';
const OtherProfileVisitorsView = ({ editable }) => {
  const { company_id } = useParams(); // Extract company_id from the URL
  const navigate = useNavigate();
  const [selectedProfileImage, setSelectedProfileImage] = useState('');
  const [storeName, setStoreName] = useState('');
  const [category, setCategory] = useState('');
  const [locationText, setLocationText] = useState('');
  const [bio, setBio] = useState('');
  const [webLinks, setWebLinks] = useState([]);
  const [review, setReview] = useState(0);
  const [catalogue, setCatalogue] = useState(null);
  const [website, setWebsite] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isDotsMenuOpen, setIsDotsMenuOpen] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false); // Track if the user is followed or not
  const [loadingFollowUp, setLoadingFollowUp] = useState(false); 
  const [isBlockPopupVisible, setIsBlockPopupVisible] = useState(false); // State for block confirmation popup
  const [isBlocked, setIsBlocked] = useState(false); // Track whether the company is blocked
  const [isBlockingAction, setIsBlockingAction] = useState(true); // Tracks if it's a block or unblock action
  const [loading, setLoading] = useState(true);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  const isProfile2 = window.location.pathname.includes('profile2');

  // Fetch profile data based on company_id
  useEffect(() => {
    if (company_id) {
      const fetchData = async () => {
        try {
          const response = await getOtherProfileById(company_id); // Fetch profile data by company_id
          console.log(response)
          if (response.status === 200) {
            const data = response.data.data;
            setStoreName(data.name);
            setCategory(data.category);
            setLocationText(data.address);
            setSelectedProfileImage(data.profile_image_url);
            setReview(data.review);
            setBio(data.bio);
            setWebLinks(data.web_links || []);
            setCatalogue(data.catalogue);
            setWebsite(data.website);

            setIsFollowed(data.is_bookmarked);

             // Set blocked status from response
          setIsBlocked(data.is_blocked); // Set the isBlocked state based on the API response

  
            // Store the uid in localStorage
            if (data.uid) {
              localStorage.setItem('uid', data.uid);
              localStorage.setItem('other_profile_name', data.name);
              localStorage.setItem('other_profile_image', data.profile_image_url); // Store uid to pass it when navigating
            }
          } else {
            console.error("Failed to fetch company data:", response.status);
          }
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
        finally {
          setLoading(false); // Stop loading after data is fetched
        }
      };
  
      fetchData();
    } else {
      console.error("company_id is undefined.");
    }
  }, [company_id]);


  useEffect(() => {
    const fetchSocialLinks = async () => {
      const companyIdToFetch = company_id || localStorage.getItem('company_id');
      if (!companyIdToFetch) {
        console.warn("No company_id available for fetching social links.");
        return;
      }
  
      try {
        const response = await get_social_links(companyIdToFetch);
        console.log("API Response (OtherProfileVisitorsView):", response); // Log entire response
        
        if (response.status === 200) {
          const data = response.data?.data;
          console.log("Social Links Data:", data); // Log extracted data
  
          const combinedLinks = [];
          if (website) combinedLinks.push(`Website: ${website}`);
          if (data?.instagram) combinedLinks.push(`Instagram: ${data.instagram}`);
          if (data?.facebook) combinedLinks.push(`Facebook: ${data.facebook}`);
          if (data?.linked_in) combinedLinks.push(`LinkedIn: ${data.linked_in}`);
          if (data?.twitter) combinedLinks.push(`Twitter: ${data.twitter}`);
  
          console.log("Combined Links to Set in webLinks:", combinedLinks); // Log combined links
          setWebLinks(combinedLinks);
        } else {
          console.warn("Failed to fetch social links, status code:", response.status);
        }
      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    };
  
    fetchSocialLinks();
  }, [company_id, website]);
  
  const handleWebLinkClick = () => {
    if (webLinks.length > 0) {
      if (isLargeScreen) {
        setIsModalOpen(true);
      } else {
        setIsBottomSheetOpen(true);
      }
    }
  };
  
  // Open the modal in a useEffect that watches both `shouldOpenModal` and `webLinks`
  useEffect(() => {
    if (shouldOpenModal && webLinks.length > 0) {
      setTimeout(() => {
        setIsModalOpen(true); // Open the modal after ensuring `webLinks` is set
        setShouldOpenModal(false); // Reset the temporary state
      }, 50); // Delay slightly to allow webLinks to update
    }
  }, [shouldOpenModal, webLinks]);

  

  // General function to navigate and pass uid or company_id based on the destination
  const navigateWithParam = (route, useCompanyId = false) => {
    if (useCompanyId) {
      // Pass company_id for the Documents route
      navigate(`${route}/${company_id}`);
    } else {
      const uid = localStorage.getItem('uid'); // Retrieve the uid from localStorage
      if (uid) {
        navigate(`${route}/${uid}`); // Pass the uid as a URL parameter for other cases
      } else {
        console.error('UID not found in local storage');
      }
    }
  };
  const handleDotsClick = () => {
    setIsDotsMenuOpen(!isDotsMenuOpen); // Toggle the dots menu
  };

  const handleViewCatalogue = () => {
    if (catalogue) {
      window.open(catalogue, '_blank');
    }
  };

  const handleAddCatalogue = () => {
    navigateWithParam('/catalog'); // Navigate to catalog page with uid
  };

 
  

  const handleShareProfileClick = () => {
    const verificationStatus = localStorage.getItem('verificationStatus');
    const profileUrl = `${window.location.origin}/profile/${company_id}`;
  
    if (verificationStatus === 'VERIFICATION_PENDING') {
      setPopupMessage('Your profile is under verification. Please wait.'); 
      setShowPopup(true);
    } else {
      // Copy the URL to the clipboard
      navigator.clipboard.writeText(profileUrl).then(() => {
        // Show a success message using the existing showToast function
        showToast('Profile URL copied to clipboard!', 'success');
      }).catch((error) => {
        console.error('Failed to copy URL:', error);
        showToast('Failed to copy URL. Please try again.', 'error');
      });
    }
  };
  
  const handleViewReviewsClick = () => {
    const uid = localStorage.getItem('uid'); // Retrieve the uid from localStorage
    if (uid) {
      navigate(`/reviews_page/${uid}`); // Navigate to the reviews page with uid
    } else {
      console.error('UID not found in local storage');
    }
  };
  const handleReportClick = () => {
    const uid = localStorage.getItem('uid'); // Retrieve uid from localStorage
    if (uid) {
      navigate(`/company_report/${uid}`); // Navigate to the report page with uid
    } else {
      console.error('UID not found in local storage');
    }
  };
  const handleFollowUpClick = async () => {
    setLoadingFollowUp(true); // Set loading state
  
    const uid = localStorage.getItem('uid'); // Retrieve uid from localStorage
  
    try {
      let response;
      if (isFollowed) {
        // If already followed, call the DELETE API (unfollow)
        response = await delete_company_bookmark(uid);
        if (response.status === 202) {
          setIsFollowed(false); // Successfully unfollowed
        
        }
      } else {
        // If not followed, call the POST API (follow)
        response = await post_company_bookmark(uid);
        if (response.status === 201) {
          setIsFollowed(true); // Successfully followed
          // showToast('Followed up successfully!', 'success');
        }
      }
    } catch (error) {
      console.error('Error in follow-up/unfollow:', error);
      // showToast('An error occurred. Please try again.', 'error');
    } finally {
      setLoadingFollowUp(false); // Stop loading state
    }
  };
 
   // Block or unblock profile based on action
   const confirmBlockUnblockProfile = async () => {
    const uid = localStorage.getItem('uid');
    if (!uid) {
     
      return;
    }

    try {
      let response;
      if (isBlockingAction) {
        response = await blockCompany(uid);
        if (response) {
          // showToast('User has been blocked!', 'success');
          setIsBlocked(true);
        }
      } else {
        response = await unblockCompany(uid);
        if (response) {
           
          setIsBlocked(false);
        }
      }
    } catch (error) {
      console.error(`Error ${isBlockingAction ? 'blocking' : 'unblocking'} profile:`, error);
      // showToast(`Failed to ${isBlockingAction ? 'block' : 'unblock'} user. Please try again.`, 'error');
    } finally {
      setIsBlockPopupVisible(false); // Close the popup
    }
  };

  const handleBlockClick = () => {
    setIsBlockingAction(true); // Set action to block
    setIsBlockPopupVisible(true); // Show the block confirmation popup
  };

  const handleUnblockClick = () => {
    setIsBlockingAction(false); // Set action to unblock
    setIsBlockPopupVisible(true); // Show the unblock confirmation popup
  };

  

  const handleMessageClick = async () => {
    const currentUserUid = localStorage.getItem('company_uid');
    const otherProfileUid = localStorage.getItem('uid'); // Get the other profile's UID from localStorage
    const otherProfileName = localStorage.getItem('other_profile_name');
    const otherProfileImage = localStorage.getItem('other_profile_image');
  
    if (!otherProfileUid || !currentUserUid) {
      console.error('User UID or other profile UID is missing from localStorage.');
      showToast('Unable to start chat. Please try refreshing the page.', 'error');
      return;
    }
  
    try {
      // Check if a chat already exists
      const existingChat = await checkIfChatExists(otherProfileUid);
      
      if (existingChat) {
        // If chat exists, navigate to the existing chat
        navigate(`/direct_message/chat/${existingChat.chatId}`);
      } else {
        // If no chat exists, create a new one
        const newChatId = await createNewChat(otherProfileUid, otherProfileName, otherProfileImage,company_id);
        navigate(`/direct_message/chat/${newChatId}`);
      }
    } catch (error) {
      console.error('Error handling message click:', error);
      showToast('Failed to open chat. Please try again.', 'error');
    }
  };
  const formatLocation = (fullLocation) => {
    if (!fullLocation) return 'Location not available';
    
    // Split the location by commas and take the last two parts
    const locationParts = fullLocation.split(',');
    
    // Check if there are at least two parts to display
    if (locationParts.length >= 2) {
      const lastTwoParts = locationParts.slice(-1).join(',').trim(); // Join last two parts and trim spaces
      return lastTwoParts;
    }
  
    return fullLocation; // Return the full location if splitting doesn't provide two parts
  };
  
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024); // Adjust threshold as needed
    };
  
    // Set initial screen size
    handleResize();
  
    // Add resize listener
    window.addEventListener('resize', handleResize);
  
    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  if (isLargeScreen) {
      
  return (
  
    <div className="flex h-full bg-[#FFFFFF] font-poppins overflow-auto w-full">
    <Sidebar />
    <div className="flex flex-col w-full" >
      {/* Header Section with Profile Image */}
        {/* Loader */}
    {loading ? (
      <div className="flex justify-center items-center h-full">
        <FaSpinner className="text-[#DB0000] animate-spin lg:w-10 lg:h-6 w-8 h-8" />
      </div>
    ) : (
      <>
       
      <div className="relative">
      <div
          className="relative w-full h-[180px] bg-cover bg-center"
          style={{
            backgroundImage: ` url(/Assets/cover_photo.svg)`, // Replace with the actual path to the uploaded image
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >


          <h1 className="absolute top-8 left-8 text-2xl font-bold text-gray-800">
            Profile
          </h1>
        </div>
        <div className="absolute top-6 right-16 lg:right-72 z-20">
  <div className="cursor-pointer" onClick={handleDotsClick}>
    <img src="/Assets/dots.svg" alt="Menu Dots" className='w-4 h-5' />
  </div>

  {/* Background Overlay */}
  {isDotsMenuOpen && (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-40 font-poppins" onClick={handleDotsClick}></div>
  )}
{/* Background Overlay */}
{isDotsMenuOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-40 font-poppins" onClick={handleDotsClick}></div>
)}

{/* Menu with Close Button */}
{isDotsMenuOpen && (
  <div className="absolute lg:right-6 right-0 mt-2 w-40 p-2 bg-white rounded-lg shadow-lg z-50">
    {/* Close Button */}
    <button
      className="absolute top-1 right-2 text-xl bg-transparent text-black hover:text-gray-500"
      onClick={handleDotsClick}
    >
      &times;
    </button>

    {/* Menu Items */}
    <div
      className="text-sm p-2 cursor-pointer hover:bg-gray-100 flex items-center space-x-2 mt-4"
      onClick={handleReportClick}
    >
      <img src="/Assets/report_icon.svg" alt="Report" className="w-4 h-4" />
      <span className="whitespace-nowrap">Report an Issue</span>
    </div>

   {/* Conditionally render Block/Unblock based on isBlocked */}
   <div
  className="text-sm p-2 cursor-pointer hover:bg-gray-100 flex items-center space-x-2"
  onClick={isBlocked ? handleUnblockClick : handleBlockClick}
>
  <img
    src={isBlocked ? "/Assets/unblock_new.svg" : "/Assets/block_new.svg"}
    alt={isBlocked ? "Unblock" : "Block"}
    className="w-4 h-4"
  />
  <span>{isBlocked ? "Unblock" : "Block"}</span>
</div>
              </div>
)}

</div>


{isBlockPopupVisible && (
            <DeletePopup
              message={`Are you sure you want to ${isBlockingAction ? 'block' : 'unblock'} this profile?`}
              onConfirm={confirmBlockUnblockProfile}
              onClose={() => setIsBlockPopupVisible(false)}
            />
          )}

        {/* Profile Image */}
        <div className="absolute -bottom-16 left-8">
        <ProfileImageContainer
              editable={editable}
              profileImage={selectedProfileImage}
              onSaveProfileImage={setSelectedProfileImage}
            />
        </div>
      </div>

      {/* Main Content */}
      <div className="px-8 mt-20 w-[84%]">
        <div className="grid grid-cols-2 gap-8">
          {/* Left Column */}
          <div>
            {/* Business Info Container */}
            <div className=" rounded-lg border border-gray-200 p-4 mb-6">
              <div className="space-y-2">
                <h2 className="text-xl font-semibold text-gray-800">{storeName}</h2>
                <p className="text-sm text-gray-600">{formatLocation(locationText)}</p>
                <div className="flex items-center">
                  <div className="flex">
                  <StarRating review={review} />
                  </div>
                  <span
              className="text-xs lg:text-sm  text-[#DB0000] cursor-pointer  underline"  
              onClick={handleViewReviewsClick}
            >
              View Reviews
            </span>
                </div>
              </div>

              {/* About Section */}
              <div className="mt-2">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">About</h3>
                <p className="text-sm text-gray-600">{bio}</p>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-4">
            {/* Web Links Container */}
            <div className=" rounded-lg border border-gray-200 p-4">
              <button 
                className="flex items-center justify-center w-full text-red-600 hover:text-red-700"
                onClick={handleWebLinkClick}
              >
                <img src="/Assets/web_link.svg" alt="Web Links" className="w-5 h-5 mr-2" />
                <span>Web Links</span>
              </button>
            </div>

            {/* Product Catalogue Container */}
            <div className=" rounded-lg border border-gray-200 p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-gray-800 font-medium">Product Catalogue</h3>
                {catalogue && (
          <button 
          className="flex items-center text-gray-600"
          onClick={handleViewCatalogue}
        >
          <img src="/Assets/eye_catalog.svg" alt="View" className="w-5 h-5 mr-1" />
          <span>View</span>
        </button>
          
        )}

              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex gap-4  ml-10 ">
            <ImageButton
  onClick={handleFollowUpClick}
  imageSrc={isFollowed ? '/Assets/unfollow_new.svg' : '/Assets/follow-up.svg'}
  buttonText={isFollowed ? 'Unfollow' : 'Follow up'}
  loading={loadingFollowUp} // Pass loading state to show spinner
/>

              <ImageButton
          onClick={handleMessageClick} // This will handle the message initiation
          imageSrc="/Assets/message-new.svg"
          buttonText="Message"
          imageSize='20px'
         
        />

          <ImageButton
            onClick={handleShareProfileClick}
            imageSrc="/Assets/share-new.svg"
            buttonText="Share"
          />
            </div>
          </div>
        </div>

        {/* Bottom Navigation Grid */}
    {/* Bottom Navigation Grid */}
<div className=" grid grid-cols-5  relative h-48 mb-2 mt-2">
  {/* Left Side Navigation */}
  <div className="col-span-2 space-y-4 mr-3">
    <button 
      onClick={() => navigateWithParam('/view-management')}
      className="w-full flex items-center justify-center space-x-2 bg-black text-white p-8 rounded-r-xl  "
    >
      <img src="/Assets/profile_management.svg" alt="" className="w-6 h-6" />
      <span>Management</span>
    </button>
    <button 
      onClick={() => navigate('/add_complaints')}
      className="w-full flex items-center justify-center space-x-2 bg-black text-white p-8 rounded-r-xl  "
    >
      <img src="/Assets/profile_complaints.svg" alt="" className="w-6 h-6" />
      <span>Complaints</span>
    </button>
  </div>

  {/* Center Grid */}
  <div className=" flex mr-4">
      <div className="grid grid-cols-2 gap-4    ">
        <button 
          onClick={() => navigateWithParam('/view_photos')}
          className="bg-[#DB0000] p-4  rounded-l-xl w-28 " 
         
        >
          <img src="/Assets/profile_photos.svg" alt="Photos" className="w-10 h-10 ml-4" />
        </button>
        <button 
          onClick={() => navigateWithParam('/uploaded_video',true)}
           className="bg-[#DB0000] p-4 rounded-r-xl w-28"
         
        >
          <img src="/Assets/profile_video.svg" alt="Videos" className="w-10 h-10 ml-4" />
        </button>
        <button 
          onClick={() => navigateWithParam('/product_service_list')}
           className="bg-[#DB0000] p-4 rounded-l-xl w-28"
         
        >
          <img src="/Assets/profile_product.svg" alt="Products" className="w-10 h-10 ml-4" />
        </button>
        <button 
          onClick={() => navigateWithParam('/profile-verification')}
           className="bg-[#DB0000] p-4 rounded-r-xl w-28"
         
        >
          <img src="/Assets/profile_verify.svg" alt="Verification" className="w-10 h-10 ml-4 " />
        </button>
      </div>
  </div>

  {/* Right Side Navigation */}
  <div className="col-span-2 space-y-4">
    <button 
      onClick={() => navigateWithParam('/company_docs_view', true)}
      className="w-full flex items-center justify-center space-x-2 bg-black text-white p-8 rounded-l-xl "
    >
      <img src="/Assets/profile_docs.svg" alt="" className="w-6 h-6" />
      <span>Documents</span>
    </button>
    <button 
      onClick={() =>  navigateWithParam('/feedback/owner')}
      className="w-full flex items-center justify-center space-x-2 bg-black text-white p-8 rounded-l-xl "
    >
      <img src="/Assets/profile_feedback.svg" alt="" className="w-6 h-6" />
      <span>Feedback</span>
    </button>
    </div>
          </div>
        </div>
      </>
    )}
  </div>
   {/* Modal for large screens */}
   <WebLinkModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        links={webLinks} 
        hideAddButton={isProfile2}
      />
      <BottomSheet 
        isOpen={isBottomSheetOpen} 
        onClose={() => setIsBottomSheetOpen(false)} 
        links={webLinks} 
        hideAddButton={isProfile2} 
      />
{/* Confirmation Popup */}
<ConfirmationPopup onClose={() => setShowPopup(false)} show={showPopup} />

</div>
    
  
);

};


  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins">
      <Sidebar />
      <div className="flex flex-col w-full h-full">
        <div
          className="relative  w-full lg:h-72 h-[272px]"
          style={{
            backgroundImage: `url(/Assets/cover_photo.svg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        > 
            {/* <h1 className="absolute text-[#263238] font-bold font-gotham-bold lg:text-2xl text-xl p-4 z-10">
            Profile
          </h1> */}

          {/* Add dots container */}
          <div className="absolute top-6 right-16 lg:right-72 z-20">
  <div className="cursor-pointer" onClick={handleDotsClick}>
    <img src="/Assets/dots.svg" alt="Menu Dots" className='w-4 h-5' />
  </div>

  {/* Background Overlay */}
  {isDotsMenuOpen && (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-40 font-poppins" onClick={handleDotsClick}></div>
  )}
{/* Background Overlay */}
{isDotsMenuOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-40 font-poppins" onClick={handleDotsClick}></div>
)}

{/* Menu with Close Button */}
{isDotsMenuOpen && (
  <div className="absolute lg:right-6 right-0 mt-2 w-40 p-2 bg-white rounded-lg shadow-lg z-50">
    {/* Close Button */}
    <button
      className="absolute top-1 right-2 text-xl bg-transparent text-black hover:text-gray-500"
      onClick={handleDotsClick}
    >
      &times;
    </button>

    {/* Menu Items */}
    <div
      className="text-sm p-2 cursor-pointer hover:bg-gray-100 flex items-center space-x-2 mt-4"
      onClick={handleReportClick}
    >
      <img src="/Assets/report_icon.svg" alt="Report" className="w-4 h-4" />
      <span className="whitespace-nowrap">Report an Issue</span>
    </div>

   {/* Conditionally render Block/Unblock based on isBlocked */}
   <div
  className="text-sm p-2 cursor-pointer hover:bg-gray-100 flex items-center space-x-2"
  onClick={isBlocked ? handleUnblockClick : handleBlockClick}
>
  <img
    src={isBlocked ? "/Assets/unblock_new.svg" : "/Assets/block_new.svg"}
    alt={isBlocked ? "Unblock" : "Block"}
    className="w-4 h-4"
  />
  <span>{isBlocked ? "Unblock" : "Block"}</span>
</div>
              </div>
)}

</div>


{isBlockPopupVisible && (
            <DeletePopup
              message={`Are you sure you want to ${isBlockingAction ? 'block' : 'unblock'} this profile?`}
              onConfirm={confirmBlockUnblockProfile}
              onClose={() => setIsBlockPopupVisible(false)}
            />
          )}


          {/* Profile Image Container */}
          <div className="absolute inset-x-0 lg:mt-20 mt-16 flex justify-center lg:w-4/5">
            <ProfileImageContainer
              editable={editable}
              profileImage={selectedProfileImage}
              onSaveProfileImage={setSelectedProfileImage}
            />
          </div>
          <div className="flex flex-col items-center lg:w-4/5 lg:mt-48 mt-40 lg:mx-0 mx-5">
          {loading ? (
              <FaSpinner className="text-[#DB0000] animate-spin lg:w-6 lg:h-6 w-4 h-4" /> // Loading spinner for company name and address
            ) : (
              <>
                <h2 className="lg:text-lg text-[15px] font-normal font-techna">{storeName}</h2>
                <p className="lg:text-sm font-normal font-gotham-Book text-[13px]">
                {formatLocation(locationText)} {/* Format the location to display only the last two parts */}
              </p>
              </>
            )}
            <div className="flex items-center space-x-2">
            <StarRating review={review} />
            {/* view reviews added */}
          
            <span
              className="text-xs lg:text-sm  text-[#DB0000] cursor-pointer  underline"  
              onClick={handleViewReviewsClick}
            >
              View Reviews
            </span>
          </div>
          </div>
        </div>

        <div className="mt-2 text-left mx-2 lg:mx-8">
        <h2 className="text-sm lg:text-lg font-semibold mb-1 font-gotham text-[#263238]">About</h2>
        <p 
    className="text-[13px] lg:text-[16px] mb-1 text-[#6B6B6B] lg:w-4/5 w-full lg:mx-0 font-gotham"  // Set width to 75% and center the text
    style={{
      wordWrap: 'break-word', // Ensure long words break correctly
    }}>
    {bio}
  </p>
          <h3
            className="flex items-center lg:text-xs text-xs font-normal mb-1 cursor-pointer text-[#DB0000] font-gotham"
            onClick={handleWebLinkClick}
          >
            <img
              src="/Assets/web_link.svg"
              alt="Link Icon"
              className="lg:w-5 lg:h-5 mr-2 w-4 h-4"
            />
            Web Links
          </h3>
          
        </div>
         {/* Block Confirmation Popup */}
       

        {/* Row of follow-up, message, and share buttons */}
        <div className="flex  lg:w-4/5 justify-center lg:space-x-2 space-x-1 mt-1  mx-4">
        <ImageButton
          onClick={handleFollowUpClick}
          imageSrc={isFollowed ? '/Assets/unfollow_new.svg' : '/Assets/follow-up.svg'} // Change icon dynamically
          buttonText={isFollowed ? 'Unfollow' : 'Follow up'} // Change text dynamically
        />

        <ImageButton
          onClick={handleMessageClick} // This will handle the message initiation
          imageSrc="/Assets/message-new.svg"
          buttonText="Message"
          imageSize='20px'
         
        />

          <ImageButton
            onClick={handleShareProfileClick}
            imageSrc="/Assets/share-new.svg"
            buttonText="Share"
          />
        </div>

        {/* Product Catalogue Section */}
        {catalogue && (
          <div className="w-full lg:w-4/5 flex justify-between items-center lg:mt-1 mt-2">
            <div className="w-1/2 text-black font-semibold lg:ml-8 ml-2 text-sm lg:text-lg">
              Product Catalogue
            </div>
            <div className="w-1/2 flex justify-end">
              <div className='lg:w-1/3 w-1/2 h-1/5 mb-2 shadow-lg mr-4'>
                <Button
                  text="View"
                  backgroundColor="bg-[#ffffff]"
                  textColor="text-[#263238]"
                  onClick={handleViewCatalogue}
                />
              </div>
            </div>
          </div>
        )}

        <div className="w-full lg:w-4/5 relative p-2 lg:ml-4 lg:mt-3">
          <div className="grid grid-cols-2 lg:grid-cols-2 lg:gap-4 gap-5">
            <TabButton
              icon="/Assets/profile_management.svg"
              label="Management"
              path={() => navigateWithParam('/view-management')}
            />
            <TabButton
              icon="/Assets/profile_docs.svg"
              label="Documents"
              path={() => navigateWithParam('/company_docs_view', true)}  // Pass company_id for Documents
            />
            <TabButton
              icon="/Assets/profile_complaints.svg"
              label="Complaints"
              path="/add_complaints"

            />
            <TabButton
              icon="/Assets/profile_feedback.svg"
              label="Feedback"
              path={() => navigateWithParam('/feedback/owner')}
            />
          </div>

          {/* Centered white circle */}
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <div className="relative lg:w-40 lg:h-40 h-25 w-25 bg-white rounded-full flex items-center justify-center">
              <div className="grid grid-cols-2 lg:gap-3 gap-2 lg:p-3 p-1">
                <img
                  src="/Assets/photos.svg"
                  alt="Photos"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/view_photos')}
                />
                <img
                  src="/Assets/videos.svg"
                  alt="Videos"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/uploaded_video',true)}
                />
                <img
                  src="/Assets/product.png"
                  alt="Products"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/product_service_list')}
                />
                <img
                  src="/Assets/verified-tick.svg"
                  alt="Verification"
                  className="lg:w-16 lg:h-16 w-10 h-10 cursor-pointer pointer-events-auto"
                  onClick={() => navigateWithParam('/profile-verification')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for large screens */}
      <WebLinkModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        links={webLinks} 
        hideAddButton={isProfile2}
      />
      <BottomSheet 
        isOpen={isBottomSheetOpen} 
        onClose={() => setIsBottomSheetOpen(false)} 
        links={webLinks} 
        hideAddButton={isProfile2} 
      />



      {/* BottomSheet for small screens */}
      
      {/* Confirmation Popup */}
      <ConfirmationPopup onClose={() => setShowPopup(false)} show={showPopup} />
    </div>
  );
};

export default OtherProfileVisitorsView;