import React, { useEffect, useState } from "react";
import './hexagon_body.css';
import { useNavigate } from "react-router-dom";




const smallScreenUrls = {
  logistics: './logisticsmobile.svg',
  financial_institutions: './financialinstituitionsmobile.svg',
  start_up: './start-up-mobile.svg',
  legal: './legal_mobile.svg',
  precious_stones_metals: './precious-mobile.svg',
  investor: './investor-mobile.svg',
  seller: './seller-mobile.svg',
  market_place: './market-place-mobile.svg',
  projects: './projects-mobile.svg',
  buyer: './buyermobile35827.svg'
};

const largeScreenUrls = {
  logistics: './new-logistics.svg',
  financial_institutions: './new-financial.svg',
  start_up: './new-start-up.svg',
  legal: './new-legal.svg',
  precious_stones_metals: './new-precious.svg',
  investor: './new-investor.svg',
  seller: './new-seller.svg',
  market_place: './new-market.svg',
  projects: './new-projects.svg',
  buyer: './new-buyer.svg'
};

function HexGrid() {
    const [imageUrls, setImageUrls] = useState(largeScreenUrls);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            console.log('Handling resize event');
            if (window.innerWidth <= 768) {
                console.log('Setting small screen URLs');
                setImageUrls(smallScreenUrls);
            } else {
                console.log('Setting large screen URLs');
                setImageUrls(largeScreenUrls);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    

    return (
        <div className="hex-grid-container-wrapper pt-4 ">
            <div className='hex-grid-container '>
                <div className='hex-item logistics' >
                    <img src={imageUrls.logistics} alt="logistics" />
                </div>
                <div className='hex-item financial_institutions' >
                    <img src={imageUrls.financial_institutions} alt="financialinstitutions" />
                </div>
                <div className='hex-item start_up' >
                    <img src={imageUrls.start_up} alt="startup" />
                </div>
                <div className='hex-item legal' >
                    <img src={imageUrls.legal} alt="legal" />
                </div>
                <div className='hex-item precious_stones_metals' >
                    <img src={imageUrls.precious_stones_metals} alt="preciousstones" />
                </div>
                <div className='hex-item investor' >
                    <img src={imageUrls.investor} alt="invest" />
                </div>
                <div className='hex-item seller' >
                    <img src={imageUrls.seller} alt="seller" />
                </div>
                <div className='hex-item market_place' >
                    <img src={imageUrls.market_place} alt="marketplace" />
                </div>
                <div className='hex-item projects' >
                    <img src={imageUrls.projects} alt="projects" />
                </div>
                <div className='hex-item buyer' >
                    <img src={imageUrls.buyer} alt="buyer" />
                </div>
            </div>
        </div>
    );
}

export default HexGrid;
