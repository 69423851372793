import { api } from './api'; // Assuming you're using axios or another service for API calls
import { POST_NOTIFICATION_PREFERENCE_URL,GET_NOTIFICATIONS_BY_USER_URL } from './urls';


// Fetch Notifications by User
const getNotificationsByUser = async (uid, skip, limit) => {
    try {
        const url = GET_NOTIFICATIONS_BY_USER_URL(uid, skip, limit);
        const res = await api.get(url);
        console.log("Get Notifications by User API Response:", res);
        return res.data; // Assuming the response contains the list of notifications in the 'data' field
    } catch (error) {
        console.error("Error getting notifications by user:", error);
        throw error;
    }
};

const update_notification_preference = async (notify) => {
    try {
        // Pass notify as a query parameter in the URL
        const res = await api.post(`${POST_NOTIFICATION_PREFERENCE_URL}?notify=${notify}`);
        console.log("Notification Preference API Response:", res);
        return res;
    } catch (error) {
        console.error("Error updating notification preferences:", error);
        throw error;
    }
};


export { 
  
    update_notification_preference ,getNotificationsByUser
  };
