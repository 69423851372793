const Tab = ({ activeTab, setActiveTab, labels }) => {
  return (
    <div className="flex justify-between bg-[#E1E1E1] h-16 w-full items-center p-10 mt-3">
      <div 
        className={`flex flex-col items-center font-bold text-sm md:text-base cursor-pointer ${activeTab === 'received' ? 'text-red-600' : 'text-gray-600'}`}
        onClick={() => setActiveTab('received')}
      >
        {labels.received.split(' ').map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
      <div 
        className={`flex flex-col items-center font-bold text-sm md:text-base cursor-pointer ${activeTab === 'submitted' ? 'text-red-600' : 'text-gray-600'}`}
        onClick={() => setActiveTab('submitted')}
      >
        {labels.submitted.split(' ').map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
    </div>
  );
};

export default Tab;
