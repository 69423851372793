import React from 'react';
import './login_image2.css';

const Image2 = ({ imgSrc }) => {
  return (
    <div className="image-container">
      <img src={imgSrc} alt="Illustration" className="image2" />
    </div>
  );
};

export default Image2;
