import React, { useState } from 'react';
import Sidebar from '../Components/main_navbar';
import Button from '../Components/button';
import InputBox from '../Components/input_box';
import { useNavigate } from 'react-router-dom';
import MapButton from '../Components/map_button';
import { update_company_name_url } from '../../Controller/company_details';
import { update_advanced_api } from '../../Controller/company_details';
import { update_location_api } from '../../Controller/company_details';
import { update_media_api } from '../../Controller/company_details';
import { useEffect } from 'react';
import MapDialog from '../Components/map';
const EditProfile = () => {
  const [name, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [address, setAddress] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [mapOpen, setMapOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const savedAddress = localStorage.getItem('address');
    if (savedAddress) {
      setAddress(savedAddress);
    }
  }, []);

  const updateCompanyName = async (name) => {
    try {
      const payload = { name };
      const res = await update_company_name_url(payload);

      if (res.status === 202) {
        localStorage.setItem('name', name);
        console.log('Company name updated successfully:', name);
      }
    } catch (error) {
      console.error('Error updating company name:', error);
    }
  };

  const updateLocationInfo = async (address) => {
    try {
      const payload = { address };
      const res = await update_location_api(payload);
      
      if (res.status === 202) {
        localStorage.setItem('address', address);
        console.log('Location updated successfully:', payload);
      }
    } catch (error) {
      console.error('Error updating location:', error);
    }
  };

  const updateMediaInfo = async (website, linkedin, phone, facebook, instagram, whatsapp) => {
    try {
      const payload = { website, linkedin, phone: phone, facebook, instagram, whatsapp };
      const res = await update_media_api(payload);

      if (res.status === 202) {
        localStorage.setItem('linkedin', linkedin);
        localStorage.setItem('phone', phone);
        console.log('Media information updated successfully:', payload);
      }
    } catch (error) {
      console.error('Error updating media:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await updateCompanyName(name);
      await updateLocationInfo(address);
      await updateMediaInfo(website, linkedin, phone, facebook, instagram, whatsapp);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleBackClick = () => {
    navigate('/');
  };

  const handleSelectLocation = (locationDetails) => {
    const { address, latitude, longitude } = locationDetails;
    setAddress(address);
    localStorage.setItem('address', address);
    localStorage.setItem('latitude', latitude);
    localStorage.setItem('longitude', longitude);
    setMapOpen(false);
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna overflow-y-auto">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="cursor-pointer mr-2 h-6 w-6"
            onClick={handleBackClick}
          />
          <h1 className="text-black font-bold text-xl">Edit Profile</h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>

      <form className="mt-2 pl-7 ml-7 p-7 sm:w-4/5 lg:w-1/2 md:w-1/2" onSubmit={handleSave}>
        <div className="space-y-3">
          <h2 className="text-lg font-bold mb-2">Company Name</h2>
          <InputBox
            value={name}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Company Name"
            name="companyName"
            padding="14px"
            borderRadius="1px"
          />

          

          <h2 className="text-lg font-bold mb-2">Website</h2>
          <InputBox
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            placeholder="Website"
            padding="14px"
            name="website"
            borderRadius="1px"
          />

         
          <h2 className="text-lg font-bold mb-2">Whatsapp</h2>
          <InputBox
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
            placeholder="Whatsapp"
            name="whatsapp"
            padding="14px"
            borderRadius="1px"
          />

          <h2 className="text-lg font-bold mb-2">Address</h2>
          <div className="flex w-full">
            <div className="flex-grow">
              <InputBox
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Office address"
                name="address"
                padding="14px"
                borderRadius="1px"
              />
            </div>
            <div className="flex-none">
              <MapButton onAddressSelect={handleSelectLocation} padding="18px" />
            </div>
          </div>

          <h2 className="text-lg font-bold mb-2 mt-4">Social Media</h2>
          <div className="space-y-3">
            <div className="flex items-center space-x-2">
              <div className="flex items-center justify-center h-14 w-24 border border-[#A2A2A2]">
                <img src="./facebook1.svg" alt="Facebook" className="w-6 h-6" />
              </div>
              <InputBox
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
                placeholder="URL"
                name="facebook"
                padding="15px"
                className="flex-grow"
                borderRadius="1px"
              />
            </div>
            <div className="flex items-center space-x-2">
              <div className="flex items-center justify-center h-14 w-24 border border-[#A2A2A2]">
                <img src="./insta.svg" alt="Instagram" className="w-6 h-6" />
              </div>
              <InputBox
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                placeholder="URL"
                name="instagram"
                padding="15px"
                className="flex-grow"
                borderRadius="1px"
              />
            </div>
            <div className="flex items-center space-x-2">
              <div className="flex items-center justify-center h-14 w-24 border border-[#A2A2A2]">
                <img src="./linkedin1.svg" alt="LinkedIn" className="w-6 h-6" />
              </div>
              <InputBox
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
                placeholder="URL"
                name="linkedin"
                padding="15px"
                className="flex-grow"
                borderRadius="1px"
              />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <Button
              text={'Save'}
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
            />
          </div>
        </div>
      </form>
      <MapDialog open={mapOpen} onClose={() => setMapOpen(false)} onSelectLocation={handleSelectLocation} />
    </div>
  );
};


export default EditProfile;
