// import React, { useState } from 'react';
// import Sidebar from '../../Components/main_navbar';
// import Steps from '../../Components/company_list_tile';
// import StepsCompleted from '../../Components/steps_completed';  // import the new component

// const CompanyDetails = () => {
//   const [completedSteps, setCompletedSteps] = useState(0);

//   const handleCompleteStep = () => {
//     if (completedSteps < 3) {
//       setCompletedSteps(prevSteps => prevSteps + 1);
//     } else {
//       // Close the page when all steps are completed
//       window.close();
//     }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-poppins">
//       <Sidebar />
      
      // <div className="flex items-center justify-between w-4/5 px-4  mt-4 md:mt-[40px] ">
      // <img
      //   src="Assets/Close_round.svg"
      //   alt="close"
      //   className="w-6 h-6 cursor-pointer"
      //   onClick={handleCompleteStep}
      // />
//       <div className="flex-grow flex justify-center ml-7 text-xs ">
//         <StepsCompleted completedSteps={completedSteps} />
//       </div>
     
//     </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
     
//       <div className="flex flex-col mx-auto items-center mt-10 w-4/5 lg:ml-7 overflow-auto">
//         <p className=' text-[#232323] text-center '>Complete your profile to get verified.</p>
//         <div className="max-w-lg w-full mt-10 ">
//           <Steps number="1" text="Add your company details and complete your profile to get verified." destination="/company_logo" />
//           <Steps number="2" text="Please add and verify your number to complete your profile and get verified." destination="/details_phone" />
//           <Steps number="3" text="Please add and verify LinkedIn to complete your profile and get verified." destination="/linkedin_page" />
//           <Steps number="4" text="Add your products." destination="/product_service" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyDetails;
// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// import Sidebar from '../../Components/main_navbar';
// import Steps from '../../Components/company_list_tile';
// import StepsCompleted from '../../Components/steps_completed';
// import ReportContainer from '../../Components/report';

// const CompanyDetails = () => {
//   const location = useLocation();

//   const stepKeys = [
//     'COMPANY_DETAILS_DESCRIPTION_COMPLETED',
//     'COMPANY_DETAILS_MANAGER_COMPLETED',
//     'COMPANY_DETAILS_DOCUMENTS_COMPLETED'
//   ];

//   const [completedSteps, setCompletedSteps] = useState([]);
//   const [incompleteSteps, setIncompleteSteps] = useState(stepKeys);

//   useEffect(() => {
//     // Load step completion status from localStorage
//     const newCompletedSteps = [];
//     const newIncompleteSteps = [...stepKeys];

//     stepKeys.forEach((key) => {
//       const isStepCompleted = localStorage.getItem(key) === 'true';
//       if (isStepCompleted) {
//         newCompletedSteps.push(key);
//         const index = newIncompleteSteps.indexOf(key);
//         if (index > -1) {
//           newIncompleteSteps.splice(index, 1);
//         }
//       }
//     });

//     setCompletedSteps(newCompletedSteps);
//     setIncompleteSteps(newIncompleteSteps);

//     return () => {
//       setCompletedSteps([]);
//       setIncompleteSteps(stepKeys);
//     };
//   }, [location]);

//   const stepMap = {
//     'COMPANY_DETAILS_DESCRIPTION_COMPLETED': { number: '1', text: 'Add your Company details', destination: '/address' },
//     'COMPANY_DETAILS_MANAGER_COMPLETED': { number: '2', text: 'Add your Account Manager', destination: '/account_manager' },
//     'COMPANY_DETAILS_DOCUMENTS_COMPLETED': { number: '3', text: 'Add your Company documents', destination: '/company_docs' }
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-normal">
//       <Sidebar />
//       <div className="flex items-center justify-between w-4/5  mt-4 md:mt-[40px]">
//         <div className="flex-grow flex justify-center ml-7 text-xs">
//           <StepsCompleted completedSteps={completedSteps.length} />
//         </div>
//         <ReportContainer />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="flex flex-col mx-4 items-center mt-4 lg:w-4/5 lg:ml-7 overflow-auto">
//         <p className="text-[#232323] font-semibold text-center text-[14px] ">Complete your profile to get verified.</p>
//         <div className="w-full mt-6">
//           <p className="text-[#232323] font-semibold text-center  text-[12px] lg:text-[14px] ">
//             Please keep the following documents ready to complete the onboarding process:
//           </p>
//           <div className="flex justify-center mt-4 space-x-2">
//             {/* Replace these images with your actual PDF icons or paths */}
//             <div className="flex items-center space-x-2 bg-gray-100 p-1 rounded-md shadow-sm">
//               <img src="/Assets/pdf.svg" alt="Business License" className="w-5 h-5" />
//               <span className="lg:text-sm text-[10px]">Business License</span>
//             </div>
//             <div className="flex items-center space-x-2 bg-gray-100 p-1 rounded-md shadow-sm">
//               <img src="/Assets/pdf.svg" alt="Tenancy Contract" className="w-5 h-5" />
//               <span className="lg:text-sm text-[10px]">Tenancy Contract</span>
//             </div>
//             <div className="flex items-center space-x-2 bg-gray-100 p-1  rounded-md shadow-sm">
//               <img src="/Assets/pdf.svg" alt="Trading Register" className="w-5 h-5" />
//               <span className="lg:text-sm text-[10px]">Trading Register</span>
//             </div>
//           </div>
//         </div>

//         <div className="max-w-lg w-full mt-10 text-[#232323]">
//           {incompleteSteps.map((step, index) => (
//             <Steps key={index} {...stepMap[step]} />
//           ))}
//           {completedSteps.length > 0 && (
//             <div className="mt-4">
//               <p className="text-left text-[#232323]">Completed</p>
//               {completedSteps.map((step, index) => (
//                 <div key={index} className="flex justify-between items-center font-normal mt-4">
//                   <p className="text-left">{stepMap[step].text}</p>
//                   <img src="/Assets/steps_tick.svg" alt="Completed" className="w-6 h-6 ml-2" />
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyDetails;
// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// import Sidebar from '../../Components/main_navbar';
// import Steps from '../../Components/company_list_tile';
// import StepsCompleted from '../../Components/steps_completed';
// import ReportContainer from '../../Components/report';

// const CompanyDetails = () => {
//   const location = useLocation();

//   const stepKeys = [
//     'COMPANY_DETAILS_DESCRIPTION_COMPLETED',
//     'COMPANY_DETAILS_MANAGER_COMPLETED',
//     'COMPANY_DETAILS_DOCUMENTS_COMPLETED'
//   ];

//   const [completedSteps, setCompletedSteps] = useState([]);
//   const [incompleteSteps, setIncompleteSteps] = useState(stepKeys);

//   // Function to save completed steps to localStorage
//   const saveCompletedStepsToLocalStorage = (completedSteps) => {
//     localStorage.setItem('completedSteps', JSON.stringify(completedSteps));
//   };

//   useEffect(() => {
//     // Fetch completed steps from localStorage on initial load
//     const completedFromStorage = [];

//     // Check each step key and verify if it's marked as "true" (as a string) in localStorage
//     stepKeys.forEach((key) => {
//       const isStepCompleted = localStorage.getItem(key) === 'true'; // Check as string 'true'
//       if (isStepCompleted) {
//         completedFromStorage.push(key); // Add to completed array if true
//       }
//     });

//     const completedArrayFromStorage = JSON.parse(localStorage.getItem('completedSteps')) || [];

//     // Merge the steps completed individually and the array stored in localStorage
//     const allCompletedSteps = [...new Set([...completedFromStorage, ...completedArrayFromStorage])];

//     const newCompletedSteps = [];
//     const newIncompleteSteps = [...stepKeys];

//     // Update the new completed and incomplete steps based on localStorage
//     stepKeys.forEach((key) => {
//       if (allCompletedSteps.includes(key)) {
//         newCompletedSteps.push(key);
//         const index = newIncompleteSteps.indexOf(key);
//         if (index > -1) {
//           newIncompleteSteps.splice(index, 1);
//         }
//       }
//     });

//     setCompletedSteps(newCompletedSteps);
//     setIncompleteSteps(newIncompleteSteps);

//     return () => {
//       setCompletedSteps([]);
//       setIncompleteSteps(stepKeys);
//     };
//   }, [location]);

//   // Function to mark a step as completed
//   const completeStep = (stepKey) => {
//     // Avoid double completion
//     if (completedSteps.includes(stepKey)) return;

//     const newCompletedSteps = [...completedSteps, stepKey];
//     const newIncompleteSteps = incompleteSteps.filter((step) => step !== stepKey);

//     setCompletedSteps(newCompletedSteps);
//     setIncompleteSteps(newIncompleteSteps);

//     // Store the completion in localStorage for future sessions
//     localStorage.setItem(stepKey, 'true'); // Save individual step as true
//     saveCompletedStepsToLocalStorage(newCompletedSteps); // Save the updated array of completed steps
//   };

//   const stepMap = {
//     'COMPANY_DETAILS_DESCRIPTION_COMPLETED': { number: '1', text: 'Add your Company details', destination: '/address' },
//     'COMPANY_DETAILS_MANAGER_COMPLETED': { number: '2', text: 'Add your Account Manager', destination: '/account_manager' },
//     'COMPANY_DETAILS_DOCUMENTS_COMPLETED': { number: '3', text: 'Add your Company documents', destination: '/company_docs' }
//   };

//   // Determine if steps are enabled based on sequential completion of previous steps
//   const isStepEnabled = (step, index) => {
//     if (index === 0) return true; // Step 1 is always enabled
//     const previousStep = stepKeys[index - 1]; // Get the previous step key
//     return completedSteps.includes(previousStep); // Enable if the previous step is completed
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-normal">
//       <Sidebar />
//       <div className="flex items-center justify-between w-4/5  mt-4 md:mt-[40px]">
//         <div className="flex-grow flex justify-center ml-7 text-xs">
//           <StepsCompleted completedSteps={completedSteps.length} />
//         </div>
//         <ReportContainer />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="flex flex-col mx-4 items-center mt-4 lg:w-4/5 lg:ml-7 overflow-auto">
//         <p className="text-[#232323] font-semibold text-center text-[14px] ">Complete your profile to get verified.</p>
//         <div className="w-full mt-6">
//           <p className="text-[#232323] font-semibold text-center text-[12px] lg:text-[14px] ">
//             Please keep the following documents ready to complete the onboarding process:
//           </p>
//           <div className="flex justify-center mt-4 space-x-2">
//             <div className="flex items-center space-x-2 bg-gray-100 p-1 rounded-md shadow-sm">
//               <img src="/Assets/pdf.svg" alt="Business License" className="w-5 h-5" />
//               <span className="lg:text-sm text-[10px]">Business License</span>
//             </div>
//             <div className="flex items-center space-x-2 bg-gray-100 p-1 rounded-md shadow-sm">
//               <img src="/Assets/pdf.svg" alt="Tenancy Contract" className="w-5 h-5" />
//               <span className="lg:text-sm text-[10px]">Tenancy Contract</span>
//             </div>
//             <div className="flex items-center space-x-2 bg-gray-100 p-1  rounded-md shadow-sm">
//               <img src="/Assets/pdf.svg" alt="Trading Register" className="w-5 h-5" />
//               <span className="lg:text-sm text-[10px]">Trading Register</span>
//             </div>
//           </div>
//         </div>

//         <div className="max-w-lg w-full mt-10 text-[#232323]">
//           {incompleteSteps.map((step, index) => (
//             <Steps 
//               key={index} 
//               {...stepMap[step]} 
//               isEnabled={isStepEnabled(step, index)} // Enable based on the completion of the previous step
//               onComplete={() => completeStep(step)} // Complete the step
//             />
//           ))}
//           {completedSteps.length > 0 && (
//             <div className="mt-4">
//               <p className="text-left text-[#232323]">Completed</p>
//               {completedSteps.map((step, index) => (
//                 <div key={index} className="flex justify-between items-center font-normal mt-4">
//                   <p className="text-left">{stepMap[step].text}</p>
//                   <img src="/Assets/steps_tick.svg" alt="Completed" className="w-6 h-6 ml-2" />
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyDetails;
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Sidebar from '../../Components/main_navbar';
import Steps from '../../Components/company_list_tile';
import StepsCompleted from '../../Components/steps_completed';
import ReportContainer from '../../Components/report';
import Button from '../../Components/button';
import { useNavigate } from 'react-router-dom';

const CompanyDetails = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate

  const stepKeys = [
    'COMPANY_DETAILS_DESCRIPTION_COMPLETED',
    'COMPANY_DETAILS_MANAGER_COMPLETED',
    'COMPANY_DETAILS_DOCUMENTS_COMPLETED'
  ];

  const [completedSteps, setCompletedSteps] = useState([]);
  const [incompleteSteps, setIncompleteSteps] = useState(stepKeys);
  const [loading, setLoading] = useState(false); // Loading state for the button

  // Function to save completed steps to localStorage
  const saveCompletedStepsToLocalStorage = (completedSteps) => {
    localStorage.setItem('completedSteps', JSON.stringify(completedSteps));
  };

  useEffect(() => {
    // Fetch completed steps from localStorage on initial load
    const completedFromStorage = [];

    // Check each step key and verify if it's marked as "true" (as a string) in localStorage
    stepKeys.forEach((key) => {
      const isStepCompleted = localStorage.getItem(key) === 'true'; // Check as string 'true'
      if (isStepCompleted) {
        completedFromStorage.push(key); // Add to completed array if true
      }
    });

    const completedArrayFromStorage = JSON.parse(localStorage.getItem('completedSteps')) || [];

    // Merge the steps completed individually and the array stored in localStorage
    const allCompletedSteps = [...new Set([...completedFromStorage, ...completedArrayFromStorage])];

    const newCompletedSteps = [];
    const newIncompleteSteps = [...stepKeys];

    // Update the new completed and incomplete steps based on localStorage
    stepKeys.forEach((key) => {
      if (allCompletedSteps.includes(key)) {
        newCompletedSteps.push(key);
        const index = newIncompleteSteps.indexOf(key);
        if (index > -1) {
          newIncompleteSteps.splice(index, 1);
        }
      }
    });

    setCompletedSteps(newCompletedSteps);
    setIncompleteSteps(newIncompleteSteps);

    return () => {
      setCompletedSteps([]);
      setIncompleteSteps(stepKeys);
    };
  }, [location]);

  // Function to mark a step as completed
  const completeStep = (stepKey) => {
    // Avoid double completion
    if (completedSteps.includes(stepKey)) return;

    const newCompletedSteps = [...completedSteps, stepKey];
    const newIncompleteSteps = incompleteSteps.filter((step) => step !== stepKey);

    setCompletedSteps(newCompletedSteps);
    setIncompleteSteps(newIncompleteSteps);

    // Store the completion in localStorage for future sessions
    localStorage.setItem(stepKey, 'true'); // Save individual step as true
    saveCompletedStepsToLocalStorage(newCompletedSteps); // Save the updated array of completed steps
  };

  const stepMap = {
    'COMPANY_DETAILS_DESCRIPTION_COMPLETED': { number: '1', text: 'Add your Company details', destination: '/address' },
    'COMPANY_DETAILS_MANAGER_COMPLETED': { number: '2', text: 'Add your Account Manager', destination: '/account_manager' },
    'COMPANY_DETAILS_DOCUMENTS_COMPLETED': { number: '3', text: 'Add your Company documents', destination: '/company_docs' }
  };

  // Determine if steps are enabled based on sequential completion of previous steps
  const isStepEnabled = (step, index) => {
    if (index === 0) return true; // Step 1 is always enabled
    const previousStep = stepKeys[index - 1]; // Get the previous step key
    return completedSteps.includes(previousStep); // Enable if the previous step is completed
  };

  const handleNextClick = () => {
    setLoading(true); // Show loading spinner

    setTimeout(() => {
      // After loading is complete, navigate to the home page
      setLoading(false); // Stop loading
      navigate('/home'); // Navigate to home page
    }, 2000); // Simulate 2-second loading delay
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-normal">
      <Sidebar />
      <div className="flex items-center justify-between lg:w-4/5 w-full  mt-4 md:mt-[40px]">
        <div className="flex-grow flex justify-center ml-7 text-xs">
          <StepsCompleted completedSteps={completedSteps.length} />
        </div>
        <div className='mr-10 lg:mr-0'>
        <ReportContainer />
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="flex flex-col mx-4 items-center mt-10 lg:w-4/5 lg:ml-7 overflow-auto">
        <p className="text-[#232323] font-semibold text-center text-[14px] ">Complete your profile to get verified.</p>
        <div className="w-full mt-10">
          <p className="text-[#232323] font-semibold text-center text-[12px] lg:text-[14px] ">
            Please keep the following documents ready to complete the onboarding process:
          </p>
          <div className="flex justify-center mt-4 space-x-2">
            <div className="flex items-center space-x-2 bg-gray-100 p-1 rounded-md shadow-sm">
              <img src="/Assets/pdf.svg" alt="Business License" className="w-5 h-5" />
              <span className="lg:text-sm text-[10px]">Business License</span>
            </div>
            <div className="flex items-center space-x-2 bg-gray-100 p-1 rounded-md shadow-sm">
              <img src="/Assets/pdf.svg" alt="Tenancy Contract" className="w-5 h-5" />
              <span className="lg:text-sm text-[10px]">Tenancy Contract</span>
            </div>
            <div className="flex items-center space-x-2 bg-gray-100 p-1  rounded-md shadow-sm">
              <img src="/Assets/pdf.svg" alt="Trading Register" className="w-5 h-5" />
              <span className="lg:text-sm text-[10px]">Trading Register</span>
            </div>
          </div>
        </div>

        <div className="max-w-lg w-full mt-10 text-[#232323]">
          {incompleteSteps.map((step, index) => (
            <Steps 
              key={index} 
              {...stepMap[step]} 
              isEnabled={isStepEnabled(step, index)} // Enable based on the completion of the previous step
              onComplete={() => completeStep(step)} // Complete the step
            />
          ))}
          {completedSteps.length > 0 && (
            <div className="mt-4">
              <p className="text-left text-[#232323]">Completed</p>
              {completedSteps.map((step, index) => (
                <div key={index} className="flex justify-between items-center font-normal mt-4">
                  <p className="text-left">{stepMap[step].text}</p>
                  <img src="/Assets/steps_tick.svg" alt="Completed" className="w-6 h-6 ml-2" />
                </div>
              ))}
            </div>
          )}

          {/* Render the "Next" button after all steps are completed */}
          {completedSteps.length === stepKeys.length && (
            <div className="mt-16">
              <Button 
                text="Next"
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                borderColor="border-transparent"
                navigateTo={null} // No navigation on click, just loading
               
                loading={loading} // Pass the loading state
                onClick={handleNextClick} // Handle button click to show loading
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;

