// resetPasswordController.js
import { resetPassword } from './auth';
import { showToast } from '../View/Components/toast';
export const handlePasswordReset = async (password, confirmPassword, email, secret, navigate, setLoading) => {
  if (password.length < 8) {
    showToast('Password must be at least 8 characters long', 'error');
    return;
  }

  if (password !== confirmPassword) {
    showToast('Passwords do not match', 'error');
    return;
  }

  const validatePassword = (password) => {
    const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    return regex.test(password);
  };

  if (!validatePassword(password)) {
    showToast('Password must contain a number and a special symbol', 'error');
    return;
  }

  setLoading(true);

  try {
    const response = await resetPassword(password, email, secret);
    if (response.status === 202) {
      navigate('/login');
    } else {
      showToast('Failed to reset password', 'error');
    }
  } catch (error) {
    console.error('Error resetting password:', error);
    showToast('Error resetting password', 'error');
  } finally {
    setLoading(false);
  }
};
