import React from 'react';
import { useNavigate } from 'react-router-dom';
import GoldSupplier from '../../Components/gold_supplier';
import Sidebar from '../../Components/main_navbar';

const VerifiedSubscriptionPlans = () => {
  const navigate = useNavigate();




  const handleGoldSupplierClick = () => {
    navigate('/otp-verification'); // replace with the actual path you want to navigate to
  };

  const handleBackClick = () => {
    navigate('/profile-verification');
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3] overflow-y-auto">
      <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
            <div className="w-full flex justify-start items-center space-x-2">
                
              <img
                src="/Assets/arrow_back.svg"
                alt="Back"
                className="cursor-pointer mr-2 h-6 w-6"
                onClick={handleBackClick}
              />
              <h1 className="text-black font-bold text-xl">Subscription Plans</h1>   </div>
               <div className="mr-5 md:mr-7 pr-5 flex self-end"> 
          <img
            src="/Assets/crown.svg" // Make sure this path is correct
            alt="crown"
            className="h-8 w-9"
            
          />
             </div>
          </div>
      
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
       <Sidebar className="hidden md:block" />

      <div className="flex flex-row w-4/5 ml-5  mx-auto overflow-y-auto">
        
        <div className='flex flex-col flex-grow items-center mt-4 min-h-screen '>
          <div className='flex flex-col space-y-5 w-full  md:px-0 max-w-lg'>
            <p className='text-lg font-bold'>Plans</p>
            <GoldSupplier 
              backgroundColor="#E5E5E5" 
              textColor="#171717" 
              title="Bronze Supplier" 
              subtitle="Standard Extended" 
              price="999$/year" 
              height="170px" 
              width="full" 
            />
            <GoldSupplier 
              backgroundColor="#A2A2A2" 
              textColor="#171717" 
              title="Silver Supplier" 
              subtitle="Standard Extended" 
              price="1999$/year" 
              height="170px" 
              width="full" 
            />
            <GoldSupplier 
              backgroundColor="#DB0000" 
              textColor="#F9F9F9" 
              title="Gold Supplier" 
              subtitle="Standard Extended" 
              price="2999$/year" 
              height="170px" 
              width="full" 
              onClick={handleGoldSupplierClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedSubscriptionPlans;