import { 
    POST_BLOCK_COMPANY_URL, 
    DELETE_BLOCK_COMPANY_URL, 
    GET_BLOCKED_COMPANIES_URL 
} from "./urls";
import { api } from "./api";

const blockCompany = async (uid) => {
    try {
        const url = POST_BLOCK_COMPANY_URL(uid);
        const res = await api.post(url);
        console.log("Block Company API Response:", res);
        return res.data;
    } catch (error) {
        console.error("Error blocking company:", error);
        throw error;
    }
};

// Unblock company by UID
const unblockCompany = async (uid) => {
    try {
        const url = DELETE_BLOCK_COMPANY_URL(uid);
        const res = await api.delete(url);
        console.log("Unblock Company API Response:", res);
        return res.data;
    } catch (error) {
        console.error("Error unblocking company:", error);
        throw error;
    }
};

// Get blocked companies for a company by UID
const getBlockedCompanies = async (uid) => {
    try {
        const url = GET_BLOCKED_COMPANIES_URL(uid);
        const res = await api.get(url);
        console.log("Get Blocked Companies API Response:", res);
        return res.data;
    } catch (error) {
        console.error("Error getting blocked companies:", error);
        throw error;
    }
}

export { blockCompany, unblockCompany, getBlockedCompanies };
