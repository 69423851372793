
import React, { useEffect, useState } from 'react';
import ProductAdd from '../../Components/product_adding';
import Sidebar from '../../Components/main_navbar';
import { useNavigate, useParams } from 'react-router-dom'; // Added useParams to get uid from the URL
import ReportContainer from '../../Components/report';
import { getProductsByCompanyUid, deleteProduct } from '../../../Controller/product_service';
import { showToast, Toast } from '../../Components/toast'; 
import DeletePopup from '../../Components/delete_pop_up';
import { FaSpinner } from 'react-icons/fa'; // Import FontAwesome Spinner

const ProductList = () => {
  const { uid } = useParams(); // Get uid from the URL params
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [isMyProfile, setIsMyProfile] = useState(false); // State to track if it's the user's own profile
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Start loading
      try {
        const companyUid = localStorage.getItem('company_uid'); // Get company_uid from localStorage
        
        // Determine if it's the user's profile or someone else's profile
        if (uid) {
          setIsMyProfile(uid === companyUid); // If uid in the URL matches company_uid in localStorage, it's the user's profile
        } else {
          setIsMyProfile(true); // No uid in the URL, assume it's the user's own profile
        }

        const targetUid = uid || companyUid; // Use uid from the URL or fallback to company_uid from localStorage
        
        if (!targetUid) {
          // showToast('Company UID not found in local storage or URL.', 'error');
          return;
        }

        const response = await getProductsByCompanyUid(targetUid);
        console.log('Fetched response:', response);

        if (response?.data?.data && Array.isArray(response.data.data)) {
          setProducts(response.data.data);
          console.log('Fetched products:', response.data.data);
        } else {
          setProducts([]);
          // showToast('No products found or unexpected response format.', 'error');
        }
      } catch (error) {
        console.error('Error:', error);
        // showToast('Failed to fetch products.', 'error');
      } finally {
        setLoading(false); // Stop loading
      }
    };
    fetchProducts();
  }, [uid]);

  const handleDeleteRequest = (id) => {
    setProductIdToDelete(id);
    setShowPopup(true);
  };

  const handleDelete = async () => {
    if (productIdToDelete === null) return;

    console.log('Deleting product with id:', productIdToDelete);

    try {
      const response = await deleteProduct(productIdToDelete);
      console.log('Delete response:', response);

      if (response.data.status_code === 202) {
        setProducts(products.filter(product => product.id !== productIdToDelete));
        showToast('Product deleted successfully.', 'success');
      } else {
        showToast('Failed to delete product.', 'error');
      }
    } catch (error) {
      showToast('Failed to delete product.', 'error');
      console.error('Error:', error);
    } finally {
      setShowPopup(false);
      setProductIdToDelete(null);
    }
  };

  const AddClick = () => {
    navigate('/product_service');
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setProductIdToDelete(null);
  };

  return (
    <div className="flex flex-col w-full h-screen bg-[#FBFCF8] font-poppins overflow-auto">
      <Sidebar />
      <Toast />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">Product / Service</h1>
        </div>
        
        {/* Only show the Add button if it's the user's profile */}
        {isMyProfile && (
          <img
            src="/Assets/review.svg"
            alt="Upload"
            className="cursor-pointer h-5 w-5 mt-2 lg:mt-0 mr-5"
            onClick={AddClick}
          />
        )}
        
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      
      <div className="lg:w-4/5 w-full mt-7">
        <div className="flex justify-center mt-4 md:mt-[25px]">
          <div className="w-full md:w-2/3 px-4">
            {loading ? (
              <div className="flex justify-center items-center h-64">
                <FaSpinner className="animate-spin text-[#DB0000]" size={28} /> {/* Spinner */}
              </div>
            ) : products.length === 0 ? (
              <div className="flex justify-center items-center h-64">
                <p className="text-gray-500 text-lg">No products to show!</p>
              </div>
            ) : (
              products.map((product, index) => (
                <ProductAdd
    key={index}
    text={product.name}
    // Only show the delete button if it's the user's profile
    onDelete={isMyProfile ? () => handleDeleteRequest(product.id) : null}
    showDelete={isMyProfile} // Pass this prop to conditionally show delete icon
  />
              ))
            )}
          </div>
        </div>
      </div>

      {showPopup && (
        <DeletePopup
          message="Are you sure you want to delete this product?"
          onConfirm={handleDelete}
          onClose={handlePopupClose}
        />
      )}
    </div>
  );
};

export default ProductList;
