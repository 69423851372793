import React from 'react';

const CenteredImageContainer = ({ imgSrc, altText = "Image", imgStyles = {} }) => {
  return (
    <div className="bg-black w-full h-full flex justify-center items-center ">
      <img src={imgSrc} alt={altText} style={imgStyles} className="w-50  h-50" />
    </div>
  );
};

export default CenteredImageContainer;
