// companyManagerController.js
import { post_manager } from './company_details';
import { showToast } from '../View/Components/toast';
import UploadDocuments from './firebase/firebase_documents';

export const uploadAuthorizationFile = async (file, setAuthorizationFile, setLoading) => {
  if (file.size > 25 * 1024 * 1024) {
    showToast('File exceeds the 25MB size limit.', 'error');
    return;
  }

  try {
    setLoading(true);
    const downloadURL = await UploadDocuments(file);
    setAuthorizationFile(downloadURL);
    showToast('File uploaded successfully!', 'success');
  } catch (error) {
    console.error('Error uploading file:', error);
    showToast(error.response?.data?.message || 'Error uploading file.', 'error');
  } finally {
    setLoading(false);
  }
};

export const saveManagerDetails = async (manager, linkedInUrl, navigate, setLoading) => {
  if (!manager) {
    showToast('Please Add your Account Manager', 'error');
    return;
  }
  
  // if (!linkedInUrl) {
  //   showToast('Please provide a valid LinkedIn URL.', 'error');
  //   return;
  // }

  setLoading(true);

  try {
    const response = await post_manager(manager, linkedInUrl);
    const message = response.data.message;

    if (response.status === 202) {
      navigate('/details_page');
    } else if (response.status === 404) {
      // Handle error response here
    }
  } catch (error) {
    console.error('Error saving company details:', error);
    // Handle error response here
  } finally {
    setLoading(false);
  }
};
