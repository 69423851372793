// // chatService.js
// import { collection, query, where, getDocs, addDoc, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
// import { db } from '../firebase_service';

// export const findOrCreateChat = async (currentUserId, otherUserId, currentUserName, otherUserName, currentUserImage, otherUserImage) => {
//   // Check if a chat between these two users already exists
//   const chatsQuery = query(
//     collection(db, 'chats'),
//     where('participants', 'array-contains', currentUserId)
//   );

//   const chatSnapshot = await getDocs(chatsQuery);
//   let existingChat = null;

//   chatSnapshot.forEach((doc) => {
//     const chatData = doc.data();
//     if (chatData.participants.includes(otherUserId)) {
//       existingChat = { id: doc.id, ...chatData };
//     }
//   });

//   if (existingChat) {
//     return existingChat;
//   } else {
//     const newChatDoc = await addDoc(collection(db, 'chats'), {
//       participants: [currentUserId, otherUserId],
//       lastMessage: '',
//       lastMessageTime: serverTimestamp(),
//       lastMessageUid: '',
//       receiverUid: otherUserId,
//       receiverName: otherUserName,
//       receiverImage: otherUserImage,
//       senderUid: currentUserId,
//       senderName: currentUserName,
//       senderImage: currentUserImage,
//       receiverIsInChatPage: false,
//       senderIsInChatPage: false,
//       startedConversation: true,
//     });

//     return { id: newChatDoc.id, participants: [currentUserId, otherUserId] };
//   }
// };

// export const sendMessage = async (chatId, message, userId, userName) => {
//   await addDoc(collection(db, 'chats', chatId, 'messages'), {
//     text: message,
//     createdAt: serverTimestamp(),
//     userId: userId,
//     userName: userName,
//   });

//   await updateDoc(doc(db, 'chats', chatId), {
//     lastMessage: message,
//     lastMessageTime: serverTimestamp(),
//     lastMessageUid: userId,
//   });
// };
import { collection, query, where, getDocs, addDoc, serverTimestamp, setDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase_service'; // Adjust the path as necessary

// Function to check if a chat exists between the current user (from localStorage) and another user
export const checkIfChatExists = async (otherProfileUid) => {
    const currentUserUid = localStorage.getItem('company_uid'); // Fetch current user UID from localStorage
    if (!currentUserUid) {
        throw new Error('Current user UID not found in localStorage');
    }

    try {
        const chatsRef = collection(db, 'chats');
        const q = query(chatsRef, where('participants', 'array-contains', currentUserUid));

        const querySnapshot = await getDocs(q);

        // Look for a chat where participants include both currentUserUid and otherProfileUid
        const existingChat = querySnapshot.docs.find(doc => {
            const chatData = doc.data();
            return chatData.participants.includes(otherProfileUid);
        });

        return existingChat ? { chatId: existingChat.id } : null; // Return chatId if chat exists, otherwise null
    } catch (error) {
        console.error('Error checking if chat exists:', error);
        throw error;
    }
};

// Function to create a new chat between two users
export const createNewChat = async (otherProfileUid, otherProfileName, otherProfileImage,receiverCompanyId) => {
    const currentUserUid = localStorage.getItem('company_uid'); // Fetch current user UID from localStorage
    const currentUserName = localStorage.getItem('companyName'); // Fetch current user's name from localStorage
    const currentUserImage = localStorage.getItem('profile_image_url'); // Fetch current user's profile image
    const company_id = localStorage.getItem('company_id');

    if (!currentUserUid) {
        throw new Error('Current user UID not found in localStorage');
    }

    try {
        // Create a combined UID for the chat document (sorted for consistency)
        const chatDocumentId = [currentUserUid, otherProfileUid].sort().join('-'); // Unique document name based on UIDs
        const chatDocRef = doc(db, 'chats', chatDocumentId);

        const chatSnapshot = await getDoc(chatDocRef);

        // Check if chat document already exists
        if (!chatSnapshot.exists()) {
            // Create new chat document
            await setDoc(chatDocRef, {
                participants: [currentUserUid, otherProfileUid], // Array of participants
                lastMessage: '', // No message initially
                lastMessageTime: serverTimestamp(), // Timestamp of the last message
                lastMessageUid: '', // Last message UID (empty initially)
                lastMessageSeen: false, // Message seen status
                lastMessageSeenTime: null, // Timestamp of when the message was last seen
                senderUid: currentUserUid,
                senderId: company_id,
                senderName: currentUserName,
                senderImage: currentUserImage || '/Assets/default_avatar.png', // Fallback avatar if none provided
                senderIsInChatPage: false,
                receiverId: receiverCompanyId,
                receiverUid: otherProfileUid,
                receiverName: otherProfileName,
                receiverImage: otherProfileImage || '/Assets/default_avatar.png', // Fallback avatar if none provided
                receiverIsInChatPage: false,
                isImportantForSender: false,
                isImportantForReceiver: false,
                isBlockedBySender: false,    // Added initial blocking state
                isBlockedByReceiver: false,   // Added initial blocking state

                startedConversation: true // Flag to indicate the chat was started
            });

            return chatDocumentId; // Return newly created chat document ID
        }
        return chatDocumentId; // Return existing chat document ID
    } catch (error) {
        console.error('Error creating new chat:', error);
        throw error;
    }
};
