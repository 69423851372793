import { DELETE_USER_URL, DELETE_COMPANY_URL } from './urls'; // Import the URL
import { api } from './api'; // Assuming you are using axios or similar

const deleteUser = async (company_id) => {
  try {
    const response = await api.delete(DELETE_USER_URL(company_id)); // Call DELETE API with company_id
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};
const deleteCompany = async () => {
    try {
      const response = await api.delete(DELETE_COMPANY_URL);
      return response.data; // Return the data from the successful deletion
    } catch (error) {
      console.error('Error deleting company:', error);
      throw error;
    }
  };
  

export { deleteUser ,deleteCompany};

