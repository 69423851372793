import { api } from "./api";
import { DELETE_USER_FEEDBACK, GET_USER_FEEDBACK, POST_FEEDBACK, UPDATE_FEEDBACK } from "./urls";
import { GET_SPECIFIC_USER_FEEDBACK } from "./urls";
import { GET_USER_FEEDBACK_ID } from "./urls";
const uploadFeedback = async (feedbacking_uid, description) => {
    try {
        const payload = {
            description: description, // Only description field as payload
        };
      
        // Pass the feedbacking_uid as a query parameter in the URL
        const res = await api.post(`${POST_FEEDBACK}?feedbacking_uid=${feedbacking_uid}`, payload);
        return res;
    } catch (error) {
        console.error("Error submitting feedback:", error);
        throw error;
    }
};

const getFeedback = async (company_uid, skip = 0, limit = 10, other_profile = false) => {
    try {
        const url = GET_USER_FEEDBACK(company_uid);  // Ensure the URL is correctly generated with company_uid
        const res = await api.get(url, {
            params: {
                skip: skip,    // Ensure skip is passed correctly
                limit: limit ,  // Ensure limit is passed correctly
                other_profile: other_profile
            }
        });
        return res.data;
    } catch (error) {
        console.error("Error fetching feedback:", error);
        throw error;
    }
};
const getFeedbackById = async (id) => {
    try {
        const response = await api.get(`${GET_USER_FEEDBACK_ID}/${id}`);
        console.log('API response:', response); // Log the entire response to see the structure
        const feedbackData = response.data?.data; // Access the nested data
        console.log('Fetched feedback data:', feedbackData); // Log the feedback data
        return feedbackData;
    } catch (error) {
        console.error(`Error fetching feedback with id ${id}:`, error);
        throw error;
    }
};


const deleteFeedback = async (id) => {
    try {
        console.log(`Deleting feedback with ID: ${id}`);
        const url = DELETE_USER_FEEDBACK(id); // Correct URL construction
        console.log(`Deleting feedback with URL: ${url}`); // Log URL for debugging
        const res = await api.delete(url); // Just use the constructed URL
        return res;
    } catch (error) {
        console.error(`Error deleting feedback with id ${id}:`, error.response?.data || error.message);
        throw error;
    }
};




const getSpecificUserFeedback = async (skip = 0, limit = 10) => {
  
  try {
      const res = await api.get(GET_SPECIFIC_USER_FEEDBACK, {
          params: {
              skip: skip,  // Skip the first 'skip' number of records
              limit: limit // Limit the number of records returned
          }
      });
      return res.data;  // Return the data from the response
  } catch (error) {
      console.error("Error fetching specific user feedback:", error);
      throw error;
  }
};

const updateFeedback = async (id, description) => {
    try {
        const payload = {
            description,  // The updated feedback description
        };
        
        // Use the constant URL and append the ID as required by the API
        const res = await api.put(`${UPDATE_FEEDBACK}/${id}`, payload);
        
        return res;
    } catch (error) {
        console.error("Error updating feedback:", error);
        throw error;
    }
};





export { uploadFeedback, getFeedback ,deleteFeedback,getSpecificUserFeedback,getFeedbackById,updateFeedback};
