import React, { useState, useEffect } from 'react';

const ResendLink = ({ linkText, onResend, hasError }) => {
  const [timer, setTimer] = useState(60); // Initially show the timer for 30 seconds
  const [canResend, setCanResend] = useState(false); // Disable resend initially

  useEffect(() => {
    if (timer > 0 && !hasError) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }

    if (timer === 0 && !hasError) {
      setCanResend(true); // Enable resend when the timer reaches 0
    }

    if (hasError) {
      // If there's an error, reset the timer and allow resend
      setTimer(0);
      setCanResend(true);
    }
  }, [timer, hasError]);

  const handleClick = async (event) => {
    event.preventDefault();
    if (canResend && !hasError && onResend) {
      const otpSent = await onResend();
      if (otpSent) {
        setTimer(60); // Restart the timer for 30 seconds on successful OTP send
        setCanResend(false); // Disable resend link during timer countdown
      }
    }
  };

  return (
    <p className="font-poppins text-sm">
      <span className="text-[#9F9F9F] lg:text-[14px] text-[12px]">Didn’t receive a code?</span> {/* Grey text */}
      {canResend ? (
        <a
          href="#"
          className={`font-poppins lg:text-base text-[11px] font-normal ${hasError ? 'text-[#9F9F9F] cursor-not-allowed' : 'text-[#DB0000]'}`} // Grey if there's an error, disable link
          onClick={handleClick}
        >
          {linkText}
        </a>
      ) : (
        <span className="font-poppins font-bold text-[#DB0000] ml-1">
          {`${timer}s`}
        </span>
      )}
    </p>
  );
};

export default ResendLink;
