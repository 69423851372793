
import React, { useEffect } from 'react';

const FeedbackSubmitted = ({ onClose }) => {
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.classList.contains('overlay')) {
                onClose();
            }
        };
        document.addEventListener('click', handleOutsideClick);
        
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [onClose]);

    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60 overlay"></div>
            <div className="fixed inset-0 flex items-center justify-center overlay">
                <div className="bg-white rounded-lg p-4 sm:p-6 shadow-lg text-center  mx-4 flex flex-col items-center">
                    <div className="flex flex-col sm:flex-row items-center justify-center w-full">
                        <img
                            src="/Assets/feedback.svg"
                            alt="People shaking hands"
                            className="w-2/3 sm:w-1/2 h-auto mb-4 sm:mb-0"
                        />
                        <div className="text-center sm:text-left sm:ml-4">
                            <img
                                src="/Assets/tick_feedback.svg"
                                alt="Tick"
                                className="w-8 h-8 mx-auto mb-2"
                            />
                            <h2 className="text-base sm:text-lg font-bold">Thank you for your</h2>
                            <p className="text-lg sm:text-xl lg:ml-9 md:ml-6 font-bold">Feedback</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeedbackSubmitted;