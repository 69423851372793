import { ref, uploadBytes,uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase_service";

const uploadVideoToFirebase = (file, setProgress) => {
  return new Promise((resolve, reject) => {
    if (!file) reject("No file provided");

    const storageRef = ref(storage, `web/videos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (setProgress) setProgress(progress);
      },
      (error) => {
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        }).catch((error) => {
          reject(error);
        });
      }
    );
  });
};

export default uploadVideoToFirebase;