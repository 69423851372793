import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';

const CurrentPlan = ({ plan }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set loading to false when plan data is available
    if (plan) {
      setIsLoading(false);
    }
  }, [plan]);

  return (
    <div className="flex flex-col items-center font-poppins mt-4 md:mt-6 w-full md:w-[600px]">
      <div className="bg-[#D6D6D6] w-full h-16 flex items-center justify-center rounded-t-lg">
        <span className="text-base md:text-lg font-normal text-[#1C1C1C]">Current Plan</span>
      </div>

      <div className="bg-[#DB0000] w-full h-auto flex flex-col p-4 text-[#E0E0E0] rounded-b-lg relative">
        {/* Loading Overlay */}
        {isLoading && (
          <div className="absolute inset-0 flex justify-center items-center bg-opacity-75 bg-[#DB0000] z-10">
            <FaSpinner className="animate-spin text-white text-xl" />
          </div>
        )}

        {/* Existing UI */}
        <div className="flex flex-col md:flex-row justify-between h-full">
          {/* Display Plan Name */}
          <div className="flex flex-col justify-center text-lg md:text-xl lg:text-2xl font-normal ml-2">
            <span className="text-base text-xl font-semibold">
              {plan?.plan_name || 'N/A'}
            </span>
          </div>

          {/* Display Expiry Date */}
          <div className="flex flex-col justify-center text-right mr-2 mb-2 md:mb-0 md:h-full">
            <div className="text-base sm:text-sm md:text-lg font-semibold">
              Expiry Date : {plan?.ends_at ? new Date(plan.ends_at).toLocaleDateString() : 'N/A'}
            </div>
          </div>
        </div>

        {/* Display Price with Plan Name */}
        <div className="mt-2 text-right text-lg font-semibold">
          {plan?.price ? `$${plan.price}/${plan?.plan_name}` : 'N/A'}
        </div>
      </div>
    </div>
  );
};

export default CurrentPlan;
