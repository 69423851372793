import React, { useEffect } from 'react';

const ReportSubmitted = ({ onClose }) => {
  
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.classList.contains('overlay')) {
                onClose();
            }
        };
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [onClose]);

    return (
        <>
            {/* Ensure the overlay has a high z-index */}
            <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60 overlay z-50"></div>
            <div className="fixed inset-0 flex items-center justify-center overlay z-50">
                <div className="bg-white rounded-lg p-6 shadow-lg text-center mx-4 flex flex-col items-center max-w-lg">
                    <img
                        src="/Assets/report_submitted.svg" // Replace with the actual image path
                        alt="Thank You"
                        className="w-full h-40 mb-6"
                    />
                    <h2 className="text-xl font-bold mb-4">
                        Thank You for Reporting the Problem!
                    </h2>
                    <p className="text-gray-600 mb-8 px-4">
                        "Your report has been submitted successfully. We appreciate you bringing this issue to our attention. Our team is reviewing the problem and will work diligently to resolve it as soon as possible."
                    </p>
                    <button
                        onClick={onClose}
                        className="bg-[#DB0000] text-white px-6 py-3 mt-8 rounded-lg w-full max-w-xs"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </>
    );
};
export default ReportSubmitted;
