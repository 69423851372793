// import React, { useState, useRef } from 'react';
// import { RiArrowDropDownLine } from "react-icons/ri";

// const ContinentDropdown = ({ selectedContinent, onChange }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const selectRef = useRef(null); // Reference for toggling the dropdown

//   const continents = [
//     { name: 'asia', imageSrc: '/Assets/asia1.svg' },
//     { name: 'europe', imageSrc: '/Assets/europe1.svg' },
//     { name: 'north_america', imageSrc: '/Assets/northamerica1.svg' },
//     { name: 'south_america', imageSrc: '/Assets/southamerica1.svg' },
//     { name: 'africa', imageSrc: '/Assets/AFRICA1.scg' },
//     { name: 'australia', imageSrc: '/Assets/australia1.svg' }
//   ];

//   const capitalizeFirstLetter = (string) => {
//     return string.charAt(0).toUpperCase() + string.slice(1).replace('_', ' ');
//   };

//   const handleIconClick = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleOptionClick = (continent) => {
//     onChange({ target: { value: continent.name } });
//     setIsOpen(false); 
//   };

//   return (
//     <div className="relative inline-block w-full">
//       <div 
//         className="flex items-center w-full bg-white border border-[#E8E8E8] px-4 py-0 h-10 rounded-md leading-tight cursor-pointer"
//         onClick={handleIconClick} // Toggle dropdown on click
//       >
//         {/* Globe Icon */}
//         <img src="./continent-dropdown.svg" alt="Globe" className="h-5 w-5 mr-2" />

//         {/* Display Selected Continent */}
//         <div className="w-full text-gray-700 text-sm">
//           {selectedContinent ? capitalizeFirstLetter(selectedContinent) : 'Select a continent'}
//         </div>

//         {/* Partition line */}
//         <div className="h-full w-[1px] bg-gray-300 mx-4"></div>

//         {/* Dropdown Icon */}
//         <RiArrowDropDownLine className="text-[#DB0000] text-3xl cursor-pointer " />
//       </div>

//       {/* Custom Dropdown Options */}
//       {isOpen && (
//         <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto shadow-lg">
//           {continents.map((continent) => (
//             <li
//               key={continent.name}
//               className="cursor-pointer px-4 py-2 hover:bg-gray-200"
//               onClick={() => handleOptionClick(continent)}
//             >
//               {capitalizeFirstLetter(continent.name)}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default ContinentDropdown;

import React, { useState, useRef, useEffect } from 'react';
import { RiArrowDropDownLine } from "react-icons/ri";

const ContinentDropdown = ({ selectedContinent, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null); // Reference for toggling the dropdown

  const continents = [
    { name: 'asia', imageSrc: '/Assets/asia1.svg' },
    { name: 'europe', imageSrc: '/Assets/europe1.svg' },
    { name: 'north_america', imageSrc: '/Assets/northamerica1.svg' },
    { name: 'south_america', imageSrc: '/Assets/southamerica1.svg' },
    { name: 'africa', imageSrc: '/Assets/AFRICA1.scg' },
    { name: 'australia', imageSrc: '/Assets/australia1.svg' }
  ];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).replace('_', ' ');
  };

  // Add event listener for clicks outside of the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleIconClick = (e) => {
    e.stopPropagation(); // Prevent the click from closing the dropdown
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (continent) => {
    onChange({ target: { value: continent.name } });
    setIsOpen(false);
  };

  return (
    <div ref={selectRef} className="relative inline-block w-full">
      <div 
        className="flex items-center w-full bg-white border border-[#E8E8E8] px-4 py-0 h-10 rounded-md leading-tight cursor-pointer"
        onClick={handleIconClick} // Toggle dropdown only on arrow icon click
      >
        {/* Globe Icon */}
        <img src="./continent-dropdown.svg" alt="Globe" className="h-5 w-5 mr-2" />

        {/* Display Selected Continent */}
        <div className="w-full text-gray-700 text-sm">
          {selectedContinent ? capitalizeFirstLetter(selectedContinent) : 'Select a continent'}
        </div>

        {/* Partition line */}
        <div className="h-full w-[1px] bg-gray-300 mx-4"></div>

        {/* Dropdown Icon */}
        <RiArrowDropDownLine className="text-[#DB0000] text-3xl cursor-pointer" />
      </div>

      {/* Custom Dropdown Options */}
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto shadow-lg">
          {continents.map((continent) => (
            <li
              key={continent.name}
              className="cursor-pointer px-4 py-2 hover:bg-gray-200"
              onClick={() => handleOptionClick(continent)}
            >
              {capitalizeFirstLetter(continent.name)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ContinentDropdown;

