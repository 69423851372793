import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OTPInputComponent from "../../Components/otp_input";
import Sidebar from "../../Components/main_navbar";
import Button from "../../Components/button";
import { resetPasswordMail } from "../../../Controller/auth";
import { showToast } from "../../Components/toast";
import { Toast } from "../../Components/toast";


const ForgotPassword = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/security_page');
    };
    const [otp, setOtp] = useState('');

    const handleChange = (otp) => {
        setOtp(otp);
    };

    const clearOtp = () => {
        setOtp('');
    };

    return (
        <div className="flex flex-col w-full h-full bg-[#F2F3F3]">
            <Sidebar />
            <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
            <div className="w-full flex justify-start items-center space-x-2">
                
              <img
                src="/Assets/arrow_back.svg"
                alt="Back"
                className="cursor-pointer mr-2 h-6 w-6"
                onClick={handleBackClick}
              />
              <h1 className="text-black font-bold text-xl">Forgot Password </h1>   </div>
              
          </div>
            <div className="border-b border-black w-full mt-8 md:mt-[25px]"></div>
            <div className="flex flex-col items-center mt-8 w-4/5 ml-8">
                <div className="flex items-center mt-5">
                    <img src='./email1.svg' alt="Email Icon" className="w-6 h-6 mr-2" />
                    <h2 className="font-techna text-xl md:text-2xl font-bold leading-[30.14px] text-[rgba(74,74,74,1)]">
                        E-mail Verification
                    </h2>
                </div>
                <p className="mt-8">
                    Enter the OTP sent to Registered Email
                </p>
                <div className="mt-8">
                    <OTPInputComponent otp={otp} handleChange={handleChange} />
                </div>
                <p className="mt-9">
                    Didn't receive the code? <span className="text-red-600 cursor-pointer font-bold">Re-send</span>
                </p>
                <div className='mt-10 w-[300px]'>
            <Button
                  text="Next "
                  backgroundColor="bg-[#DB0000]"
                  textColor="text-white" 
                  navigateTo="/new_password"
                  
              />
            </div>
            </div>
        </div>
    );
};

export default ForgotPassword;