import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase_service';
import Sidebar from '../../Components/main_navbar';
import ChatWindow from '../../Components/chatmsg_ex';
import { FaSpinner } from 'react-icons/fa';

const ImportantMessages = () => {
  const [importantMessages, setImportantMessages] = useState([]);
  const [userImages, setUserImages] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const companyUid = localStorage.getItem('company_uid');
    if (companyUid) {
      setCurrentUser({ id: companyUid });
    } else {
      navigate('/');
    }
  }, [navigate]);

  // Fetch user images for both sender and receiver
  useEffect(() => {
    const fetchUserImages = async () => {
      const userIds = new Set();

      // Collect unique user IDs from important messages
      importantMessages.forEach((message) => {
        userIds.add(message.senderUid);
        userIds.add(message.receiverUid);
      });

      // Fetch profile images for each user
      const userPromises = Array.from(userIds).map(async (uid) => {
        const userDoc = doc(db, 'users', uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          return [uid, userData.profileImage || '/Assets/default_profile.svg'];
        }
        return [uid, '/Assets/default_profile.svg'];
      });

      const userImageResults = await Promise.all(userPromises);
      setUserImages(Object.fromEntries(userImageResults));
    };

    if (importantMessages.length > 0) {
      fetchUserImages();
    }
  }, [importantMessages]);

  useEffect(() => {
    if (!currentUser) return;

    const q1 = query(
      collection(db, 'chats'),
      where('participants', 'array-contains', currentUser.id),
      where('isImportantForSender', '==', true)
    );

    const q2 = query(
      collection(db, 'chats'),
      where('participants', 'array-contains', currentUser.id),
      where('isImportantForReceiver', '==', true)
    );


    
    let allMessages = new Map();

    const handleSnapshot = (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const messageData = { id: change.doc.id, ...change.doc.data() };
        const isCurrentUserSender = currentUser.id === messageData.senderUid;

        if (change.type === 'added' || change.type === 'modified') {
          if (isCurrentUserSender ? messageData.isImportantForSender : messageData.isImportantForReceiver) {
            allMessages.set(change.doc.id, messageData);
          } else {
            allMessages.delete(change.doc.id);
          }
        } else if (change.type === 'removed') {
          allMessages.delete(change.doc.id);
        }
      });
      
  const sortedMessages = Array.from(allMessages.values()).sort((a, b) => {
    const timeA = a.lastMessageTime?.toMillis ? a.lastMessageTime.toMillis() : 0;
    const timeB = b.lastMessageTime?.toMillis ? b.lastMessageTime.toMillis() : 0;
    return timeB - timeA; // Sort in descending order (newest first)
  });
  setImportantMessages(sortedMessages);

      setImportantMessages(Array.from(allMessages.values()));
      setLoading(false);
    };

    const unsubscribe1 = onSnapshot(q1, handleSnapshot);
    const unsubscribe2 = onSnapshot(q2, handleSnapshot);

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, [currentUser]);





  const handleSelectMessage = async (messageId) => {
    const selectedMessage = importantMessages.find((msg) => msg.id === messageId);
    if (selectedMessage) {
      const chatDoc = await getDoc(doc(db, 'chats', messageId));
      if (chatDoc.exists()) {
        const fullChatData = { id: chatDoc.id, ...chatDoc.data() };
        setSelectedMessage(fullChatData);
      } else {
        console.error('Selected chat document does not exist');
        setSelectedMessage(null);
      }
    } else {
      console.error('Selected message not found in importantMessages');
      setSelectedMessage(null);
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleBackClick = () => {
    setSelectedMessage(null);
  };

  const trimMessage = (message, length = 25) => {
    return message && message.length > length ? `${message.substring(0, length)}...` : message;
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
      <Sidebar />

      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          {isMobile && selectedMessage && (
            <img
              src="/Assets/arrow_back.svg"
              alt="Back"
              className="cursor-pointer h-6 w-6 mr-2"
              onClick={handleBackClick}
            />
          )}
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">Important Messages</h1>
        </div>
        <div className="mr-7"></div>
      </div>

      <div className="border-b border-black mt-4 md:mt-[25px]"></div>

      <div className="flex w-full flex-grow overflow-hidden">
        {(isMobile && !selectedMessage) || !isMobile ? (
          <div className="w-full lg:w-1/4 h-full bg-[#F9F9F9] border-r overflow-y-auto">
            {loading ? (
              <div className="flex items-center justify-center h-full">
                <FaSpinner className="animate-spin text-xl text-gray-500" />
              </div>
            ) : importantMessages.length === 0 ? (
              <div className="flex items-center justify-center h-full text-gray-500">
                No important chats to show
              </div>
            ) : (
              <div className="space-y-2 p-4">
                {importantMessages.map((message) => {
                  const isSender = currentUser.id === message.senderUid;
                  const displayName = isSender ? message.receiverName : message.senderName;
                  const displayImage = userImages[isSender ? message.receiverUid : message.senderUid];

                  const showDoubleTick =
                    isSender && message.lastMessageSeen && message.lastMessageSeenTime;

                       // Add new message indicator logic
                  const hasUnreadMessages = !message.lastMessageSeen && 
                  message.lastMessageUid !== currentUser.id;

                  return (
                    <div
                      key={message.id}
                      className="flex items-center p-4 bg-gray-100 rounded-lg shadow-md cursor-pointer hover:bg-gray-200 transition-colors"
                      onClick={() => handleSelectMessage(message.id)}
                    >
                      {/* <img
                        src={displayImage || '/Assets/default_profile.svg'}
                        alt={displayName}
                        className="h-12 w-12 rounded-full mr-4"
                      /> */}
                         <div className="relative">
                        <img
                          src={displayImage || '/Assets/default_profile.svg'}
                          alt={displayName}
                          className="h-12 w-12 rounded-full mr-4"
                        />
                        {hasUnreadMessages && (
                          <div className="absolute -top-1 -right-1 w-3 h-3 bg-red-600 rounded-full"></div>
                        )}
                      </div>
                      <div className="flex-grow">
                        <h2 className="font-semibold text-lg">{displayName}</h2>
                        <div className="flex items-center space-x-2">
                          <p className="text-sm text-gray-600 flex-grow">
                            {trimMessage(message.lastMessage)}
                          </p>
                          {showDoubleTick && (
                            <img
                              src="/Assets/double_tick.svg"
                              alt="Seen"
                              className="w-4 h-4 ml-2"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : null}

        {(isMobile && selectedMessage) || (!isMobile && selectedMessage) ? (
          <div className={`w-full ${isMobile ? '' : 'hidden lg:flex lg:w-3/4'} h-full overflow-y-auto`}>
            {selectedMessage && currentUser && (
              <ChatWindow
                message={selectedMessage}
                selectedUser={
                  currentUser.id === selectedMessage.senderUid
                    ? {
                        userName: selectedMessage.receiverName,
                        userImage: selectedMessage.receiverImage,
                        id: selectedMessage.receiverUid,
                      }
                    : {
                        userName: selectedMessage.senderName,
                        userImage: selectedMessage.senderImage,
                        id: selectedMessage.senderUid,
                      }
                }
                currentUserUid={currentUser.id}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ImportantMessages;