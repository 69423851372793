import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ProfileRedirector = () => {
  const { company_id } = useParams(); // Get company_id from URL
  const navigate = useNavigate();

  useEffect(() => {
    const localCompanyId = localStorage.getItem('company_id'); // Get company_id from localStorage

    if (company_id === localCompanyId) {
      // If the company_id matches, navigate to MyProfile (ProfileVisitorsView)
      navigate(`/profile1/${company_id}`);
    } else {
      // If the company_id doesn't match, navigate to OtherProfile (OtherProfileVisitorsView)
      navigate(`/profile2/${company_id}`);
    }
  }, [company_id, navigate]);

  return null; // No need to render anything
};

export default ProfileRedirector;
