import { ADD_REVIEW ,GET_COMPANY_REVIEWS_URL,DELETE_REVIEW_URL} from "./urls";
import { api } from "./api";
const add_review = async (reviewing_uid, payload) => {
    try {
        const url = `${ADD_REVIEW}?reviewing_uid=${reviewing_uid}`;
        const res = await api.post(url, payload);
        console.log("Add Review API Response:", res);
        return res;
    } catch (error) {
        console.error("Error adding review:", error);
        throw error;
    }
};
const getCompanyReviews = async (company_uid, skip, limit) => {
    try {
        const url = GET_COMPANY_REVIEWS_URL(company_uid, skip, limit);
        const res = await api.get(url);
        console.log("Get Company Reviews API Response:", res);
        return res.data;
    } catch (error) {
        console.error("Error getting company reviews:", error);
        throw error;
    }
}

const deleteReview = async (id) => {
    try {
        const url = DELETE_REVIEW_URL(id);
        const res = await api.delete(url);
        console.log("Delete Review API Response:", res);
        return res.data;
    } catch (error) {
        console.error("Error deleting review:", error);
        throw error;
    }
}

export  {add_review,getCompanyReviews,deleteReview}