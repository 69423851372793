import React from 'react';
import StarRating from './review_star';
import { categoryMapping } from './category';
const CompanyCard = ({ company }) => {
  // Get the category label based on the categoryId
  const categoryLabel = categoryMapping[company.category] || 'Category not provided';
  const formatLocation = (fullLocation) => {
    if (!fullLocation) return 'Location not provided';
    
    // Split the location by commas and take the last two parts
    const locationParts = fullLocation.split(',');
    
    // Check if there are at least two parts to display
    if (locationParts.length >= 2) {
      const lastTwoParts = locationParts.slice(-1).join(',').trim(); // Join last two parts and trim spaces
      return lastTwoParts;
    }
  
    return fullLocation; // Return the full location if splitting doesn't provide two parts
  };
  

  return (
    <div className="flex flex-row w-full bg-[#FFFFFF] shadow-lg  rounded-lg p-2">
      {/* Left Section for the Logo */}
      <div className="flex items-center justify-center p-0 md:p-4 bg-[#FFFFFF] w-1/3 rounded-lg ">
        {company.logo ? (
          <img src={company.logo} alt={company.name} className="h-20 w-20 rounded-full" />
        ) : (
          <div className="h-20 w-20 bg-gray-300 rounded-full flex items-center justify-center lg:text-lg  text-[14px] font-bold">
            {company.name.charAt(0)} {/* Show the first letter if logo is not available */}
          </div>
        )}
      </div>

      {/* Right Section for Details */}
      <div className="flex flex-col justify-center p-4 md:p-4 bg-white w-2/3 rounded-lg">
        {/* Company Name */}
        <h3 className="mb-1 sm:text-sm text-left font-poppins font-semibold lg:text-lg leading-tight text-[#181717] overflow-hidden text-ellipsis whitespace-nowrap">
          {company.name}
        </h3>

        {/* Category */}
        <p className="text-xs lg:text-sm  font-gotham mb-1 text-[#181717] ">
          {categoryLabel} {/* Display the category name */}
        </p>


        {/* Location with Icon */}
        {/* <div className="flex items-center mb-1 text-sm font-semibold text-[#181717]">
          <img src="/Assets/location-new.svg" alt="Location" className="w-4 h-4 mr-1" />
          <span>{company.location || 'Location not provided'}</span>
        </div> */}
     <div 
  className="flex items-center mb-1 text-[12px] lg:text-[14px] font-gotham text-[#181717] overflow-hidden text-ellipsis whitespace-nowrap "  
 
>
          <img src="/Assets/location-new.svg" alt="Location" className="w-4 h-4 mr-1" />
          <span className="font-normal">
            {formatLocation(company.location)} {/* Format the location to display only the last two parts */}
          </span>
        </div>

        {/* Star Rating */}
        <div className="mt-1">
          <StarRating review={company.rating } /> {/* Pass the rating to the StarRating component */}
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
