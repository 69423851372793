
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputBox from '../../Components/input_box';
import Button from '../../Components/button';
import Sidebar from '../../Components/main_navbar';
import LogoUploader from '../../Components/circle_avatar';
import DeletePopup from '../../Components/delete_pop_up'; // Import the DeletePopup component
import { createManagementMember, updateManagementMember, deleteManagementMember } from '../../../Controller/management';
import uploadImage from '../../../Controller/firebase/firebase_upload_image';

const AddManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const profile = location.state?.profile || {};
  const [name, setFullName] = useState(profile.name || '');
  const [position, setPosition] = useState(profile.position || '');
  const [linkedinUrl, setLinkedinUrl] = useState(profile.linkedin_url || '');  
  const [logo, setLogo] = useState(profile.image_url || null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false); // For showing/hiding options
  const [showDeletePopup, setShowDeletePopup] = useState(false); // For showing the delete popup
  const [isDeleting, setIsDeleting] = useState(false); // For showing loader during delete

  useEffect(() => {
    if (profile.image_url) {
      setLogo(profile.image_url);
    }
    if (profile.linkedin_url) {
      setLinkedinUrl(profile.linkedin_url);
    }
  }, [profile.image_url, profile.linkedin_url]);

  const handleImageUpload = (croppedImageUrl) => {
    setLogo(croppedImageUrl); // Update the logo state with the new cropped image URL
    // If updating a specific profile, also update its data structure
    if (profile) {
      profile.image_url = croppedImageUrl; // Update the image URL in the profile object
    }
  };
  

  const isValidLinkedInUrl = (url) => {
    const linkedInRegex = /^https:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
    return linkedInRegex.test(url);
  };

  const handleSave = async () => {
    if (!name || !position || !linkedinUrl || !logo) {
      setError('Please fill in all fields and upload an image.');
      return;
    }

    if (!isValidLinkedInUrl(linkedinUrl)) {
      setError('Please enter a valid LinkedIn URL.');
      return;
    }

    setIsLoading(true);

    try {
      let response;

      if (profile.id) {
        response = await updateManagementMember(profile.id, name, position, linkedinUrl, logo);
      } else {
        response = await createManagementMember(name, position, linkedinUrl, logo);
      }

      console.log('Server response:', response);
      navigate('/view-management', { state: { newProfileAdded: true } });
    } catch (error) {
      console.error('Error creating/updating management member:', error);
      setError('Failed to save management member. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = () => {
    setShowDeletePopup(true); // Show the delete popup when delete button is clicked
  };

  const handleDeleteConfirm = async () => {
    setIsDeleting(true); // Show loader during delete
    try {
      await deleteManagementMember(profile.id);
      navigate('/view-management', { state: { deletedProfile: true } });
    } catch (error) {
      console.error('Error deleting management member:', error);
    } finally {
      setIsDeleting(false);
      setShowDeletePopup(false); // Close the popup after delete
    }
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false); // Close the popup if "No" is clicked
  };
   // Navigate to the report page
   const handleReportClick = () => {
    navigate('/report_page'); // Navigate to report_page when Report is clicked
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins">
      <Sidebar />
      <div className="flex justify-between ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-black font-semibold text-xl">Management</h1>
        </div>
        <div className="relative">
          <img
            src="/Assets/dots.svg" 
            alt="Options"
            className="cursor-pointer lg:h-5 lg:w-5 w-4 h-4 mt-2 lg:mr-0 mr-2 mb-1"
            onClick={() => setShowOptions(!showOptions)} 
          />
          {showOptions && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded-md">
             <button className="block w-full text-left px-4 py-2 text-sm text-black hover:bg-gray-200 flex items-center" onClick={handleReportClick}>
                <img src="/Assets/report_icon.svg" alt="Report Icon" className="w-5 h-5 mr-3" /> {/* Report icon */}
                Report an issue
              </button>
              
              {profile.id && (
                <button className="block w-full text-left px-4 py-2 text-sm text-black hover:bg-gray-200 flex items-center" onClick={handleDeleteClick}>
                  <img src="/Assets/bin.svg" alt="Delete Icon" className="w-5 h-5 mr-3" /> {/* Delete icon */}
                  Delete
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-7 mt-1 p-3">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="flex justify-center ">
            {/* <LogoUploader 
              logo={logo} 
              onUpload={handleImageUpload} 
              isEditable={true} 
              showEditIcon={true} 
              error={error}
              onError={setError}
            /> */}
           <LogoUploader 
  logo={logo}
  onUpload={handleImageUpload}
/>
                        </div>
          <div className="flex flex-col mt-5 font-poppins">
            <p className="font-semibold">Full Name</p>
            <InputBox
              placeholder="Full Name"
              value={name}
              onChange={(e) => setFullName(e.target.value)}
              borderRadius="1px"
            />
          </div>
          <div className="flex flex-col mt-5 font-poppins">
            <p className="font-semibold">Position</p>
            <InputBox
              placeholder="Designation"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              borderRadius="1px"
            />
          </div>
          <div className="flex flex-col mt-5 font-poppins">
            <p className="font-semibold">LinkedIn Url</p>
            <InputBox
              placeholder="https://www.linkedin.com/in/username"
              value={linkedinUrl}
              onChange={(e) => setLinkedinUrl(e.target.value)} 
              borderRadius="1px"
            />
          </div>
          {error && <p className="text-red-600 text-[10px] mt-4">{error}</p>}
          <div className="flex justify-center mt-20">
            <Button
              text={profile.id ? "Update" : "Save"}
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSave}
              loading={isLoading}
            />
          </div>
        </div>
      </div>

      {/* Show the delete popup when the delete button is clicked */}
      {showDeletePopup && (
        <DeletePopup
          message="Are you sure you want to delete this management member?" 
          onConfirm={handleDeleteConfirm} 
          onClose={handleDeleteCancel}
          isLoading={isDeleting} // Pass the loading state to the popup
        />
      )}
    </div>
  );
};

export default AddManagement;



