
// import React, { useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import Sidebar from '../../Components/main_navbar';
// import ReportContainer from '../../Components/report';
// import { Toast } from '../../Components/toast';
// import { get_documents_api } from '../../../Controller/company_details';

// const CompanyDocsView = () => {
//   const { company_id } = useParams();  // Get company_id from the URL if present
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [isMyProfile, setIsMyProfile] = useState(false); // To determine if it's the logged-in user's profile
//   const [documentUrls, setDocumentUrls] = useState({
//     'Trading License': '',
//     'Trading Register': '',
//     'Tenancy Contract': '',
//   });

//   useEffect(() => {
//     const fetchDocuments = async () => {
//       setLoading(true);
//       try {
//         let idToUse = company_id; // Assume the company_id from params is for other profiles
//         const localCompanyId = localStorage.getItem('company_id'); // Get company_id from localStorage for logged-in user
  
//         if (!company_id) {
//           // If there's no company_id in the URL, assume it's the user's own profile
//           if (!localCompanyId) {
//             console.error("No company ID found in localStorage.");
//             setLoading(false);
//             return;
//           }
//           idToUse = localCompanyId;
//           setIsMyProfile(true); // This is the user's own profile
//         } else {
//           // If company_id is in the URL, assume it's another profile
//           setIsMyProfile(false);
//         }
  
//         const res = await get_documents_api(idToUse); // Fetch documents using the appropriate company_id
//         console.log("Documents API Response:", res.data);
  
//         const fetchedDocuments = res.data.data || {};
  
//         // Filter out documents that are null or empty and set the state accordingly
//         const filteredDocuments = {
//           'Business License': fetchedDocuments.trading_license || '',
//           'Tenancy Contract': fetchedDocuments.tenancy_contract || '',
//           'Trading Register': fetchedDocuments.trading_register || '',
//         };
  
//         // Remove entries that are empty or null
//         const validDocuments = Object.keys(filteredDocuments).reduce((acc, key) => {
//           if (filteredDocuments[key]) {
//             acc[key] = filteredDocuments[key];
//           }
//           return acc;
//         }, {});
  
//         setDocumentUrls(validDocuments);
        
//       } catch (error) {
//         console.error("Error fetching document details:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     fetchDocuments();
//   }, [company_id]); // Runs whenever company_id changes
//   // Runs whenever company_id changes

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-semibold">
//       <Sidebar />
//       <Toast />
//       <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">
//             {isMyProfile ? ' Company Documents' : 'Company Documents'}
//           </h1>
//         </div>
//         {/* <ReportContainer /> */}
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-4 p-4">
//         <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
//           <div className="flex flex-col font-semibold font-poppins">
//             {Object.keys(documentUrls).map((label, index) => (
//               <div key={index} className="mb-4">
//                 <p className="mb-2 text-black font-semibold">{label}</p>
//                 <div className="flex justify-between items-center p-8 bg-gray-200 rounded-md">
//                   <div className="flex items-center">
//                     <img src="/Assets/pdf.svg" alt="PDF" className="w-8 h-8 mr-2" />
//                     <span className="text-black font-semibold">Document</span>
//                   </div>
//                   {documentUrls[label] ? (
//                     <a href={documentUrls[label]} target="_blank" rel="noopener noreferrer" className="text-red-600 underline">
//                       View
//                     </a>
//                   ) : (
//                     <span className="text-gray-500"></span>
//                   )}
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyDocsView;


import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import ReportContainer from '../../Components/report';
import { Toast } from '../../Components/toast';
import { get_documents_api } from '../../../Controller/company_details';
import Modal from '../../Components/pdf_opener';

const CompanyDocsView = () => {
  const { company_id } = useParams();  // Get company_id from the URL if present
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isMyProfile, setIsMyProfile] = useState(false); // To determine if it's the logged-in user's profile
  const [documentUrls, setDocumentUrls] = useState({
    'Trading License': '',
    'Trading Register': '',
    'Tenancy Contract': '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalUrl, setModalUrl] = useState('');

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        let idToUse = company_id;
        const localCompanyId = localStorage.getItem('company_id');
  
        if (!company_id) {
          if (!localCompanyId) {
            console.error("No company ID found in localStorage.");
            setLoading(false);
            return;
          }
          idToUse = localCompanyId;
          setIsMyProfile(true);
        } else {
          setIsMyProfile(false);
        }
  
        const res = await get_documents_api(idToUse);
        console.log("Documents API Response:", res.data);
  
        const fetchedDocuments = res.data.data || {};
  
        const filteredDocuments = {
          'Business License': fetchedDocuments.trading_license || '',
          'Tenancy Contract': fetchedDocuments.tenancy_contract || '',
          'Trading Register': fetchedDocuments.trading_register || '',
        };
  
        const validDocuments = Object.keys(filteredDocuments).reduce((acc, key) => {
          if (filteredDocuments[key]) {
            acc[key] = filteredDocuments[key];
          }
          return acc;
        }, {});
  
        setDocumentUrls(validDocuments);
        
      } catch (error) {
        console.error("Error fetching document details:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchDocuments();
  }, [company_id]);

  const handleViewDocument = (title, url) => {
    setModalTitle(title);
    setModalUrl(url);
    setIsModalOpen(true);
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-semibold">
      <Sidebar />
      <Toast />
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">
            {isMyProfile ? ' Company Documents' : 'Company Documents'}
          </h1>
        </div>
        {/* <ReportContainer /> */}
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-4 p-4">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="flex flex-col font-semibold font-poppins">
            {Object.keys(documentUrls).map((label, index) => (
              <div key={index} className="mb-4">
                <p className="mb-2 text-black font-semibold">{label}</p>
                <div className="flex justify-between items-center p-8 bg-gray-200 rounded-md">
                  <div className="flex items-center">
                    <img src="/Assets/pdf.svg" alt="PDF" className="w-8 h-8 mr-2" />
                    <span className="text-black font-semibold">Document</span>
                  </div>
                  {documentUrls[label] ? (
                    <button
                      onClick={() => handleViewDocument(label, documentUrls[label])}
                      className="text-red-600 underline"
                    >
                      View
                    </button>
                  ) : (
                    <span className="text-gray-500"></span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal for viewing document */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title={modalTitle}>
        <div className="w-full h-full">
          {modalUrl ? (
            <iframe src={modalUrl} title={modalTitle} className="w-full h-full" />
          ) : (
            <p className="text-center">Document not available</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CompanyDocsView;

