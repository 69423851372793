import React from 'react';

const EmailVerifiedMessage = ({ message }) => {
  return (
    <div className="text-center flex justify-center items-center mb-10">
      <h2 className="text-lg font-techna ">{message}</h2>
    </div>
  );
};

export default EmailVerifiedMessage;
