import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CenteredImageContainer from '../Components/black_container';
import InputBox from '../Components/input_box';
import DocumentUpload from '../Components/document_upload';
import MapButton from '../Components/map_button';
import { update_company_details } from '../../Controller/company_details';
import LoaderSpinner from '../Components/loader_spinner';
import iconUp from './icon-up.svg';
import EditPopup from '../Components/edit_profile_pop_up';
import { ScaleLoader } from 'react-spinners';
import GeminiContentGenerator from '../Components/generate_ai';

const EditForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupHeading, setPopupHeading] = useState('');
  const [popupIcon, setPopupIcon] = useState('/pop-up.svg');
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);

  // Retrieve values from localStorage
  const storedCompanyName = localStorage.getItem('name') || '';
  const storedWebsite = localStorage.getItem('website') || '';
  const storedAccountManager = localStorage.getItem('manager') || '';
  const storedOfficeLocation = localStorage.getItem('location') || '';
  const storedBio = localStorage.getItem('Bio') || ''; // Ensure this is declared before use
  const storedAuthorizationDocument = localStorage.getItem('authorization_document') || '';
  const storedTradingLicense = localStorage.getItem('trading_license') || '';
  const storedTenancyContract = localStorage.getItem('tenancy_contract') || '';
  const storedTradingRegister = localStorage.getItem('trading_register') || '';

  const [companyName, setCompanyName] = useState(storedCompanyName);
  const [website, setWebsite] = useState(storedWebsite);
  const [accountManager, setAccountManager] = useState(storedAccountManager);
  const [officeLocation, setOfficeLocation] = useState(storedOfficeLocation);
  const [description, setDescription] = useState(storedBio); // Initialize description here
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false);

  // Log values from localStorage
  useEffect(() => {
    console.log('Stored Company Name:', storedCompanyName);
    console.log('Stored Website:', storedWebsite);
    console.log('Stored Account Manager:', storedAccountManager);
    console.log('Stored Office Location:', storedOfficeLocation);
    console.log('Stored Bio:', storedBio);
  }, []); // Log once on mount

  const handleSelectLocation = (locationDetails) => {
    const { address, latitude, longitude } = locationDetails;
    console.log('Location selected:', locationDetails);
    setOfficeLocation(address);
    setLatitude(latitude);
    setLongitude(longitude);
    setIsAddressSelected(true);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
    setIsDescriptionValid(value.trim().length >= 150); // Validate min length
  };

  const handleSave = async () => {
    setLoading(true);

    // Validate description before submission
    if (description.trim().length < 150) {
      setIsDescriptionValid(false);
      setPopupHeading('Error');
      setPopupMessage('Company Description must be at least 150 characters.');
      setPopupIcon('/error-icon.svg');
      setShowPopup(true);
      setLoading(false);
      return;
    }

    try {
      // Send description as bio in the payload
      const res = await update_company_details(
        companyName,
        website,
        officeLocation,
        latitude,
        longitude,
        accountManager,
        description, // Description sent as bio
        storedAuthorizationDocument,
        storedTradingLicense,
        storedTradingRegister,
        storedTenancyContract,
        false
      );

      if (res.status === 202) {
        navigate('/profile1');
      } else if (res.status === 400) {
        // Show popup for 400 status
        setPopupHeading('Error');
        setPopupMessage('Please wait till your previous edit gets verified.');
        setPopupIcon('/error-icon.svg');
        setShowPopup(true);
      }
    } catch (error) {
      // Check if the error is due to status code 400
      if (error.response && error.response.status === 400) {
        setPopupHeading('Error');
        setPopupMessage('Please wait till your previous edit gets verified.');
        setPopupIcon('/error-icon.svg');
        setShowPopup(true);
      } else {
        console.error('Request failed. Please check your data.', error);
      }
    } finally {
      setLoading(false);
    }
  
  };

  return (
    <div className="flex w-full h-full overflow-y-auto bg-[#FBFCF8] font-gotham">
      <div className="hidden lg:flex w-full lg:w-2/5 h-full overflow-y-auto">
        <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
      </div>
      <div className="flex flex-col w-full lg:w-3/5 h-full p-8">
        <h2 className="text-2xl font-semibold mb-1 font-gotham">Edit</h2>
        <p className="text-lg mb-6 text-gray-500 font-gotham">Fill the field to update the form</p>

        <div className="bg-white shadow-lg rounded-lg p-8 overflow-y-auto">
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
            <div className="lg:col-span-1">
              <div className="flex flex-col">
                <label className="text-[#989898] mb-2 font-gotham">Company name</label>
                <InputBox
                  placeholder="Company name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="font-gotham"
                />
              </div>

              <div className="flex flex-col mt-6">
                <label className="text-[#989898] mb-2 font-gotham">Website</label>
                <InputBox
                  placeholder="www.eswaf.com"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  className="font-gotham"
                />
              </div>

              <div className="flex flex-col mt-6">
                <label className="text-[#989898] mb-2 font-gotham">Account managed by</label>
                <InputBox
                  placeholder="Name"
                  value={accountManager}
                  onChange={(e) => setAccountManager(e.target.value)}
                  className="font-gotham"
                />
              </div>
            </div>

            <div className="lg:col-span-1 flex flex-col">
              <label className="text-[#989898] mb-2 font-gotham">Office Location</label>
              <div className="flex flex-col">
                <InputBox
                  placeholder="Ernakulam, Edapally, Kerala"
                  value={officeLocation}
                  onChange={(e) => setOfficeLocation(e.target.value)}
                  className="font-gotham"
                />
                <div className="w-full mt-4">
                  <MapButton onAddressSelect={handleSelectLocation} padding="15px" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-6">
            <label className="text-[#989898] mb-2 font-gotham">Company Description</label>
            <GeminiContentGenerator
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Describe your company in brief"
              minLength={150}
              maxLength={200}
            />
            {!isDescriptionValid && (
              <p className="text-red-600 text-sm mt-1">Minimum 150 characters required.</p>
            )}
          </div>

          <h3 className="text-xl font-semibold mb-4 font-gotham">Upload Documents</h3>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <DocumentUpload
              label="Business License"
              existingFile={storedTradingLicense}
              documentType="trading_license"
              imgSrc="/Assets/icon-up.svg"
            />
            <DocumentUpload
              label="Tenancy Contract"
              existingFile={storedTenancyContract}
              documentType="tenancy_contract"
              imgSrc="/Assets/icon-up.svg"
            />
            <DocumentUpload
              label="Trading Register"
              existingFile={storedTradingRegister}
              documentType="trading_register"
              imgSrc="/Assets/icon-up.svg"
            />
          </div>

          <button
            className="bg-[#DB0000] text-white px-6 py-3 mt-8 rounded-lg w-full flex justify-center items-center font-gotham"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? <ScaleLoader color="#FFFFFF" height={10} /> : 'Save'}
          </button>
        </div>
      </div>

      <EditPopup
        show={showPopup}
        onClose={() => setShowPopup(false)}
        heading={popupHeading}
        message={popupMessage}
        icon={popupIcon}
      />
    </div>
  );
};

export default EditForm;
