import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import OTPInputComponent from '../../Components/otp_input';
import { useNavigate } from 'react-router-dom';
import ReportContainer from '../../Components/report';

const VerificationWithOTP = () => {
  const location = useLocation();
  const isVerified = location.pathname.includes('verified');
  const navigate = useNavigate();

  const profileData = {
    storeName: 'Electronics Store',
    category: 'Electronics',
    location: 'Kerala, India',
    establishedOn: '12 Feb 2004',
    userSince: '15 March 2024',
    verifiedOn: '17 March 2024',
    validityDate: '17 March 2029',
    logoUrl: './electronics.svg',
  };

  const handleBackClick = () => {
   navigate('/verified-subscription')


  };

  return (
    <>
      {/* <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna "> */}
      <div className="h-full w-full flex flex-col overflow-y-auto">
      <Sidebar />
      <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
            <div className="w-full flex justify-start items-center space-x-2">
                
              <img
                src="/Assets/arrow_back.svg"
                alt="Back"
                className="cursor-pointer mr-2 h-6 w-6"
                onClick={handleBackClick}
              />
              <h1 className="text-black font-bold text-xl"> {isVerified ? 'Verified Profile' : 'Verification'}</h1>   </div>
              <ReportContainer/>
          </div>
     
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="flex flex-col mx-auto items-center mt-10 lg:ml-7 lg:w-4/5  overflow-auto">
       

        <main className="flex-grow p-4 w-full flex justify-center ">
          <div className="p-4  rounded-lg max-w-lg w-full">
            <div className="flex flex-col mb-4">
              <img src={profileData.logoUrl} alt="logo" className="w-28 h-28 mb-4" />
              <div className="mb-5">
                <div className="flex justify-between ">
                  <h2 className="text-xl font-bold">{profileData.storeName}</h2>
                  {isVerified && (
                    <div className="flex items-center">
                      <img
                        src="./tick (2).svg"
                        alt="Verified"
                        className="h-6 w-6 mr-1"
                      />
                      <span className="text-xs text-red-600">Verified</span>
                    </div>
                  )}
                </div>
                <p className="text-sm font-bold">{profileData.category}</p>
                <p className="text-base font-bold">{profileData.location}</p>
              </div>
            </div>
           
              <div className="lg:pr-16">
                <OTPInputComponent /> 
              </div>
              <div className=" rounded-md mb-4 text-left">
              <p className="text-sm mt-7 ml-2" style={{ color: '#535353' }}>

               Please enter the OTP we sent you by post
              </p>
            </div>
            <div className="flex justify-center pt-12">
              <Button 
                text="next" 
                navigateTo="/verification-success"
                backgroundColor="bg-custom-button-color" 
                textColor="text-white" 
                className="w-3/4 md:w-3/4 md:ml-4"
              />
            </div>
          </div>
        </main>
      </div>
      </div>
    </>
  );
};

export default VerificationWithOTP;
