import React, { useState } from 'react';
import MapDialog from './map';

const MapButton = ({ padding, onAddressSelect, disabled }) => {
  const [showMap, setShowMap] = useState(false);

  const handleLocationSelected = (location) => {
    onAddressSelect(location); // Pass the entire location details back to the parent
    setShowMap(false); // Close the map dialog after selecting the location
  };

  return (
    <div>
      <button
        onClick={() => setShowMap(true)}
        className="bg-[#DB0000] text-white rounded-sm border text-xs font-techna"
        style={{ borderRadius: '8px', padding }}
        disabled={disabled}
      >
        Find on Maps
      </button>
      <MapDialog open={showMap} onClose={() => setShowMap(false)} onSelectLocation={handleLocationSelected} />
    </div>
  );
};

export default MapButton;
