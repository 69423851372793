import { api } from './api'; // Assuming you're using axios or another service for API calls
import { GET_OTHER_PROFILE } from './urls';

export const getOtherProfileById = async (company_id) => {
  try {
    const res = await api.get(GET_OTHER_PROFILE(company_id));
    return res; // Return the response, you can adjust it if needed
  } catch (error) {
    console.error("Error fetching company profile by ID:", error);
    throw error; // Re-throw the error for further handling
  }
};


