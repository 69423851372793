import React from 'react';

// StarRating component
const StarRating = ({ review }) => {
  const maxStars = 5;
  const filledStars = Math.floor(review); // Full stars
  const hasHalfStar = review % 1 >= 0.25 && review % 1 <= 0.75; // Half star logic
  const emptyStars = maxStars - filledStars - (hasHalfStar ? 1 : 0); // Remaining stars

  return (
    <div className="flex">
      {/* Render filled stars */}
      {[...Array(filledStars)].map((_, index) => (
        <img key={index} src="/Assets/filled_star.svg" alt="Filled Star" className="lg:w-5 lg:h-5 w-4 h-4" />
      ))}
      
      {/* Render half star if applicable */}
      {hasHalfStar && (
        <img src="/Assets/half_star.svg" alt="Half Star" className="lg:w-5 lg:h-5 w-4 h-4" />
      )}

      {/* Render empty stars */}
      {[...Array(emptyStars)].map((_, index) => (
        <img key={index} src="/Assets/unfilled_star.svg" alt="Empty Star" className="lg:w-5 lg:h-5 w-4 h-4" />
      ))}
    </div>
  );
};

export default StarRating;
