
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import FileUpload from '../../Components/image_upload';
import InputBox from '../../Components/input_box';
import TextArea from '../../Components/text_area';
import { uploadMedia } from '../../../Controller/company_products';
import { showToast } from '../../Components/toast'; // Import the toast function

const CompanyProduct = () => {
    const navigate = useNavigate();
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleBackClick = () => {
        navigate('/view_photos');
    };

    // const handleUploadMedia = async () => {
    //     // Trim productName and description to avoid empty spaces
    //     const trimmedProductName = productName.trim();
    //     const trimmedDescription = description.trim();

    //     // Validation for fields
    //     if (!trimmedProductName || !trimmedDescription || images.length === 0) {
    //         showToast('Please fill all fields and upload at least one image.'); // Show toast instead of alert
    //         return;
    //     }

    //     setLoading(true);

    //     try {
    //         const imageUrlArray = images.map(img => img.url);
    //         const response = await uploadMedia(trimmedProductName, trimmedDescription, imageUrlArray);
    //         if (response.status === 201) {
    //             console.log('Media uploaded successfully:', response.data);
    //             navigate("/view_photos");
    //         } else {
    //             console.error("Error uploading media:", response);
    //             showToast('Failed to upload media.'); // Show toast for failure
    //         }
    //     } catch (error) {
    //         console.error("Error uploading media:", error);
    //         showToast('An error occurred during upload.'); // Show toast for error
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleUploadMedia = async () => {
        // Trim productName and description to avoid empty spaces
        const trimmedProductName = productName.trim();
        const trimmedDescription = description.trim();
    
        // Validation for fields
        if (!trimmedProductName || !trimmedDescription || images.length === 0) {
            showToast('Please fill all fields and upload at least one image.'); // Show toast instead of alert
            return;
        }
    
        // Check if description has at least 30 characters
        if (trimmedDescription.length < 30) {
            showToast('Description must be at least 30 characters long.'); // Show validation error
            return;
        }
    
        setLoading(true);
    
        try {
            const imageUrlArray = images.map(img => img.url);
            const response = await uploadMedia(trimmedProductName, trimmedDescription, imageUrlArray);
            if (response.status === 201) {
                console.log('Media uploaded successfully:', response.data);
                navigate("/view_photos");
            } else {
                console.error("Error uploading media:", response);
                showToast('Failed to upload media.'); // Show toast for failure
            }
        } catch (error) {
            console.error("Error uploading media:", error);
            showToast('An error occurred during upload.'); // Show toast for error
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
            <Sidebar />
            <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
                <div className="flex items-center space-x-2">
                    <h1 className="text-black font-semibold text-xl">Create Post</h1>
                </div>
            </div>
            <div className="border-b border-black mt-4 md:mt-[25px]"></div>
            <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-1 mx-4 ">
                <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
                    {/* Product Name Field */}
                    <div className="mt-8">
                        <p className="font-semibold text-sm lg:text-[16px]">Product/Service Name</p>
                        <div className='mt-2'>
                        <InputBox
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            placeholder="Enter the product/service"
                            borderRadius="1px"
                            maxLength={50}
                     

                        />
                           </div>
                    </div>

                    {/* Description Field */}
                    <div className="mt-3">
                        <p className="font-semibold text-sm lg:text-[16px]">Description</p>
                        <TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter the product/service description"
                            className="mt-2"
                            maxLength={200}
                        />
                    </div>

                    {/* File Upload Component with maxFiles prop */}
                    <FileUpload
                        files={images}
                        setFiles={setImages}
                        svgImagePath="/Assets/grey_plus.svg"
                        bgColor="#E6E6E6"
                        borderRadius="8px"
                        width="90px"
                        height="90px"
                        maxFiles={3} // Set the maximum number of files to be uploaded to 3
                    />

                    {/* Display Loading or Next Button */}
                    <div className="mt-24">
                        <Button
                            text='Next'
                            borderColor="border-red-600"
                            backgroundColor="bg-[#DB0000]"
                            textColor="text-white"
                            onClick={handleUploadMedia}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyProduct;
