// import { auth } from '../../firebase_service';
// import { signInWithPhoneNumber } from 'firebase/auth';
// import { RecaptchaVerifier } from 'firebase/auth';



// export const sendOTP = (phoneNumber, setFinal, setShow) => {
//   // Initialize reCAPTCHA
//   const verify = new RecaptchaVerifier('recaptcha-container', {
//     'size': 'invisible',
//     'callback': (response) => {
//       console.log("Captcha Completed");
//     }
//   }, auth);

//   signInWithPhoneNumber(auth, phoneNumber, verify)
//     .then((result) => {
//       setFinal(result);
//       alert("Code sent");
//       setShow(true);
//     })
//     .catch((err) => {
//       alert(err.message);
//       window.location.reload();
//     });
// };

// // Function to validate OTP
// export const validateOtp = async (confirmationResult, otp) => {
//   try {
//     const result = await confirmationResult.confirm(otp);
//     return result;
//   } catch (error) {
//     console.error("Error verifying OTP: ", error);
//     throw error;
//   }
// };
import { auth } from '../../firebase_service';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';

export const sendOTP = async (phoneNumber) => {
  try {
    await auth.signOut();
    console.log("Signed out successfully");

    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier = null;
  }
  
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          console.log("Captcha Completed");
        }
      });
    }
    console.log("RecaptchaVerifier:", window.recaptchaVerifier);

    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
    console.log("Confirmation result:", confirmationResult);
    
    return confirmationResult;

  } catch (error) {
    console.error("Full error object:", error);
    console.error("Error code:", error.code);
    console.error("Error message:", error.message);
    if (error.serverResponse) {
      console.error("Server response:", error.serverResponse);
    }
    throw error; 
  }
};