// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import OTPInputComponent from "../../Components/otp_input";
// import Button from "../../Components/button";
// import { Toast } from "../../Components/toast";
// import CenteredImageContainer from "../../Components/black_container";
// import { verifyOtpAndNavigate } from "../../../Controller/reset_otp_page_controller";
// const ResetOtpPassword = () => {
//     const navigate = useNavigate();
//     const [otp, setOtp] = useState('');
//     const [email, setEmail] = useState('');
//     const [loading, setLoading] = useState(false); 

//     useEffect(() => {
//         const emailFromStorage = localStorage.getItem('email');
//         if (emailFromStorage) {
//             setEmail(emailFromStorage);
//         }
//     }, []);

//     const handleChange = (otp) => {
//         setOtp(otp);
//     };

//     const handleSubmit = async () => {
//         verifyOtpAndNavigate(email, otp, navigate, setLoading); // Call the controller function
//     };

//     return (
//         <div className="h-full w-full flex bg-[#FBFCF8] font-poppins">
//             <Toast />
//             <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
//                 <div className='w-4/5 h-full'> 
//                     <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
//                 </div>
//             </div>
//             <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-4 font-poppins">
//                 <div className="lg:p-6 p-4 w-full max-w-2/5">
//                     <div className="flex items-center mb-6">
//                         <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
//                     </div>
//                     <h2 className="text-3xl font-semibold text-[#0D0907] mb-6">Email verification</h2>
//                     <p className="text-sm text-[#9F9F9F] mb-6">Please enter the OTP sent to your mail</p>
//                     <div className="flex justify-center items-center mb-4">
//                         <OTPInputComponent otp={otp} handleChange={handleChange} />
//                     </div>
//                     <p className="flex justify-center text-sm mb-4">
//                         Didn't receive the code? <span className="text-red-600 cursor-pointer font-bold">Re-send</span>
//                     </p>
//                     <div className='mt-10'>
//                         <Button
//                             text="Save"
//                             backgroundColor="bg-[#DB0000]"
//                             textColor="text-white"
//                             onClick={handleSubmit}
//                             loading={loading}
//                         />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ResetOtpPassword;
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OTPInputComponent from "../../Components/otp_input";
import Button from "../../Components/button";
import { Toast, showToast } from "../../Components/toast"; // Ensure showToast is imported
import CenteredImageContainer from "../../Components/black_container";
import ResendLink from "../../Components/resend-link";// Import ResendLink component
import { verifyOtpAndNavigate } from "../../../Controller/reset_otp_page_controller";
import { check_email } from "../../../Controller/auth";// Import your resend OTP function
import { resetPasswordMail } from "../../../Controller/auth";


const ResetOtpPassword = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false); 
    const [hasError, setHasError] = useState(false); // State for handling resend errors
    const [isLoading, setIsLoading] = useState(false); // State for the resend link loading

    useEffect(() => {
        const emailFromStorage = localStorage.getItem('email');
        if (emailFromStorage) {
            setEmail(emailFromStorage);
        }
    }, []);

    const handleChange = (otp) => {
        setOtp(otp);
    };

    const handleSubmit = async () => {
        setLoading(true);
        await verifyOtpAndNavigate(email, otp, navigate, setLoading); // Call the controller function
        setLoading(false);
    };

    // Handle resend link click
    // Handle resend link click
// Handle resend link click
const handleResend = async () => {
    if (!email) {
        showToast('Email address is missing. Please try again.', 'error');
        setHasError(true); // Set error if email is missing
        return false; // Return false to avoid restarting timer
    }

    setIsLoading(true); // Start resend loading
    try {
        const response = await resetPasswordMail(email); // Call your resend OTP function
        
        // Log the response data from Axios
        console.log('API Response:', response);

        if (response.status === 200) { // Axios response status
            return true; // Return true to restart the timer
        } else {
            throw new Error('Failed to resend OTP');
        }
    } catch (error) {
        console.error('Error resending OTP:', error);
        return false; // Return false to avoid restarting timer
    } finally {
        setIsLoading(false); // Stop loading after API call
    }
};



    return (
        <div className="h-full w-full flex bg-[#FBFCF8] font-poppins">
            <Toast />
            <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
                <div className='w-4/5 h-full'> 
                    <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
                </div>
            </div>
            <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-4 font-poppins">
                <div className="lg:p-6 p-4 w-full max-w-2/5">
                    <div className="flex items-center mb-6">
                        <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
                    </div>
                    <h2 className="text-3xl font-semibold text-[#0D0907] mb-6">Email verification</h2>
                    <p className="text-sm text-[#9F9F9F] mb-6">Please enter the OTP sent to your mail</p>
                    <div className="flex justify-center items-center mb-4">
                        <OTPInputComponent otp={otp} handleChange={handleChange} />
                    </div>

                    {/* ResendLink Component */}
                    <div className="flex justify-center text-sm mb-4">
                    <ResendLink
                        linkText="Re-send"
                        onResend={handleResend} // Pass the resend function here
                        hasError={hasError} // Pass error state to disable link if needed
                    />
                    </div>

                    <div className='mt-10'>
                        <Button
                            text="Save"
                            backgroundColor="bg-[#DB0000]"
                            textColor="text-white"
                            onClick={handleSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetOtpPassword;
