import React, { useState } from 'react';
import { FaEllipsisV, FaEnvelope } from 'react-icons/fa'; // Import three-dot icon and message icon
import { useNavigate } from 'react-router-dom';
import { categoryMapping } from './category';
import { checkIfChatExists,createNewChat } from '../../services/chatservice';
import Modal from './pdf_opener';

const PostDetails = ({ post, onPostDeleted, isMyPosts,openPdfModal }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
const [pdfUrl, setPdfUrl] = useState(null);

  const navigate = useNavigate();

  if (!post) {
    return <div>No post details available</div>; // Safeguard against null post
  }

  const handleDelete = async () => {
    try {
      await onPostDeleted(post.id); // Call the delete function passed from PostList
      setShowMenu(false); // Hide the menu after deletion
    } catch (error) {
      console.error('Failed to delete post:', error);
    }
  };

  const handleNavigateToProfile = () => {
    if (post.company_id) {
      navigate(`/profile/${post.company_id}`); // Navigate to the profile page with the company_uid
    }
  };



  const handleSendMessage = async () => {
    const company_uid = post.company_uid;
    const currentUserUid = localStorage.getItem('company_uid');
    
    if (!company_uid || !currentUserUid) {
      console.error('User UID or company UID is missing.');
      return;
    }

    try {
      // Check if a chat already exists
      const existingChat = await checkIfChatExists(company_uid);
      
      if (existingChat) {
        // Navigate to existing chat
        navigate(`/direct_message/chat/${existingChat.chatId}`);
      } else {
        // Create new chat with all required parameters
        const newChatId = await createNewChat(
          company_uid,                    // receiver UID
          post.company_name || 'Unknown', // receiver name
          post.profile_image_url || '',   // receiver profile image
          post.company_id || ''           // receiver company ID
        );
        
        if (newChatId) {
          navigate(`/direct_message/chat/${newChatId}`);
        } else {
          console.error('Failed to create chat: Chat ID not returned');
        }
      }
    } catch (error) {
      console.error('Error handling message click:', error);
      // Optionally show error to user via toast/alert
    }
  };



  const handleViewDocument = () => {
    if (post.detail_document) {
      openPdfModal(post.detail_document); // Use openPdfModal function to set the PDF URL and open modal
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg w-full mb-4 flex flex-col items-center">
      {/* Top section with three-dot menu and Message button */}
      <div className="relative bg-gray-100 rounded-t-lg p-4 w-full flex items-center justify-between">
        {/* Left section: Profile Image and Name */}
        <div
          className={`flex items-center ${!isMyPosts ? 'cursor-pointer' : ''}`}
          onClick={!isMyPosts ? handleNavigateToProfile : null} // Navigate to profile when not own post
        >
          <img
            src={post.profile_image_url || '/profile.webp'}
            alt="Company Logo"
            className="rounded-full w-[40px] h-[40px] mr-3"
          />
          <div>
            <h2 className="font-semibold text-lg">{post.company_name}</h2>
          </div>
        </div>

        {/* Right section: Message icon for other posts */}
        <div className="flex items-center">
          {!isMyPosts && (
           <img
           src="/Assets/message-icon.svg"
           alt="Message Icon"
           onClick={handleSendMessage}
           className="cursor-pointer w-7 h-7 ml-3"
           title="Message"
         />
          )}

          {isMyPosts && (
            <>
              <FaEllipsisV
                className="cursor-pointer text-gray-500 text-2xl ml-3"
                onClick={() => setShowMenu(!showMenu)}
              />
              {showMenu && (
                <div className="absolute top-8 right-4 w-24 bg-white shadow-lg rounded-lg z-10">
                  <div
                    className="cursor-pointer text-sm p-2 hover:bg-gray-200"
                    onClick={handleDelete}
                  >
                    Delete
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Post details content */}
      <div className="w-full p-4">
        <div className="mt-4">
          {/* Product Name */}
          <div className="mb-2">
            <span className="text-sm font-medium text-gray-700">Product Name</span>
            <div className="text-sm font-normal text-gray-900">{post.name}</div>
          </div>

          {/* Category / Trade Role */}
          <div className="mb-2">
            <span className="text-sm font-medium text-gray-700">Trade Role</span>
            <div className="text-sm font-normal text-gray-900">
              {categoryMapping[post.category] || 'Unknown Category'} {/* Map category ID to name */}
            </div>
          </div>

          {/* Quantity */}
          <div className="mb-2">
            <span className="text-sm font-medium text-gray-700">Quantity</span>
            <div className="text-sm font-normal text-gray-900">{post.quantity}</div>
          </div>

          {/* Detailed Requirement */}
          <div className="mb-2">
            <span className="text-sm font-medium text-gray-700">Detailed Requirement</span>
            <div className="text-sm font-normal text-gray-900">{post.details || 'N/A'}</div>
          </div>
        </div>

        {/* PDF Document Section */}
        {post.detail_document && (
          <div className="mt-6 flex items-center justify-between bg-gray-100 p-4 rounded-lg">
            <div className="flex items-center">
              <img src="/Assets/pdf.svg" alt="PDF" className="w-8 h-8 mr-3" />
              <span className="text-sm font-medium text-gray-700">Document</span>
            </div>
            {/* <button
                  onClick={() => {
                   setPdfUrl(post.detail_document);
                   setIsPdfModalOpen(true);
                  }}
                 className="text-sm font-medium text-red-600 underline"
                  >
                 View
                   </button> */}
                    <button onClick={handleViewDocument} className="text-sm font-medium text-red-600 underline">
              View
            </button>
                 
          </div>
        )}
      </div>
     
    </div>
    
    
  );

};

export default PostDetails;
