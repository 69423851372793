import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

const FailurePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isFailure = location.pathname.includes('failure');

  useEffect(() => {
    if (isFailure) {
      const timeout = setTimeout(() => {
        navigate('/subscription_plans', { state: { paymentFailed: true } });
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [isFailure, navigate]);

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-white text-gray-400">
      <FaSpinner className="text-3xl animate-spin" />
      <div className="mt-4 text-xl font-bold">Redirecting...</div>
    </div>
  );
};

export default FailurePage;
