

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import Photoupload from '../../Components/file_upload_container';
import { createVerificationImage, updateVerificationImage } from '../../../Controller/verification';
import { showToast, Toast } from '../../Components/toast';

const CompanyPhotoManagement = ({ headingofcontent, paragraph, nextPath, verificationType }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [existingImageId, setExistingImageId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const storedPhotos = JSON.parse(localStorage.getItem('existingPhotos'));
    const existingData = storedPhotos?.find(photo => photo.verification_type === verificationType);
    if (existingData?.image_url) {
      setFiles([{ url: existingData.image_url }]);
      setExistingImageId(existingData.id);
    }
  }, [verificationType]);
  const handleSubmit = async () => {
    if (files.length === 0) {
      showToast('Please upload an image.', 'error');
      return;
    }
  
    setLoading(true);
    try {
      const image_url = files[0].url;
      let response;
  
      if (existingImageId) {
        // Update case (PUT)
        response = await updateVerificationImage(existingImageId, image_url);
        if (response) {
          // Update local storage
          const updatedPhotos = JSON.parse(localStorage.getItem('existingPhotos')) || [];
          const updatedPhotosList = updatedPhotos.map(photo => {
            if (photo.id === existingImageId) {
              return { ...photo, image_url: image_url };
            }
            return photo;
          });
          localStorage.setItem('existingPhotos', JSON.stringify(updatedPhotosList));
          showToast('Image updated successfully.', 'success');
        }
      } else {
        // Create new case (POST)
        response = await createVerificationImage(image_url, verificationType);
        if (response?.status === 201) {
          // Add the new photo to local storage
          const updatedPhotos = JSON.parse(localStorage.getItem('existingPhotos')) || [];
          updatedPhotos.push({
            id: response.data.id, // Assuming response contains the new image ID
            image_url: image_url,
            verification_type: verificationType,
            is_deleted: false,
          });
          localStorage.setItem('existingPhotos', JSON.stringify(updatedPhotos));
        
        }
      }
  
      navigate(nextPath);
    } catch (error) {
      console.error('Failed to save/update verification image:', error);
    
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleFileChange = (newFiles) => {
    setFiles(newFiles);
  
    if (newFiles.length > 0 && newFiles[0].url !== files[0]?.url) {
      if (existingImageId) {
        // The existing image ID should remain intact if it's just an update with a new image
        // Only reset it if the verification type or image has been removed entirely
        const updatedPhotos = JSON.parse(localStorage.getItem('existingPhotos')) || [];
        const updatedPhotosList = updatedPhotos.map(photo => {
          if (photo.verification_type === verificationType) {
            // Replace the existing photo with the new file, but retain the existingImageId
            return { ...photo, image_url: newFiles[0].url, is_deleted: false };
          }
          return photo;
        });
        localStorage.setItem('existingPhotos', JSON.stringify(updatedPhotosList));
      } else {
        setExistingImageId(null); // Reset only when there's no existing image ID
      }
    }
  };
  
  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
      <Sidebar />
      <Toast />
      <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
        <div className="flex items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">
            Company Photos
          </h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-1 mx-4">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg mt-8 lg:mt-10">
          <p className="mt-1 font-semibold text-[16px] lg:text-xl">{headingofcontent}</p>
          
          <p className="flex items-center justify-center mt-2 text-[12px] lg:text-sm font-gotham text-gray-700 font-normal">
            ({paragraph})
          </p>
          
          <div className="mt-8 mb-6 ml-2 flex space-x-4 justify-center">
            <Photoupload
            files={files}
              setFiles={handleFileChange}
              svgImagePath="Assets/grey_plus.svg"
              editIconPath="Assets/edit.svg" // Pass edit icon path here
              bgColor="#E6E6E6"
              borderRadius="8px"
              width="150px"
              height="220px"
              type="image"
            />
          </div>
          <div className="mt-16">
            <Button
              text={existingImageId ? "Update" : "Next"}
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSubmit}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyPhotoManagement;
