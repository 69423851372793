import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { FaTimes } from 'react-icons/fa';

const CompanyHeader = ({ setSearch, onLocationSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [locationQuery, setLocationQuery] = useState('');

  // Handle search query change
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setSearch(query); // Pass the search query to the parent component
  };

  // Handle location query change
  const handleLocationChange = (value) => {
    setLocationQuery(value);
  };

  // Handle location selection and get lat/lng
  const handleSelectLocation = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(results[0]);
      setLocationQuery(address); 
      onLocationSelect(lat, lng); 
    } catch (error) {
      console.error('Error selecting location:', error);
    }
  };

  return (
    <div className="p-4 relative">
      <div className="flex flex-col md:flex-row items-center md:ml-4 md:space-x-4 w-full h-1/2 mt-6 lg:mt-8">
        
        {/* Search Input */}
        <div className="relative flex items-center w-full md:w-1/3">
          <input
            type="text"
            value={searchQuery}
            placeholder="Search"
            className="p-1 md:p-2 pr-10 w-full border border-gray-400 rounded bg-[#FFFFFF]"
            onChange={handleSearchChange}
          />
        </div>

        {/* Location Input with Google Places Autocomplete */}
        <div className="relative flex items-center w-full md:w-1/3 mt-4 md:mt-0">
          <PlacesAutocomplete
            value={locationQuery}
            onChange={handleLocationChange}
            onSelect={handleSelectLocation}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="relative w-full">
                <input
                  {...getInputProps({
                    placeholder: 'Location',
                    className: 'p-1 md:p-2 pr-10 w-full border border-gray-400 rounded bg-[#FFFFFF]',
                  })}
                />
                <img
                  src="./location.svg"
                  alt="Location Icon"
                  className="absolute right-2 bottom-3 lg:h-5 w-5 h-4 w-4"
                />

                {/* Add a clear button */}
                {locationQuery && (
                  <button
                    className="absolute right-8 bottom-3 p-1"
                    onClick={() => {
                      setLocationQuery('');
                      onLocationSelect('', ''); // Clear lat and lon
                    }}
                  >
                    <FaTimes className="text-gray-400" style={{ fontSize: '14px' }} />
                  </button>
                )}

                {/* Location Suggestions Dropdown */}
                <div className="absolute top-full left-0 right-0 bg-white rounded-md overflow-y-auto z-50">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, index) => (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: suggestion.active ? 'suggestion-item--active' : 'suggestion-item',
                        style: suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' },
                      })}
                      key={index}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      </div>
    </div>
  );
};

export default CompanyHeader;
