import { POST_REPORT,POST_COMPANY_REPORT} from "./urls";
import { api } from "./api";

const post_report= async (title,description,image) => {
    const res = await api.post(POST_REPORT, {
      title: title,
      description: description,
      image: image
     

    });
    return res; 
  };
  const post_company_report = async (reporting_uid, title, description, image_url) => {
    const res = await api.post(
      `${POST_COMPANY_REPORT}?reporting_uid=${reporting_uid}`, // Append reporting_uid as query parameter
      {
        title: title,
        description: description,
        image_url: image_url // Send the image_url array in the body
      }
    );
    return res;
  };

  export  {post_report,post_company_report}