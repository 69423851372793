import React from 'react';

const VideoCard = ({ videoId, thumbnail, onClick }) => {
  return (
    <div className="rounded-lg w-full h-64 cursor-pointer" onClick={onClick}> {/* Change h-56 to control the height */}
      <img
        src={thumbnail}
        alt="Thumbnail"
        className="w-full h-full object-cover rounded-lg"
      />
      <div className="absolute bottom-2 left-2 flex items-center space-x-4">
        {/* Add your other elements */}
      </div>
    </div>
  );
};


export default VideoCard;
