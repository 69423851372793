import React from 'react';
import './multi_select.css';

const CategorySelectList = ({ categories, onChange, selectedOption }) => {
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (selectedOption === value) {
      onChange('');
    } else {
      onChange(value);
    }
  };

  return (
    <div className="flex flex-col items-start space-y-2 font-poppins" style={{ fontWeight: '400' }}>
      {categories.map(option => (
        <label key={option} className="flex items-center h-12 text-[#545454] ">
          <input
            type="checkbox"
            value={option}
            checked={selectedOption === option}
            onChange={handleCheckboxChange}
            className="form-checkbox text-red-500 h-4 w-4 rounded-full"
          />
          <span className="ml-2 text-base lg:text-lg ">{option}</span>
        </label>
      ))}
      <label className="flex items-center h-12">
        <input
          type="checkbox"
          value="other"
          checked={selectedOption === 'other'}
          onChange={handleCheckboxChange}
          className="form-checkbox text-red-500 h-4 w-4 rounded-full"
        />
        <span className="ml-2 lg:text-lg text-[#545454] text-base">Other</span>
      </label>
    </div>
  );
};

export default CategorySelectList;
