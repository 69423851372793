// import React, { useState } from 'react';
// import uploadFiles from '../../Controller/firebase/firebase_upload';
// import LoaderSpinner from './loader_spinner';

// const FileUpload = ({ files, setFiles, svgImagePath, bgColor, borderRadius, width, height, type }) => {
//   const [loading, setLoading] = useState(false);

//   const handleFileUpload = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setLoading(true);
//       try {
//         const downloadURL = await uploadFiles(file);
//         setFiles([...files, { file, url: downloadURL }]);
//       } catch (error) {
//         console.error("Error uploading file:", error);
//       } finally {
//         setLoading(false);
//       }
//     }
//   };

//   const removeFile = (index) => {
//     setFiles(files.filter((_, i) => i !== index));
//   };

//   return (
//     <div className="flex">
//       {files.length > 0 ? (
//         files.map((file, index) => (
//           <div key={index} className="relative" style={{ width: width || '80px', height: height || '90px' }}>
//             <img src={file.url} alt="upload" className="object-cover w-full h-full" style={{ borderRadius: borderRadius || '8px', backgroundColor: bgColor || '#E6E6E6' }} />
//             <button
//               className="absolute top-0 right-0 text-white rounded-full p-1"
//               onClick={() => removeFile(index)}
//             >
//               &times;
//             </button>
//           </div>
//         ))
//       ) : (
//         <label
//           htmlFor="file-upload"
//           className="cursor-pointer flex items-center justify-center"
//           style={{
//             backgroundColor: bgColor || '#C1C1C1',
//             borderRadius: borderRadius || '8px',
//             width: width || '80px',
//             height: height || '90px',
//           }}
//         >
//           {loading ? (
//             <LoaderSpinner visible={true} />
//           ) : (
//             <>
//               <img src={svgImagePath} alt="Upload" className="w-5 h-5" />
//               <input type="file" accept={type === 'image' ? 'image/*' : 'video/*'} onChange={handleFileUpload} className="hidden" id="file-upload" />
//             </>
//           )}
//         </label>
//       )}
//     </div>
//   );
// };

// export default FileUpload;
import React, { useState } from 'react';
import uploadImage from '../../Controller/firebase/firebase_upload_image';
import LoaderSpinner from './loader_spinner';

const FileUpload = ({ files, setFiles, svgImagePath, bgColor, borderRadius, width, height, maxFiles = 1 }) => {
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (e) => {
    const uploadedFiles = Array.from(e.target.files);
    setLoading(true);

    try {
      // Upload all selected files and retrieve their URLs
      const uploadedFileData = await Promise.all(
        uploadedFiles.map(async (file) => {
          const downloadURL = await uploadImage(file);
          return { file, url: downloadURL };
        })
      );

      // Append new files to the existing list of files up to the maximum limit
      const updatedFiles = [...files, ...uploadedFileData].slice(0, maxFiles);
      setFiles(updatedFiles);
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <div className="flex">
      {/* File Upload Button */}
      <label
        htmlFor="file-upload"
        className={`cursor-pointer flex items-center justify-center ${files.length >= maxFiles ? 'pointer-events-none opacity-50' : ''}`}
        style={{
          backgroundColor: bgColor || '#C1C1C1',
          borderRadius: borderRadius || '8px',
          width: width || '80px',
          height: height || '90px',
        }}
      >
        {loading ? (
          <LoaderSpinner visible={true} />
        ) : (
          <>
            <img src={svgImagePath} alt="Upload" className="w-5 h-5" />
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileUpload}
              className="hidden"
              id="file-upload"
              disabled={files.length >= maxFiles} // Disable input when maxFiles is reached
            />
          </>
        )}
      </label>

      {/* Display uploaded files */}
      <div className="flex ml-4 space-x-4">
        {files.map((file, index) => (
          <div key={index} className="relative" style={{ width: width || '80px', height: height || '90px' }}>
            <img
              src={file.url}
              alt="upload"
              className="object-cover w-full h-full"
              style={{ borderRadius: borderRadius || '8px', backgroundColor: bgColor || '#E6E6E6' }}
            />
            <button
              className="absolute top-0 right-0 text-white rounded-full p-1"
              onClick={() => removeFile(index)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUpload;
