// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import Button from '../Components/button';

// const VerificationStatus = ({ verificationStatus, onClose }) => {
//   const navigate = useNavigate();

//   const handleResubmit = () => {
//     navigate('/update-form');
//   };

//   const handleNext = () => {
//     navigate('/');
//   };

//   if (!verificationStatus) {
//     return null; // Optionally, you can add a loading spinner here
//   }

//   return (
//     <>
//       {verificationStatus && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60 overlay"></div>
//       )}

//       <div className="fixed inset-0 flex items-center justify-center overlay">
//         <div className="bg-[#F2F2F2] p-8 rounded-lg shadow-lg flex flex-col sm:flex-row items-center space-y-5 sm:space-y-0 sm:space-x-5 h-auto sm:h-1/2 mx-8 relative">
//           <button
//             onClick={onClose}
//             className="absolute top-2 right-2 text-gray-600 hover:text-gray-900   flex items-center justify-center text-2xl"
//           >
//             &times;
//           </button>

//           {verificationStatus === 'VERIFICATION_PENDING' && (
//             <>
//               <div className="max-w-md text-center sm:text-left">
//                 <h1 className="text-2xl font-bold text-gray-900">Thank you for completing your profile.Your profile is under verification.</h1>
//                 <p className="mt-4 text-gray-600">We will let you know once the verification process is completed.</p>
//               </div>
//               <div>
//                 <img src="/Assets/profile_verification.svg" alt="Verification" className="w-60 h-60" />
//               </div>
//             </>
//           )}

//           {verificationStatus === 'VERIFICATION_REJECTED' && (
//             <>
//               <div className="max-w-md text-center sm:text-left">
//                 <h1 className="text-2xl font-bold text-gray-900">Your profile is rejected</h1>
//                 <p className="mt-4 text-gray-600">Click the below button to re submit your profile.</p>
//               </div>
//               <div>
//                 <img src="/Assets/verification_rejected.svg" alt="Verification Rejected" className="w-60 h-60" />
//                 <Button
//                   text="Resubmit"
//                   borderColor="border-red-600"
//                   backgroundColor="bg-[#DB0000]"
//                   textColor="text-white"
//                   onClick={handleResubmit}
//                   className="absolute top-5 right-5"
//                 />
//               </div>
//             </>
//           )}

//           {verificationStatus === 'VERIFICATION_COMPLETED' && (
//             <>
//               <div className="max-w-md text-center sm:text-left">
//                 <h1 className="text-2xl font-bold text-gray-900">Success</h1>
//                 <p className="mt-4 text-gray-600">Your profile has been verified successfully.</p>
//               </div>
//               <div>
//                 <img src="/Assets/verification_success.svg" alt="Verification Success" className="w-60 h-60" />
//                 <Button
//                   text="Next"
//                   borderColor="border-red-600"
//                   backgroundColor="bg-[#DB0000]"
//                   textColor="text-white"
//                   onClick={handleNext}
//                   className="absolute top-5 right-5"
//                 />
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default VerificationStatus;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Components/button';

const VerificationStatus = ({ verificationStatus, onClose }) => {
  const navigate = useNavigate();

  const handleResubmit = () => {
    navigate('/update-form');
  };

  const handleNext = () => {
    navigate('/');
  };

  // Return null if there is no status
  if (!verificationStatus) {
    return null;
  }

  return (
    <>
      {verificationStatus && (
        <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60 overlay"></div>
      )}

      <div className="fixed inset-0 flex items-center justify-center overlay">
        <div className="bg-[#F2F2F2] p-8 rounded-lg shadow-lg flex flex-col sm:flex-row items-center space-y-5 sm:space-y-0 sm:space-x-5 h-auto sm:h-1/2 mx-8 relative">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 flex items-center justify-center text-2xl"
          >
            &times;
          </button>

          {verificationStatus === 'VERIFICATION_PENDING' && (
            <>
              <div className="max-w-md text-center sm:text-left">
                <h1 className="text-2xl font-bold text-gray-900">Thank you for completing your profile. Your profile is under verification.</h1>
                <p className="mt-4 text-gray-600">We will let you know once the verification process is completed.</p>
              </div>
              <div>
                <img src="/Assets/profile_verification.svg" alt="Verification" className="w-60 h-60" />
              </div>
            </>
          )}

          {verificationStatus === 'VERIFICATION_REJECTED' && (
            <>
              <div className="max-w-md text-center sm:text-left">
                <h1 className="text-2xl font-bold text-gray-900">Your profile is rejected</h1>
                <p className="mt-4 text-gray-600">Click the below button to resubmit your profile.</p>
              </div>
              <div>
                <img src="/Assets/verification_rejected.svg" alt="Verification Rejected" className="w-60 h-60" />
                <Button
                  text="Resubmit"
                  borderColor="border-red-600"
                  backgroundColor="bg-[#DB0000]"
                  textColor="text-white"
                  onClick={handleResubmit}
                  className="absolute top-5 right-5"
                />
              </div>
            </>
          )}

          {verificationStatus === 'VERIFICATION_COMPLETED' && (
            <>
              <div className="max-w-md text-center sm:text-left">
                <h1 className="text-2xl font-bold text-gray-900">Your profile is completed</h1>
                <p className="mt-4 text-gray-600">More features will be available in the future updates.</p>
              </div>
              <div>
                <img src="/Assets/profile_verification.svg" alt="Profile Completed" className="w-60 h-60" />
               
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VerificationStatus;
