import React, { useState } from 'react';
import TextArea from './text_area';
import Button from './button';


const EditReplyPopup = ({ reply, onSubmit, onClose }) => {
const [updatedReply, setUpdatedReply] = useState(reply?.text || '');
    const handleUpdate = () => {
        onSubmit(updatedReply);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50 font-poppins">
            <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-lg">
                <h2 className="text-xl font-semibold mb-4">Edit Reply</h2>
                <TextArea
    value={updatedReply || ''}
    onChange={(e) => setUpdatedReply(e.target.value)}
    placeholder="Update your reply"
/>
                <div className="flex justify-end space-x-2 mt-4 space-y-1 py-1">
                <Button
    text="Cancel"
    backgroundColor="bg-transparent"  // Transparent background
    textColor="text-gray-500"         // Gray text color for Cancel
    borderColor="border-gray-500"     // Gray border for Cancel
    borderWidth="border"              // Ensure the border is visible
    onClick={onClose}
/>
<Button
    text="Update"
    backgroundColor="bg-transparent"  // Transparent background
    textColor="text-red-600"          // Red text color for Update
    borderColor="border-red-600"      // Red border for Update
    borderWidth="border"              // Ensure the border is visible
    onClick={handleUpdate}
/>

                </div>
            </div>
        </div>
    );
};

export default EditReplyPopup;
