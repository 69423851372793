// Toast.js
import toast, { Toaster } from 'react-hot-toast';

const showToast = (message, type = 'default') => {
  switch (type) {
    case 'success':
      toast.success(message);
      break;
    case 'error':
      toast.error(message);
      break;
    default:
      toast(message);
  }
};

const Toast = () => {
  return <Toaster position="top-right" reverseOrder={false} />;
};

export { showToast, Toast };
