// import React from 'react';
// import Sidebar from '../../Components/main_navbar';
// import { useNavigate } from 'react-router-dom';

 
// const Paymentmethod = () => {
//   const navigate = useNavigate();
//   const handleBackClick = () => {
//     navigate('/my_subscription');
//   };

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F2F3F3]">
//       <Sidebar />
//       <div className="w-full flex justify-start items-center space-x-2 ml-4 md:ml-[70px] mt-4 md:mt-[40px]">
//         <img
//           src="/Assets/arrow_back.svg"
//           alt="Back"
//           className="cursor-pointer h-6 w-6"
//           onClick={handleBackClick}
//         />
//         <h1 className="text-black font-bold sm:text-lg lg:text-xl">
//           Subscription Plans
//         </h1>
//       </div>
//       <div className="flex self-end lg:mr-5 md:mr-7 pr-5">
//         <img
//           src="/Assets/crown.svg" 
//           alt="crown"
//           className="h-8 w-8"
//         />
//       </div>
//       <div className="border-b border-black w-full mt-4 md:mt-[45px]"></div>
      // <div className="flex flex-col items-center mt-8">
      //   <div className="bg-[#A2A2A2] p-8 rounded-lg w-1/3 h-full p-7">
      //     <div className="mb-4">
      //       <input
      //         type="text"
      //         placeholder="Holder Name"
      //         className="w-full p-3 rounded bg-white"
      //       />
      //     </div>
      //     <div className="mb-4 flex items-center">
      //       <input
      //         type="text"
      //         placeholder="Card Number"
      //         className="w-full p-3 rounded bg-white"
              
      //       />
      //       <img src="./visa.svg" alt="Visa" className="ml-2 w-12" />
      //     </div>
      //     <div className="flex space-x-4">
      //       <input
      //         type="text"
      //         placeholder="MM/YY"
      //         className="w-1/2 p-3 rounded bg-white"
      //       />
      //       <input
      //         type="text"
      //         placeholder="CVC"
      //         className="w-1/2 p-3 rounded bg-white"
      //       />
      //     </div>
      //   </div>
      //   <div className="mt-8 flex justify-between w-96">
      //     <img src="./Paypal.svg" alt="PayPal" className="w-20" />
      //     <img src="./gpay.svg" alt="GPay" className="w-16" />
      //     <img src="./apple-pay.svg" alt="Apple Pay" className="w-16" />
      //   </div>
      // </div>
//     </div>
//   );
// };

// export default Paymentmethod;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';

const stripePromise = loadStripe('pk_test_51Oqj9IJI3AyIcueOZlXsVob324A19wJMInhlkcEnYclAQfiHoLHDcpgQvV3cr4Be7KAHSjcHCKvXnM1SD05vu5Ks00xArfrvJ3');

const SubscriptionPlans = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="flex flex-col w-full h-full bg-[#F2F3F3]">
        <div className="flex ml-7 mt-4 w-4/5">
          <Sidebar />
          <div className="w-full flex justify-start items-center space-x-2">
            <img
              src="/Assets/arrow_back.svg"
              alt="Back"
              className="cursor-pointer mr-2 h-6 w-6"
              onClick={handleBackClick}
            />
            <h1 className="text-black font-bold sm:text-lg lg:text-xl">Payment Method</h1>
          </div>
          <div className="lg:mr-5 md:mr-7 pr-5 flex self-end">
            <img
              src="/Assets/crown.svg" // Make sure this path is correct
              alt="crown"
              className="h-8 w-8"
            />
          </div>
        </div>

        <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

        <div className="flex justify-center w-4/5 lg:ml-5 mx-auto overflow-auto">
          <div className="mt-12 w-full max-w-md">
            <CheckoutForm />
          </div>
        </div>

       
      </div>
    </Elements>
  );
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });

    if (error) {
      console.error('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-[#A2A2A2] p-8 mt-7 rounded-lg w-full max-w-md shadow-md">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Holder Name"
          className="w-full p-6 rounded bg-white border border-gray-300 text-[#A2A2A2] text-base font-semibold"
        />
      </div>
      <div className="relative mb-4">
        <CardNumberElement
          className="w-full p-6 rounded bg-white border border-gray-300"
          options={cardNumberElementOptions}
        />
        <img src="/visa.svg" alt="Visa" className="absolute right-3 top-1/2 transform -translate-y-1/2 w-12" />
      </div>
      <div className="flex space-x-4 mb-4">
        <CardExpiryElement
          className="w-1/2 p-7 rounded bg-[#F2F2F2] border border-gray-300"
          options={cardElementOptions}
        />
        <CardCvcElement
          className="w-1/2 p-7 rounded bg-white border border-gray-300"
          options={cardElementOptions}
        />
      </div>
      <button type="submit" disabled={!stripe} className="mt-4 p-3 w-full rounded bg-green-500 text-white cursor-pointer">
        Pay
      </button>
      <div className="flex justify-between mt-6">
        <img src="./Paypal.svg" alt="PayPal" className="w-20" />
        <img src="./gpay.svg" alt="GPay" className="w-16" />
        <img src="./apple-pay.svg" alt="Apple Pay" className="w-16" />
      </div>
    </form>
  );
};

const cardNumberElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#A2A2A2',
      '::placeholder': {
        color: '#A2A2A2',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
  placeholder: 'Card Number',
};

const cardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#A2A2A2',
      '::placeholder': {
        color: '#A2A2A2',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export default SubscriptionPlans;
