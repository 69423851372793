import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../../Components/main_navbar';
import { FaSpinner, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../Components/toast';
import PostCard from '../../Components/post_card';
import PostDetails from '../../Components/post_details';
import { getPostByCompanyUID, deletePostById } from '../../../Controller/post_request';
import { Toast } from '../../Components/toast';
import DeletePopup from '../../Components/delete_pop_up';
import { getPostById } from '../../../Controller/post_request';

const MyPostsComponent = () => {
    const [posts, setPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [postIdToDelete, setPostIdToDelete] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [showPopup, setShowPopup] = useState(false);  // Define showPopup state
  
    const loadingRef = useRef(null);
    const popupRef = useRef(null);
    
    const navigate = useNavigate();
    const company_id = localStorage.getItem('company_id'); // Get the company ID from local storage
  
    useEffect(() => {
      fetchMyPosts();
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [currentPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loadingMore) {
          handleLoadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [hasMore, loadingMore]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const fetchMyPosts = async () => {
    try {
      setLoading(true);
      const skip = (currentPage - 1) * 5;
      const res = await getPostByCompanyUID(company_id, skip);
  
      if (Array.isArray(res)) {
        setPosts((prevPosts) => 
          currentPage === 1 ? res : [...prevPosts, ...res]
        );
        setHasMore(res.length === 5);
      } else {
        setPosts([]);
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching my posts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    if (!loadingMore && hasMore) {
      setLoadingMore(true);
      setCurrentPage((prevPage) => prevPage + 1);
      fetchMyPosts().finally(() => setLoadingMore(false));
    }
  };

  const handleViewPost = (postId) => {
    // Fetch post details and display
    getPostById(postId)
      .then((res) => {
        setSelectedPost(res);
        if (isMobile) {
          setShowPopup(true); // Show as popup only in mobile view
        }
      })
      .catch((error) => {
        console.error('Error viewing post:', error);
      });
  };

  const handleAddPost = () => {
    navigate('/post_request');
  };

  const confirmDeletePost = async () => {
    try {
      if (postIdToDelete) {
        await deletePostById(postIdToDelete);
        setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postIdToDelete));
        setSelectedPost(null);
       
      }
    } catch (error) {
      console.error('Failed to delete post:', error);
      showToast('Failed to delete post.', 'error');
    } finally {
      setShowDeletePopup(false); // Close the popup
      setPostIdToDelete(null); // Reset the post ID
    }
  };

  const handleDeletePost = (postId) => {
    setPostIdToDelete(postId); // Set the ID of the post to be deleted
    setShowDeletePopup(true); // Show the delete confirmation popup
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup && isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup, isMobile]);

  return (
    <div className="flex flex-col md:flex-row w-full h-full bg-[#FBFCF8] font-poppins overflow-y-auto relative">
      <Toast />
      <Sidebar />
      <div className="flex flex-col w-full">
        <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5 items-center justify-between">
          <h1 className="text-black font-semibold text-xl">My Posts</h1>
        </div>

        <div className="border-b border-black mt-4 md:mt-[25px] grow"></div>

        <div className="flex flex-col md:flex-row h-full relative">
          {/* Left Section with Scrollable Content */}
          <div className={`md:w-1/3 ${isMobile ? '' : 'border-r border-gray-400'} relative h-full overflow-y-auto`}>
          <div className="flex flex-col items-center p-3 md:p-9 h-full">
            {loading && posts.length === 0 ? (
              <div className="my-4">
                <FaSpinner className="animate-spin text-gray-500 text-2xl" />
              </div>
            ) : (
              <>
                {posts.length > 0 ? (
                  posts.map((post, index) => (
                    <PostCard
                      key={index}
                      post={post}
                      onView={handleViewPost}
                    />
                  ))
                ) : (
                  <div className="flex justify-center items-center h-screen">
                    <div className="text-center text-black mt-4">No Posts to show!</div>
                  </div>
                )}
                {hasMore && (
                  <div ref={loadingRef} className="my-4">
                    {loadingMore && <FaSpinner className="animate-spin text-gray-500 text-2xl" />}
                  </div>
                )}
              </>
            )}
          </div>

            {/* Floating Add Button positioned in the left section, always visible */}
            <button
              className="fixed bottom-5 right-5 lg:left-[28%] bg-red-600 rounded-full w-14 h-14 flex items-center justify-center text-white shadow-lg hover:bg-red-500 transition duration-300 z-50"
              onClick={handleAddPost}
            >
              <FaPlus size={24} />
            </button>
          </div>

          {/* Right Section - Fixed */}
          {selectedPost && !isMobile && (
            <div className="md:w-2/3 lg:w-1/2 h-full sticky top-0 p-10">
              <div className="w-full text-left">
                <PostDetails post={selectedPost} isMyPosts={true} onPostDeleted={handleDeletePost} />
              </div>
            </div>
          )}
        </div>
      </div>

      {showPopup && isMobile && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div ref={popupRef} className="bg-white p-5 rounded-lg w-11/12 max-w-md">
            <PostDetails post={selectedPost} isMyPosts={true} onPostDeleted={handleDeletePost} />
          </div>
        </div>
      )}

      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <DeletePopup 
          message="Are you sure you want to delete this post?"
          onConfirm={confirmDeletePost} // Function to confirm deletion
          onClose={() => setShowDeletePopup(false)} // Function to close popup
        />
      )}
    </div>
  );
};

export default MyPostsComponent;
