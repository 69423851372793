
import React from 'react';
import ReportContainer from './report';

const EswafHeaderLogo = () => {
  return (
    <header className="flex justify-start p-2 w-1/2 ml-8">
      <div className="header-image-container  lg:ml-12 ">
        <img 
          src='./eswaf.svg' 
          alt="Logo"
          className="header-image" 
        />
      </div>
      
    </header>
  );
};

export default EswafHeaderLogo;
