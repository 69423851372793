import React, { useState } from 'react';
import InputBox from '../../Components/input_box'; // Assuming the path to your InputBox component
import Button from '../../Components/button';
import Sidebar from '../../Components/main_navbar';
import { useNavigate } from 'react-router-dom';
import ReportContainer from '../../Components/report';

const ProductCatalog = () => {
  
  const navigate = useNavigate();
      
  const handleBackClick = () => {
    navigate('/my_subscription');
  };
  const OnClick = () => {
    navigate('/catalog_download'); 
  };
  return (
    <div className="flex flex-col w-full h-full bg-[#F2F3F3] font-techna">
      <Sidebar />
      <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
            <div className="w-full flex justify-start items-center space-x-2">
                
              <img
                src="/Assets/arrow_back.svg"
                alt="Back"
                className="cursor-pointer mr-2 h-6 w-6"
                onClick={handleBackClick}
              />
              <h1 className="text-black font-bold text-xl">Product Catalogue</h1>   </div>
              <ReportContainer/>
          </div>
      <div className="border-b border-black mt-4 md:mt-[25px] bg-[#F2F3F3] "></div>
      <div className="w-4/5 ml-10 mt-9  ">
      <div className=" mr-7 mx-auto p-4 md:p-6 lg:p-8 bg-[#D9D9D9] rounded-lg font-techna" style={{ height: '600px', width: 'full' }}>
       </div>
    </div>
    </div>
  );
};

export default ProductCatalog;
