// import React, { useState } from 'react';
// import InputBox from '../../Components/input_box';
// import ReportContainer from '../../Components/report';
// import Sidebar from '../../Components/main_navbar';
// import MapButton from '../../Components/map_button';
// import Button from '../../Components/button';
// import TextArea from '../../Components/text_area';  // TextArea component
// import { useNavigate } from 'react-router-dom';
// import { Toast, showToast } from '../../Components/toast';
// import { saveCompanyDetails } from '../../../Controller/company_address';
// import MapDialog from '../../Components/map';
// import GeminiContentGenerator from '../../Components/generate_ai';

// const CompanyAddress = () => {
//   const [address, setAddress] = useState('');
//   const [website, setWebsite] = useState('');
//   const [description, setDescription] = useState('');
//   const [latitude, setLatitude] = useState(null);
//   const [longitude, setLongitude] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [isAddressSelected, setIsAddressSelected] = useState(false); // Track if address is selected
//   const [websiteError, setWebsiteError] = useState('');
//   const [custom_address, setCustomAddress] = useState('');  // State for custom address
//   const [showMap, setShowMap] = useState(false);
//   const [descriptionError, setDescriptionError] = useState('');
//   const [addressError, setAddressError] = useState('');  // Error for primary address
//   const [customAddressError, setCustomAddressError] = useState(''); // Error for custom address
//   const navigate = useNavigate();

//   // Handle when a location is selected from the map
//   const handleSelectLocation = (locationDetails) => {
//     const { address, latitude, longitude } = locationDetails;
//     setAddress(address);
//     setLatitude(latitude);
//     setLongitude(longitude);
//     setIsAddressSelected(true); // Enable editing for the address field
//     setShowMap(false);  // Close the map dialog
//     setAddressError('');  // Clear address error once a valid address is selected
//   };

//   // Handle manual address changes after picking from map
//   const handleAddressChange = (e) => {
//     setAddress(e.target.value); // Allow user to edit the address
//   };

//   // Handle website URL input and validation
//   const handleWebsiteChange = (e) => {
//     const url = e.target.value;
//     setWebsite(url);

//     const urlPattern = new RegExp(
//       '^(https?:\\/\\/)?' + 
//       '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + 
//       '((\\d{1,3}\\.){3}\\d{1,3}))' + 
//       '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + 
//       '(\\?[;&a-z\\d%_.~+=-]*)?' + 
//       '(\\#[-a-z\\d_]*)?$',
//       'i'
//     );
//     const error = url && !urlPattern.test(url) ? 'Invalid website URL' : '';
//     setWebsiteError(error);
//   };
 
//   const handleDescriptionChange = (e) => {
//     setDescription(e.target.value);
//     if (!e.target.value.trim()) {
//       setDescriptionError('Enter a valid company description');
//     } else {
//       setDescriptionError('');
//     }
//   };

//   // Handle custom address change
//   const handleCustomAddressChange = (e) => {
//     setCustomAddress(e.target.value);
//     if (!e.target.value) {
//       setCustomAddressError('Enter the custom address');  // Show error if empty
//     } else {
//       setCustomAddressError('');  // Clear error if valid
//     }
//   };

//   // Handle saving the form
//   const handleSave = async () => {
//     let formValid = true;

//     // Check for description validity (not just spaces)
//     if (!description.trim()) {
//       setDescriptionError('Enter a valid company description');
//       formValid = false;
//     }

//     // Check for address validity (not just spaces)
//     if (!address.trim() || !latitude || !longitude) {
//       setAddressError('Enter a valid primary address');
//       formValid = false;
//     }

//     if (!custom_address.trim()) {
//       setCustomAddressError('Enter the custom address');
//       formValid = false;
//     }

//     if (!website) {
//       setWebsiteError('Enter the website URL');
//       formValid = false;
//     }

//     if (!formValid || websiteError) {
//       showToast('Please fill all the fields correctly.', 'error');
//       return;
//     }

//     setLoading(true);
//     try {
//       await saveCompanyDetails(description.trim(), website, address.trim(), latitude, longitude, custom_address.trim(), navigate, setLoading);
//       localStorage.setItem('COMPANY_DETAILS_DESCRIPTION_COMPLETED', 'true');  
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   return (
//     <div className="flex flex-col w-full h-full bg-[#F9F9F9]">
//       <Sidebar />
//       <Toast />
//       <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
//         <div className="w-full flex justify-start items-center space-x-2">
//           <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Details</h1>
//         </div>
//         <ReportContainer />
//       </div>
//       <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//       <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-1 mx-4  overflow-auto">
//         <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
//           <div className="flex items-center mt-1">
//             <img src="Assets/filedock.svg" alt="company icon" className="w-6 h-6 mr-2" />
//             <p className="font-semibold font-poppins text-sm lg:text-[16px]">Company Description</p>
//           </div>
//           <div className="mt-2 ">
//           <GeminiContentGenerator
//     value={description}
//     onChange={setDescription}
//     placeholder="Describe your company in brief"
//     minLength={150}
//     maxLength={200}
//   />
//             {descriptionError && <p className="text-[#DB0000] text-xs font-poppins mt-1">{descriptionError}</p>} {/* Show error */}
//           </div>
//           <div className="flex items-center mt-1">
//             <img src="Assets/website.svg" alt="company icon" className="w-6 h-6 mr-2" />
//             <p className="font-semibold font-poppins text-sm lg:text-[16px] ">Primary Address</p>
//           </div>
//           <div className="mt-2 ">
//             <TextArea
//               placeholder="Unit,Building Name,Street,Province,State,Country,Postal Code (e.g., 101,ABC Towers, 123 Main St, Ontario, CA, USA, 12345)"
//               value={custom_address}
//               onChange={handleCustomAddressChange}
//             />
//             {customAddressError && <p className="text-[#DB0000] text-sm font-poppins mt-1">{customAddressError}</p>} {/* Show error */}
//           </div>

//           <div className="flex items-center mt-1">
//             <img src="Assets/office_address.svg" alt="company icon" className="w-6 h-6 mr-2" />
//             <p className="font-semibold font-poppins text-sm lg:text-[16px] ">Office Location</p>
//           </div>
      
//           <div className="mt-2">
//             <TextArea
//               placeholder="Office Address"
//               borderRadius="4px"
//               className="h-18"
//               value={address}
//               onClick={() => setShowMap(true)}  // Open the map dialog on input box click
//               readOnly={true}  // Prevent manual editing, but allow selecting from the map
//             />
//             {addressError && <p className="text-[#DB0000] text-sm font-poppins mt-1">{addressError}</p>} {/* Show error */}
//           </div>

//           <div className="flex mt-1">
//             <MapButton onAddressSelect={handleSelectLocation} padding="14px" />
//           </div>

//           {showMap && (
//             <MapDialog 
//               open={showMap} 
//               onClose={() => setShowMap(false)} 
//               onSelectLocation={handleSelectLocation} 
//             />
//           )}

        

//           <div className="flex items-center mt-2">
//             <img src="Assets/website.svg" alt="company icon" className="w-6 h-6 mr-2 mb-1" />
//             <p className="font-semibold font-poppins text-sm lg:text-[16px]">Website</p>
//           </div>

//           <InputBox
//             placeholder="www.example.com"
//             borderRadius="4px"
//             value={website}
//             onChange={handleWebsiteChange}
//           />
//           {websiteError && <p className="text-[#DB0000] text-sm font-poppins mt-1">{websiteError}</p>} {/* Show error */}

//           <div className="flex justify-center items-center lg:mt-4 mt-5">
//             <Button
//               text="Save"
//               borderColor="border-red-600"
//               backgroundColor="bg-[#DB0000]"
//               textColor="text-white"
//               onClick={handleSave}
//               loading={loading}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyAddress;


import React, { useState } from 'react';
import InputBox from '../../Components/input_box';
import ReportContainer from '../../Components/report';
import Sidebar from '../../Components/main_navbar';
import MapButton from '../../Components/map_button';
import Button from '../../Components/button';
import TextArea from '../../Components/text_area';  // TextArea component
import { useNavigate } from 'react-router-dom';
import { Toast, showToast } from '../../Components/toast';
import { saveCompanyDetails } from '../../../Controller/company_address';
import MapDialog from '../../Components/map';
import GeminiContentGenerator from '../../Components/generate_ai';

const CompanyAddress = () => {
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAddressSelected, setIsAddressSelected] = useState(false); // Track if address is selected
  const [websiteError, setWebsiteError] = useState('');
  const [custom_address, setCustomAddress] = useState('');  // State for custom address
  const [showMap, setShowMap] = useState(false);
  const [descriptionError, setDescriptionError] = useState('');
  const [addressError, setAddressError] = useState('');  // Error for primary address
  const [customAddressError, setCustomAddressError] = useState(''); // Error for custom address
  const navigate = useNavigate();

  // Handle when a location is selected from the map
  const handleSelectLocation = (locationDetails) => {
    const { address, latitude, longitude } = locationDetails;
    setAddress(address);
    setLatitude(latitude);
    setLongitude(longitude);
    setIsAddressSelected(true); // Enable editing for the address field
    setShowMap(false);  // Close the map dialog
    setAddressError('');  // Clear address error once a valid address is selected
  };

  // Handle manual address changes after picking from map
  const handleAddressChange = (e) => {
    setAddress(e.target.value); // Allow user to edit the address
  };

  // Handle website URL input and validation
  const handleWebsiteChange = (e) => {
    const url = e.target.value;
    setWebsite(url);
  
    const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*\/?$/i;
    const error = url && !urlPattern.test(url) ? 'Invalid website URL' : '';
    setWebsiteError(error);
  };
  
 
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    if (!e.target.value.trim()) {
      setDescriptionError('Enter a valid company description');
    } else {
      setDescriptionError('');
    }
  };

  // Handle custom address change
  const handleCustomAddressChange = (e) => {
    setCustomAddress(e.target.value);
    if (!e.target.value) {
      setCustomAddressError('Enter the custom address');  // Show error if empty
    } else {
      setCustomAddressError('');  // Clear error if valid
    }
  };

  // Handle saving the form
  const handleSave = async () => {
    let formValid = true;
  
    // Log current state values for debugging
    console.log({
      description,
      address,
      latitude,
      longitude,
      custom_address,
      website,
      websiteError,
    });
  
    // Validate description
    if (!description.trim()) {
      setDescriptionError('Enter a valid company description');
      formValid = false;
    } else {
      setDescriptionError('');
    }
  
    // Validate primary address
    if (!address.trim() || latitude === null || longitude === null) {
      setAddressError('Enter a valid primary address');
      formValid = false;
    } else {
      setAddressError('');
    }
  
    // Validate custom address
    if (!custom_address.trim()) {
      setCustomAddressError('Enter the custom address');
      formValid = false;
    } else {
      setCustomAddressError('');
    }
  
    // Validate website
    if (!website) {
      setWebsiteError('Enter the website URL');
      formValid = false;
    } else if (websiteError) {
      formValid = false;  // If there's an existing error
    } else {
      setWebsiteError('');
    }
  
    // If the form is invalid, show the toast and exit
    if (!formValid) {
      showToast('Please fill all the fields correctly.', 'error');
      return;
    }
  
    setLoading(true);
    try {
      await saveCompanyDetails(
        description.trim(),
        website,
        address.trim(),
        latitude,
        longitude,
        custom_address.trim(),
        navigate,
        setLoading
      );
      localStorage.setItem('COMPANY_DETAILS_DESCRIPTION_COMPLETED', 'true');
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9]">
      <Sidebar />
      <Toast />
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Details</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 lg:mt-6 mt-1 mx-4  overflow-auto">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="flex items-center mt-1">
            <img src="Assets/filedock.svg" alt="company icon" className="w-6 h-6 mr-2" />
            <p className="font-semibold font-poppins text-sm lg:text-[16px]">Company Description</p>
          </div>
          <div className="mt-2 ">
          <GeminiContentGenerator
    value={description}
    onChange={setDescription}
    placeholder="Describe your company in brief"
    minLength={150}
    maxLength={200}
  />
            {descriptionError && <p className="text-[#DB0000] text-xs font-poppins mt-1">{descriptionError}</p>} {/* Show error */}
          </div>
          <div className="flex items-center mt-1">
            <img src="Assets/website.svg" alt="company icon" className="w-6 h-6 mr-2" />
            <p className="font-semibold font-poppins text-sm lg:text-[16px] ">Primary Address</p>
          </div>
          <div className="mt-2 ">
            <TextArea
              placeholder="Unit , Building Name , Street , Province , State , Country , Postal Code (e.g., 101, ABC Towers, 123 Main St , Ontario , CA , USA , 12345)"
              value={custom_address}
              onChange={handleCustomAddressChange}
            />
            {customAddressError && <p className="text-[#DB0000] text-sm font-poppins mt-1">{customAddressError}</p>} {/* Show error */}
          </div>

          <div className="flex items-center mt-1">
            <img src="Assets/office_address.svg" alt="company icon" className="w-6 h-6 mr-2" />
            <p className="font-semibold font-poppins text-sm lg:text-[16px] ">Office Location</p>
          </div>
      
          <div className="mt-2">
            <TextArea
              placeholder="Office Address"
              borderRadius="4px"
              className="h-18"
              value={address}
              onClick={() => setShowMap(true)}  // Open the map dialog on input box click
              readOnly={true}  // Prevent manual editing, but allow selecting from the map
            />
            {addressError && <p className="text-[#DB0000] text-sm font-poppins mt-1">{addressError}</p>} {/* Show error */}
          </div>

          {/* <div className="flex mt-1">
            <MapButton onAddressSelect={handleSelectLocation} padding="14px" />
          </div> */}

          {showMap && (
            <MapDialog 
              open={showMap} 
              onClose={() => setShowMap(false)} 
              onSelectLocation={handleSelectLocation} 
            />
          )}

        

          <div className="flex items-center mt-2">
            <img src="Assets/website.svg" alt="company icon" className="w-6 h-6 mr-2 mb-1" />
            <p className="font-semibold font-poppins text-sm lg:text-[16px]">Website</p>
          </div>

          <InputBox
            placeholder="www.example.com"
            borderRadius="4px"
            value={website}
            onChange={handleWebsiteChange}
          />
          {websiteError && <p className="text-[#DB0000] text-sm font-poppins mt-1">{websiteError}</p>} {/* Show error */}

          <div className="flex justify-center items-center lg:mt-4 mt-5">
            <Button
              text="Save"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSave}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAddress;