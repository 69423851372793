import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Components/button';
import { get_verified } from '../../Controller/company_details';

const VerificationPage = () => {
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkVerificationStatus = async () => {
      try {
        const response = await get_verified();
        console.log('Verification status data:', response.data);
        if (response.status === 200) {
          const status = response.data.data.verification_status;
          setVerificationStatus(status);

          if (status === 'VERIFICATION_COMPLETED') {
            navigate('/');
          } else if (status === 'VERIFICATION_PENDING') {
            navigate('/verification_page');
          }
        }
      } catch (error) {
        console.error('Error checking verification status:', error);
      } finally {
        setLoading(false);
      }
    };

    checkVerificationStatus();
  }, [navigate]);

  const handleResubmit = () => {
    navigate('/company_docs');
  };

  if (loading) {
    return null; // Remove the loading text and return null
  }

  return (
    <>
    <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60 overlay"></div>
            <div className="fixed inset-0 flex items-center justify-center overlay">
      <div className="bg-[#F2F2F2] p-8 rounded-lg shadow-lg flex flex-col sm:flex-row items-center space-y-5 sm:space-y-0 sm:space-x-5 h-auto sm:h-1/2 mx-8 relative">
        <div className="max-w-md text-center sm:text-left">
          {verificationStatus === 'VERIFICATION_REJECTED' ? (
            <>
              <h1 className="text-2xl font-bold text-gray-900">
                Your profile is rejected
              </h1>
              <p className="mt-4 text-gray-600">
                Click the below button to <br /> re submit your profile.
              </p>
            </>
          ) : (
            <>
              <h1 className="text-2xl font-bold text-gray-900">
                Your profile is under <br /> verification
              </h1>
              <p className="mt-4 text-gray-600">
                We will let you know once the verification <br /> process is completed.
              </p>
            </>
          )}
        </div>
        <div>
          {verificationStatus === 'VERIFICATION_REJECTED' ? (
            <img
              src="/Assets/verification_rejected.svg" // Change this to the rejected image path
              alt="Verification Rejected"
              className="w-60 h-60"
            />
          ) : (
            <img
              src="/Assets/profile_verification.svg"
              alt="Verification"
              className="w-60 h-60"
            />
          )}
          {verificationStatus === 'VERIFICATION_REJECTED' && (
            <Button
              text="Resubmit"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleResubmit}
              className="absolute top-5 right-5"
            />
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default VerificationPage;
