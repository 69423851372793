// categoryController.js
import { showToast } from '../View/Components/toast';
import { get_sub_companies_api } from './auth';
import { postsubcategory } from './auth';
// export const fetchCategories = async (setCategories, setCategoryMap, setLoading) => {
//   setLoading(true);
//   try {
//     const response = await get_sub_companies_api();
//     if (response.status === 200 && response.data.status_code === 200) {
//       const companies = response.data.data;
//       if (Array.isArray(companies)) {
//         const fetchedCategories = companies.map(item => item.name);
//         const categoryMap = {};
//         companies.forEach(item => {
//           categoryMap[item.name] = item.id;
//         });
//         setCategories(fetchedCategories);
//         setCategoryMap(categoryMap);
//         localStorage.setItem('fetchedCompanies', JSON.stringify(companies));
//       }
//     }
//   } catch (error) {
//     console.error("Error fetching companies:", error);
//   } finally {
//     setLoading(false);
//   }
// };
export const fetchCategories = async (setCategories, setCategoryMap, setLoading) => {
  setLoading(true);
  try {
    const response = await get_sub_companies_api();
    console.log('API Response:', response);

    if (response.status === 200 && response.data.status_code === 200) {
      const companies = response.data.data;
      if (Array.isArray(companies)) {
        const fetchedCategories = companies.map(item => item.name); // Or use id here based on the response
        const categoryMap = {};
        companies.forEach(item => {
          categoryMap[item.name] = item.id; // Store the id
        });
        setCategories(fetchedCategories);
        setCategoryMap(categoryMap);
        localStorage.setItem('fetchedCompanies', JSON.stringify(companies));
      }
    }
  } catch (error) {
    console.error("Error fetching companies:", error);
  } finally {
    setLoading(false);
  }
};

export const handleSaveCategory = async (
  selectedOption, 
  otherInputValue, 
  categoryMap, 
  navigate, 
  setLoading, 
  completed
) => {
  if (!selectedOption) {
    showToast('Please select an option', 'error');
    setLoading(false);
    return;
  }

  setLoading(true);

  if (selectedOption === 'other') {
    if (!otherInputValue.trim()) {
      showToast('Please enter a value for Other', 'error');
      setLoading(false);
      return;
    }

    const category = localStorage.getItem('category');
    const payload = {
      category,
      sub_category_name: otherInputValue.trim(),
    };

    try {
      const response = await postsubcategory(payload);

      if (response.status === 200 || response.data.status_code === 201) {
        const newSubCategoryId = response.data.id;
        localStorage.setItem('sub_category', newSubCategoryId);
        localStorage.setItem('sub_category', otherInputValue.trim());
        
        // Stop loading and navigate to the next page
        setLoading(false);
        navigate('/area-of-operation', { state: { completed }, replace: true });

        // Add custom state to handle back button behavior
        window.history.pushState(null, '', '/area-of-operation');

        // Handle back button behavior
        const handleBackButton = () => {
          const confirmResult = window.confirm('Are you sure you want to go back? You will be redirected to the login page.');
          if (confirmResult) {
            navigate('/login', { replace: true });
          } else {
            // Prevent default back behavior by pushing a new state
            window.history.pushState(null, '', window.location.pathname);
          }
        };

        // Add event listener for back button
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', handleBackButton);

        // Cleanup event listener
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error in postsubcategory:', error);
      showToast('An error occurred while saving the subcategory', 'error');
      setLoading(false);
    }
  } else {
    const selectedId = categoryMap[selectedOption];
    localStorage.setItem('sub_category', selectedId);
    
    // Stop loading and navigate to the next page
    setLoading(false);
    navigate('/area-of-operation', { state: { completed }, replace: true });

    // Add custom state to handle back button behavior
    window.history.pushState(null, '', '/area-of-operation');

    // Handle back button behavior
    // const handleBackButton = () => {
    //   const confirmResult = window.confirm('Are you sure you want to go back? You will be redirected to the login page.');
    //   if (confirmResult) {
    //     navigate('/login', { replace: true });
    //   } else {
    //     // Prevent default back behavior by pushing a new state
    //     window.history.pushState(null, '', window.location.pathname);
    //   }
    // };

    // // Add event listener for back button
    // window.history.pushState(null, '', window.location.pathname);
    // window.addEventListener('popstate', handleBackButton);

    // // Cleanup event listener
    // return () => {
    //   window.removeEventListener('popstate', handleBackButton);
    // };
  }
};
