import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';

const VerificationSuccess = () => {
  const location = useLocation();
  const isVerified = location.pathname.includes('verified');

  const handleBackClick = () => {
    
  };

  return (
    <>
      <div className="h-full w-full  flex flex-col ">
      <Sidebar />
      <div className="flex  ml-7 mt-4 md:mt-[40px] w-4/5">
            <div className="w-full flex justify-start items-center space-x-2">
                
              <img
                src="/Assets/arrow_back.svg"
                alt="Back"
                className="cursor-pointer mr-2 h-6 w-6"
                onClick={handleBackClick}
              />
              <h1 className="text-black font-bold text-xl"> {isVerified ? 'Verified Profile' : 'Verification'}</h1>   </div>
          </div>
     
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>


      <div className="w-4/5 ml-10 overflow-y-auto">
      <div className="max-w-full  md:max-w-lg lg:max-w-xl mx-auto p-4 md:p-6 lg:p-8 bg-[#F9F9F9] rounded-lg font-techna mt-5" style={{ height: '550px', width: '450px' }}>
      
        <div className='flex justify-center '>
        <img src="Assets/phone_otp.svg" alt="company icon" className="w-60 h-72 mt-9"/>
        </div>
        <div className='flex justify-center space-x-3 mt-3 ' >
        <img src="./tick.svg"  className='w-7 h-7'></img>
        <p className="font-bold text-lg">Verified</p>
        </div>
        
     <div className="flex justify-center mt-16">
        <Button
          text="Next"
          borderColor="border-red-600"
          backgroundColor="bg-[#DB0000]"
          textColor="text-white"
          navigateTo="/details_page"
        />
        </div>
        
      </div>
    </div>
        
      </div>
    </>
  );
};

export default VerificationSuccess;
