import React from 'react';
import './multi_select.css';

const MultiSelect = ({ onChange, selectedOptions }) => {
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    let updatedOptions = [...selectedOptions];

    if (value === 'Both') {
      // When 'Both' is selected, select both Domestic and International without ticking them separately
      if (selectedOptions.includes('Both')) {
        updatedOptions = [];
      } else {
        updatedOptions = ['Domestic', 'International'];
      }
    } else {
      // Handle Domestic and International separately
      if (value === 'Domestic') {
        if (selectedOptions.includes('Domestic')) {
          updatedOptions = updatedOptions.filter(opt => opt !== 'Domestic');
        } else {
          updatedOptions = ['Domestic'];
        }
      }

      if (value === 'International') {
        if (selectedOptions.includes('International')) {
          updatedOptions = updatedOptions.filter(opt => opt !== 'International');
        } else {
          updatedOptions = ['International'];
        }
      }
    }

    // Update state
    onChange(updatedOptions);
    // Store only Domestic or International in local storage, no need to store 'Both'
    localStorage.setItem('area_of_operation', JSON.stringify(updatedOptions));
  };

  return (
    <div className="flex flex-col items-start">
      {/* Show Domestic and International checkboxes */}
      <label className="flex items-center mb-4 cursor-pointer">
        <input
          type="checkbox"
          value="Domestic"
          checked={selectedOptions.includes('Domestic') && !selectedOptions.includes('International')}
          onChange={handleCheckboxChange}
          className="form-checkbox text-red-500 h-4 w-4 font-helvetica"
        />
        <span className="ml-2 text-[15px] lg:text-lg font-gotham font-light">Domestic</span>
      </label>
      <label className="flex items-center mb-4 cursor-pointer">
        <input
          type="checkbox"
          value="International"
          checked={selectedOptions.includes('International') && !selectedOptions.includes('Domestic')}
          onChange={handleCheckboxChange}
          className="form-checkbox text-red-500 h-4 w-4"
        />
        <span className="ml-2 text-[15px] lg:text-lg font-gotham font-light">International</span>
      </label>

      {/* Always show the Both checkbox */}
      <label className="flex items-center cursor-pointer">
        <input
          type="checkbox"
          value="Both"
          checked={selectedOptions.includes('Domestic') && selectedOptions.includes('International')}
          onChange={handleCheckboxChange}
          className="form-checkbox text-red-500 h-4 w-4"
        />
        <span className="ml-2 text-[15px] lg:text-lg font-gotham font-light">Both</span>
      </label>
    </div>
  );
};

export default MultiSelect;
