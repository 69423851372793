import React from 'react';
import Image2 from '../../Components/login_image2.jsx';
import PasswordInputField from '../../Components/password-input-field.jsx';
import Button from '../../Components/button.jsx';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import InputBox from '../../Components/input_box.jsx';
import { ACCESS_TOKEN } from '../../../Controller/Constants/constants.js';
import { REFRESH_TOKEN } from '../../../Controller/Constants/constants.js';
import { check_email } from '../../../Controller/auth.js';
import { showToast } from '../../Components/toast.jsx';
import LoaderSpinner from '../../Components/loader_spinner.jsx';
import { resetPasswordMail } from '../../../Controller/auth.js';
import CenteredImageContainer from '../../Components/black_container.jsx';


import { sendResetPasswordEmail } from '../../../Controller/reset_password_mail_controller.js';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    sendResetPasswordEmail(email, navigate, setLoading); // Call the controller function
  };

  return (
    <div className="h-full w-full flex bg-[#FBFCF8]">
      <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
        <div className='w-4/5 h-full'> 
          <CenteredImageContainer imgSrc="Assets/logo-eswaf.svg" />
        </div>
      </div>
      <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-7 font-poppins">
        <div className="p-6 w-full max-w-2/5 space-y-5">
          <div className="flex items-center mb-6">
            <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
          </div>
          <h1 className="text-2xl font-poppins font-semibold mb-4 text-[#171717]">Login Help</h1>
          <form className="flex flex-col space-y-7" onSubmit={handleSignUp}>
            <div className="space-y-3">
              <p className="text-base font-poppins font-semibold text-[#171717]">Find your Account</p>
              <p className="text-sm font-poppins text-[#171717]">Enter the Email linked to your Account</p>
            </div>
            <InputBox
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              borderRadius="4px"
              className="placeholder-gray-700 font-helvetica h-full"
              border="2px solid rgba(162, 162, 162, 1)"
              padding="10px"
            />
            <div className="">
              <Button
                text={loading ? <LoaderSpinner visible={loading} /> : "Next"}
                backgroundColor="bg-custom-button-color"
                textColor="text-white"
                type="submit"
                loading={loading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
