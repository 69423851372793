import React from 'react';

const SharePopup = ({ onClose }) => {
    return (
        <>
        <div className="fixed inset-0 flex items-center justify-center bg-black opacity-60"></div>
        <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white rounded-lg p-4 w-80">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-bold text-black">Share To</h2>
                    <button onClick={onClose} className="text-3xl font-bold text-black">&times;</button>
                </div>
                <ul>
                    <li className="flex items-center mb-2">
                        <img src="/linkedin.svg" alt="LinkedIn" className="w-12 h-12 mr-2" />
                        <span>Share to LinkedIn</span>
                    </li>
                    <li className="flex items-center mb-2">
                        <img src="/instagram.svg" alt="Instagram" className="w-12 h-12 mr-2" />
                        <span>Share to Instagram</span>
                    </li>
                    <li className="flex items-center mb-2">
                        <img src="/facebook.svg" alt="Facebook" className="w-12 h-12 mr-2" />
                        <span>Share to Facebook</span>
                    </li>
                    <li className="flex items-center mb-2">
                        <img src="/whatsapp.svg" alt="WhatsApp" className="w-12 h-12 mr-2" />
                        <span>Share to WhatsApp</span>
                    </li>
                    <li className="flex items-center">
                        <img src="/x.svg" alt="X" className="w-12 h-12 mr-2" />
                        <span>Share to X</span>
                    </li>
                </ul>
            </div>
        </div>
        </>
    );
};

export default SharePopup;
