import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GoldSupplier from '../../Components/gold_supplier';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import { get_subscriptions_api, post_user_payments_api } from '../../../Controller/subscriptions';
import { FaSpinner } from 'react-icons/fa';
import { showToast } from '../../Components/toast';
const SubscriptionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  let product_id = state?.product_id || sessionStorage.getItem('product_id');
  const backgroundColor = state?.backgroundColor || "#DB0000";

  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paying, setPaying] = useState(false);

  useEffect(() => {
    const fetchPlanDetails = async () => {
      try {
        const response = await get_subscriptions_api();
        if (response.status === 200) {
          const selectedPlan = response.data.data.find(p => p.product_id === product_id);
          setPlan(selectedPlan);
        }
      } catch (error) {
        console.error('Error fetching subscription details:', error);
        showToast('Failed to fetch subscription details.', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchPlanDetails();
  }, [product_id]);

  const handlePayNowClick = async () => {
    if (!product_id) {
      // Show toast notification if product_id is not available
      showToast('Product ID not found. Please select a valid product.', 'error');
      return;
    }

    setPaying(true);
    try {
      const paymentResponse = await post_user_payments_api(product_id);
      if (paymentResponse.status === 200) {
        const checkoutUrl = paymentResponse.data.data.url;
        window.location.href = checkoutUrl;
      } else {
        console.error('Failed to post user payment');
      }
    } catch (error) {
      console.error('Error posting user payment:', error);
    } finally {
      setPaying(false);
    }
  };

  // Render loading spinner
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="text-xl animate-spin" />
      </div>
    );
  }

  // Determine text color based on plan name
  const isMonthlySupplier = plan?.name?.trim().toLowerCase() === 'monthly';
  const textColor = isMonthlySupplier ? '#171717' : '#F9F9F9';

  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="cursor-pointer mr-2 h-6 w-6"
            onClick={() => navigate('/subscription_plans')}
          />
          <h1 className="text-black font-bold sm:text-lg lg:text-xl">Subscription</h1>
        </div>
        <div className="mr-5 md:mr-7 pr-5 flex self-end">
          <img
            src="/Assets/crown.svg"
            alt="crown"
            className="h-8 w-8"
          />
        </div>
      </div>
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
      <div className="flex justify-center w-4/5 lg:ml-5 mx-auto overflow-auto">
        <div className="w-full md:w-[550px] bg-[#F0F0F0] mt-7">
          {plan && (
            <GoldSupplier
              backgroundColor={backgroundColor}
              textColor={textColor}
              title={plan.name}
              subtitle="Standard Extended"
              price={`${plan.price}$`}
              height="150px"
              width="full"
            />
          )}
         
          {/* Subscription features */}
          <ul className="font-normal mt-4 ml-7">
            <li className="mb-3">Exclusive Discounts</li>
            <li className="mb-3">Free Shipping</li>
            <li className="mb-3">Early Access to Sales</li>
            <li className="mb-3">Personalized Recommendations</li>
            <li className="mb-3">Dedicated Customer Support</li>
            <li className="mb-3">Ad-Free Experience</li>
            <li className="mb-3">Content Access</li>
            <li className="mb-3">Early Product Testing</li>
          </ul>

          <a
            href="#"
            className="text-[#DB0000] font-light text-[16px] leading-[19.06px] underline ml-7 mt-3"
          >
            More Information
          </a>

          <div className="lg:mx-16 mt-5">
            <Button
              text="Pay Now"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handlePayNowClick}
              loading={paying}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetails;
