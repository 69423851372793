import React from 'react';
import { useNavigate } from 'react-router-dom';

const HelpSupport = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8]">
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-normal sm:text-lg lg:text-xl font-techna">Help & Support - eSwaF by eMartApp</h1>
        </div>
      </div>
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

      <div className="flex flex-col gap-4 md:gap-[25px] p-6 items-center w-full overflow-y-auto">
        
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px]">
          <h2 className="text-black font-bold text-lg">Welcome to eSwaF Support!</h2>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            We're here to help you. Find answers to common issues and get assistance as needed.
          </p>
        </div>

        {/* Section 1: Getting Started */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px]">
          <h2 className="text-black font-bold text-lg">1. Getting Started</h2>
          <h3 className="text-black font-semibold text-md mt-2">Download and Installation</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>iOS: Open the App Store, search for "eSwaF," and tap "Download."</li>
            <li>Android: Open Google Play Store, search for "eSwaF," and tap "Install."</li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Account Setup</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>Create Account: Open the app, tap "Sign Up," and follow the instructions.</li>
            <li>Verify Email: Check your email and verify your account using the provided link.</li>
          </ul>
        </div>

        {/* Section 2: Troubleshooting */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px]">
          <h2 className="text-black font-bold text-lg">2. Troubleshooting</h2>
          <h3 className="text-black font-semibold text-md mt-2">App Crashes or Not Opening</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>Restart Device: Restart your device to clear temporary issues.</li>
            <li>Update App: Check for updates in the App Store or Google Play Store.</li>
            <li>Reinstall: Uninstall and reinstall the app if problems persist.</li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Login Issues</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>Forgot Password: Tap "Forgot Password" and follow the reset instructions.</li>
            <li>Locked Account: Contact support for help with a locked account.</li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Payment Issues</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>Transaction Problems: Verify payment details and try again. Contact support for unresolved issues.</li>
            <li>Refunds: Review our [Refund Policy] and request a refund if eligible.</li>
          </ul>
        </div>

        {/* Section 3: Features and Usage */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px]">
          <h2 className="text-black font-bold text-lg">3. Features and Usage</h2>
          <h3 className="text-black font-semibold text-md mt-2">Navigating the App</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>Overview: Use the home screen to access main features. Refer to in-app guides for details.</li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Settings</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>Account Settings: Update personal information and preferences under "Settings."</li>
            <li>App Preferences: Customize themes, language, and other settings.</li>
          </ul>
        </div>

        {/* Section 4: Contact Support */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px]">
          <h2 className="text-black font-bold text-lg">4. Contact Support</h2>
          <h3 className="text-black font-semibold text-md mt-2">Reach Us</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>Email: <a href="mailto:support@eSwaF.com" className="text-blue-500">support@eSwaF.com</a></li>
            <li>Live Chat: Available in the app from 10am to 4pm PDT – Time zone.</li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Response Times</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>Email: Typically within 24-48 hours.</li>
            <li>Live Chat: Live Chat Hours - 10am to 4pm PDT – Time zone.</li>
          </ul>
        </div>

        {/* Section 5: Privacy and Security */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px]">
          <h2 className="text-black font-bold text-lg">5. Privacy and Security</h2>
          <h3 className="text-black font-semibold text-md mt-2">Data Protection</h3>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            Privacy: We safeguard your data. See our [Privacy Policy] for details.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">Security Tips</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>Strong Passwords: Use unique, strong passwords.</li>
            <li>Two-Factor Authentication: Enable this for added security.</li>
          </ul>
        </div>

        {/* Section 6: Legal Information */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px]">
          <h2 className="text-black font-bold text-lg">6. Legal Information</h2>
          <h3 className="text-black font-semibold text-md mt-2">Terms of Service</h3>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            Agreement: By using the app, you agree to our [Terms of Service].
          </p>
          <h3 className="text-black font-semibold text-md mt-2">Privacy Policy</h3>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            Details: Read our [Privacy Policy] to understand how we handle your data.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">Liability</h3>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            Disclaimer: We are not liable for indirect or consequential damages. The app is provided "as is."
          </p>
          <h3 className="text-black font-semibold text-md mt-2">Dispute Resolution</h3>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            Arbitration: Disputes are resolved through arbitration as per our [Arbitration Policy] in terms and conditions.
          </p>
        </div>

        {/* Section 7: FAQ */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px]">
          <h2 className="text-black font-bold text-lg">7. FAQ</h2>
          <h3 className="text-black font-semibold text-md mt-2">General</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>What is eSwaF?: "A Business Focused platform that connects your Brand, Service, or Business to the World — Everything Electronically."</li>
            <li>Updating the App: Updates are available in the App Store.</li>
          </ul>
          <h3 className="text-black font-semibold text-md mt-2">Technical</h3>
          <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>App Not Responding: Refer to troubleshooting steps in section 2.</li>
          </ul>
        </div>

        {/* Section 8: Feedback and Suggestions */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px]">
          <h2 className="text-black font-bold text-lg">8. Feedback and Suggestions</h2>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            Share your feedback at  [support@eSwaF.com].
          </p>
        </div>

        {/* Section 9: Additional Support */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px]">
          <h2 className="text-black font-bold text-lg">9. Additional Support</h2>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
           Contact us at [support@eSwaF.com] for more help.
          </p>
        </div>

        {/* Final Statements */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px]">
  <ul className="list-disc list-inside text-[#171717] mt-2 space-y-2">
    <li className="text-[14px] lg:text-[16px]">
      This version is direct and easy to navigate, ensuring users can quickly find the information they need.
    </li>
    <li className="text-[14px] lg:text-[16px]">
      By using our app, you acknowledge that you have read, understood, and agreed to be bound by these Terms and Conditions.
    </li>
    <li className="text-[14px] lg:text-[16px]">
      These Terms and Conditions constitute the entire agreement between you and eSwaF by eMartApp.
    </li>
    <li className="text-[14px] lg:text-[16px]">
      We reserve the right to modify or update these Terms and Conditions at any time.
    </li>
  </ul>
</div>

      </div>
    </div>
  );
};

export default HelpSupport;
