// resetPasswordController.js
import { showToast } from '../View/Components/toast';
import { resetPasswordOtp } from './auth';
export const verifyOtpAndNavigate = async (email, otp, navigate, setLoading) => {
  if (!otp) {
    showToast('Please enter the OTP', 'error');
    return;
  }

  setLoading(true);

  try {
    const res = await resetPasswordOtp(email, otp);

    if (res.status === 200) {
      const secret = res.data.data.secret;
      navigate('/reset-password-page', { state: { secret, email } });
    } else {
      showToast('Failed to verify OTP', 'error');
    }
  } catch (error) {
    console.error('Error resetting password', error);
    showToast('Error verifying OTP', 'error');
  } finally {
    setLoading(false);
  }
};
