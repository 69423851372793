import { api } from "./api";
import { DELETE_COMPLAINT, GET_COMPLAINTS_ADDED_MYSELF } from "./urls";
import { GET_COMPLAINTS_AGAINST_ME } from "./urls";
import { POST_COMPLAINT } from "./urls";
import { POST_REPLY } from "./urls";
import { DELETE_REPLY } from "./urls";
import { GET_COMPLAINT } from "./urls";
import { UPDATE_COMPLAINT } from "./urls";
const getComplaintsAddedMyself = async (skip, limit) => {
    try {
        // Passing skip and limit as query parameters
        const res = await api.get(GET_COMPLAINTS_ADDED_MYSELF, { 
            params: { skip, limit },
        });
        return res; 
    } catch (error) {
        console.error("Error fetching complaints added by myself:", error);
        throw error;
    }
};
const getComplaintsAgainstCompany = async (company_uid, skip, limit,other_profile = false) => {
    try {
        // Passing company_uid, skip, and limit as query parameters
        const res = await api.get(GET_COMPLAINTS_AGAINST_ME(company_uid), { 
            params: { skip, limit ,other_profile},
        });
        return res; 
    } catch (error) {
        console.error("Error fetching complaints against company:", error);
        throw error;
    }
};



const getComplaintById = async (complaintId) => {
    
    try {
        const res = await api.get(`/complaints/${complaintId}`); // Adjust the endpoint as per your API
        return res;
    } catch (error) {
        console.error("Error fetching complaint by ID:", error);
        throw error;
    }
};

const deleteComplaintById = async (id) => {
    try {
       
        const res = await api.delete(DELETE_COMPLAINT(id)); // Use POST_REPLY(id) to get the correct URL
        return res;
    } catch (error) {
        console.error("Error deleting  reply:", error);
        throw error;
    }
};




const uploadComplaint = async (complaint_to, product_name, title, description, confirmation_document, image_url = []) => {
    try {
        const payload = {
            product_name: product_name, 
            title: title,
            description: description,
            confirmation_document: confirmation_document,
            image_url: image_url,
        };
      
        const res = await api.post(`${POST_COMPLAINT}?complaint_to=${complaint_to}`, payload);
        return res;
    } catch (error) {
        console.error("Error submitting complaint:", error);
        throw error;
    }
};
  
  



const updateComplaint = async (id, complaintData) => {
    try {
        const res = await api.put(`${UPDATE_COMPLAINT(id)}`, complaintData);
        return res;
    } catch (error) {
        console.error("Error updating complaint:", error);
        throw error;
    }
};


const postReply = async (id, reply) => {
    try {
        const payload = {
            reply: reply
        };

        const res = await api.post(POST_REPLY(id), payload); // Use POST_REPLY(id) to get the correct URL
        return res;
    } catch (error) {
        console.error("Error posting reply:", error);
        throw error;
    }
};
const deleteReply = async (id, reply) => {
    try {
        const payload = {
            reply: reply
        };

        const res = await api.post(DELETE_REPLY(id), payload); // Use POST_REPLY(id) to get the correct URL
        return res;
    } catch (error) {
        console.error("Error deleting  reply:", error);
        throw error;
    }
};

export {getComplaintsAddedMyself,getComplaintsAgainstCompany,uploadComplaint,postReply,deleteReply,getComplaintById,deleteComplaintById,updateComplaint}